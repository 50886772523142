import React, { useEffect, useState } from 'react'
import { ButtonHeader } from './AfaCartHeader'
import { useGetAfaCartProducts } from '../../hooks/useGetAfaCartProducts'
import { useGetCartDropsQuery } from '../../services/afaProduct'
import { useDispatch, useSelector } from 'react-redux'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import { useTranslation } from 'react-i18next'
import { successNotification } from '../../components/Notification/notifications'
import { OUT_OF_ASSORTMENT, EXPIRED_PRODUCTS, UNAVAILABLE_KEYS } from './AfaCartContent'
import { currentNotificationsListSelector } from '../../store/afaCartNotifications/selectors'
import afaCartOutOfAssortmentActions from '../../store/afaCartOutOfAssortment/actions'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'
import useAfaGetCartUnavailableKeys from '../../hooks/useAfaGetCartUnavailableKeys'
import { getAllUnavailableCartProductsFromKeys } from '../../libs/afa'

type Props = {
  className?: string
}

const CheckAdjustButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isChecking, setIsChecking] = useState(false)
  const { cartProducts } = useGetAfaCartProducts()
  const dropsQuery = useGetCartDropsQuery()
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()
  const currentNotificationsList = useSelector(currentNotificationsListSelector)
  const { unavailableKeys } = useAfaGetCartUnavailableKeys()
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    if (isDisabled && !dropsQuery.isLoading && !dropsQuery.isFetching && dropsQuery.data) {
      setTimeout(() => {
        setIsDisabled(false)
      }, 500)
    }

    if (isChecking && !dropsQuery.isLoading && !dropsQuery.isFetching && dropsQuery.data) {
      setIsChecking(false)

      switch (true) {
        case currentNotificationsList.includes(OUT_OF_ASSORTMENT):
          dispatch(afaCartOutOfAssortmentActions.setIsOpen(true))
          break

        case currentNotificationsList.includes(EXPIRED_PRODUCTS):
          dispatch(afaCartAdjustActions.setIsManageQuantities('expired'))
          dispatch(afaCartAdjustActions.setCartProducts(expiredDropsCartProducts))
          dispatch(afaCartAdjustActions.setIsOpen(true))
          break

        case currentNotificationsList.includes(UNAVAILABLE_KEYS):
          dispatch(
            afaCartAdjustActions.setCartProducts(
              getAllUnavailableCartProductsFromKeys(cartProducts, unavailableKeys),
            ),
          )
          dispatch(afaCartAdjustActions.setIsOpen(true))
          break

        default:
          successNotification({
            message: t('Afa.Cart.Adjust.NotNeededText'),
          })
          break
      }
    }
  }, [
    cartProducts,
    expiredDropsCartProducts,
    currentNotificationsList,
    dispatch,
    dropsQuery,
    isChecking,
    t,
    unavailableKeys,
    isDisabled,
  ])

  return (
    <ButtonHeader
      className={`${className ?? ''} ${isChecking ? 'loading' : ''}  ${
        isDisabled ? 'disabled' : ''
      }`}
      onClick={() => setIsChecking(true)}
    >
      {t('Afa.checkAdjust')}
    </ButtonHeader>
  )
}

export default CheckAdjustButton
