import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { usernameSelector } from '../../../../store/auth/selectors'
import { getFluidFontSize, getFluidSize, palette, pxToRem, spacing } from '../../../../style/theme'
import RCIcon from '../../../../components/UI/RCIcon'

/////////TYPES////////////
type StyledCustomIconandtextButton = {
  icon?: string
  text: string
  textFirst?: boolean
  onClick?: () => void
  className?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

type StyledLiCheck = {
  handleFromULchecksComponent: (quantity: number, isChecked: boolean) => void
  element: number
}

type StyledULchecksComponent = {
  handleQuantityFilterFromProcessComponent: (arrFromQuantityList: number[]) => void
  checksElements: number[]
}

export const icons = {
  copy: 'fas fa-copy',
  clipboard: 'fas fa-clipboard',
  select: 'fa fa-plus',
  upload: 'fas fa-upload',
  download: 'fas fa-download',
  arrowDown: 'fas fa-chevron-down',
  arrowUp: 'fas fa-chevron-up',
}

/////////STYLED////////////
export const CartProductsListRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 13fr 6fr 5fr 22fr 5fr 16fr 13fr 10fr;

  &.notProcessed {
    grid-template-columns: 13fr 6fr 27fr 5fr 16fr 13fr 10fr;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  flex-direction: row-reverse;
  background: ${palette.white};
  color: ${palette.bermudaGray};
  height: 2em;
`

export const LabelInput = styled.label`
  position: absolute;
  color: ${palette.gray};
  pointer-events: none;

  ${StyledInput}:focus ~ & {
    display: none;
  }

  &.none {
    display: none;
  }
`

export const StyledInputFilter = styled.input`
  padding: ${pxToRem(spacing(1))}rem;
  width: 100%;
  border: 0.9px solid ${palette.alto};
  outline: none;
  flex-direction: row-reverse;
  height: 36.3px;

  ::-webkit-calendar-picker-indicator {
    margin-left: 0;
  }

  ::-webkit-datetime-edit-fields-wrapper {
    text-transform: uppercase;
  }
`

export const StyledTableTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${getFluidFontSize('14px')};
  background-color: ${palette.linkWater};
  padding: ${pxToRem(spacing(2.5))}rem;
`

export const StyledTableWrapper = styled.div`
  border-collapse: collapse;
  width: 100%;
  background-color: ${palette.white};

  tr {
    background-color: ${palette.white};
  }

  td {
    font-size: ${getFluidFontSize('12px')};
    padding: ${pxToRem(spacing(2))}rem;
  }
`

export const StyledTableTh = styled.th`
  position: sticky;
  top: 0;
  color: ${palette.hibiscus};
  background-color: ${palette.white};
  text-transform: uppercase;
  font-size: ${getFluidFontSize('12px')};
  padding: ${pxToRem(spacing(2))}rem;

  &.thButton {
    width: 10px;
  }
`

/**
 * A checkbox element with internal state
 * @param {boolean} isCheckForStyle (require) - Enable/disable the flag of checkbox
 * @param {string} borderColor (optional)
 * @param {string} accentColor (optional)
 * @returns A checkbox element with internal state
 */
export const StyledCheckBox = styled.input.attrs({
  type: 'checkbox',
})<{
  checked: boolean
  borderColor?: string
  accentColor?: string
}>`
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: ${palette.white};
  width: ${pxToRem(spacing(2.5))}rem;
  height: ${pxToRem(spacing(2.5))}rem;
  border: ${pxToRem(spacing(0.2))}rem solid ${palette.viking};
  border-radius: ${pxToRem(spacing(0.4))}rem;
  border-color: ${({ borderColor }) => borderColor ?? palette.viking};
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: ${pxToRem(spacing(1.5))}rem;
    height: ${pxToRem(spacing(1.5))}rem;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(${({ checked }) => (checked ? 1 : 0)});
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset ${pxToRem(spacing(2))}rem ${pxToRem(spacing(2))}rem;
  }

  &:checked {
    background: ${({ accentColor }) => accentColor ?? palette.viking};
    border-color: ${({ accentColor }) => accentColor ?? palette.viking};
  }
`

export const StyledDivLikeInputField = styled.div<{ hide?: boolean }>`
  border: ${pxToRem(spacing(0.5 / 10))}rem solid;
  border-radius: ${pxToRem(spacing(0.5))}rem;
  border-color: ${palette.geyser};
  height: ${pxToRem(spacing(4))}rem;
  display: ${props => (props.hide ? 'inline-table' : 'none')};
  position: ${props => (props.hide ? 'absolute' : 'unset')};
  background-color: ${palette.white};
  padding: ${pxToRem(spacing(0))}rem;
`

/**
 * A simple customizable styled button
 * @param {number} borderRadius (optional)
 * @param {string} borderColor (optional)
 * @param {string} color (optional)
 * @param {string} backgroundColor (optional)
 * @returns A simple customizable styled button
 */
export const StyledButton = styled.button<{
  borderRadius?: number
  borderColor?: string
  color?: string
  backgroundColor?: string
}>`
  border: ${pxToRem(spacing(0.5 / 10))}rem solid;
  border-radius: ${props =>
    props.borderRadius
      ? `${pxToRem(spacing(props.borderRadius))}rem`
      : `${pxToRem(spacing(0.5))}rem`};
  border-color: ${props => (props.borderColor ? props.borderColor : `${palette.geyser}`)};
  color: ${props => (props.color ? props.color : `${palette.congressBlue}`)};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : `${palette.white}`};
  height: ${pxToRem(spacing(4))}rem;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  padding: ${pxToRem(spacing(1))}rem;
  align-items: center;
  align-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 1rem;
  font-size: ${pxToRem(spacing(1.5))}rem;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`
export const CustomInput = ({
  type,
  placeholder,
  onChange,
  value,
  onBlur,
  ...props
}: React.HTMLProps<HTMLInputElement> & {
  as?: undefined
}) => {
  return (
    <StyledInput
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      {...props}
    />
  )
}

export const CustomInputFilter = ({ ...obj }) => {
  const { type, placeholder, onChange, value } = obj
  return (
    <StyledInputFilter type={type} placeholder={placeholder} onChange={onChange} value={value} />
  )
}

/**
 * A styled button with a <span> with "text" param value and <i> with "icon" param value (see "order" param)
 * @param {string} icon - The reference to icon class
 * @param {string} text - The text of button
 * @param {string} order (optional):
 *  Write "icon-text" to render first icon then text.
 *  Write "text-icon" to render first text then icon.
 *  The default setting is "icon-text"
 * @returns A button with a span with "text" param value and i html element with "icon" param value (or reverse by order param)
 */
export const Icon = styled.i<{
  isActive?: boolean
  color?: string
}>`
  color: ${props => props.color && props.color};
  cursor: pointer;
`
export const CustomIconAndTextButton = ({
  icon,
  text,
  textFirst = false,
  onClick,
  className,
  type,
  disabled,
}: StyledCustomIconandtextButton) => {
  const elements = [icon && <RCIcon key="icon" type={icon} />, <span key="text">{text}</span>]

  textFirst && elements.reverse()

  return (
    <StyledButton type={type} className={className} onClick={onClick} disabled={disabled}>
      {elements}
    </StyledButton>
  )
}

const UlChecks = styled.ul`
  list-style: none;
  padding-left: ${pxToRem(spacing(0))}rem;
  margin: ${pxToRem(spacing(0))}rem;
  color: ${palette.black};
  font-weight: bold;
`
const UlChecksLi = styled.li`
  display: flex;
  gap: ${pxToRem(spacing(1))}rem;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: ${pxToRem(spacing(1))}rem;
  border-bottom: ${pxToRem(spacing(0.1))}rem solid;
  border-color: ${palette.geyser};
`
const UlChecksApplyFilter = styled.div`
  display: flex;
  justify-content: center;
  padding: ${pxToRem(spacing(1))}rem;
  background-color: ${palette.geyser};
`
const LIchecks = ({ handleFromULchecksComponent, element }: StyledLiCheck) => {
  const [isCheck, setIsCheck] = useState(false)
  useEffect(() => {
    handleFromULchecksComponent(element, isCheck)
  }, [isCheck, element, handleFromULchecksComponent])
  return (
    <>
      <StyledCheckBox onClick={() => setIsCheck(!isCheck)} checked={isCheck} />
      <span>{element}</span>
    </>
  )
}
export const ULchecksComponent = ({
  handleQuantityFilterFromProcessComponent,
  checksElements,
}: StyledULchecksComponent) => {
  const [arrChecked, setArrChecked] = useState<number[]>([])
  const handleFromULchecksComponent = useCallback(
    (quantity: number, isChecked: boolean) => () => {
      isChecked
        ? arrChecked.push(quantity)
        : setArrChecked(arrChecked.filter(element => element !== quantity))
    },
    [arrChecked],
  )
  return (
    <UlChecks>
      {checksElements.map(element => {
        return (
          <UlChecksLi key={element.toString()}>
            <LIchecks handleFromULchecksComponent={handleFromULchecksComponent} element={element} />
          </UlChecksLi>
        )
      })}
      <UlChecksApplyFilter>
        <StyledButton
          onClick={() => handleQuantityFilterFromProcessComponent(arrChecked)}
          borderRadius={2}
          borderColor="transparent"
          color={palette.white}
          backgroundColor={palette.congressBlue}
        >
          Apply filter
        </StyledButton>
      </UlChecksApplyFilter>
    </UlChecks>
  )
}

const MailListWrapper = styled.div`
  position: absolute;
  top: ${pxToRem(spacing(4.5))}rem;
  border: 1px solid ${palette.alto};
  background: ${palette.white};
  border-radius: ${pxToRem(5)}rem;
  padding: ${pxToRem(spacing(2))}rem;
  color: ${palette.tangaroa};
  width: 40%;
`

export const MailList = () => {
  const username = useSelector(usernameSelector)

  return (
    <MailListWrapper>
      <p>{username}</p>
      <p>usernameprova@luxottica.com</p>
    </MailListWrapper>
  )
}

export const WrapperSection = styled.section`
  font-weight: 400;
  color: ${palette.biscay};
  margin-bottom: 0.5vw;
  display: flex;
  justify-content: space-around;
  gap: ${pxToRem(spacing(0.5))}rem;
  flex-wrap: wrap;
  font-size: ${getFluidFontSize('12px', 15)};
`

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${pxToRem(16)}rem;
  padding: ${getFluidSize(`${pxToRem(15)}rem`, 20)} ${getFluidSize(`${pxToRem(10)}rem`, 20)};

  span {
    font-size: ${getFluidFontSize(`${pxToRem(10.5)}rem`, 15)};
    font-weight: bold;
  }

  span.filters-applied {
    margin: 0 ${pxToRem(spacing(2))}rem 0 ${pxToRem(spacing(1))}rem;
    font-size: ${getFluidFontSize(`${pxToRem(12.5)}rem`, 15)};
    text-transform: uppercase;
  }
`

export const NextButton = styled.button<{
  showButtonAsLink?: boolean
}>`
  border-radius: 2.5em;
  padding: 0.5em 3.75em 0.5em;
  background-color: ${palette.congressBlue};
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  color: ${palette.white};
  min-width: 7em;
  text-transform: uppercase;
`

export const CustomDatePicker = styled(DatePicker)`
  width: 80%;
  border: solid 1px ${palette.bondiBlueDark};
  border-radius: ${pxToRem(spacing(0.5))}rem;
  background: ${palette.white};
  padding: ${pxToRem(8)}rem ${pxToRem(12)}rem;

  .react-date-picker__wrapper {
    border: none;
    height: 2em;
  }

  .react-date-picker__inputGroup {
    padding: 0;
    order: 2;
    color: ${palette.doveGray};
  }

  .react-date-picker__clear-button {
    order: 3;
    font-size: ${getFluidFontSize('12px', 15)};

    svg {
      stroke: ${palette.bondiBlueDark};
      width: 1em;
      height: 1em;
    }
  }

  .react-date-picker__calendar-button {
    order: 1;
    color: ${palette.bondiBlueDark};

    svg {
      width: 1em;
      height: 1em;
    }
  }
`
