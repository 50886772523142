import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AfaProduct, AfaCartProduct } from '../../model/afa'
import { Base64File, DdMmYyyyDateString } from '../../model/model'

import { resetForNewClient } from '../extraActions'

type SelectedAddress = {
  doorId: string
  selectedAddressId: string
}

export type CancellationInfo = {
  deliveryDate: DdMmYyyyDateString
  cancellationDate: DdMmYyyyDateString
}

type UploadCartPayload = {
  FileName: string
  File: Base64File
}

type Upload = {
  step: number
  fileData?: UploadCartPayload
}

const DEFAULT_STATE = {
  filtersOpen: false,
  cartFiltersOpen: false,
  searchOpen: false,
  sortOpen: false,
  products: [] as AfaProduct[],
  expiredProductsToRemove: [] as AfaCartProduct[],
  selectedAddresses: [] as SelectedAddress[],
  cancellationDates: [] as CancellationInfo[],
  duplicateCartOnDoorModalOpen: false,
  cartAdjustAllModalOpen: false,
  cartAdjustAllKeys: undefined as undefined | string[],
  adjustAllIsSimulate: false,
  shareCartOnDoorModalOpen: false,
  pdpRemoveExpiredItemsModalOpen: false,
  checkout: {
    importExport: {
      step: 0,
    },
    upload: {
      step: 0,
    } as Upload,
  },
  previousUrl: '',
  cartIsUpdating: false,
}

export const slice = createSlice({
  name: 'afa',
  initialState: DEFAULT_STATE,
  reducers: {
    toggleFiltersOpen: state => {
      state.filtersOpen = !state.filtersOpen
      state.sortOpen = false
      state.searchOpen = false
    },

    toggleCartFiltersOpen: state => {
      state.cartFiltersOpen = !state.cartFiltersOpen
    },
    closeCartFilter: state => {
      state.cartFiltersOpen = false
      state.filtersOpen = false
    },

    toggleSearchOpen: state => {
      state.searchOpen = !state.searchOpen
      state.filtersOpen = false
      state.sortOpen = false
    },

    openSearch: state => {
      state.searchOpen = true
      state.filtersOpen = false
      state.sortOpen = false
    },

    toggleSortOpen: state => {
      state.sortOpen = !state.sortOpen
      state.filtersOpen = false
      state.searchOpen = false
    },

    setProducts: (state, { payload }: PayloadAction<AfaProduct[]>) => {
      state.products = payload
    },

    setSelectedAddress: (state, { payload }: PayloadAction<SelectedAddress>) => {
      const id = state.selectedAddresses.findIndex(({ doorId }) => doorId === payload.doorId)
      if (id === -1) {
        state.selectedAddresses.push(payload)
      } else {
        state.selectedAddresses[id] = payload
      }
    },

    setCancellationDates: (state, { payload }: PayloadAction<CancellationInfo[]>) => {
      state.cancellationDates = payload
    },

    setCancellationDate: (state, { payload }: PayloadAction<CancellationInfo>) => {
      const updatedDates = state.cancellationDates.map(cancellationDate =>
        cancellationDate.deliveryDate === payload.deliveryDate ? payload : cancellationDate,
      )
      state.cancellationDates = updatedDates
    },

    toggleDuplicateCartOnDoorModalOpen: state => {
      state.duplicateCartOnDoorModalOpen = !state.duplicateCartOnDoorModalOpen
    },

    toggleCartAdjustAllModalOpen: state => {
      state.cartAdjustAllModalOpen = !state.cartAdjustAllModalOpen
    },

    setCartAdjustAllKeys: (state, { payload }: PayloadAction<string[] | undefined>) => {
      state.cartAdjustAllKeys = payload
    },

    setAdjustAllIsSimulate: (state, { payload }: PayloadAction<boolean>) => {
      state.adjustAllIsSimulate = payload
    },

    toggleShareCartOnDoorModalOpen: state => {
      state.shareCartOnDoorModalOpen = !state.shareCartOnDoorModalOpen
    },

    togglePdpRemoveExpiredItemsModalOpen: state => {
      state.pdpRemoveExpiredItemsModalOpen = !state.pdpRemoveExpiredItemsModalOpen
    },

    setExpiredProductsToRemove: (state, { payload }: PayloadAction<AfaCartProduct[]>) => {
      state.expiredProductsToRemove = payload
    },

    previusImportExportStep: state => {
      if (state.checkout.importExport.step > 0) {
        state.checkout.importExport.step -= 1
      }
    },

    nextImportExportStep: state => {
      state.checkout.importExport.step += 1
    },

    setImportExportStep: (state, { payload }: PayloadAction<number>) => {
      state.checkout.importExport.step = payload
    },

    setFileData: (state, { payload }: PayloadAction<UploadCartPayload | undefined>) => {
      state.checkout.upload.fileData = payload
    },
    setPreviousUrl: (state, { payload }: PayloadAction<string>) => {
      state.previousUrl = payload
    },
    setAfaCartIsUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.cartIsUpdating = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
