import { createSelector } from '@reduxjs/toolkit'

import app_config from '../../../config/app/config'
import { brandExistAndIsAdult, getBrandByBrandCode, sortBrands } from '../../../libs/brand'
import { getBrandsFromCartItems, isMocoInCart } from '../../../libs/cart'
import { matchFilters } from '../../../libs/filters'
import { Brand } from '../../../model/brand'
import { CartProduct, MocoInCart, SizeInCart } from '../../../model/cart'
import { Door } from '../../../model/customer'
import { CartFilters } from '../../../model/filters'
import { MocoCode, RootState } from '../../../model/model'
import { Product, Size } from '../../../model/product'
import {
  brandEnabledSelector,
  brandsSelector,
  subbrandToMainBrandMapSelector,
} from '../../brands/selectors'
import { activeDoorsSelector } from '../../customer/selectors'
import { activeCartFiltersSelector, selectedCartFiltersSelector } from '../../filters/selectors'
import {
  notificationsSelector,
  socketConnectionStatusSelector,
} from '../../notifications/selectors'

export const cartSelector = (state: RootState) => state.cart

export const cartProductsRawSelector = (state: RootState) => state.cart.cartData?.products || []

export const cartTimestampSelector = (state: RootState) => state.cart.cartData?.timestamp

export const cartBackOfficeNoteSelector = (state: RootState) => state.cart.cartData?.backOfficeNote

export const brandsGroupMapSelector = createSelector(brandsSelector, brands =>
  brands.reduce((result: Record<string, string[]>, brand) => {
    result[brand.code] = brand.group
    return result
  }, {}),
)

export const cartProductsSelector = createSelector(
  cartProductsRawSelector,
  brandsSelector,
  (cartProducts, brands) =>
    cartProducts.filter(cartProduct => {
      const brandGroup = brands.some(
        b => cartProduct.brandCode && b.group.includes(cartProduct.brandCode),
      )
      return brandGroup
    }),
)

export const cartProductsDetailsSelector = (state: RootState) => state.cart.cartProductDetails || {}

export const apiRequestsBufferSelector = (state: RootState) => state.cart.apiRequestsBuffer

export const insertToCartRequestSelector = createSelector(
  apiRequestsBufferSelector,
  apiRequestsBuffer =>
    apiRequestsBuffer.filter(
      request => ['waiting', 'pending'].includes(request.requestStatus) && request.oldQnt == 0,
    ),
)

const checkUpdateCartInProgress = (
  state: RootState,
  productSizes: (Size | SizeInCart)[],
  doors: string[] | undefined,
) =>
  !!state.cart.apiRequestsBuffer.find(
    apiRequest =>
      productSizes &&
      productSizes.find(
        size =>
          apiRequest.upc === size.upc &&
          doors &&
          doors.includes(apiRequest.doorId) &&
          apiRequest.requestStatus === 'pending',
      ),
  )

const getPendingNotification = (
  state: RootState,
  productSizes: (Size | SizeInCart)[],
  onlyVisible: boolean,
) => {
  const notifications = notificationsSelector(state)
  return notifications.fastAddsToCart
    .filter(notification => (onlyVisible ? notification.show : true))
    .find(
      notification =>
        productSizes &&
        notification.notificationPayload?.productSizes.find((size: Size) =>
          productSizes.find(ownSize => ownSize.upc === size.upc),
        ),
    )
}

export const cartIsLoadingSelector = (state: RootState) =>
  !state.cart.cartData?.loaded && state.cart.cartData?.loading

export const cartIsLoadedSelector = (state: RootState) => state.cart.cartData?.loaded

export const cartIsUpdatingSelector = (state: RootState) => state.cart.cartData?.updating

const checkRevertableUpdateCartInProgress = (
  state: RootState,
  productSizes: (Size | SizeInCart)[],
) => Boolean(getPendingNotification(state, productSizes, true))

export const disablePurchaseControls = (
  productSizes: (Size | SizeInCart)[],
  doors: string[] | undefined,
  dislikeItems: string[] | undefined,
  brandCode: string,
  headerControlCode?: string,
) => (state: RootState) => {
  const updateCartInProgress = checkUpdateCartInProgress(state, productSizes, doors)
  if (updateCartInProgress) {
    return [true, 'updateCartInProgress']
  }

  const revertableUpdateCartInProgress = checkRevertableUpdateCartInProgress(state, productSizes)
  if (revertableUpdateCartInProgress) {
    return [true, 'revertableUpdateCartInProgress']
  }

  const cartIsLoading = cartIsLoadingSelector(state)
  if (cartIsLoading) {
    return [true, 'cartIsLoading']
  }

  const enabledDoors = activeDoorsSelector(state)
  const noEnabledDoors = !enabledDoors || !enabledDoors.length
  if (noEnabledDoors) {
    return [true, 'noEnabledDoors']
  }

  const socketConnectionStatus = socketConnectionStatusSelector(state)
  const socketIsDown = socketConnectionStatus && !socketConnectionStatus.connected
  if (socketIsDown) {
    return [true, 'socketIsDown']
  }

  // eslint-disable-next-line no-extra-boolean-cast
  const isHeaderPurchaseControl = Boolean(headerControlCode)
  const sizesMocos = productSizes.map(s => s.mocoCode)
  const isDisabledByDislike = isHeaderPurchaseControl
    ? sizesMocos.every(s => dislikeItems && dislikeItems.includes(s))
    : sizesMocos.some(s => dislikeItems && dislikeItems.includes(s))
  if (isDisabledByDislike) {
    return [true, 'isDisabledByDislike']
  }

  const brandDisabled = !brandEnabledSelector(brandCode)(state)
  if (brandDisabled) {
    return [true, 'DigitalPreviewBrandsPage.locked']
  }

  return [false]
}

export const disableDoorPurchaseControls = ({
  productSize,
  doorIds,
  doorId,
}: {
  productSize: Size
  doorIds: string[]
  doorId?: string
}) => (state: RootState) => {
  const updateCartInProgress = checkUpdateCartInProgress(state, [productSize], doorIds)
  const revertableUpdateCartInProgress = checkRevertableUpdateCartInProgress(state, [productSize])
  const defaultDoor = state.customer.doors.find(
    door => door.mainDoor === true && door.id === doorId,
  )
  const cartIsLoading = cartIsLoadingSelector(state)
  const socketConnectionStatus = socketConnectionStatusSelector(state)
  const socketIsDown = socketConnectionStatus && !socketConnectionStatus.connected

  return (
    (defaultDoor && !defaultDoor.enabled) ||
    socketIsDown ||
    updateCartInProgress ||
    revertableUpdateCartInProgress ||
    cartIsLoading
  )
}

export const getPendingNotificationSelector = ({
  productSize,
  productSizes,
}: {
  productSize?: Size
  productSizes?: (Size | SizeInCart)[]
}) => (state: RootState) => {
  const pendingNotification = getPendingNotification(
    state,
    productSizes || (productSize ? [productSize] : []),
    false,
  )
  return pendingNotification
    ? pendingNotification.notificationPayload
    : { doors: [], productSizes: [], qntToAdd: 0, cartItemSizes: [] }
}

export const cartViewTypeSelector = (state: RootState) => state.cart.viewType

export const assortmentsViewTypeSelector = (state: RootState) => state.cart.assortmentsViewType

export const cartEditableSelector = (state: RootState) => state.cart.cartData?.editable

export const activeBrandCodeSelector = (state: RootState) => state.cart.activeBrand

export const activeBrandSelector = createSelector(
  activeBrandCodeSelector,
  brandsSelector,
  (activeBrandCode, brands) =>
    brands.find(({ group }) => activeBrandCode && group.includes(activeBrandCode)),
)

export const cartDataSelector = (state: RootState) => state.cart.cartData

export const myShopLastAddedProductUpcsSelector = (state: RootState) =>
  state.cart.myShopLastAddedProductUpcs

export const allBrandCodesMapSelector = createSelector(brandsSelector, brands =>
  brands
    .flatMap(brand => brand.group)
    .reduce((result, brandCode) => {
      result[brandCode] = true
      return result
    }, {} as Record<string, true>),
)

const cartProductMatchCriteria = (
  allBrandsCodeMap: Record<string, boolean>,
  activeDoors: Door[],
  cartProductsDetails: Record<string, Product>,
  filters: CartFilters,
) => (cartProduct: CartProduct) =>
  cartProduct.brandCode &&
  allBrandsCodeMap[cartProduct.brandCode] &&
  activeDoors.map(door => door.id).includes(cartProduct.doorId) &&
  matchFilters(filters, cartProductsDetails, cartProduct)

function filteredCartProductsSelector_cb(
  cartProducts: CartProduct[],
  cartProductsDetails: Record<string, Product>,
  activeFilters: CartFilters,
  activeDoors: Door[],
  allBrandsCodeMap: Record<string, boolean>,
) {
  return cartProducts.filter(
    cartProductMatchCriteria(allBrandsCodeMap, activeDoors, cartProductsDetails, activeFilters),
  )
}
export const filteredCartProductsSelector = createSelector(
  cartProductsSelector,
  cartProductsDetailsSelector,
  activeCartFiltersSelector,
  activeDoorsSelector,
  allBrandCodesMapSelector,
  filteredCartProductsSelector_cb,
)

function filteredCartProductsSelector_selectedFilters_cb(
  cartProducts: CartProduct[],
  cartProductsDetails: Record<string, Product>,
  selectedFilters: CartFilters,
  activeDoors: Door[],
  allBrandsCodeMap: Record<string, boolean>,
) {
  return cartProducts.filter(
    cartProductMatchCriteria(allBrandsCodeMap, activeDoors, cartProductsDetails, selectedFilters),
  )
}

export const filteredCartProductsSelector_selectedFilters = createSelector(
  cartProductsSelector,
  cartProductsDetailsSelector,
  selectedCartFiltersSelector,
  activeDoorsSelector,
  allBrandCodesMapSelector,
  filteredCartProductsSelector_selectedFilters_cb,
)

export const cartBrandsSelector = createSelector(
  filteredCartProductsSelector,
  brandsSelector,
  cartProductsDetailsSelector,
  (filteredCartProducts, brands, productsDetails) => {
    const cartBrands = getBrandsFromCartItems(filteredCartProducts, brands, productsDetails)

    return sortBrands(Object.values(cartBrands))
  },
)

export const selectedCartCategorySelector = (state: RootState) => state.cart?.category

export const pendingCartUpdateSelector = (state: RootState) =>
  Boolean(
    state.cart.cartData?.loading ||
      state.cart.cartData?.updating ||
      state.cart.apiRequestsBuffer.length ||
      state.notifications.fastAddsToCart.length,
  )

export const lastAddedProductRecapSelector = (state: RootState) => state.cart.lastAddedProductRecap
export const isCartViewSelector = (state: RootState) =>
  cartViewTypeSelector(state) === app_config.viewType.table

export const currencySelector = (state: RootState) =>
  state.brands.items[0] ? state.brands.items[0].currency : ''

export const priceOpticalSelector = (state: RootState) =>
  state.cart.analytics?.total.price_optical || 0
export const priceSunSelector = (state: RootState) => state.cart.analytics?.total.price_sun || 0
export const totalOpticalSelector = (state: RootState) => state.cart.analytics?.total.optical || 0
export const totalSunSelector = (state: RootState) => state.cart.analytics?.total.sun || 0
export const analyticsSelector = (state: RootState) => state.cart.analytics

export const mocosInCart = (
  filteredCartProducts: CartProduct[],
  cartProductsDetails: Record<string, Product> | undefined,
  brands: Brand[],
) => {
  const mocosInCartMap = filteredCartProducts.reduce((result, cartProduct) => {
    const {
      colorCode,
      modelCode,
      upc,
      quantity,
      confirmedQuantity,
      doorId,
      brandCode,
      mocoCode,
      notes,
      insertedAt,
      outOfAssortment,
    } = cartProduct

    if (outOfAssortment) {
      if (!result[mocoCode]) {
        result[mocoCode] = cartProduct
      }
      return result
    }

    const modelDetails = cartProductsDetails && cartProductsDetails[modelCode]
    if (!modelDetails || !modelDetails.mocos[colorCode]) {
      return result
    }

    const moco = modelDetails.mocos[colorCode]
    const size = moco && moco.sizes[upc]
    const existingMoco = result[mocoCode]
    const resultSizes = (isMocoInCart(existingMoco) && existingMoco.sizes) || {}

    const sizes = {
      ...resultSizes,
      [upc]: {
        ...size,
        quantity,
        doors: ((resultSizes[upc] && resultSizes[upc].doors) || []).concat({
          doorId,
          quantity,
          confirmedQuantity,
          notes,
        }),
      },
    }

    const brand = getBrandByBrandCode(brands, brandCode)
    if (!brand) {
      return result
    }

    const _firstSizeInsertedAt = isMocoInCart(existingMoco) && existingMoco.firstSizeInsertedAt
    const firstSizeInsertedAt =
      _firstSizeInsertedAt && _firstSizeInsertedAt < insertedAt ? _firstSizeInsertedAt : insertedAt

    const mocoDetails = {
      ...modelDetails.mocos[colorCode],
      firstSizeInsertedAt,
      categoryId: modelDetails.category?.id || '',
      hasRTR: modelDetails.hasRTR,
      sizes,
      shopDataOrder: 0,
    }
    result[mocoCode] = mocoDetails

    return result
  }, {} as Record<MocoCode, MocoInCart | CartProduct>)

  return Object.values(mocosInCartMap).sort((a, b) => {
    const insertedAtToUseA = isMocoInCart(a) ? a.firstSizeInsertedAt : a.insertedAt
    const insertedAtToUseB = isMocoInCart(b) ? b.firstSizeInsertedAt : b.insertedAt

    return insertedAtToUseA > insertedAtToUseB ? -1 : 1
  })
}

export const convertModelIntoMocosInCart = (
  model: Product,
  cartProduct: CartProduct[],
  brands: Brand[],
) =>
  mocosInCart(
    cartProduct ? (Array.isArray(cartProduct) ? cartProduct : [cartProduct]) : [],
    { [model.modelCode]: model },
    brands,
  ).filter(isMocoInCart)

export const mocosInCartFilteredBy_doors_activeBrand_filters__Selector = createSelector(
  filteredCartProductsSelector,
  cartProductsDetailsSelector,
  brandsSelector,
  activeBrandCodeSelector,
  brandsGroupMapSelector,
  (filteredProductsInCart, cartProductsDetails, brands, activeBrand, brandsGroupMap) => {
    const filterByBrand = (cartProduct: CartProduct) => {
      if (!activeBrand) {
        return true
      }
      const { brandCode } = cartProduct
      const brandGroup =
        brandsGroupMap[activeBrand] ||
        Object.values(brandsGroupMap).find(v => v.includes(activeBrand)) ||
        []
      return brandCode === activeBrand || (brandCode && brandGroup.includes(brandCode))
    }
    const filteredCartProducts = filteredProductsInCart.filter(filterByBrand)
    return mocosInCart(filteredCartProducts, cartProductsDetails, brands)
  },
)

export const cartProductsFilteredByActiveDoorsSelector = createSelector(
  cartProductsSelector,
  activeDoorsSelector,
  subbrandToMainBrandMapSelector,
  brandsSelector,
  (cartProducts, activeDoors, mappedBrands, allBrands) => {
    const productsFiltered = cartProducts.filter(
      cartProduct =>
        activeDoors.find(activeDoor => activeDoor.id === cartProduct.doorId) &&
        allBrands.find(
          b => cartProduct.brandCode && b.code === mappedBrands[cartProduct.brandCode],
        ),
    )

    return productsFiltered
  },
)

export const upcInCartMapByActiveDoorSelector = createSelector(
  cartProductsFilteredByActiveDoorsSelector,
  cartProducts => {
    return cartProducts.reduce((result, { upc }) => {
      result[upc] = true
      return result
    }, {} as Record<string, true>)
  },
)

export const cartProductsFilteredBy_NoJunior_Selector = createSelector(
  cartProductsSelector,
  subbrandToMainBrandMapSelector,
  brandsSelector,
  (cartProducts, subbrandToMainBrandMap, allBrands) => {
    const productsFiltered = cartProducts.filter(cartProduct => {
      return brandExistAndIsAdult(allBrands, cartProduct.brandCode, subbrandToMainBrandMap)
    })

    return productsFiltered
  },
)

export const mocosInCartFilteredByDoorsSelector = createSelector(
  cartProductsFilteredByActiveDoorsSelector,
  cartProductsDetailsSelector,
  brandsSelector,
  (cartProductsFilteredByActiveDoors, cartProductsDetails, brands) => {
    return mocosInCart(cartProductsFilteredByActiveDoors, cartProductsDetails, brands)
  },
)

export const loadingCartProductDetailsSelector = (state: RootState) =>
  state.cart.loadingCartProductDetails

export const loadedCartProductDetailsSelector = (state: RootState) =>
  state.cart.loadedCartProductDetails

export const cartUnitsByDoor = createSelector(
  (state: RootState) => state.cart.statistics?.itemsByDoors,
  unitsByDoor => unitsByDoor,
)
