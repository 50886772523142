import { Button } from 'antd'
import { History } from 'history'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { Dispatch } from '@reduxjs/toolkit'

import { getDeviceAbsolutePath, isPageMatches } from '../../../libs/url'
import { CheckoutFiltersOptions } from '../../../model/checkout'
import { CartFilters } from '../../../model/filters'
import {
  applyFilters,
  applySegmentFilter,
  closeFooterModal,
  reloadCouvettes,
  resetFiltersAction,
  resetSegmentFiltersAction,
  toggleFilters as toggleFiltersAction,
  toggleSegmentFilterAction,
} from '../../../store/actions'
import { brandsSelector, starsBrandsSelector } from '../../../store/brands/selectors'
import {
  segmentFiltersOptionsSelector,
  selectedFiltersSelector,
  thereAreFiltersToApplyselector,
} from '../../../store/filters/selectors'
import starsActions from '../../../store/stars/actions'
import { cartModeSelector } from '../../../store/stars/selectors'
import { palette, pxToRem, spacing } from '../../../style/theme'
import BaseFilter from '../BaseFilter/BaseFilter'

const FiltersRecapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.gallery};
  padding: 1vw;
  margin: 2.2vw 1.35vw 1vw;

  .ant-checkbox-wrapper,
  .ant-btn {
    font-size: inherit;
  }
`

const FiltersRecapHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${palette.gray};
  justify-content: space-between;
  margin-bottom: ${pxToRem(spacing(1))}rem;
`

const FilterRecapTitle = styled.div`
  font-size: 1.25em;
  font-weight: bold;
`

const FiltersRecapBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 1em 0;
`

const FiltersRecapChoices = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    color: ${palette.biscay};
    display: block;
    margin: 0 0 1em 0;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border: 1px solid ${palette.cornflowerBlue};
      border-radius: 3.6px;
      background-color: ${palette.selago};
    }

    &.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: ${palette.congressBlue};
    }

    &::after {
      border-color: ${palette.congressBlue};
    }
  }
`

const FilterRecapReset = styled.div`
  .btn-reset {
    width: auto;
    height: auto;
    text-transform: lowercase;
    text-decoration: underline;
    background: transparent;
    border: none;
    color: ${palette.biscay};
    font-weight: bold;
    padding: 0.5em 0;
  }
`

const ApplyFilter = styled.button`
  align-self: center;
  height: auto;
  text-transform: uppercase;
  outline: none;
  border-radius: 2em;
  padding: 0.8em 1.5em;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: ${palette.congressBlue};
  border-color: ${palette.congressBlue};
  color: ${palette.white};
  padding: 0.5em 1em;
`

type ConnectedPropsCart = {
  filtersToShow: {
    filterKey: keyof CartFilters
    selectedId: string
    selected: boolean
    label: string
    optionName: string
    quantity?: number
    disabled?: boolean
  }[]
  isApplyFilterButtonVisible: boolean
}

type ActionPropsCart = {
  applyFilters: (history: History) => void
  resetFilters: () => void
  toggleFilter: (filterKey: keyof CartFilters, selectedId: string) => void
}

type ParentProps = {
  title?: string
}

type PropsCart = ConnectedPropsCart & ActionPropsCart & ParentProps

export const FiltersRecapPanel: React.FC<PropsCart & { className?: string }> = ({
  filtersToShow,
  isApplyFilterButtonVisible,
  resetFilters,
  applyFilters,
  toggleFilter,
  title,
  className,
}) => {
  const { t } = useTranslation()

  const history = useHistory()

  return (
    <FiltersRecapWrapper className={className}>
      <FiltersRecapHeader>
        <FilterRecapTitle>{title || t('GenericWords.filters')}</FilterRecapTitle>
        {resetFilters && (
          <FilterRecapReset>
            <Button className="btn-reset" onClick={resetFilters}>
              {t('Filters.item_reset')}
            </Button>
          </FilterRecapReset>
        )}
      </FiltersRecapHeader>
      <FiltersRecapBody className="filters-recap-wrapper">
        <FiltersRecapChoices className="filters-recap-choices">
          {filtersToShow.map(
            ({ filterKey, selectedId, label, optionName, quantity, selected, disabled }) => (
              <BaseFilter
                key={`${filterKey}${selectedId}`}
                value={selectedId}
                checked={selected}
                quantity={quantity}
                toggle={() => toggleFilter(filterKey, selectedId)}
                optionName={optionName}
                optionLabel={label}
                type={'checkbox'}
                filterName={filterKey}
                disabled={disabled}
              />
            ),
          )}
        </FiltersRecapChoices>
        {isApplyFilterButtonVisible && (
          <ApplyFilter className="btn-apply" onClick={() => applyFilters(history)}>
            {t('Filters.item_apply_filters')}
          </ApplyFilter>
        )}
      </FiltersRecapBody>
    </FiltersRecapWrapper>
  )
}

const handleApplyFilters = (
  dispatch: Dispatch<any>,
  history: History,
  plpMode: 'stars' | 'wholesale',
) => {
  if (isPageMatches('cart')) {
    dispatch(closeFooterModal())
    dispatch(applyFilters())
  } else {
    plpMode === 'wholesale' && history.push(`${getDeviceAbsolutePath()}/products`)
    dispatch(applyFilters())
    dispatch(
      plpMode === 'wholesale'
        ? reloadCouvettes()
        : starsActions.loadFilteredStarsAssortment({ history }),
    )
  }
}

const handleApplySegmentFilters = (
  dispatch: Dispatch<any>,
  history: History,
  plpMode: 'stars' | 'wholesale',
) => {
  plpMode === 'wholesale' && history.push(`${getDeviceAbsolutePath()}/products`)
  dispatch(applySegmentFilter())
  dispatch(applyFilters())
  dispatch(
    plpMode === 'wholesale'
      ? reloadCouvettes()
      : starsActions.loadFilteredStarsAssortment({ history }),
  )
}

export const FiltersRecapPanelGeneral: React.FC<ParentProps> = ({ title }) => {
  const filtersToShow = useSelector(selectedFiltersSelector)
  const isApplyFilterButtonVisible = useSelector(thereAreFiltersToApplyselector)
  const plpMode = useSelector(cartModeSelector) === 'stars' ? 'stars' : 'wholesale'

  const dispatch = useDispatch()

  const resetFilters = () => dispatch(resetFiltersAction(['brand_slug']))
  const applyFilters = (history: History) => handleApplyFilters(dispatch, history, plpMode)
  const toggleFilter = (filterKey: keyof CartFilters, selectedId: string) =>
    dispatch(toggleFiltersAction(filterKey, selectedId))

  return (
    <FiltersRecapPanel
      title={title}
      filtersToShow={filtersToShow}
      isApplyFilterButtonVisible={isApplyFilterButtonVisible}
      resetFilters={resetFilters}
      applyFilters={applyFilters}
      toggleFilter={toggleFilter}
    />
  )
}

export const FiltersRecapPanelStyled = styled(FiltersRecapPanel)`
  width: min-content;
`

export const FiltersRecapPanelBrand: React.FC<ParentProps> = ({ title }) => {
  const dispatch = useDispatch()
  const segmentFiltersOptions = useSelector(segmentFiltersOptionsSelector)
  const cartMode = useSelector(cartModeSelector)
  const brands = useSelector(brandsSelector)
  const starsBrands = useSelector(starsBrandsSelector)

  const filtersToShow = segmentFiltersOptions.map(option => {
    const modeBrands = cartMode === 'units' ? brands : starsBrands
    const disabled = !modeBrands.some(({ segment }) => segment === option.selectedId)
    return { ...option, disabled }
  })

  const resetFilters = () => dispatch(resetSegmentFiltersAction())
  const applyFilters = (history: History) =>
    handleApplySegmentFilters(dispatch, history, cartMode === 'stars' ? 'stars' : 'wholesale')
  const toggleFilter = (filterKey: keyof CartFilters, selectedId: string) =>
    dispatch(toggleSegmentFilterAction(selectedId))

  return (
    <FiltersRecapPanelStyled
      title={title}
      filtersToShow={filtersToShow}
      isApplyFilterButtonVisible={true}
      resetFilters={resetFilters}
      applyFilters={applyFilters}
      toggleFilter={toggleFilter}
    />
  )
}

type ConnectedPropsCheckout = {
  filtersToShow: {
    filterKey: keyof CheckoutFiltersOptions
    selectedId: string
    selected: boolean
    label: string
    optionName: string
    quantity?: number
  }[]
  isApplyFilterButtonVisible: boolean
}

type ActionPropsCheckout = {
  applyFilters: (history: History) => void
  resetFilters: () => void
  toggleFilter: (filterKey: keyof CheckoutFiltersOptions, selectedId: string) => void
}

type PropsCheckout = ConnectedPropsCheckout & ActionPropsCheckout & ParentProps

export const FiltersRecapPanelCheckout: React.FC<PropsCheckout & { className?: string }> = ({
  filtersToShow,
  isApplyFilterButtonVisible,
  resetFilters,
  applyFilters,
  toggleFilter,
  title,
  className,
}) => {
  const { t } = useTranslation()

  const history = useHistory()
  return (
    <FiltersRecapWrapper className={className}>
      <FiltersRecapHeader>
        <FilterRecapTitle>{title || t('GenericWords.filters')}</FilterRecapTitle>
        {resetFilters && (
          <FilterRecapReset>
            <Button className="btn-reset" onClick={resetFilters}>
              {t('Filters.item_reset')}
            </Button>
          </FilterRecapReset>
        )}
      </FiltersRecapHeader>
      <FiltersRecapBody className="filters-recap-wrapper">
        <FiltersRecapChoices className="filters-recap-choices">
          {filtersToShow
            .filter(({ quantity }) => !!quantity)
            .map(({ filterKey, selectedId, label, optionName, quantity, selected }) => (
              <BaseFilter
                key={`${filterKey}${selectedId}`}
                value={selectedId}
                checked={selected}
                quantity={quantity}
                toggle={() => toggleFilter(filterKey, selectedId)}
                optionName={optionName}
                optionLabel={label}
                type={'checkbox'}
                filterName={filterKey}
                isCheckoutRecap={true}
              />
            ))}
        </FiltersRecapChoices>
        {isApplyFilterButtonVisible && (
          <ApplyFilter className="btn-apply" onClick={() => applyFilters(history)}>
            {t('Filters.item_apply_filters')}
          </ApplyFilter>
        )}
      </FiltersRecapBody>
    </FiltersRecapWrapper>
  )
}
