import flat from 'flat'
import React from 'react'
import styled from 'styled-components'

import ServiceApi from './ServiceApi'

function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    return
  }
  navigator.clipboard.writeText(text)
}

const Table = styled.table`
  width: 100%;
  font-family: monospace;
  font-size: 0.9rem;

  tr {
    border-bottom: 1px solid #ccc;

    &:hover {
      background-color: papayawhip;
    }
  }

  td {
    padding: 0.3rem;
  }
`

const TableData: React.FC<{ data: Record<string, any> }> = ({ data }) => {
  const flatData = flat(data) as Record<string, any>

  return (
    <React.Fragment>
      <Table className={'table-data'}>
        <tbody>
          {Object.keys(flatData).map((r, i) => (
            <tr key={i}>
              <td>
                <strong>{r}</strong>
              </td>

              <td onDoubleClick={() => copyTextToClipboard(flatData[r])}>{String(flatData[r])}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p className="service-content__notes">Double click on value to copy data to clipboard</p>
    </React.Fragment>
  )
}

const RawJson: React.FC<{ json: Record<string, any> }> = ({ json }) => (
  <details style={{ padding: '.5rem', marginBottom: '2rem' }}>
    <summary>JSON</summary>
    <pre>{JSON.stringify(json, undefined, 4)}</pre>
  </details>
)

function getBaseDomain(url: string) {
  return typeof url === 'string'
    ? url.split('/').reduce((url, seg, i) => (i < 3 ? (url += seg + '/') : url), '') + 'service'
    : ''
}

const Wrapper = styled.div`
  padding: 3rem;
  overflow-y: auto;
  height: 100vh;

  h1 {
    font-size: 3rem;
  }

  &__notes {
    padding: 0.3rem;
    font-size: 0.8rem;
    color: #999;
    text-align: right;
  }
`

type Props = {
  config: Record<string, any>
}

const ServiceContent: React.FC<Props> = ({ config }) => {
  return (
    <Wrapper className="service-content">
      <h1>Service Info</h1>

      <h2>UI</h2>

      <TableData data={config} />
      <RawJson json={config} />

      <h2>API</h2>

      <ServiceApi apiEndpoint={getBaseDomain(config.apiUrl)}>
        {({ loading, error, json }: any) => (
          <React.Fragment>
            {loading && <p>Loading...</p>}
            {json && (
              <React.Fragment>
                <TableData data={json} />
                <RawJson json={json} />
              </React.Fragment>
            )}

            {error && <pre>{JSON.stringify(error, undefined, 4)}</pre>}
          </React.Fragment>
        )}
      </ServiceApi>
    </Wrapper>
  )
}

export default ServiceContent
