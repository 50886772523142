import { RootState } from '../../model/model'

export const initialNotificationCountSelector = (state: RootState) =>
  state.afaCartNotifications.initialNotificationCount

export const initialNotificationsListSelector = (state: RootState) =>
  state.afaCartNotifications.initialNotificationsList

export const currentNotificationsListSelector = (state: RootState) =>
  state.afaCartNotifications.currentNotificationsList
