import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useSearchParams } from '../../hooks/useSearchParams'
import { getDeviceAbsolutePath } from '../../libs/url'
import { useGetAllPlpFacetsQuery } from '../../services/afaProducts'
import { breakpoints, getFluidSizeWithFullFormula, palette, pxToRem } from '../../style/theme'
import { sortCategoriesFunction } from '../../libs/afa'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: ${pxToRem(40)}rem;
  row-gap: ${getFluidSizeWithFullFormula('px', 40, 120, 1366, 3840)};
  grid-row-start: 1;

  @media screen and (max-width: ${breakpoints.M}) {
    grid-row-start: auto;
    justify-content: center;
    flex-direction: row;
  }
`

const CategoryButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${palette.bermudaGray};
  text-transform: uppercase;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};

  &:link {
    text-decoration: none;
  }

  &.active {
    color: ${palette.tangaroa};
  }

  @media screen and (max-width: ${breakpoints.M}) {
    border-bottom: 4px solid transparent;
    color: ${palette.shadowBlue};

    &.active {
      border-color: #031434;
      color: #031434;
      font-weight: bold;
    }
  }
`

const AfaCategorySwitcher: React.FC = () => {
  const { category: selectedCategory, brandCode, assortment } = useParams<{
    category: string
    brandCode: string
    assortment: string
  }>()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const sport = searchParams.get('sport')

  const facetsQuery = useGetAllPlpFacetsQuery()

  const categories = facetsQuery.data?.facets.find(item => item.name === 'category')

  return (
    <Wrapper>
      {[...(categories?.options || [])].sort(sortCategoriesFunction()).map(category => (
        <CategoryButton
          key={category.name}
          className={category.name === selectedCategory ? 'active' : ''}
          to={`${getDeviceAbsolutePath()}/${assortment}/single-brand/${brandCode}/${category.name}${
            sport ? `?sport=${sport}` : ''
          }`}
        >
          <span>{t(`Afa.${category.name.toLowerCase()}`)}</span>
        </CategoryButton>
      ))}
    </Wrapper>
  )
}

export default AfaCategorySwitcher
