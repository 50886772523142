import { useSelector } from 'react-redux'
import { cmsTagAfaSelector } from '../store/app/selectors'

export const useGetReleaseNameFormCmsAfaTag = () => {
  const cmsTagAfa = useSelector(cmsTagAfaSelector) || ''
  const cmsTagAfaMatch = cmsTagAfa.match(/(\d+)(\w+)/)
  const release = cmsTagAfaMatch ? `${cmsTagAfaMatch[1]} ${cmsTagAfaMatch[2]}` : ''

  return release
}
