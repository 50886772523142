import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import BannerBtn from '../../components/UI/BannerBtn'
import StarsIcon from '../../components/UI/StarsIcon/index'
import app_config from '../../config/app/config'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { useHandleOpenChooseBrandPage } from '../../hooks/useHandleOpenPages'
import { checkRedirect } from '../../libs'
import { getDefaultDoorId } from '../../libs/doors'
import socketManager from '../../libs/socketManager'
import { getDeviceAbsolutePath, isTableDevice } from '../../libs/url'
import { Brand } from '../../model/brand'
import { Door } from '../../model/customer'
import { useGetBrandLogo } from '../../services/afaContents'
import appActions, { getEventReleases, showFooterModal } from '../../store/app/actions'
import {
  eventIdSelector,
  isDigitalEventSelector,
  isKamUserSelector,
  languageSelector,
  springSummerImageSelector,
} from '../../store/app/selectors'
import {
  brandsLoadedSelector,
  brandsSelector,
  normalBrandsSelector,
  starsBrandsSelector,
} from '../../store/brands/selectors'
import { setCartViewType } from '../../store/cart/actions'
import { setDigitalPreviewVisited } from '../../store/customer/actions'
import {
  customerDoorsSelector,
  digitalPreviewVisitedSelector,
  doorLoadingStatusSelector,
  pricePreferencesLoadingStatusSelector,
} from '../../store/customer/selectors'
import {
  preselectBrandsAction,
  resetPlpFiltersAction,
  setCategoryFilter,
  setFiltersActivePageName,
  toggleBrandsBarAction,
  toggleFilters,
} from '../../store/filters/actions'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import { isSearchVisibleSelector } from '../../store/search/selectors'
import { BrandLogoImage } from '../../style/CommonComponents'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import FooterNav from '../FooterNav'
import { errorNotification } from '../../components/Notification/notifications'
import { useGetInitiativesQuery } from '../../services/initiatives'
import {
  Content,
  EvidenceContent,
  EvidenceCollection,
  EvidenceTitle,
  InitiativeButton,
  InitiativeContainer,
  InitiativeDescription,
  Wrapper,
} from './style'
import { ContentInEvidenceBackgrounds, EvidenceInitiative } from '../../store/initiatives/slice'
import { searchCouvettes } from '../../store/search/actions'
import { TDispatch } from '../../model/model'
import RCIcon from '../../components/UI/RCIcon'
import initiativesActions from '../../store/initiatives/actions'
import { setWishlistCurrentCategoryId } from '../../store/actions'
import starsActions from '../../store/stars/actions'
import { slice } from '../../store/couvettes/slice'
import Carousel from 'react-simply-carousel'

const NUMBER_OF_COLUMNS = 7

const ChooseBrandPageLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BannerBtnWrapper = styled.div`
  position: absolute;
  bottom: 8vh;
  right: 3vh;

  @media screen and (max-width: ${breakpoints.L}) {
    bottom: 6vh;
    right: 1vh;
  }
`

const BrandsGridWrapper = styled.div<{
  starRows: number
  normalRows: number
}>`
  background-color: ${palette.wildSand};
  flex: 1;
  display: grid;
  overflow: hidden;
  gap: ${spacing(pxToRem(2))}rem;
  grid-template-rows: ${props =>
    props.starRows > 0 && props.starRows > 0 ? `${props.starRows}fr ${props.normalRows}fr` : '1fr'};
`

const BrandSection = styled.div<{
  color?: string
  backgroundColor?: string
}>`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  border-radius: ${spacing(pxToRem(1))}rem;
  padding: 2vh 2vw;
`

const BrandSectionHeader = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-size: ${getFluidFontSize('16px')};
  font-weight: 900;
  margin-bottom: 3vh;

  span {
    margin-left: ${spacing(pxToRem(2))}rem;
  }
`

const BrandsGridContainer = styled.div<{
  numberOfRows?: number
  initiatives?: number
}>`
  display: grid;
  grid-template-columns: repeat(${NUMBER_OF_COLUMNS}, 1fr);
  width: 100%;
  height: 100%;
  row-gap: 2vw;
  column-gap: ${({ initiatives }) => (initiatives && initiatives > 0 ? 3 : 5)}vw;
  grid-template-rows: repeat(${props => props.numberOfRows}, 1fr);

  @media (min-width: ${breakpoints.M}) and (max-width: ${breakpoints.XL}) {
    row-gap: 1vw;
    column-gap: ${({ initiatives }) => (initiatives && initiatives > 0 ? 2 : 6)}vw;
  }
`

const BrandLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  height: max(4vh, 25px);
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
  }

  img {
    height: 100%;
  }
`

const EndContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const checkDoorsAvailability = (customerDoors: Door[], t: TFunction) => {
  const defaultDoor = getDefaultDoorId(customerDoors)

  if (customerDoors.length === 0) {
    errorNotification({
      message: t('Errors.customer_no_doors'),
    })
  } else if (!defaultDoor) {
    errorNotification({
      message: t('Errors.customer_no_default_door'),
    })
  }
}

const getDefaultEvidenceCategories = (brands: string[]): string => {
  // By default SUN has to be selected, so it's category 2 (or K for Electronics)
  let result = '2'

  if (brands.length > 1 && brands.includes('RW')) {
    result = '2,K'
  }
  if (brands?.length === 1 && brands[0] === 'RW') {
    result = 'K'
  }

  return result
}

const BrandLogo: React.FC<{
  brand: Brand
  isClickable?: boolean
  logoVariant?: 'standard' | 'alternative'
}> = ({ brand, isClickable, logoVariant }) => {
  const isDe = useSelector(isDigitalEventSelector)
  const isKamUser = useSelector(isKamUserSelector)

  const history = useHistory()

  const { assortment } = useParams<{ assortment?: string }>()
  const assortmentWithDefault = assortment || 'frames'

  const { code, slug } = brand
  const { brandLogo, isSuccess } = useGetBrandLogo(code)
  return (
    <BrandLogoWrapper
      key={code}
      className={isClickable ? 'enabled' : 'disabled'}
      onClick={() => {
        if (isClickable && (isKamUser || brand.enabled)) {
          const url = isDe
            ? `/digital-events/single-brand/${slug}`
            : `${getDeviceAbsolutePath()}/${
                assortmentWithDefault === 'frames' ? '' : `${assortmentWithDefault}/`
              }single-brand/${slug}`
          history.push(url)
        }
      }}
    >
      {brandLogo || !isSuccess ? (
        <BrandLogoImage
          className={logoVariant === 'alternative' ? 'alternative' : ''}
          src={brandLogo}
          alt={code}
        />
      ) : (
        <span>{brand.brand}</span>
      )}
    </BrandLogoWrapper>
  )
}

const Brands: React.FC<{ initiatives?: number }> = ({ initiatives = 0 }) => {
  const { t } = useTranslation()

  const normalBrands = useSelector(normalBrandsSelector)
  const starsBrands = useSelector(starsBrandsSelector)

  const starRows = Math.ceil(starsBrands.length / NUMBER_OF_COLUMNS)
  const normalRows = Math.ceil(normalBrands.length / NUMBER_OF_COLUMNS)
  return (
    <>
      {starsBrands.length > 0 && (
        <BrandSection color={palette.white} backgroundColor={palette.blueStars}>
          <BrandSectionHeader>
            <StarsIcon />
            <span>{t('HomepageStars.starsBrands')}</span>
          </BrandSectionHeader>
          <BrandsGridContainer numberOfRows={starRows}>
            {starsBrands.map(brand => (
              <BrandLogo
                key={brand.code}
                brand={brand}
                isClickable={true}
                logoVariant="alternative"
              />
            ))}
          </BrandsGridContainer>
        </BrandSection>
      )}
      {normalBrands.length > 0 && (
        <BrandSection color={palette.congressBlue} backgroundColor={palette.white}>
          <BrandSectionHeader>
            <span>{starsBrands.length != 0 ? t('HomepageStars.otherBrands') : ''}</span>
          </BrandSectionHeader>
          <BrandsGridContainer numberOfRows={normalRows} initiatives={initiatives}>
            {normalBrands.map(brand => (
              <BrandLogo key={brand.code} brand={brand} isClickable={true} />
            ))}
          </BrandsGridContainer>
        </BrandSection>
      )}
    </>
  )
}

type EvidenceProps = {
  initiatives: EvidenceInitiative[]
}
const Evidence: React.FC<EvidenceProps> = ({ initiatives }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)
  const dispatch: TDispatch = useDispatch()
  const history = useHistory()

  const moveNext = () => {
    const next = document.getElementById('next-initiative') as HTMLElement
    next.click()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      moveNext()
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [initiatives, selected])

  const goToPLP = async (upc: string[], initiativeCode: string, brands: string[]) => {
    try {
      dispatch(slice.actions.removeCouvettes())
      dispatch(initiativesActions.setSelectedInitiative(initiativeCode))
      dispatch(initiativesActions.setLaunchInitiative(true))
      dispatch(initiativesActions.setCategoryIsLoading(true))
      dispatch(appActions.setShowSpinner(true))
      dispatch(setWishlistCurrentCategoryId('2'))
      dispatch(setCategoryFilter('2'))
      dispatch(starsActions.resetStarsFilters())
      history.push(`${getDeviceAbsolutePath()}/products?initiative=${initiativeCode}`)
      await dispatch(searchCouvettes('', upc, getDefaultEvidenceCategories(brands), 'release'))
    } catch (e) {
      errorNotification({ message: '' })
    }
  }

  const getInitiatives = useMemo(() => {
    if (initiatives.length > 0) {
      const width = `calc(calc(calc(100vw - 5rem) - 1rem) * ${
        screen.width >= 3840 ? 0.22 : screen.width < 1367 ? 0.25 : 0.2
      })`
      return initiatives.map(({ description, image, products, initiativecode, brands }, index) => (
        <InitiativeContainer key={index} style={{ width }}>
          <EvidenceContent background={`${image}`}>
            <EndContent>
              <InitiativeDescription>{description}</InitiativeDescription>
              <InitiativeButton
                data-index={index}
                className="initiative-button"
                onClick={() =>
                  goToPLP(
                    products.map(({ upc }) => upc),
                    initiativecode,
                    brands,
                  )
                }
              >
                {t('HomepageStars.seeMore')}
              </InitiativeButton>
            </EndContent>
          </EvidenceContent>
        </InitiativeContainer>
      ))
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatives, t])

  const handlePage = useCallback(
    (e: any) => {
      e.stopPropagation()
      const index = e.target.getAttribute('data-index')
      const { products, initiativecode, brands } = initiatives[Number(index)]
      goToPLP(
        products.map(({ upc }) => upc),
        initiativecode,
        brands,
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initiatives],
  )

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSelectedDot = useCallback(() => {}, [])

  useEffect(() => {
    const buttons = document.getElementsByClassName('initiative-button')
    for (const i in buttons) {
      buttons.item(Number(i))?.removeEventListener('click', handlePage)
    }

    const dots = document.getElementsByClassName('itemBtnProps')
    for (const i in dots) {
      dots.item(Number(i))?.removeEventListener('click', handleSelectedDot)
    }
    for (const i in dots) {
      dots.item(Number(i))?.addEventListener('click', handleSelectedDot)
    }

    /*    const button = buttons[initiatives.length]
        button.addEventListener('click', handlePage)*/

    /*    return () => {
          clearInterval(timer)
        }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatives, selected])

  return (
    <EvidenceCollection>
      <EvidenceTitle>{t('HomepageStars.evidence')}</EvidenceTitle>
      <Wrapper id="evidence-wrapper">
        <Carousel
          containerProps={{
            style: {
              width: '100%',
              justifyContent: 'space-between',
              userSelect: 'none',
              height: '100%',
            },
          }}
          speed={200}
          preventScrollOnSwipe
          activeSlideIndex={selected}
          onRequestChange={setSelected}
          forwardBtnProps={{
            className: 'initiative-arrow-button',
            children: (
              <span id="next-initiative">
                <RCIcon type="dxarrow" />
              </span>
            ),
            show: true,
            style: {
              width: getFluidSizeWithFullFormula('px', 36, 72, 1366, 3840),
              height: getFluidSizeWithFullFormula('px', 36, 72, 1366, 3840),
              borderRadius: '50%',
              backgroundColor: 'rgb(217 217 217 / 70%)',
              color: palette.tangaroa,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              float: 'right',
              top: '45%',
              position: 'absolute',
              right: '15px',
            },
          }}
          backwardBtnProps={{
            children: <RCIcon type="dxarrow" />,
            show: true,
            className: 'initiative-arrow-button',
            style: {
              zIndex: 1,
              width: getFluidSizeWithFullFormula('px', 36, 72, 1366, 3840),
              height: getFluidSizeWithFullFormula('px', 36, 72, 1366, 3840),
              borderRadius: '50%',
              backgroundColor: 'rgb(217 217 217 / 70%)',
              color: palette.tangaroa,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              float: 'right',
              top: '45%',
              position: 'absolute',
              left: '15px',
              transform: 'rotate(180deg)',
            },
          }}
          dotsNav={{
            show: true,
            containerProps: {
              style: {
                position: 'absolute',
                bottom: '2vh',
                gap: 15,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              },
            },
            itemBtnProps: {
              className: 'itemBtnProps',
              style: {
                height: gF('px', 12, 24, 1366, 3840),
                width: gF('px', 12, 24, 1366, 3840),
                borderRadius: '50%',
                border: 0,
                backgroundColor: palette.santasGray,
              },
            },
            activeItemBtnProps: {
              style: {
                height: gF('px', 12, 24, 1366, 3840),
                width: gF('px', 12, 24, 1366, 3840),
                borderRadius: '50%',
                border: 0,
                background: 'white',
              },
            },
          }}
          itemsToShow={1}
          centerMode
        >
          {getInitiatives}
        </Carousel>
      </Wrapper>
    </EvidenceCollection>
  )
}

const ChooseBrandPage: React.FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })

  const eventId = useSelector(eventIdSelector)
  const springSummerImage = useSelector(springSummerImageSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const isSearchVisible = useSelector(isSearchVisibleSelector)
  const digitalPreviewVisited = useSelector(digitalPreviewVisitedSelector)
  const doorsLoadingStatus = useSelector(doorLoadingStatusSelector)
  const customerDoors = useSelector(customerDoorsSelector)
  const pricePreferencesLoadingStatus = useSelector(pricePreferencesLoadingStatusSelector)
  const brandsLoaded = useSelector(brandsLoadedSelector)
  const normalBrands = useSelector(normalBrandsSelector)
  const starsBrands = useSelector(starsBrandsSelector)
  const language = useSelector(languageSelector)
  const [initiativesBrand, setInitiativesBrands] = useState<Record<string, string>>({})
  const emitNavigationEvent = useHandleOpenChooseBrandPage()

  useEffect(() => {
    dispatch(setCartViewType(app_config.viewType.table as 'wallView' | 'tableView'))
    dispatch(setFiltersActivePageName('plp'))
    dispatch(resetPlpFiltersAction())
    dispatch(getEventReleases(eventId, 'frames'))
  }, [dispatch, eventId])

  useEffect(() => {
    if (doorsLoadingStatus === 'ok') {
      checkDoorsAvailability(customerDoors, t)
    }
  }, [doorsLoadingStatus, customerDoors, t])

  useEffect(() => {
    if (!isPdpVisible) {
      emitNavigationEvent()
    }
  }, [isPdpVisible, emitNavigationEvent])

  useEffect(() => {
    if (!isSearchVisible) {
      emitNavigationEvent()
    }
  }, [isSearchVisible, emitNavigationEvent])

  useEffect(() => {
    if (pricePreferencesLoadingStatus === 'ko') {
      errorNotification({
        message: t('Errors.customer_no_price_prefs'),
      })
    }
  }, [pricePreferencesLoadingStatus, t])

  const toggleFiltersModal = () => {
    if (isTableDevice() && !digitalPreviewVisited) {
      dispatch(setDigitalPreviewVisited())
      socketManager.emitSignal('play_digitalPreview_video')
    }
    dispatch(toggleFilters('release', 'dp', 'checkbox', true))
    dispatch(preselectBrandsAction((app_config.digitalPreviewBrands as any) as string[]))
    dispatch(showFooterModal('filters'))
    dispatch(toggleBrandsBarAction(false))
  }

  const doorsLoaded = ['ok', 'ko'].includes(doorsLoadingStatus)

  const numberOfCols = 6
  const starRows = Math.ceil(starsBrands.length / numberOfCols)
  const normalRows = Math.ceil(normalBrands.length / numberOfCols)

  const { data } = useGetInitiativesQuery()
  const initiatives = data?.initiatives || []

  const activeBrands = useSelector(brandsSelector)
  const activeBrandCodes = activeBrands
    .map(({ group }) => group)
    .reduce((acc, curr) => acc.concat(curr), [])

  const initiativesFiltered = initiatives
    .filter(({ brands: initiativeBrandCodes }) =>
      activeBrandCodes.some(brandCode => initiativeBrandCodes.includes(brandCode)),
    )
    .slice(0, 3)

  useEffect(() => {
    const getInitiativesContent = async () => {
      const content = await fetch(
        `${app_config.apiUrl}/content/inEvidence?eventId=${eventId}&language=${language}`,
      )
      const response = (await content.json()) as ContentInEvidenceBackgrounds[]

      if (response[0]?.pictures) {
        const pictures = response[0]?.pictures.reduce((acc, { subjectTaxonomy, data }) => {
          return subjectTaxonomy[0].externalReference
            ? {
                ...acc,
                [subjectTaxonomy[0].externalReference]: data,
              }
            : acc
        }, {})

        setInitiativesBrands(pictures)
      }
    }

    getInitiativesContent()
  }, [eventId, language])

  const intiativesWithImages = () => {
    return initiativesFiltered.map(initiative => ({
      ...initiative,
      image: initiativesBrand[initiative?.cmskey] || '',
    }))
  }

  return checkRedirect({ app: { eventId } }) ? (
    <Redirect to={isTableDevice() ? '/events' : ''} />
  ) : (
    <>
      <ChooseBrandPageLayout className="device-table">
        <Header />
        <Content initiatives={!!initiativesFiltered.length}>
          <BrandsGridWrapper starRows={starRows} normalRows={normalRows}>
            {!brandsLoaded || !doorsLoaded ? (
              <Loading />
            ) : (
              <Brands initiatives={initiativesFiltered?.length} />
            )}
          </BrandsGridWrapper>
          {!!initiativesFiltered.length && <Evidence initiatives={intiativesWithImages()} />}
        </Content>
        {app_config.digitalPreviewVisible.chooseBrandPage && (
          <BannerBtnWrapper>
            <BannerBtn imageUrl={springSummerImage?.url || ''} onClick={toggleFiltersModal}>
              <div>
                <div>{t('DigitalPreviewBanner.title')}</div>
                <div>{t('DigitalPreviewBanner.description')}</div>
              </div>
            </BannerBtn>
          </BannerBtnWrapper>
        )}
      </ChooseBrandPageLayout>

      <FooterNav />
    </>
  )
}

export default ChooseBrandPage
