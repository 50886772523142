import { Modal } from 'antd'
import classnames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Iframe from 'react-iframe'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { getWCSToken } from '../../../libs/auth'
import { getDeviceAbsolutePath } from '../../../libs/url'
import { useConfirmCartMutation } from '../../../services/cart'
import {
  customerIdSelector,
  eventIdSelector,
  selectedEventSelector,
} from '../../../store/app/selectors'
import { tokenSelector } from '../../../store/auth/selectors'
import { getCart } from '../../../store/cart/actions'
import { cartEditableSelector } from '../../../store/cart/selectors'
import checkoutActions from '../../../store/checkout/actions'
import { palette } from '../../../style/theme'
import Loading from '../../Loading'

const Wrapper = styled.div<{
  showButtonAsLink: boolean
}>`
  ${props =>
    !props.showButtonAsLink
      ? ` width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
`
      : ''}
`

const Button = styled.button<{
  showButtonAsLink: boolean
}>`
  ${props =>
    !props.showButtonAsLink
      ? `
  border-radius: 2.5em;
  padding: 0.5em 1.1em 0.5em;
  background-color: ${palette.congressBlue};
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  color: ${palette.white};
  text-transform: uppercase;
  min-width: 7em;
  `
      : ''}
`

type State = {
  isWarningVisible: boolean
  isCheckoutVisible: boolean
  isWarningExitVisible: boolean
  checkoutMounted: boolean
}

type Props = {
  showButtonAsLink?: boolean
}

const GotoCheckoutButton: React.FC<Props> = ({ showButtonAsLink = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const token = useSelector(tokenSelector)
  const event = useSelector(selectedEventSelector)
  const newCheckout = event?.newbackoffice === true
  const isCartEditable = useSelector(cartEditableSelector)
  const [, confirmCartResult] = useConfirmCartMutation()

  const [state, setState] = useState<State>({
    isWarningVisible: false,
    isCheckoutVisible: false,
    isWarningExitVisible: false,
    checkoutMounted: false,
  })

  const getBaseUrl = () => {
    const isCerti = Boolean(window.location.hostname.match(/localhost|test|dev|qua/))
    const baseUrl = `https://${isCerti ? 'certi-' : ''}redcarpet-orders.luxottica.com`
    return baseUrl
  }

  const getCheckoutUrl = () => {
    const {
      checkout: { storeId, length, pageNumber },
    } = app_config

    const wcsToken = getWCSToken(token)
    const baseUrl = getBaseUrl()

    const checkoutUrl = `${baseUrl}/webapp/wcs/stores/servlet/RedCarpetLoginCmd?storeId=${storeId}&eventId=${eventId}&length=${length}&pageNumber=${pageNumber}&customerId=${customerId}&authToken=${wcsToken}`

    return checkoutUrl
  }

  const getLogoutUrl = () => {
    const baseUrl = getBaseUrl()

    const logoutUrl = `${baseUrl}/webapp/wcs/stores/servlet/Logoff?catalogId=10001&langId=-1&storeId=30001&URL=LogonForm`

    return logoutUrl
  }

  const updateState = (val: Partial<State>) => setState({ ...state, ...val })

  const renderWarningModal = () => (
    <Modal
      className="modal-checkout"
      maskClosable={false}
      centered
      wrapClassName={'checkout-modal-wrapper'}
      title={t('Checkout.modal_warning_title')}
      visible={state.isWarningVisible}
      onOk={() => updateState({ isWarningVisible: false, isCheckoutVisible: true })}
      onCancel={() => updateState({ isWarningVisible: false })}
      destroyOnClose={true}
      cancelText={t('Checkout.modal_cancel')}
      okText={t('Checkout.modal_ok')}
      maskStyle={{ zIndex: 1011 }}
    >
      {t('Checkout.modal_warning_body')}
    </Modal>
  )

  const renderWarningExitModal = () => (
    <Modal
      className="modal-checkout"
      mask={false}
      maskClosable={false}
      centered
      wrapClassName={'checkout-modal-wrapper'}
      title={t('Checkout.modal_warningExit_title')}
      visible={state.isWarningExitVisible}
      onOk={() => {
        dispatch(getCart(null, null, true))
        updateState({ isCheckoutVisible: false, isWarningExitVisible: false })
      }}
      onCancel={() => updateState({ isWarningExitVisible: false })}
      destroyOnClose={true}
      cancelText={t('Checkout.modal_cancel')}
      okText={t('Checkout.modal_ok')}
      maskStyle={{ zIndex: 1011 }}
    >
      {t('Checkout.modal_warningExit_body')}
    </Modal>
  )

  const renderCheckoutModal = () => {
    const checkoutUrl = getCheckoutUrl()
    const logoutUrl = getLogoutUrl()

    return (
      <Modal
        className="modal-checkout modal-checkout-finalize"
        maskClosable={false}
        width="100%"
        wrapClassName={'checkout-modal-wrapper'}
        visible={state.isCheckoutVisible}
        onOk={() => updateState({ isCheckoutVisible: false })}
        onCancel={() =>
          newCheckout
            ? updateState({ isCheckoutVisible: false })
            : updateState({ isWarningExitVisible: true })
        }
        destroyOnClose={false}
        footer={null}
        title={null}
        maskStyle={{ zIndex: 1011 }}
      >
        <div
          className={classnames({
            'checkout-iframe-container': true,
            loading: !state.checkoutMounted,
          })}
        >
          {!state.checkoutMounted && !newCheckout && <Loading isFullPage={false} />}
          {newCheckout ? (
            <Redirect to={`${getDeviceAbsolutePath()}/checkout`} />
          ) : (
            <Iframe
              url={state.isCheckoutVisible ? checkoutUrl : logoutUrl}
              onLoad={() => setState({ ...state, checkoutMounted: state.isCheckoutVisible })}
              width="100%"
              height="100%"
              id="checkout-iframe"
              position="relative"
            />
          )}
        </div>
      </Modal>
    )
  }

  return (
    <Wrapper showButtonAsLink={showButtonAsLink}>
      {isCartEditable && (
        <Button
          className={showButtonAsLink ? 'sub-menu hidden' : ''}
          showButtonAsLink={showButtonAsLink}
          onClick={() => {
            if (newCheckout) {
              updateState({ isCheckoutVisible: true })
              dispatch(checkoutActions.resetCheckout())
              confirmCartResult.reset()
            } else {
              updateState({ isWarningVisible: true })
            }
          }}
          disabled={state.checkoutMounted}
        >
          {t('Checkout.Footer.SubmitOrder')}
        </Button>
      )}

      {renderWarningModal()}
      {renderWarningExitModal()}
      {renderCheckoutModal()}
    </Wrapper>
  )
}

export default GotoCheckoutButton
