import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled, { css } from 'styled-components/macro'

import Loading from '../../components/Loading/index'
import { getDefaultColor } from '../../libs/afa'
import { useGetProductQuery } from '../../services/afaProduct'
import {
  breakpoints,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../style/theme'
import AfaPdpSelectedColorRecap from './AfaPdpSelectedColorRecap'
import AfaPdpTabSwitcher from './AfaPdpTabSwitcher'
import ColorDoorsAndDrops from './ColorDoorsAndDrops'
import { useAfaSelectedColor } from '../../hooks/useAfaSelectedColor'
import { useElementDimensions } from '../../hooks/useElementDimensions'
import { breakpointsCross } from '../../style/theme'
import { getProductImageUrl } from '../../libs/productImages'

const Wrapper = styled.div`
  font-size: ${getFluidFontSize('16px')};
  flex-grow: 1;
  display: block;
  padding: 2em 2em 5em;
  background-color: #f8f7f7;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.5em;
  margin-bottom: 2rem;
`

const Title = styled.div`
  color: ${palette.tangaroa};
  font-size: ${getFluidSizeWithFullFormula('px', 24, 64, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
`

const SwitcherWrapper = styled.div`
  font-size: ${getFluidFontSize('16px')};
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;

  @media screen and (min-width: ${breakpoints.L}) {
    display: none;
  }

  > * {
    max-width: ${getFluidSizeWithFullFormula('px', 425, 642, 1366, 3840)};
  }
`

const SwitcherWrapper4K = styled(SwitcherWrapper)`
  display: none;
  margin-top: ${getFluidSizeWithFullFormula('px', 36, 72, 1920, 3840)};

  @media screen and (min-width: ${breakpoints.L}) {
    display: flex;
  }
`

const Colors = styled.div<{ footerHeight: number }>`
  display: grid;
  grid-template-columns: ${getFluidSizeWithFullFormula('px', 100, 210, 1366, 3840)} auto min-content;
  ${({ footerHeight }) =>
    css`
      height: calc(100% - ${footerHeight * 2}px);

      @media screen and (max-width: ${breakpointsCross.XL.max}) {
        height: calc(100% - ${footerHeight * 2.5}px);
      }
    `}
`

const ColorImagesWrapper = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-auto-rows: min-content;
  gap: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  @media screen and (min-width: ${breakpoints.L}) {
    margin-top: 2rem;
    gap: 2rem;
  }
`

const ImageWrapper = styled.div`
  aspect-ratio: 1;
  background-color: white;
  opacity: 0.5;
  width: ${getFluidSizeWithFullFormula('px', 98, 206, 1366, 3840)};
`

const ImageWrapperColors = styled(ImageWrapper)`
  border: 2px solid trasparent;

  &.active {
    opacity: 1;
    border: 2px solid ${palette.shadowBlue};
  }
`

const Img = styled.img`
  height: 100%;
  object-fit: contain;
`

const SelectedColorData = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ColorDoorsAndDropsWithMargin = styled(ColorDoorsAndDrops)`
  margin-top: 2rem;
  margin-left: 1.5rem;
`

const SelectedColorWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content 1fr;
  width: 100%;

  .active {
    opacity: 1;
    border: 2px solid ${palette.shadowBlue};
    border-right: none;
    border-top-left-radius: ${pxToRem(4)}rem;
    border-bottom-left-radius: ${pxToRem(4)}rem;
    height: 100%;
  }
`

const ColorName = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};
  font-family: GilmerMedium, sans-serif;
  text-align: center;
  color: ${palette.tangaroa};
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${getFluidSizeWithFullFormula('px', 98, 206, 1366, 3840)};
  white-space: nowrap;
`

const AfaPdpDropAndSizeTab: React.FC = () => {
  const { t } = useTranslation()

  const { modelCode } = useParams<{ modelCode: string }>()

  const { selectedColor, setSelectedColor } = useAfaSelectedColor(modelCode)

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data

  const colors = Object.values(product?.mocos || {})

  const footer = document.getElementsByTagName('footer')[0] as HTMLElement
  const { height: footerHeight } = useElementDimensions(footer)

  useEffect(() => {
    if (!selectedColor && product && colors.length > 0) {
      const colorCode = getDefaultColor(product)?.colorCode
      if (colorCode) setSelectedColor(colorCode)
    }
  }, [selectedColor, product, setSelectedColor, colors.length])

  return (
    <Wrapper>
      <Header>
        <Title>{t('Afa.setSizeAndQuantityForEachShipment')}</Title>
        <SwitcherWrapper>
          <AfaPdpTabSwitcher />
        </SwitcherWrapper>
      </Header>
      {productQuery.isFetching && <Loading />}

      {product && selectedColor && (
        <>
          <SelectedColorWrapper>
            <ImageWrapper className="active">
              <Img
                src={getProductImageUrl({ path: selectedColor.catalogImgPath, imwidth: 100 })}
                alt=""
              />
            </ImageWrapper>
            <AfaPdpSelectedColorRecap
              name={product.name}
              modelCode={modelCode}
              selectedColor={selectedColor}
            />
          </SelectedColorWrapper>

          <Colors footerHeight={footerHeight}>
            <ColorImagesWrapper>
              {colors.map(({ colorCode, colorDescription, catalogImgPath }) => (
                <div key={colorCode}>
                  <ImageWrapperColors
                    className={selectedColor.colorCode === colorCode ? 'active' : ''}
                  >
                    <Img
                      src={getProductImageUrl({ path: catalogImgPath, imwidth: 100 })}
                      alt=""
                      onClick={() => setSelectedColor(colorCode)}
                    />
                  </ImageWrapperColors>
                  <ColorName>{colorDescription}</ColorName>
                </div>
              ))}
            </ColorImagesWrapper>
            <SelectedColorData>
              <ColorDoorsAndDropsWithMargin color={selectedColor} />
              <SwitcherWrapper4K>
                <AfaPdpTabSwitcher inputName="afaPdpTabSwitcher4K" />
              </SwitcherWrapper4K>
            </SelectedColorData>
          </Colors>
        </>
      )}
    </Wrapper>
  )
}

export default AfaPdpDropAndSizeTab
