import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { AfaBrand, BrandCategories } from '../model/afa'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'

export const afaApi = createApi({
  reducerPath: 'afaApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/afa`),
  endpoints: builder => ({
    getCustomer: builder.query<
      {
        id: string
        name?: string
        nationality?: string | null
        currency?: string
        stars: boolean
        afawarehouse: string
      },
      {
        customerId: string
        eventId: string
      }
    >({
      query: ({ customerId, eventId }) => ({
        url: `/customer/${customerId}?eventId=${eventId}`,
      }),
    }),

    getCustomerAssortmentTypes: builder.query<
      string[],
      {
        customerId: string
        eventId: string
      }
    >({
      query: ({ customerId, eventId }) => ({
        url: `/customer/${customerId}/assortments?eventId=${eventId}`,
      }),
    }),

    getBrands: builder.query<
      AfaBrand[],
      {
        customerId: string
        eventId: string
      }
    >({
      query: ({ customerId, eventId }) => ({
        url: `/brands?eventId=${eventId}&customerId=${customerId}`,
      }),
    }),

    getBrandCategories: builder.query<
      BrandCategories,
      {
        customerId: string
        eventId: string
        brandCode: string
        afawarehouse: string
      }
    >({
      query: ({ customerId, eventId, brandCode, afawarehouse }) => ({
        url: `/brand/${brandCode}/categories?eventId=${eventId}&customerId=${customerId}&afawarehouse=${afawarehouse}`,
      }),
    }),
  }),
})

export const useGetSelectedCustomerQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return afaApi.useGetCustomerQuery({
    eventId,
    customerId,
  })
}

export const useGetAfaWarehouse = () => {
  const customerQuery = useGetSelectedCustomerQuery()
  const afawarehouse = customerQuery.data?.afawarehouse
  return afawarehouse
}

export const useGetSelectedCustomerAssortmentTypesQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return afaApi.useGetCustomerAssortmentTypesQuery({
    eventId,
    customerId,
  })
}

export const useGetBrandsQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return afaApi.useGetBrandsQuery({
    eventId,
    customerId,
  })
}

export const useGetAfaBrandByCode = (brandCode: string) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return afaApi.useGetBrandsQuery(
    {
      eventId,
      customerId,
    },
    {
      selectFromResult: result => {
        return { ...result, data: result.data?.find(({ code }) => code === brandCode) }
      },
    },
  )
}

export const useGetBrandCategoriesQuery = (brandCode: string) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const afawarehouse = useGetAfaWarehouse() || ''

  return afaApi.useGetBrandCategoriesQuery(
    { eventId, customerId, brandCode, afawarehouse },
    { skip: !afawarehouse || !brandCode },
  )
}

export const { useGetCustomerAssortmentTypesQuery } = afaApi
