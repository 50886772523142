import { ToastPosition, TypeOptions, toast } from 'react-toastify'
import SuccessIcon from './SuccessIcon'
import ErrorIcon from './ErrorIcon'
import NoticeIcon from './NoticeIcon'

const DURATION = 5000 //milliseconds

type NotificationsPositions =
  | 'TOP_LEFT'
  | 'TOP_CENTER'
  | 'TOP_RIGHT'
  | 'BOTTOM_LEFT'
  | 'BOTTOM_CENTER'
  | 'BOTTOM_RIGHT'

type NotificationCommonProps = {
  className?: string
  duration?: number | false
  icon?: JSX.Element
  position?: NotificationsPositions
  toastId?: number | string
}

type NotificationWithString = ({
  message,
  useAsAfa,
}: {
  message: string
  useAsAfa?: boolean
} & NotificationCommonProps) => void

type NotificationWithComponent = ({
  component,
  notificationType,
}: {
  component: React.ReactNode
  notificationType: 'success' | 'error'
} & NotificationCommonProps) => void

const setNotificationPosition = (position?: NotificationsPositions): ToastPosition => {
  let toastPosition: ToastPosition

  switch (position) {
    case 'TOP_LEFT':
      toastPosition = toast.POSITION.TOP_LEFT
      break
    case 'TOP_CENTER':
      toastPosition = toast.POSITION.TOP_CENTER
      break
    case 'TOP_RIGHT':
      toastPosition = toast.POSITION.TOP_RIGHT
      break
    case 'BOTTOM_LEFT':
      toastPosition = toast.POSITION.BOTTOM_LEFT
      break
    case 'BOTTOM_CENTER':
      toastPosition = toast.POSITION.BOTTOM_CENTER
      break
    default:
      toastPosition = toast.POSITION.BOTTOM_RIGHT
      break
  }

  return toastPosition
}

export const notificationWithComponent: NotificationWithComponent = ({
  component,
  notificationType,
  duration = DURATION / 2,
  position: positionProp,
  className: classNameProp = '',
  icon: iconProp,
  toastId,
}) => {
  let toastIcon: JSX.Element
  let toastType: TypeOptions
  let toastClassName: 'afa-toast-success' | 'afa-toast-error'

  switch (notificationType) {
    case 'success':
      toastIcon = <SuccessIcon />
      toastType = toast.TYPE.SUCCESS
      toastClassName = 'afa-toast-success'
      break
    case 'error':
      toastIcon = <ErrorIcon />
      toastType = toast.TYPE.ERROR
      toastClassName = 'afa-toast-error'
      break
  }

  toast(component, {
    position: setNotificationPosition(positionProp),
    autoClose: duration,
    className: `afa-toast-with-links ${toastClassName} ${classNameProp}`.trim(),
    icon: iconProp || toastIcon,
    type: toastType,
    toastId,
    hideProgressBar: true,
  })
}

export const successNotification: NotificationWithString = ({
  message,
  className = '',
  duration = DURATION,
  position: positionProp,
  icon: iconProp,
  useAsAfa,
  toastId,
}) => {
  const isAfa = window.location.href.includes('afa')

  isAfa || useAsAfa
    ? toast.success(message, {
        position: setNotificationPosition(positionProp),
        autoClose: duration,
        className: `afa-toast-success ${className}`.trim(),
        icon: iconProp || <SuccessIcon />,
        toastId,
        hideProgressBar: true,
      })
    : toast.success(message, {
        position: setNotificationPosition(positionProp),
        autoClose: duration,
        className: `frames-toast ${className}`.trim(),
        icon: iconProp || <NoticeIcon />,
        toastId,
        hideProgressBar: true,
      })
}

export const errorNotification: NotificationWithString = ({
  message,
  className = '',
  duration = DURATION,
  position: positionProp,
  icon: iconProp,
  useAsAfa,
  toastId,
}) => {
  const isAfa = window.location.href.includes('afa')

  isAfa || useAsAfa
    ? toast.error(message, {
        position: setNotificationPosition(positionProp),
        autoClose: duration,
        className: `afa-toast-error ${className}`.trim(),
        icon: iconProp || <ErrorIcon />,
        toastId,
        hideProgressBar: true,
      })
    : toast.error(message, {
        position: setNotificationPosition(positionProp),
        autoClose: duration,
        className: `frames-toast ${className}`.trim(),
        icon: iconProp || <NoticeIcon />,
        toastId,
        hideProgressBar: true,
      })
}
