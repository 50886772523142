import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { brandsLoadedSelector, brandsSelector } from '../../../../store/brands/selectors'
import useFetchInitialData from '../../../../hooks/useFetchInitialData'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import RCIcon from '../../../../components/UI/RCIcon'
import { useHistory, useLocation } from 'react-router'
import { palette, getFluidSizeWithFullFormula as gF } from '../../../../style/theme'
import { getDeviceBasePath, isIPadView } from '../../../../libs/url'
import { doorLoadingStatusSelector } from '../../../../store/customer/selectors'
import Loading from '../../../../components/Loading'
import BrandLogo from './BrandLogo'
import { useHandleOpenAaChooseBrandPage } from '../../../../hooks/useHandleOpenPages'
import { useSearchParams } from '../../../../hooks/useSearchParams'

const NUMBER_OF_COLUMNS_DESKTOP = 7
const NUMBER_OF_COLUMNS_IPAD = 6

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.75vw;
  background-color: ${palette.cloudBurst};
  font-family: 'EuclidCircularB-Regular', sans-serif;
`

const BrandsGridWrapper = styled.div`
  background-image: linear-gradient(
    rgba(223, 239, 255, 0.96),
    rgba(255, 246, 246, 0.96),
    rgba(255, 246, 246, 0.96)
  );
  border-radius: 1vw;
  flex: 1;
  display: grid;
  overflow: hidden;
  padding: 0 1.5vw;
  grid-template-rows: auto 1fr auto;
`

const BrandsGridContainer = styled.div<{
  numberOfRows: number
  numberOfColumns: number
}>`
  display: grid;
  grid-template-columns: repeat(${({ numberOfColumns }) => numberOfColumns}, 1fr);
  width: 100%;
  height: 100%;
  gap: ${gF('px', 7, 10, 1024, 1920)};
  grid-template-rows: repeat(${({ numberOfRows }) => numberOfRows}, 1fr);
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${gF('px', 43, 61, 1366, 1920)};
  margin-top: ${gF('px', 26, 37, 1366, 1920)};
  margin-bottom: ${gF('px', 22, 32, 1366, 1920)};
  color: ${palette.midnight};
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5ch;
  font-size: ${gF('px', 44, 54, 1024, 1920)};
  line-height: 1;

  & > span:last-of-type {
    color: ${palette.cornflowerBlueAA};
  }
`

const Subtitle = styled.div`
  font-size: ${gF('px', 16, 20, 1024, 1920)};
  display: flex;
  justify-content: space-between;
`

const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${gF('px', 6, 9, 1024, 1920)};
`

const BrandsCount = styled.span`
  font-size: ${gF('px', 15, 18, 1366, 1920)};
  letter-spacing: -0.24px;
  color: ${palette.cornflowerBlueAA};
`

const SelectionFlag = styled.div`
  width: ${gF('px', 25, 32, 1366, 1920)};
  height: ${gF('px', 25, 32, 1366, 1920)};
  border-radius: 10px;
  border: solid 2px ${palette.cornflowerBlueAA};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.div<{ image: string }>`
  background-size: contain;
  background-repeat: no-repeat;
  ${({ image }) =>
    image === 'rectangle' &&
    css`
      width: 70%;
      height: 70%;
      margin-top: 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='3' viewBox='0 0 18 3' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='18' height='3' fill='black' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    `}
  ${({ image }) =>
    image === 'ticked' &&
    css`
      width: 65%;
      height: 65%;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.713 0c.366 0 .685.12.927.361.24.242.36.56.36.927 0 .15-.026.3-.077.45a1.984 1.984 0 0 1-.22.434L8.06 17.314c-.141.216-.32.387-.533.506-.218.12-.462.18-.726.18-.495 0-.926-.235-1.265-.667L.359 10.864a1.71 1.71 0 0 1-.262-.446A1.342 1.342 0 0 1 0 9.928c0-.364.126-.685.375-.93.248-.243.569-.365.935-.365.454 0 .843.22 1.125.604l4.281 5.447L15.57.676c.11-.18.233-.326.374-.434l.109-.074c.191-.114.414-.168.66-.168zM5.966 16.993l.355-.278c.16.203.31.285.479.285.1 0 .177-.019.24-.054a.521.521 0 0 0 .18-.175l9.643-15.14c.057-.087.094-.16.113-.216A.395.395 0 0 0 17 1.288c0-.113-.024-.176-.068-.22-.044-.045-.107-.068-.219-.068a.305.305 0 0 0-.148.027.562.562 0 0 0-.145.177L7.184 15.818l-.379.598-.437-.557-4.729-6.017c-.113-.153-.212-.21-.33-.21-.111 0-.179.026-.233.08-.052.05-.076.112-.076.216 0 .034.008.076.028.127a.72.72 0 0 0 .112.185l5.178 6.472-.352.281z' fill='black' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
    `}
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2em;
  margin-bottom: 2.5em;
  font-size: clamp(18px, 1vw, 36px);
`

const SwitcherWrapper = styled.div`
  justify-self: end;
  display: flex;
  flex-direction: row;
  padding: 0.45em;
  border-radius: 1em;
  background-color: rgba(68, 138, 244, 0.12);
  cursor: pointer;
  line-height: 1;
  height: min-content;
  align-self: end;
  font-size: inherit;

  & > span {
    padding: 0.75em 1.25em;
    color: ${palette.cornflowerBlueAA};
  }

  & > span.active {
    background-color: ${palette.white};
    border-radius: 0.75em;
    color: ${palette.midnight};
  }
`

const ShowAssortment = styled.div`
  justify-self: center;
  padding: 1em 2em;
  border-radius: 0.5em;
  background-color: ${palette.midnight};
  font-size: inherit;
  color: ${palette.white};
  cursor: pointer;

  &.disabled {
    cursor: default;
    background-color: ${palette.dustyGray};
  }
`

const GoBack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75vw;
  font-size: inherit;
  color: ${palette.midnight};
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.midnight};
    border-radius: 1em;
    height: 2.5em;
    width: 2.5em;
  }

  img {
    height: 1em;
    width: 1em;
  }
`

const AAChooseBrand: React.FC = () => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const brands = useSelector(brandsSelector)
  const doorsLoadingStatus = useSelector(doorLoadingStatusSelector)
  const brandsLoaded = useSelector(brandsLoadedSelector)
  const columns = isIPadView() ? NUMBER_OF_COLUMNS_IPAD : NUMBER_OF_COLUMNS_DESKTOP
  const rows = Math.ceil(brands.length / columns)
  const [searchParams, setSearchParams] = useSearchParams()
  const isFromPlp = location.state === 'from-plp'
  const brandsParams = searchParams.get('brands')
  const isJunior = searchParams.get('junior') === 'true'

  const selectedBrandCodes = useMemo(() => {
    const brandCodes = brandsParams
    return brandCodes ? brandCodes.split(',') : []
  }, [brandsParams])

  const filteredBrands = useMemo(() => {
    const filteredBrands = brands.filter(brand => {
      return brand.subBrands.some(subBrand => {
        return subBrand.junior === isJunior
      })
    })
    return filteredBrands
  }, [brands, isJunior])

  const selectionFlagImage = useMemo(() => {
    let imageToShow = ''
    if (filteredBrands.length > 0 && selectedBrandCodes.length === filteredBrands.length) {
      imageToShow = 'ticked'
    } else if (selectedBrandCodes.length > 0 && selectedBrandCodes.length < filteredBrands.length) {
      imageToShow = 'rectangle'
    }
    return imageToShow
  }, [filteredBrands, selectedBrandCodes])

  const setSelectedBrandCodes = useCallback(
    (brandCodes: string[] | null) => {
      if (brandCodes === null) {
        searchParams.delete('brands')
      } else {
        searchParams.set('brands', brandCodes.join(','))
      }
      setSearchParams(searchParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  const toggleSelectedBrand = useCallback(
    (brandCode: string) => {
      const newSelectedBrandCodes = [...selectedBrandCodes]
      if (newSelectedBrandCodes.includes(brandCode)) {
        newSelectedBrandCodes.splice(
          newSelectedBrandCodes.findIndex(code => code === brandCode),
          1,
        )
      } else {
        newSelectedBrandCodes.push(brandCode)
      }

      if (newSelectedBrandCodes.length !== selectedBrandCodes.length) {
        setSelectedBrandCodes(newSelectedBrandCodes)
      }
    },
    [selectedBrandCodes, setSelectedBrandCodes],
  )

  const setJunior = useCallback(
    (junior: boolean) => {
      if (junior) {
        searchParams.set('junior', 'true')
      } else {
        searchParams.delete('junior')
      }
      setSelectedBrandCodes(null)
    },
    [searchParams, setSelectedBrandCodes],
  )

  useEffect(() => {
    if (brandsParams === null && brands.length > 0) {
      const brandCodes = filteredBrands.map(({ code }) => code)
      setSelectedBrandCodes(brandCodes)
    }
  }, [brands, brandsParams, filteredBrands, searchParams, setSearchParams, setSelectedBrandCodes])

  useHandleOpenAaChooseBrandPage()

  const doorsLoaded = ['ok', 'ko'].includes(doorsLoadingStatus)

  const handleSelection = () => {
    if (selectionFlagImage === '') {
      const allBrandCodes = filteredBrands.map(brand => brand.code)
      setSelectedBrandCodes(allBrandCodes)
    } else {
      setSelectedBrandCodes([])
    }
  }

  return (
    <Wrapper>
      <BrandsGridWrapper>
        <Header>
          <Title>
            <span>{t('AA.Brands.Title1')}</span>
            <span>{t('AA.Brands.Title2')}</span>
          </Title>
          <Subtitle>
            <span>{t('AA.brandsSelection')}</span>
            <SelectionWrapper>
              <BrandsCount>{`(${selectedBrandCodes.length})`}</BrandsCount>
              <span>{selectedBrandCodes.length === 0 ? t('AA.selectAll') : t('AA.selected')}</span>
              <SelectionFlag onClick={handleSelection}>
                <Image image={selectionFlagImage} />
              </SelectionFlag>
            </SelectionWrapper>
          </Subtitle>
        </Header>

        <BrandsGridContainer numberOfRows={rows} numberOfColumns={columns}>
          {!brandsLoaded || !doorsLoaded ? (
            <Loading />
          ) : (
            filteredBrands.map(brand => (
              <BrandLogo
                selected={selectedBrandCodes.includes(brand.code)}
                key={brand.code}
                brand={brand}
                onClick={() => toggleSelectedBrand(brand.code)}
              />
            ))
          )}
        </BrandsGridContainer>

        <Footer>
          <GoBack onClick={() => history.push(`${getDeviceBasePath()}/choose-brand`)}>
            <div>
              <RCIcon type="back" />
            </div>
            {t('AA.Brands.Back')}
          </GoBack>
          <ShowAssortment
            className={selectedBrandCodes.length === 0 ? 'disabled' : ''}
            onClick={() => {
              if (selectedBrandCodes.length > 0) {
                const url = `${getDeviceBasePath()}/assortment-advisor/plp?brands=${selectedBrandCodes.join(
                  ',',
                )}${isJunior ? '&junior=true' : ''}`
                if (isFromPlp) {
                  history.replace(url)
                } else {
                  history.push(url, 'from-choose-brand')
                }
              }
            }}
          >
            {t('AA.Brands.ShowAssortment')}
          </ShowAssortment>
          <SwitcherWrapper>
            <span
              onClick={() => {
                setJunior(false)
              }}
              className={isJunior ? '' : 'active'}
            >
              {t('AA.Brands.Adults')}
            </span>
            <span
              onClick={() => {
                setJunior(true)
              }}
              className={isJunior ? 'active' : ''}
            >
              {t('AA.Brands.Kids')}
            </span>
          </SwitcherWrapper>
        </Footer>
      </BrandsGridWrapper>
    </Wrapper>
  )
}

export default AAChooseBrand
