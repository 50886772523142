import {
  AfaCartProduct,
  AfaDrop,
  AfaMoco,
  AfaPrebook,
  AfaProduct,
  AfaSeasonSale,
} from '../model/afa'
import { DdMmYyyyDateString, Upc } from '../model/model'
import { countries } from './countries'
import { convertDdMmYyyyToDate, sortDdMmYyyyDates } from './time'
import { isNotUndefined } from './utils'
import { sortProductsByDelivery } from './productsV2'

export const getDefaultColor = (product?: AfaProduct): AfaMoco | undefined => {
  const colors = Object.values(product?.mocos || {})

  const defaultColor = colors.sort((c1, c2) => c1.order - c2.order)[0]

  return defaultColor
}

const categoriesOrder = ['goggles', 'helmets', 'apparel', 'accessories', 'footwear']
const categoriesFallbackOrder = ['apparel', 'goggles', 'helmets', 'footwear', 'accessories']

export const sortCategoriesFunction = (fallback?: boolean) => (
  category1: { name: string },
  category2: { name: string },
) => {
  const order = fallback ? categoriesFallbackOrder : categoriesOrder
  return order.indexOf(category1.name.toLowerCase()) - order.indexOf(category2.name.toLowerCase())
}

export const sortAfaSizes = (s1: string, s2: string) => {
  const size1 = s1 || ''
  const size2 = s2 || ''

  const conventionalSizeMap: Record<string, number> = {
    XXXS: 1,
    'XXXS/XXS': 2,
    XXS: 3,
    'XXS/XS': 4,
    XS: 5,
    'XS/S': 6,
    S: 7,
    'S/M': 8,
    M: 9,
    'M/L': 10,
    L: 11,
    'L/XL': 12,
    XL: 13,
    'XL/XXL': 14,
    XXL: 15,
    'XXL/XXXL': 16,
    XXXL: 17,
    U: 18,
  }

  const conventionalSizes = Object.keys(conventionalSizeMap)
  const isConventionalSize = conventionalSizes.includes(size1) && conventionalSizes.includes(size2)
  if (isConventionalSize) {
    return conventionalSizeMap[size1] - conventionalSizeMap[size2]
  }

  const decimalSeparator = isNaN(Number('1.1')) ? ',' : '.'
  const wrongDecimalSeparator = decimalSeparator === '.' ? ',' : '.'
  const s1Num = Number(size1.replace(wrongDecimalSeparator, decimalSeparator))
  const s2Num = Number(size2.replace(wrongDecimalSeparator, decimalSeparator))
  const isNumberSize = !isNaN(s1Num) && !isNaN(s2Num)
  if (isNumberSize) {
    return s1Num - s2Num
  }

  // we can treat all sizes that have 2 numbers separated by a char as if they were decimal numbers:
  // 42x32 -> 42,32
  // 11-12 -> 11,12
  // 10/11 -> 10,11
  const separatedNumbersRegExp = new RegExp('^([0-9.,]+)[Xx/-]([0-9.,]+)$')
  const separatedNumbersMatch1 = size1.match(separatedNumbersRegExp)
  const separatedNumber1 = separatedNumbersMatch1
    ? Number(
        `${separatedNumbersMatch1[1]}${decimalSeparator}${
          Number(separatedNumbersMatch1[2]) > 9 ? '' : 0
        }${separatedNumbersMatch1[2]}`,
      )
    : 0
  const separatedNumbersMatch2 = size2.match(separatedNumbersRegExp)
  const separatedNumber2 = separatedNumbersMatch2
    ? Number(
        `${separatedNumbersMatch2[1]}${decimalSeparator}${
          Number(separatedNumbersMatch2[2]) > 9 ? '' : 0
        }${separatedNumbersMatch2[2]}`,
      )
    : 0
  if (separatedNumber1 !== 0 && separatedNumber2 !== 0) {
    return separatedNumber1 - separatedNumber2
  }

  // sort sizes like 17WDE, 195CM, 16REG, ecc.
  const numbersFollewedByChars = new RegExp(/^([0-9.,]+)\w+$/)
  const s1NumbersFollewdByChars = size1.match(numbersFollewedByChars)
  const s1OnlyNumbers = s1NumbersFollewdByChars
    ? Number(s1NumbersFollewdByChars[1].replace(wrongDecimalSeparator, decimalSeparator))
    : 0
  const s2NumbersFollewdByChars = size2.match(numbersFollewedByChars)

  const s2OnlyNumbers = s2NumbersFollewdByChars
    ? Number(s2NumbersFollewdByChars[1].replace(wrongDecimalSeparator, decimalSeparator))
    : 0
  const thereAreNumbers = s1OnlyNumbers !== 0 && s2OnlyNumbers !== 0
  if (thereAreNumbers) {
    return s1OnlyNumbers - s2OnlyNumbers
  }

  return size1.localeCompare(size2)
}

export const isSeasonSale = (drop: AfaDrop): drop is AfaSeasonSale => drop.type === 'SEASON'

export const isPrebook = (drop: AfaDrop): drop is AfaPrebook => drop.type === 'DROP'

export const getCartRowAvailableQuantity = (
  { upc, availabilityDate }: { upc: string; availabilityDate: DdMmYyyyDateString },
  availability?: Record<Upc, AfaDrop[]>,
) => {
  if (!availability) {
    return undefined
  }

  const cartRowSeasonSale = (availability[upc] || []).find(
    drop => drop.availabilityDate === availabilityDate,
  )

  return cartRowSeasonSale?.availableQuantity
}

export const addCalculatedAvailability = (products: AfaCartProduct[], availability?: number) => {
  return products.reduce(
    (products, { availabilityDate, upc, deliveryDate, unconfirmedQuantity, key }, index) => {
      products.push({
        upc,
        deliveryDate,
        unconfirmedQuantity,
        availabilityDate,
        key,
        calculatedAvailability:
          index >= 1 && availability && products[index - 1].calculatedAvailability
            ? Number(products[index - 1].calculatedAvailability) -
              products[index - 1].unconfirmedQuantity
            : availability,
      })

      return products
    },
    [] as {
      upc: string
      deliveryDate: string
      availabilityDate: DdMmYyyyDateString
      calculatedAvailability?: number
      unconfirmedQuantity: number
      key: string
    }[],
  )
}

export const getUpcAvailabilityByDelivery = ({
  availabilityDate,
  deliveryDate,
  cartProducts,
  availabilityByUpc,
  upc,
  groupDoors,
}: {
  upc: string
  cartProducts: AfaCartProduct[]
  availabilityDate: string
  deliveryDate: string
  availabilityByUpc: { upc: string; availableQuantity?: number }[]
  groupDoors?: boolean
}) => {
  const sizeFromAllDevileries = cartProducts
    .filter(
      cartProduct => cartProduct.upc === upc && cartProduct.availabilityDate === availabilityDate,
    )
    .sort(sortProductsByDelivery)

  const availabilitySize = availabilityByUpc.find(availability => availability.upc === upc)
    ?.availableQuantity

  if (availabilitySize === undefined) {
    return undefined
  }

  const upcsFromAllDevileriesWithAvailability = addCalculatedAvailability(
    sizeFromAllDevileries,
    availabilitySize,
  )

  const upcAvailabilityByDelivery = upcsFromAllDevileriesWithAvailability
    .filter(cartProduct => cartProduct.upc === upc && cartProduct.deliveryDate === deliveryDate)
    .sort((a, b) => {
      if (!a.calculatedAvailability || !b.calculatedAvailability) return 0
      if (groupDoors) {
        return a.calculatedAvailability - b.calculatedAvailability
      } else {
        return b.calculatedAvailability - a.calculatedAvailability
      }
    })[0]?.calculatedAvailability

  return upcAvailabilityByDelivery
}

export const getUnavailableKeys = (
  cartProducts: AfaCartProduct[],
  availability?: Record<string, AfaDrop[]>,
) => {
  if (!availability) {
    return cartProducts.map(({ key }) => key)
  }

  const groupedUpcs = cartProducts.reduce((result, cartProduct) => {
    const key = `${cartProduct.upc}___${cartProduct.availabilityDate}`
    result[key] = result[key] ? result[key].concat(cartProduct) : [cartProduct]
    return result
  }, {} as Record<string, AfaCartProduct[]>)

  const unavailableKeys = Object.values(groupedUpcs).flatMap(groupedCartProducts => {
    const availableQuantity = getCartRowAvailableQuantity(groupedCartProducts[0], availability)

    const groupedCartProductsWithCalculatedAvailability = addCalculatedAvailability(
      groupedCartProducts,
      availableQuantity,
    )

    return groupedCartProductsWithCalculatedAvailability
      .filter(product => {
        const {
          availabilityDate,
          upc,
          deliveryDate,
          unconfirmedQuantity,
          calculatedAvailability,
        } = product

        const availability = getUpcAvailabilityByDelivery({
          cartProducts,
          upc,
          availabilityDate,
          availabilityByUpc: [{ upc, availableQuantity }],
          deliveryDate,
          groupDoors: true,
        })

        return (
          availableQuantity !== undefined &&
          availability !== undefined &&
          calculatedAvailability !== undefined &&
          unconfirmedQuantity > calculatedAvailability
        )
      })
      .map(({ key }) => key)
  })

  return unavailableKeys
}

export const applyAfaFilters = (
  {
    sizes,
    sports,
    colors,
    technologies,
    genders,
    season,
    families,
    productTypes,
    collections,
    limitedReleases,
    fabrics,
    fabricGroups,
    fits,
    afaStyles,
    categories,
    avds,
    adv,
    hasKeylooks,
    afacatalogid,
  }: {
    sizes?: string[] | null
    sports?: string[] | null
    colors?: string[] | null
    technologies?: string[] | null
    genders?: string[] | null
    season?: string[] | null
    families?: string[] | null
    productTypes?: string[] | null
    collections?: string[] | null
    limitedReleases?: string[] | null
    fabrics?: string[] | null
    fabricGroups?: string[] | null
    fits?: string[] | null
    afaStyles?: string[] | null
    categories?: string[] | null
    avds?: string[] | null
    adv?: string[] | null
    hasKeylooks?: string[] | null
    afacatalogid?: string[] | null
  },
  cartProductDetails: AfaProduct,
  cartProduct: AfaCartProduct,
) => {
  const model = cartProductDetails
  const moco = cartProductDetails.mocos[cartProduct.colorCode]
  const upc = cartProductDetails.mocos[cartProduct.colorCode]?.sizes[cartProduct.upc]
  const mocoAvds = moco?.availabilityDataList.map(avd => avd.avdCode)

  return [
    {
      selectedOptions: sizes,
      check: !!upc?.size && sizes?.includes(upc?.size),
    },
    {
      selectedOptions: sports,
      check: sports?.some(sport => moco?.sports.includes(sport)),
    },
    {
      selectedOptions: colors,
      check: !!moco?.colorDescription && colors?.includes(moco?.colorDescription),
    },
    {
      selectedOptions: technologies,
      check: technologies?.some(technology => moco?.technologies.includes(technology)),
    },
    {
      selectedOptions: genders,
      check: !!moco?.gender && genders?.includes(moco?.gender),
    },
    {
      selectedOptions: season,
      check: season?.some(season => upc?.season.includes(season)),
    },
    {
      selectedOptions: afacatalogid,
      check: afacatalogid?.some(afacatalogid => upc?.afacatalogid.includes(afacatalogid)),
    },
    { selectedOptions: families, check: !!model.family && families?.includes(model.family) },
    {
      selectedOptions: productTypes,
      check: !!model.productType && productTypes?.includes(model.productType),
    },
    {
      selectedOptions: collections,
      check: !!model.collection && collections?.includes(model.collection),
    },
    {
      selectedOptions: limitedReleases,
      check: !!upc?.limitedRelease && limitedReleases?.includes(upc?.limitedRelease),
    },
    { selectedOptions: fabrics, check: !!upc?.fabric && fabrics?.includes(upc?.fabric) },
    {
      selectedOptions: fabricGroups,
      check: !!model.fabricGroup && fabricGroups?.includes(model.fabricGroup),
    },
    {
      selectedOptions: fits,
      check: !!upc?.fit && fits?.includes(upc?.fit),
    },
    {
      selectedOptions: afaStyles,
      check: !!model?.afaStyle && afaStyles?.includes(model?.afaStyle),
    },
    {
      selectedOptions: categories,
      check: !!model?.category && categories?.includes(model?.category),
    },
    {
      selectedOptions: avds,
      check: !!moco?.availabilityDataList.length && avds?.some(avd => mocoAvds.includes(avd)),
    },
    {
      selectedOptions: adv,
      check: !!upc?.adv,
    },
    {
      selectedOptions: hasKeylooks,
      check: !!moco?.keylooks && moco.keylooks.length > 0,
    },
  ].every(({ selectedOptions, check }) => (!selectedOptions ? true : check))
}

export const getNextAvailability = (
  afaCartProduct: AfaCartProduct[],
  data?: Record<string, AfaDrop[]> | undefined,
): string | undefined => {
  const mocoDeliveryDates = afaCartProduct
    .flatMap(product => {
      return data && data[product.upc]
    })
    .filter(isNotUndefined)
    .map(({ minDeliveryDate }) => minDeliveryDate)

  const mocoDeliveryDates_deduplicated = [...new Set(mocoDeliveryDates)]

  const mocoDeliveryDates_sorted = mocoDeliveryDates_deduplicated.sort(sortDdMmYyyyDates)

  const dropIndex = mocoDeliveryDates_sorted.indexOf(afaCartProduct[0]?.minDeliveryDate)

  return mocoDeliveryDates_sorted[dropIndex + 1]
}

export const getCountryName = (countryCode: string) => {
  let countryName = ''

  if (countryCode.length === 2) {
    countryName =
      countries.find(country => country.twoChars.toLowerCase() === countryCode.toLowerCase())
        ?.name || ''
  } else if (/^\d+$/.test(countryCode) && countryCode.length === 3) {
    //test if countryCode is numeric
    countryName = countries.find(country => country.code === countryCode)?.name || ''
  } else if (countryCode.length === 3) {
    countryName =
      countries.find(country => country.threeChars.toLowerCase() === countryCode.toLowerCase())
        ?.name || ''
  }

  return countryName
}

export const removeAfaCartDuplicates = (products: AfaCartProduct[]): AfaCartProduct[] => {
  return products.reduce((acc: AfaCartProduct[], current: AfaCartProduct) => {
    const existingProduct = acc.find(p => p.upc === current.upc)
    if (!existingProduct) {
      acc.push(current)
    }
    return acc
  }, [])
}

export const sortDropsByMinDeliveryDate = (
  { minDeliveryDate: minDeliveryDate1 }: { minDeliveryDate: string },
  { minDeliveryDate: minDeliveryDate2 }: { minDeliveryDate: string },
) => {
  const firstDate = (minDeliveryDate1 && convertDdMmYyyyToDate(minDeliveryDate1).getTime()) || 0
  const secondDate = (minDeliveryDate2 && convertDdMmYyyyToDate(minDeliveryDate2).getTime()) || 0
  if (firstDate > secondDate) {
    return 1
  } else if (firstDate < secondDate) {
    return -1
  } else {
    return 0
  }
}

export const getAllUnavailableCartProductsFromKeys = (
  cartProducts: AfaCartProduct[],
  unavailableKeys: string[],
) => {
  const unavailableCartProductsKeys = cartProducts
    .filter(({ key }) => unavailableKeys.includes(key))
    .map(({ availabilityDate, upc, dropType }) => `${availabilityDate}-${dropType}-${upc}`)
  const unavailableCartProduct = cartProducts.filter(({ availabilityDate, upc, dropType }) => {
    return unavailableCartProductsKeys.includes(`${availabilityDate}-${dropType}-${upc}`)
  })
  return unavailableCartProduct
}
