import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import afaActions from '../../store/afa/actions'
import { afaExpiredProductsToRemove } from '../../store/afa/selectors'

import {
  ButtonCancel,
  ButtonConfirm,
  ButtonsGroup,
  ModalTitle,
} from '../../components/AfaCommonComponents'
import { getFluidSizeWithFullFormula as gfs, pxToRem } from '../../style/theme'
import { useUpdateAfaCartProductsMutation } from '../../services/afaCart'
import { errorNotification } from '../../components/Notification/notifications'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;
  font-family: GilmerMedium;
`

const ModalText = styled.p`
  margin: ${pxToRem(72)}rem 0;
  font-family: GilmerMedium, sans-serif;
  font-size: ${gfs('px', 20, 40, 1366, 3840)};
`

const AfaPdpRemoveExpiredItemsModal: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [updateCartProducts, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation(
    'remove-expired-drops-products',
  )
  const expiredProductsToRemove = useSelector(afaExpiredProductsToRemove)

  useEffect(() => {
    if (updateAfaCartProductResult.isSuccess || updateAfaCartProductResult.isError) {
      if (updateAfaCartProductResult.isError) {
        errorNotification({
          message: t('Afa.errorTryingUpdateCart'),
        })
      }
      dispatch(afaActions.setExpiredProductsToRemove([]))
      dispatch(afaActions.togglePdpRemoveExpiredItemsModalOpen())
    }
  }, [t, dispatch, updateAfaCartProductResult])

  const removeItems = () => {
    updateCartProducts(
      (expiredProductsToRemove || []).map(product => ({ ...product, quantity: 0 })),
    )
  }

  const quantity = expiredProductsToRemove.reduce((sum, product) => {
    sum += product.unconfirmedQuantity || 0
    return sum
  }, 0)

  return (
    <Wrapper>
      <div>
        <ModalTitle>{t('Afa.Cart.RemoveItems')}</ModalTitle>
        <ModalText>{t('Afa.Cart.removeModalText', { numberOfItems: quantity })}</ModalText>
      </div>
      <ButtonsGroup>
        <ButtonCancel onClick={() => dispatch(afaActions.togglePdpRemoveExpiredItemsModalOpen())}>
          {t('Afa.Cart.Cancel')}
        </ButtonCancel>
        <ButtonConfirm onClick={removeItems}>{t('Afa.Cart.removeItems')}</ButtonConfirm>
      </ButtonsGroup>
    </Wrapper>
  )
}

export default AfaPdpRemoveExpiredItemsModal
