import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { useAfaFilters } from '../../hooks/useAfaFilters'
import { useSearchParams } from '../../hooks/useSearchParams'
import { AfaFacet } from '../../model/afa'
import {
  breakpoints,
  getFluidSizeWithFullFormula,
  getFluidSizeWithFullFormula as gF,
  palette,
} from '../../style/theme'
import Loading from '../Loading'
import AfaFiltersCategoryList from './AfaFiltersCategoryList'
import AfaFiltersChips from './AfaFiltersChips'
import AfaFiltersLayerCategoryOptions from './AfaFiltersLayerCategoryOptions'
import { useGetReleaseNameFormCmsAfaTag } from '../../hooks/useGetReleaseNameFromCmsAfaTag'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  position: relative;
  flex: 1;

  padding-top: ${gF('px', 40, 114, 1366, 3840)};
  padding-left: ${gF('px', 32, 114, 1366, 3840)};
  padding-bottom: ${gF('px', 32, 116, 1366, 3840)};
  padding-right: ${gF('px', 32, 114, 1366, 3840)};

  @media (max-width: ${breakpoints.M}) {
    padding-bottom: ${gF('px', 32, 18, 1024, 1366)};
  }

  --scrollbar-thumb-color: ${palette.white};
  --scrollbar-track-color: ${palette.shadowBlue};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`

const Title = styled.div`
  font-size: ${gF('px', 28, 40, 1366, 3840)};
  text-transform: capitalize;
`

const ResetButton = styled.button`
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  text-transform: capitalize;
`

const Categories = styled.div`
  flex-grow: 1;
  padding-top: ${gF('px', 32, 58, 1366, 3840)};
  display: grid;
  column-gap: 3rem;
  row-gap: 1rem;
  grid-template-columns: 40% 1fr;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    'categories options'
    'categories button';
  overflow: hidden;
`

const ShowResultsButton = styled.button`
  grid-area: button;
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  text-transform: uppercase;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: ${getFluidSizeWithFullFormula('px', 13, 24, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 56, 66, 1366, 3840)};
`

export const getAfaFilterTranslation = (t: TFunction, key: string, fallback: string) => {
  const translatedKey = t(key)
  return translatedKey === key ? fallback : translatedKey
}

type Props = {
  categories: AfaFacet[]
  showLoader: boolean
  defaultSelectedCategoryName: string
  numberOfResults?: number
  showResults: () => void
}

const AfaFiltersLayer: React.FC<Props> = ({
  categories,
  showLoader,
  defaultSelectedCategoryName,
  numberOfResults,
  showResults,
}) => {
  const location = useLocation()
  const { t } = useTranslation()

  const [selectedCategoryName, setSelectedCategoryName] = useState(defaultSelectedCategoryName)
  const selectedCategory: AfaFacet | undefined =
    categories.find(({ name }) => name === selectedCategoryName) || categories[0]
  const release = useGetReleaseNameFormCmsAfaTag()

  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedFilters] = useAfaFilters(categories)

  const filteredFacets = categories.filter(
    ({ name }) => (name[0] !== '_' || name === '_AVD_facet_') && name !== 'adv',
  )

  useEffect(() => {
    const sortedCategories = filteredFacets
      .filter(({ options }) => options.length)
      .sort((a, b) => a.name.localeCompare(b.name))

    if (sortedCategories.length && !selectedCategoryName) {
      setSelectedCategoryName(sortedCategories[0].name)
    }
  }, [categories, filteredFacets, selectedCategoryName])

  const filtersApplied = Object.values(selectedFilters || {}).some(options =>
    Object.values(options).some(Boolean),
  )

  const facetsForChips = categories
    .filter(({ name }) => {
      return (
        (name[0] === '_' || ['release', 'adv'].includes(name)) &&
        !['_AVD_facet_', '_PO_facet_'].includes(name)
      )
    })
    .map(category => {
      if (category.name === 'release') {
        return {
          name: release,
          options: category.options.filter(({ name }) => name === release),
        }
      } else {
        return category
      }
    })

  return (
    <Wrapper onClick={e => e.stopPropagation()}>
      {showLoader && <Loading isFullPage={false} />}

      <Header>
        <Title>{t('GenericWords.filter')}</Title>

        {filtersApplied && (
          <ResetButton
            onClick={() => {
              categories.forEach(category => {
                searchParams.delete(category.name)
              })
              searchParams.delete('hasKeylooks')
              searchParams.delete('adv')
              searchParams.delete('start')
              setSearchParams(searchParams, { replace: true, state: location.state })
            }}
          >
            {t('Afa.resetFilters')}
          </ResetButton>
        )}
      </Header>

      <AfaFiltersChips facets={facetsForChips} />

      <Categories>
        <AfaFiltersCategoryList
          facets={filteredFacets}
          selectedCategoryName={selectedCategoryName}
          setSelectedCategoryName={setSelectedCategoryName}
        />

        <AfaFiltersLayerCategoryOptions selectedCategory={selectedCategory} />

        <ShowResultsButton onClick={() => showResults()}>
          {t('Afa.showResults')}{' '}
          {filtersApplied && <span data-testid="number-of-products">({numberOfResults})</span>}
        </ShowResultsButton>
      </Categories>
    </Wrapper>
  )
}

export default AfaFiltersLayer
