import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import app_config from '../../config/app/config'
import { withTranslation } from 'react-i18next'

import { mapDispatchToProps } from '../../libs'
import { getDeviceAbsolutePath } from '../../libs/url'
import { history as PropHistory } from '../../types/react-router'
import { errorsSelector, eventIdSelector, customerIdSelector } from '../../store/app/selectors'
import { tokenSelector } from '../../store/auth/selectors'
import { apiLog } from '../../api/restApi'

import PageSocketManager from '../PageSocketManager'
import GlobalError from './GlobalError'
import { errorNotification } from '../Notification/notifications'

const handledTypes = Object.values(app_config.errorTypes)

class ErrorBoundary extends React.Component {
  componentDidUpdate = prevProps => {
    const { socketIoEvent } = this.props

    if (prevProps.socketIoEvent != socketIoEvent && socketIoEvent === 'do_clear_storage') {
      localStorage.clear()
      window.location.reload()
    }
  }

  componentDidCatch = error => {
    const isCustomError =
      error.type && (handledTypes.includes(error.type) || typeof error.type === 'object')

    apiLog(error)

    if (isCustomError) {
      this.handleCustomError(error)
    } else {
      this.props.actions.storeError(error)
    }
  }

  handleCustomError = error => {
    const { t } = this.props

    errorNotification({
      message: t(error.type.message || error.type),
    })
  }

  goBack = error => {
    const { actions, history } = this.props

    actions.removeError(error)
    history.replace(`${getDeviceAbsolutePath()}/choose-brand`)
  }

  render = () => {
    const { errors, children, token, eventId, customerId, t } = this.props

    return errors.length ? (
      <GlobalError
        errors={errors}
        goBack={this.goBack}
        eventId={eventId}
        customerId={customerId}
        token={token}
        t={t}
      />
    ) : (
      children
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.shape({
    storeError: PropTypes.func,
    removeError: PropTypes.func,
  }),
  errors: PropTypes.array,
  history: PropHistory,
  t: PropTypes.func,
  socketIoEvent: PropTypes.string,
  customerId: PropTypes.string,
  eventId: PropTypes.string,
  token: PropTypes.string,
}

const mapStateToProps = state => ({
  errors: errorsSelector(state),
  token: tokenSelector(state),
  eventId: eventIdSelector(state),
  customerId: customerIdSelector(state),
})

export { ErrorBoundary }
export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(withTranslation('common')(PageSocketManager(ErrorBoundary, ['do_clear_storage'])))
