import React, { useEffect, useState } from 'react'

type Props = {
  apiEndpoint: string
  children: ({
    loading,
    error,
    json,
  }: {
    loading: boolean
    error?: Error
    json?: Record<string, any>
  }) => JSX.Element
}

const ServiceApi: React.FC<Props> = ({ apiEndpoint, children }) => {
  const [config, setConfig] = useState<Record<string, any>>()
  const [error, setError] = useState<Error>()

  useEffect(() => {
    fetch(apiEndpoint)
      .then(r => r.json())
      .then(json => setConfig(json))
      .catch(e => setError(e))
  }, [apiEndpoint])

  return children({
    loading: error === undefined && config === undefined,
    error,
    json: config,
  })
}

export default ServiceApi
