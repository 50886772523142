import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  virtualMirrorConfigSelector,
  virtualMirrorUnmountSelector,
} from '../../store/pdp/selectors'
import pdpActions from '../../store/pdp/actions'

const CONTAINER_ID = 'vmmv-container'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .vmmv-menu-wrapper > button:last-of-type {
    visibility: hidden;
  }

  #${CONTAINER_ID} {
    width: 100%;
    height: 100%;
  }
`

const VirtualMirrorComponent: React.FC = () => {
  const dispatch = useDispatch()
  const configData = useSelector(virtualMirrorConfigSelector)
  const unmountStatus = useSelector(virtualMirrorUnmountSelector)
  const [mounted, setMounted] = useState(false)
  const [unmounting, setUnmounting] = useState(false)

  const vmapp = useMemo(() => {
    if (Object.keys(configData).length > 0 && configData.products.length > 0) {
      const vm = new window.vmmv.VMWidgetApp({
        ...configData,
        isTryOnEnabled: true,
        transitionFeature: { enabled: true },
      })
      return vm
    }
  }, [configData])

  useEffect(() => {
    if (vmapp?.root && !mounted) {
      setMounted(true)
      // eslint-disable-next-line
      console.info('vm init')
      vmapp.init()
    }
  }, [mounted, vmapp])

  useEffect(() => {
    if (unmountStatus === 'requested' && vmapp && !unmounting) {
      setUnmounting(true)
      // eslint-disable-next-line
      console.info('vm unmount')
      vmapp.unmount().then(() => {
        dispatch(pdpActions.setVirtualMirrorUnmountStatus('done'))
      })
    }
  }, [dispatch, unmountStatus, unmounting, vmapp])

  return <Wrapper id={CONTAINER_ID} />
}

export default VirtualMirrorComponent
