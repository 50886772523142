import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AfaFooter from '../../components/AfaFooter'
import AfaSearch from '../../components/AfaSearch/AfaSearch'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { isKamUserSelector } from '../../store/app/selectors'
import { NextButton } from '../AfaCheckout/components/AfaCheckoutCommonComponents'
import AfaCartBrands from './AfaCartBrands'
import AfaCartContent from './AfaCartContent'
import AfaCartHeader from './AfaCartHeader'
import AfaCartSummary from './AfaCartSummary/AfaCartSummary'
import AfaModal from '../../components/AfaModal'
import {
  cartAdjustAllKeysSelector,
  cartAdjustAllModalOpenSelector,
  duplicateCartOnDoorModalOpenSelector,
  shareCartOnDoorModalOpenSelector,
} from '../../store/afa/selectors'
import {
  afaCartApi,
  TAG_CART,
  useAdjustQuantitiesMutation,
  useGetAfaCartQuery,
  useGetSelectedDelivery,
  useUpdateAfaCartProductsMutation,
} from '../../services/afaCart'
import afaActions from '../../store/afa/actions'
import { useSearchParams } from '../../hooks/useSearchParams'
import AfaCancellationDateModal from '../../components/AfaCancellationDateModal/AfaCancellationDateModal'
import AfaDuplicateCartOnDoorsModal from './AfaDuplicateCart/AfaDuplicateCartOnDoorsModal'
import AfaCartAdjustAll from './AfaCartAdjustAll/AfaCartAdjustAll'
import AfaShareCart from './AfaShareCart/AfaShareCart'
import AfaCartAdjust from './AfaCartAdjust'
import { afaCartAdjustIsOpenSelector } from '../../store/afaCartAdjust/selectors'
import AfaCartFilterButton from './AfaCartFilterButton'
import AfaCartFilters from './AfaCartFilters'
import { errorNotification, successNotification } from '../../components/Notification/notifications'
import useAfaGetCartUnavailableKeys from '../../hooks/useAfaGetCartUnavailableKeys'
import { useGetExpiredCartProducts } from '../../hooks/useGetExpiredCartProducts'
import afaCartAdjustActions from '../../store/afaCartAdjust/actions'
import useAfaGetOutOfAssortmentCartProducts from '../../hooks/useAfaGetOutOfAssortmentCartProducts'
import afaCartOutOfAssortmentActions from '../../store/afaCartOutOfAssortment/actions'
import RemoveSelectedFromCart from '../../components/RemoveSelectedFromCart/RemoveSelectedFromCart'
import { afaCartOutOfAssortmentIsOpenSelector } from '../../store/afaCartOutOfAssortment/selectors'
import { useHistory, useParams } from 'react-router'
import { getDeviceAbsolutePath } from '../../libs/url'
import afaCartSummaryActions from '../../store/afaCartSummary/actions'
import { getAllUnavailableCartProductsFromKeys } from '../../libs/afa'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Main = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`

const AfaModalShare = styled(AfaModal)`
  > div {
    overflow: visible;
  }
`

const AfaCart: React.FC = () => {
  const adjustBypass = localStorage.getItem('rc-afa-adjust-checkout-bypass') !== null
  useFetchInitialData({ loadCustomer: true })

  const { t } = useTranslation()

  const showCheckoutButton = useSelector(isKamUserSelector)

  const openOutOfAssortmentModal = useSelector(afaCartOutOfAssortmentIsOpenSelector)
  const duplicateCartOnDoorModalOpen = useSelector(duplicateCartOnDoorModalOpenSelector)
  const isAdjustOpen = useSelector(afaCartAdjustIsOpenSelector)
  const checkAdjustCartOnDoorModalOpen = useSelector(cartAdjustAllModalOpenSelector)
  const shareCartOnDoorModalOpen = useSelector(shareCartOnDoorModalOpenSelector)
  const [checkoutAdjustNotNeeded, setCheckoutAdjustNotNeeded] = useState(false)

  const [updateCartProducts, updateAfaCartProductResult] = useUpdateAfaCartProductsMutation(
    'remove-out-of-assortment-products',
  )

  const { unavailableKeys } = useAfaGetCartUnavailableKeys()
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()
  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()

  const getCartQuery = useGetAfaCartQuery()
  const cartProducts = useMemo(() => {
    return getCartQuery.data?.items || []
  }, [getCartQuery.data?.items])

  const cartQuantity = cartProducts.reduce((result, item) => {
    result += item.unconfirmedQuantity
    return result
  }, 0)

  const dispatch = useDispatch()

  const [cancellationDatesModalOpen, setCancellationDatesModalOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDelivery = searchParams.get('delivery') || ''

  const setSelectedDelivery = useCallback(
    (delivery: string) => {
      searchParams.set('delivery', delivery)
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams, searchParams],
  )

  const selectedDeliveryDetails = useGetSelectedDelivery()

  const history = useHistory()

  const { assortment } = useParams<{
    assortment: string
  }>()

  useEffect(() => {
    if (!selectedDelivery && selectedDeliveryDetails) {
      setSelectedDelivery(selectedDeliveryDetails.deliveryDate)
    }
  }, [selectedDelivery, setSelectedDelivery, selectedDeliveryDetails])

  useEffect(() => {
    if (updateAfaCartProductResult.isError) {
      errorNotification({
        message: t('Afa.errorTryingUpdateCart'),
      })
    } else if (
      updateAfaCartProductResult.data?.warnings &&
      updateAfaCartProductResult.data?.warnings.length > 0
    ) {
      updateAfaCartProductResult.data?.warnings.forEach(message => {
        errorNotification({
          message: message.faultmessage,
        })
      })
    } else if (updateAfaCartProductResult.isSuccess && updateAfaCartProductResult.data?.result) {
      successNotification({
        message: t('Afa.Cart.Adjust.Success'),
      })
    }
  }, [updateAfaCartProductResult, t])

  const [adjustQuantities] = useAdjustQuantitiesMutation({
    cacheKey: 'adjustAll',
  })

  const keysToAdjust = useSelector(cartAdjustAllKeysSelector)

  const handleSubmit = () => {
    if (!cartQuantity) {
      errorNotification({
        message: t('Afa.Cart.addItems'),
      })
    }
    if (outOfAssortmentCartProducts.length > 0 && !adjustBypass) {
      dispatch(afaCartOutOfAssortmentActions.setIsOpen(true))
      return
    }

    if (expiredDropsCartProducts.length > 0 && !adjustBypass) {
      dispatch(afaCartAdjustActions.setCartProducts(expiredDropsCartProducts))
      dispatch(afaCartAdjustActions.setIsManageQuantities('expired'))
      dispatch(afaCartAdjustActions.setIsOpen(true))
      return
    }

    if (unavailableKeys.length > 0 && !adjustBypass) {
      dispatch(
        afaCartAdjustActions.setCartProducts(
          getAllUnavailableCartProductsFromKeys(cartProducts, unavailableKeys),
        ),
      )
      dispatch(afaCartAdjustActions.setIsOpen(true))
      return
    }

    setCheckoutAdjustNotNeeded(false)

    if (cartQuantity) {
      setCancellationDatesModalOpen(true)
    } else {
      dispatch(afaCartSummaryActions.hideMultidoorNotification())
      history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout`)
    }

    adjustQuantities({ simulate: true, keys: keysToAdjust })
    dispatch(afaActions.setAdjustAllIsSimulate(true))
  }

  useEffect(() => {
    if (updateAfaCartProductResult.isSuccess) {
      dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))
      updateAfaCartProductResult.reset()
    }
  }, [dispatch, updateAfaCartProductResult, updateAfaCartProductResult.isSuccess])

  const removeAllItems = () => {
    updateCartProducts(
      outOfAssortmentCartProducts.map(cartProduct => ({ ...cartProduct, quantity: 0 })),
    )
  }

  const adjustNeededCallback = () => {
    if (unavailableKeys.length > 0 && !adjustBypass) {
      dispatch(
        afaCartAdjustActions.setCartProducts(
          getAllUnavailableCartProductsFromKeys(cartProducts, unavailableKeys),
        ),
      )
      dispatch(afaCartAdjustActions.setIsOpen(true))
      setCancellationDatesModalOpen(false)
    }
  }

  const adjustNotNeededCallback = () => {
    setCheckoutAdjustNotNeeded(true)
  }

  const onCloseDuplicate = () => {
    duplicateCartOnDoorModalOpen && dispatch(afaActions.toggleDuplicateCartOnDoorModalOpen())
  }

  useEffect(() => {
    dispatch(afaCartApi.util.invalidateTags([TAG_CART]))
  }, [dispatch])

  const allKeys = useMemo(() => {
    return [...new Set(cartProducts.map(({ key }) => key))]
  }, [cartProducts])

  useEffect(() => {
    dispatch(afaActions.setCartAdjustAllKeys(allKeys))
  }, [allKeys, dispatch])

  return isAdjustOpen ? (
    <AfaCartAdjust />
  ) : (
    <Wrapper>
      <AfaSearch />

      <AfaCartHeader />

      <Main>
        <AfaCartFilters />
        <AfaCartBrands />
        <AfaCartContent />
        <AfaCartSummary />
      </Main>

      <AfaFooter
        leftColContent={<AfaCartFilterButton />}
        centralColContent={
          showCheckoutButton ? (
            <NextButton onClick={handleSubmit}>{t('Checkout.Footer.SubmitOrder')}</NextButton>
          ) : null
        }
      />

      <AfaModal isOpen={duplicateCartOnDoorModalOpen} onRequestClose={() => onCloseDuplicate()}>
        <AfaDuplicateCartOnDoorsModal onClose={() => onCloseDuplicate()} />
      </AfaModal>

      <AfaModal
        isOpen={checkAdjustCartOnDoorModalOpen}
        onRequestClose={() => {
          dispatch(afaActions.setCartAdjustAllKeys(allKeys))
          dispatch(afaActions.toggleCartAdjustAllModalOpen())
        }}
        fullscreen={false}
      >
        <AfaCartAdjustAll />
      </AfaModal>

      <AfaModalShare
        isOpen={shareCartOnDoorModalOpen}
        onRequestClose={() => {
          dispatch(afaActions.toggleShareCartOnDoorModalOpen())
        }}
        fullscreen={false}
      >
        <AfaShareCart onClose={() => dispatch(afaActions.toggleShareCartOnDoorModalOpen())} />
      </AfaModalShare>

      <AfaModal
        isOpen={cancellationDatesModalOpen}
        onRequestClose={() => {
          setCancellationDatesModalOpen(false)
        }}
      >
        {checkoutAdjustNotNeeded || adjustBypass ? (
          <AfaCancellationDateModal
            onCancel={() => {
              setCancellationDatesModalOpen(false)
            }}
          />
        ) : (
          <AfaCartAdjustAll
            notNeededCallback={adjustNotNeededCallback}
            neededCallback={adjustNeededCallback}
            isOpen={cancellationDatesModalOpen}
          />
        )}
      </AfaModal>

      <AfaModal
        isOpen={openOutOfAssortmentModal}
        onRequestClose={() => dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))}
      >
        <RemoveSelectedFromCart
          onCancel={() => dispatch(afaCartOutOfAssortmentActions.setIsOpen(false))}
          onRemoveItems={removeAllItems}
          title={t('Afa.Cart.Remove')}
          confirmText={t('Afa.Cart.RemoveItemsModalSubtitle')}
          cartProducts={outOfAssortmentCartProducts}
          updateCartIsLoading={updateAfaCartProductResult.isLoading}
        />
      </AfaModal>
    </Wrapper>
  )
}

export default AfaCart
