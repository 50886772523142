import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { useSearchParams } from '../../hooks/useSearchParams'
import afaActions from '../../store/afa/actions'
import { sortOpenSelector } from '../../store/afa/selectors'
import { breakpointsNoUnit, getFluidSizeWithFullFormula, palette } from '../../style/theme'
import Backdrop from '../../styleguide/Backdrop'
import Radio from '../../styleguide/Radio'

const Wrapper = styled.div`
  padding-top: ${getFluidSizeWithFullFormula('px', 40, 114, 1366, 3840)};
  padding-left: ${getFluidSizeWithFullFormula('px', 32, 114, 1366, 3840)};
  padding-bottom: ${getFluidSizeWithFullFormula('px', 18, 116, 1366, 3840)};
  padding-right: ${getFluidSizeWithFullFormula('px', 32, 114, 1366, 3840)};
  display: flex;
  flex-direction: column;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px gray;
  padding: 1rem 0;
  font-size: ${getFluidSizeWithFullFormula('px', 28, 40, 1366, 3840)};
`

const Body = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  gap: ${getFluidSizeWithFullFormula('px', 16, 44, 1366, 3840)};
`

const SortBackdrop = styled(Backdrop)`
  & > div {
    left: unset;
    top: 0;
    bottom: unset;
    right: 0;
  }

  @media (min-width: ${breakpointsNoUnit.M + 1}px) {
    & > div {
      left: 0;
      right: unset;
      top: unset;
      bottom: 0;
      transform: translateX(-100%);

      .is-open & {
        transform: translateX(0);
      }
    }
  }
`

const AfaSort: React.FC = () => {
  const sortOpen = useSelector(sortOpenSelector)

  const QS_KEY = 'sort'
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const sortByQuery = searchParams.get(QS_KEY) || 'model'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(QS_KEY, e.target.value)
    searchParams.set('start', '0')
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <SortBackdrop onClick={() => dispatch(afaActions.toggleSortOpen())} open={sortOpen}>
      <Wrapper onClick={e => e.stopPropagation()}>
        <Header>{t('Afa.sortBy')}</Header>
        <Body>
          {['relevance', 'newest', 'name', 'model', 'price_desc', 'price_asc'].map(sortByKey => (
            <Radio
              value={sortByKey}
              key={sortByKey}
              checked={sortByKey === sortByQuery}
              onChange={handleChange}
              backgroundColorChecked={palette.bermudaGray}
              backgroundColorUnchecked={palette.white}
              borderColor={palette.white}
            >
              {t(`Afa.sortByOptions.${sortByKey}`)}
            </Radio>
          ))}
        </Body>
      </Wrapper>
    </SortBackdrop>
  )
}

export default AfaSort
