import { Carousel } from 'antd'
import classNames from 'classnames'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { VtoImage } from '@luxottica/vto-image'

import { apiLog } from '../../api/restApi'
import app_config from '../../config/app/config'
import { arrayChunks, mapDispatchToProps } from '../../libs'
import log from '../../libs/log'
import { RootState } from '../../model/model'
import { NullableProductAttribute } from '../../model/product'
import {
  seeThemOnCurrentFacesBlockId,
  seeThemOnCurrentSlideSelector,
} from '../../store/pdp/selectors'
import { breakpoints } from '../../style/theme'
import VtoRender from './VtoRender/VtoRender'

const { vtoVideosId } = app_config

const getVideoFacesIds = (brandCode: string) => {
  // TODO: when implementing other clusters, remove RX and RW from switch case and use only brandcode of parent brand from the brands redux state
  switch (brandCode) {
    case 'RX':
    case 'RW':
    case 'RB':
      return [
        'RB-female-caucasian-1',
        'RB-male-caucasian-1',
        'RB-female-afro-1',
        'RB-male-afro-1',
        'RB-female-asian-1',
        'RB-male-asian-1',
      ]
    default:
      return [
        'XBRAND-female-caucasian-1-outfit-1',
        'XBRAND-female-asian-1-outfit-1',
        'XBRAND-female-afro-1-outfit-1',
        'XBRAND-male-caucasian-1-outfit-1',
        'XBRAND-male-asian-1-outfit-1',
        'XBRAND-male-afro-1-outfit-1',
      ]
  }
}

const Wrapper = styled.div`
  width: 26.5vw;
  height: 59vh;
  box-sizing: border-box;
  border: 0.1vh solid #cbcbcb;
  border-radius: 8px;
  padding: 2.5vh 2.5vh 4.5vh;
  position: relative;
  display: flex;
  flex-direction: column;

  &.hidden {
    visibility: hidden;
  }

  .ant-carousel {
    flex: 1;
    overflow: hidden;
  }

  &.view-type-single {
    --repeat-factor: 1;

    .vto__carousel .slick-dots li {
      width: 3.5%;
    }
    .ant-carousel .slick-dots li.slick-active {
      width: 2%;
    }
  }

  &.view-type-four {
    --repeat-factor: 2;
  }

  @media screen and (max-width: ${breakpoints.M}) {
    max-height: 30vh;
    width: auto;
  }

  .vto__carousel.slick-slider {
    position: static;
    height: 100%;

    .slick-list,
    .slick-track,
    .slick-slide > div,
    .slick-slide > div > div {
      height: 100%;
    }

    .slick-next,
    .slick-prev {
      bottom: calc(5% - 16px);
      width: 32px;
      height: 32px;
      top: auto;
    }
    .slick-prev,
    .slick-prev:focus {
      background-image: url('${app_config.publicUrl}/assets/images/carousel_arrow_SX.svg');
      z-index: 1;
      left: 0.5vh;
    }
    .slick-next,
    .slick-next:focus {
      background-image: url('${app_config.publicUrl}/assets/images/carousel_arrow_RX.svg');
      right: 0.5vh;
    }
    .slick-dots {
      bottom: 5%;
      left: 13%;
      width: 74%;
      display: flex !important;
      padding: 0 1;
      justify-content: space-between;
;
      li {
        flex: 1;
        .vto__dots {
          position: relative;
          .vto__button {
            position: absolute;
            background-size: cover;
            width: 4vh;
            height: 4vh;
            top: -1.8vh;
            cursor: pointer;
          }
        }
        button {
          background: #9a9a9a;
          width: 100%;
          height: 0.5vh;
          border-radius: 8px;
        }
        &.slick-active {
          flex: 0.5;
          button {
            width: 100%;
            background: #005192;
          }
        }
      }
    }

    .error-text{
      text-align: center;
    }

    .vto__container {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .render {
        flex: 1;
        width: 100%;
        overflow: hidden;

        &.image .vto-image-rotate-bar {
          display: none;
        }
      }

      .vto__shape {
        color: #333;
        font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
        font-size: 1.2vh;
        letter-spacing: 0.1vh;
        padding: 1vh 0;
        text-align: center;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .vto__page {
    position: absolute;
    bottom: 0;
    right: 2vh;

    button {
      color: #005192;
      font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
      font-size: 2rem;
      font-weight: bold;
      background-color: transparent;
      border-width: 0;
      @media screen and (max-width: 1920px) {
        font-size: 1rem;
      }
    }
  }

  .vto__disabled {
    width: 100%;
    height: 40vh;
    background-color: #dadada;
  }

  .vto__label {
    text-align: center;
    font-style: italic;
    color: black;
    margin-bottom: 0.5rem;
  }

  .vto__rotateIcon {
    width: 2rem;
    margin-left: 0.5rem;
  }
`

const SlideContent = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--repeat-factor), 1fr);
  grid-template-rows: repeat(var(--repeat-factor), 1fr);
  height: 100%;
`

type StateProps = {
  activeSlide: number
  currentFacesBlockId: null | string
}

type DispatchProps = {
  actions: {
    setSeeThemOnCurrentSlide: (index: number) => void
    setSeeThemOnCurrentFacesBlockId: (blockId?: string) => void
  }
}

type OwnProps = {
  brandCode: string
  isJunior?: boolean
  gender: NullableProductAttribute
  geofit: NullableProductAttribute
  upc: string
  hasImage: boolean
  hasVideo: boolean
  visible: boolean
  pageSize: number
  imgbrand?: string
}

type Props = StateProps &
  DispatchProps &
  OwnProps & {
    t: (key: string) => string
  }

type VideoIdData = {
  videoId: string
  loading: boolean
  loaded: boolean
  renderedAt: Date | null
}

type State = {
  capture: boolean
  upcAvailable: boolean
  userId?: string
  videoIds: VideoIdData[]
  slides: string[][]
  initialSlideIndex: number
  upc: string
  pageSize: undefined
}

class Vto extends Component<Props, State> {
  state: State = {
    capture: false,
    upcAvailable: true,
    userId: undefined,
    videoIds: [],
    slides: [],
    initialSlideIndex: 0,
    // bsaically upc and pageSize in state are just a mirror of the omonimous props
    // we store them in the state just to be able to check if they are changed
    // in the getDerivedStateFromProps method
    upc: '',
    pageSize: undefined,
  }

  slider?: Carousel | null
  vtoNode?: HTMLDivElement | null

  carouselSettings = {
    afterChange: (index: number) => {
      this.props.actions.setSeeThemOnCurrentSlide(index)
      this.renderVtoPage(index)
    },
    infinite: false,
    className: 'vto__carousel',
    touchMove: false,
    customPaging: (i: number) => (
      <div className="vto__dots">
        <button>{i + 1}</button>
      </div>
    ),
    ref: (c: Carousel | null) => {
      this.slider = c
    },
    onInit: () => {
      // update currentSlide on initialSlide change
      this.props.actions.setSeeThemOnCurrentSlide(this.state.initialSlideIndex)
      this.renderVtoPage(this.state.initialSlideIndex)
    },
    arrows: true,
  }

  componentDidMount = () => {
    this.renderVtoPage(this.props.activeSlide)
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.upc !== prevProps.upc) {
      this.renderVtoPage(this.props.activeSlide)
    }
    if (this.props.pageSize !== prevProps.pageSize) {
      this.renderVtoPage(this.state.initialSlideIndex)
    }
  }

  shouldComponentUpdate = (nextProps: Props) => {
    const { upc, visible, pageSize } = this.props
    if (upc !== nextProps.upc || visible !== nextProps.visible || pageSize !== nextProps.pageSize)
      return true
    return false
  }

  static selectVideoIdBlock = ({
    geofit,
    gender,
    isJunior,
  }: {
    geofit: NullableProductAttribute
    gender: NullableProductAttribute
    isJunior?: boolean
  }) => {
    if (isJunior) {
      return 'junior'
    } else if (geofit && geofit.id && geofit.id.toLowerCase() === 'asian') {
      return `asian${gender?.label.toLowerCase()}`
    } else {
      return gender?.id.toLowerCase()
    }
  }

  onInit = (userId: string, supportedFeatures: any) => {
    log.info({
      userId: userId,
      supportedFeatures: supportedFeatures,
    })
    this.setState({
      userId: userId,
    })
  }

  /**
   * Updates specified video's state and calls callback function if provided
   * (Callback is usually used to trigger loading of next VTO items in background)
   */
  updateVideoState = (
    videoId: string,
    isLoaded: boolean,
    isLoading: boolean,
    callback?: () => void,
  ) => {
    const updatedVideoInfo = {
      videoId,
      renderedAt: isLoaded ? new Date() : null,
      loaded: isLoaded,
      loading: isLoading,
    }

    this.setState(
      prevState => {
        const updatedVideoState = [...prevState.videoIds]
        const videoIndex = updatedVideoState.findIndex(v => v.videoId === videoId)
        updatedVideoState[videoIndex] = updatedVideoInfo
        return {
          videoIds: updatedVideoState,
        }
      },
      () => {
        callback && callback()
      },
    )
  }

  /**
   * Called when rendering VTO for 'videoId' is finished
   */
  onVtoRendered = (videoId: string) => {
    this.updateVideoState(videoId, true, false)
  }

  /**
   * Checks if there are VTO videos that have not been loaded
   * If such videos are found it triggers rendering maximum 'PAGE_SIZE' videos
   */
  renderNotLoadedVto = () => {
    const { pageSize } = this.props
    const hasLoadingVideo = this.state.videoIds.find(v => v.loading)
    if (!hasLoadingVideo) {
      const notLoadedVideos = this.state.videoIds.filter(v => !v.loaded)
      const videosToRender = notLoadedVideos.slice(0, pageSize) // render one page
      this.renderVtoItems(videosToRender)
    }
  }

  /**
   * Triggers rendering VTO items for page number provided
   */
  renderVtoPage = (page: number) => {
    if (this.props.visible) {
      const { pageSize } = this.props
      const pageItems = this.state.videoIds.slice(page * pageSize, (page + 1) * pageSize)
      this.renderVtoItems(pageItems)
    }
  }

  /**
   * Method which is used to trigger loading of VTO items
   */
  renderVtoItems = (items = [] as VideoIdData[]) => {
    items.forEach(({ videoId }) => this.renderVto(videoId))
  }

  /**
   * Renders VTO item. Calling vto package 'render' method is put inside setTimeout to prevent blocking UI
   */
  renderVto = (videoId: string) => {
    const index = this.state.videoIds.findIndex(v => v.videoId === videoId)
    const divId = `model-${index}`

    this.updateVideoState(videoId, false, true, () => {
      setTimeout(() => {
        VtoImage.renderImage({
          divId: divId,
          videoId: videoId,
          upc: this.props.upc,
        })
          .then(() => this.onVtoRendered(videoId))
          .catch(error => apiLog(error))
      })
    })
  }

  getShapeFromVideoId = (videoId: string) => {
    const videoIdParts = videoId.split('-')
    const genderPartIndex = videoIdParts && videoIdParts.findIndex(el => el.includes('male'))
    if (genderPartIndex !== -1 && genderPartIndex > 0) {
      return videoIdParts[genderPartIndex - 1]
    }
  }

  static getDerivedStateFromProps = (
    {
      activeSlide,
      pageSize,
      geofit,
      gender,
      isJunior,
      upc,
      currentFacesBlockId,
      actions,
      hasVideo,
      brandCode,
    }: Props,
    state: State,
  ) => {
    const facesBlockId = Vto.selectVideoIdBlock({ geofit, gender, isJunior })
    actions.setSeeThemOnCurrentFacesBlockId(facesBlockId)

    const videoFacesId = getVideoFacesIds(brandCode)
    const imageFacesId = facesBlockId
      ? ([] as string[]).concat(vtoVideosId[facesBlockId as '1' | '2' | '5'])
      : []
    const facesId = hasVideo ? videoFacesId : imageFacesId
    const slides = arrayChunks(facesId, pageSize)

    let initialSlideIndex = state.initialSlideIndex
    let videoIds = state.videoIds

    if (state.pageSize && state.pageSize !== pageSize) {
      initialSlideIndex = pageSize === 1 ? activeSlide * 4 : Math.floor(activeSlide / 4)
    }

    if (upc !== state.upc) {
      initialSlideIndex = activeSlide && activeSlide < slides.length ? activeSlide : 0
      initialSlideIndex = currentFacesBlockId === facesBlockId ? initialSlideIndex : 0

      videoIds = facesId.map((videoId, index) => ({
        videoId,
        renderedAt: state.videoIds[index] ? state.videoIds[index].renderedAt : null,
        //save renderedAt date if upc is changed (because we do not clear loaded vto)
        loaded: false,
        loading: false,
      }))
    }

    return {
      upc,
      slides,
      initialSlideIndex,
      pageSize,
      videoIds,
    }
  }

  render = () => {
    const { upc, visible, pageSize, hasVideo, t } = this.props

    return upc === '0' ? (
      <div className="vto__disabled" />
    ) : (
      <Wrapper
        className={classNames({
          vto: true,
          hidden: !visible,
          'view-type-single': this.props.pageSize === 1,
          'view-type-four': this.props.pageSize === 4,
        })}
        ref={node => (this.vtoNode = node)}
      >
        {!hasVideo && (
          <div className="vto__label">
            {t('Vto.clickAndDragToRotate')}
            <img
              className="vto__rotateIcon"
              src={`${app_config.publicUrl}/assets/images/rotate.svg`}
            />
          </div>
        )}
        <Carousel
          key={this.state.initialSlideIndex}
          {...this.carouselSettings}
          initialSlide={this.state.initialSlideIndex}
        >
          {this.state.slides.map((slide, slideIndex) => (
            <div key={`${slideIndex}_${slide.length}`}>
              <SlideContent>
                {slide.map((videoId, videoIdIndex) => (
                  <div
                    key={`${videoIdIndex}_${videoId}`}
                    // span={pageSize === 4 ? 12 : 24}
                    className="vto__container"
                  >
                    <VtoRender
                      divId={`model-${videoIdIndex + slideIndex * pageSize}`}
                      hasVideo={hasVideo}
                      videoId={videoId}
                      upc={upc}
                      slideIndex={slideIndex}
                    />
                    {!hasVideo && (
                      <div className="vto__shape">
                        <span>{t(`Vto.txt${this.getShapeFromVideoId(videoId)}`)}</span>
                      </div>
                    )}
                  </div>
                ))}
              </SlideContent>
            </div>
          ))}
        </Carousel>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  activeSlide: seeThemOnCurrentSlideSelector(state),
  currentFacesBlockId: seeThemOnCurrentFacesBlockId(state),
})

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps as any,
)(withTranslation('common')(Vto as any))
