import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { PdpContent } from '../../../model/content'
import { Moco, Product } from '../../../model/product'
import { juniorBrandsSelector } from '../../../store/brands/selectors'
import { setSeeThemOnViewType } from '../../../store/pdp/actions'
import { seeThemOnViewTypeSelector } from '../../../store/pdp/selectors'
import { seeThemOnViewTypes } from '../../../store/pdp/slice'
import { breakpoints, getFluidFontSize, palette, pxToRem, spacing } from '../../../style/theme'
import { IconButton } from '../../CommonComponents'
import OpticianTips from '../../OpticianTips'
import SeeThrough from '../../SeeThrough'
import FourImagesIcon from '../../UI/FourImagesIcon'
import RCIcon from '../../UI/RCIcon'
import SingleImageIcon from '../../UI/SingleImageIcon'
import Vto from '../../Vto/Vto'

const Wrapper = styled.div`
  position: absolute;
  top: 12vh;
  left: 20vw;
  width: 75%;
  height: 67%;
  border-radius: 4px;
  background-color: ${palette.white};
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 2.1vh;
  align-items: center;
  z-index: 11;
  overflow: auto;
  @media screen and (max-width: ${breakpoints.L}) {
    left: 17vw;
    width: 76%;
  }
  @media (max-height: ${breakpoints.M}) {
    height: 77%;
  }
`
const SeeThemOnHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1.5vh;
`

const SeeThemOnTitle = styled.h2`
  font-size: ${getFluidFontSize('16px')};
  grid-column: 2;
  text-transform: uppercase;
  font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
SeeThemOnTitle.displayName = 'SeeThemOnTitle'

const SwitchSeeThemOnView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: ${pxToRem(spacing(1))}rem;
  }

  button {
    width: calc(2.5rem + 2px);
    height: calc(2.5rem + 2px);

    &.active {
      color: ${palette.congressBlue};
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const NoContent = styled.div`
  height: 59vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: ${getFluidFontSize('30px')};
  }
`

const VtoWrapper = styled.div`
  width: 35%;
  height: auto;
  padding: 0;
  margin-right: 2vw;
  @media screen and (max-width: ${breakpoints.L}) {
    width: 45%;
  }

  .vto {
    margin: 0 auto;
    @media screen and (max-width: ${breakpoints.M}) {
      max-height: none;
    }
  }
`

const SeeThroughWrapper = styled.div`
  width: 60%;
  padding: 0;
  min-height: 32vh;
  margin-left: auto;
  margin-right: 0;
  position: relative;

  h2 {
    text-transform: uppercase;
    text-align: center;
  }
  @media screen and (max-width: ${breakpoints.L}) {
    width: 51%;
  }
  .st__container {
    height: auto;
    width: auto;
    overflow: hidden;
  }

  &.optical {
    align-self: flex-start;
    padding-top: 3vh;
    padding-left: 4vw;
    @media screen and (max-width: ${breakpoints.L}) {
      padding-top: 2vh;
    }
  }
`

const SwitchLabel = styled.div`
  color: ${palette.congressBlue};
`

const SeeThroughTitle = styled.h2`
  font-size: ${getFluidFontSize('16px')};
`

const CloseButton = styled.button`
  position: absolute;
  top: 2vh;
  right: 2vw;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${palette.alto};
  font-size: ${getFluidFontSize('11px')};
  padding: 1vh 1vw;
  z-index: 10;
  margin-left: 0.5rem;
  @media screen and (max-width: ${breakpoints.M}) {
    top: 2vh;
    bottom: auto;
  }

  .rc-icon {
    margin-right: 0.5em;
  }
`

type Props = {
  content?: PdpContent
  brandLogo?: string
  model?: Product
  moco?: Moco
  hideModalProductView: () => void
  isSeeThemOnAvailable: boolean
  className?: string
}

export const ProductModalView: React.FC<Props> = ({
  model,
  content,
  brandLogo,
  hideModalProductView,
  moco,
  isSeeThemOnAvailable,
  className,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const juniorBrands = useSelector(juniorBrandsSelector)
  const seeThemOnViewType = useSelector(seeThemOnViewTypeSelector)
  const isSunglasses = model?.category?.id === app_config.sunCategory
  const sizes = Object.values(moco?.sizes || {})
  const hasVtoImage = sizes.some(s => s.hasVto?.image)
  const hasVtoVideo = sizes.some(s => s.hasVto?.video)
  const hasVto = hasVtoImage || hasVtoVideo
  const upcVto = sizes.find(s => (hasVtoVideo ? s.hasVto?.video : s.hasVto?.image))?.upc

  return (
    <Wrapper
      className={['product-modal-view', className].join(' ')}
      data-testid="product-modal-view"
    >
      {!isSeeThemOnAvailable && (
        <NoContent className="no-seethemon-content">
          <h1>{t('ProductPage.seeThemOnEmpty')}</h1>
        </NoContent>
      )}
      {isSeeThemOnAvailable && (
        <>
          <VtoWrapper className={classnames({ 'wall-pdp-content__vto': true })}>
            {hasVto && (
              <SeeThemOnHeader>
                <SeeThemOnTitle>{t('ProductPage.seeThemOn')}</SeeThemOnTitle>{' '}
                <SwitchSeeThemOnView>
                  <SwitchLabel>{t('View')}</SwitchLabel>
                  <IconButton
                    onClick={() => dispatch(setSeeThemOnViewType('single'))}
                    className={classnames({
                      active: seeThemOnViewType === seeThemOnViewTypes.single,
                    })}
                  >
                    <SingleImageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => dispatch(setSeeThemOnViewType('four'))}
                    className={classnames({
                      active: seeThemOnViewType === 'four',
                    })}
                  >
                    <FourImagesIcon />
                  </IconButton>
                </SwitchSeeThemOnView>
              </SeeThemOnHeader>
            )}
            {upcVto && model && (
              <Vto
                brandCode={model.brandCode}
                upc={upcVto} // BBB: we always use the first size
                imgbrand={brandLogo}
                gender={model.gender}
                geofit={model.geofit}
                hasImage={hasVtoImage}
                hasVideo={hasVtoVideo}
                visible={hasVto}
                isJunior={(juniorBrands || []).includes(model?.brandCode || '')}
                pageSize={seeThemOnViewType === seeThemOnViewTypes.single ? 1 : 4}
              />
            )}
          </VtoWrapper>
          <SeeThroughWrapper
            className={classnames({
              'wall-pdp-content__see-through': isSunglasses,
              'wall-pdp-content__see-through optical': !isSunglasses,
            })}
          >
            {content &&
              ((isSunglasses && content.with && content.with.url) ||
                (!isSunglasses && content.opticianTips)) && (
                <SeeThroughTitle>
                  {isSunglasses ? t('ProductPage.seeLensEffect') : t('ProductPage.opticianTips')}
                </SeeThroughTitle>
              )}
            {isSunglasses ? (
              <SeeThrough
                imageBefore={content && content.image && content.image.url}
                imageAfter={content && content.with && content.with.url}
                isLoading={Boolean(content && content.seethroughWithoutLoading)}
              />
            ) : (
              <OpticianTips
                frontalTips={content && content.opticianTips}
                othersTips={content && content.others}
                templeTips={content && content.prescriptionTips}
              />
            )}
          </SeeThroughWrapper>
        </>
      )}
      <CloseButton onClick={hideModalProductView} className="close">
        <RCIcon type="close" />
        <span>{t('GenericWords.close')}</span>
      </CloseButton>
    </Wrapper>
  )
}

export default ProductModalView
