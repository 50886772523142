import { TFunction } from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import DigitalEventsFooter from '../components/DigitalEventsFooter/DigitalEventsFooter'
import DigitalEventsHeader from '../components/DigitalEventsHeader/DigitalEventsHeader'
import useFetchBackgrounds from '../hooks/useFetchBackgrounds'
import { homePageBgSelector, homePageCardsBgSelector } from '../store/digitalEvents/selectors'
import {
  CtaLink,
  MainFullBleed,
  PageWrapper,
  ScrollContainer,
} from '../style/DigitalEventsCommonComponents'
// import Swiper core and required modules
import {
  breakpoints,
  breakpointsNoUnit,
  DigitalEventsBoxBorderRadius,
  getFluidFontSize,
  getFluidSize,
  palette,
} from '../style/theme'
import Icon from '../styleguide/Icon'
import BackgroundVideo from '../components/BackgroundVideo'
import SwiperStyle from '../style/SwiperStyle'

export type HomePageAssets = {
  background: {
    image: string
    video: string
  }
  cards: {
    calendar: string
    dashboard: string
    brands: string
  }
}

SwiperCore.use([Pagination, Navigation])

const ExploreLink = styled(CtaLink)`
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem 2.5rem;
  font-size: 1rem;
`

const MainStyled = styled(MainFullBleed)`
  min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .paginator-wrapper {
    font-size: ${getFluidFontSize('30px')};
    color: ${palette.white};
    display: flex;
    justify-content: center;
    align-items: center;

    .left-arrow {
      font-size: ${getFluidFontSize('20px')};
      margin-right: ${getFluidSize('30px')};

      &-hidden {
        opacity: 0;
      }
    }

    .right-arrow {
      font-size: ${getFluidFontSize('20px')};
      margin-left: ${getFluidSize('30px')};

      &-hidden {
        opacity: 0;
      }
    }

    .numbers {
      display: inline-flex;
      line-height: 1;
      vertical-align: text-top;
    }

    .numbers .current {
      line-height: 1;
    }

    .numbers .opaque {
      opacity: 0.5;
      font-size: ${getFluidFontSize('15px')};
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: ${breakpoints.M}) {
      height: 78%;
      padding-top: 40px;
    }

    @media (max-width: ${breakpoints.S}) {
      height: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      max-height: 500px;
    }
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    border-radius: ${DigitalEventsBoxBorderRadius};

    &.swiper-slide-active {
      transform: scale(1);
    }

    transform: scale(0.8);
    transition: transform 0.2s;

    &.swiper-slide-next {
      transform-origin: center left;
    }

    &.swiper-slide-prev {
      transform-origin: center right;
    }
  }
`

const HomePageElement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const InnerContainer = styled.div<{ bg: string }>`
  ${props =>
    props.bg
      ? `
  background-image: url(${props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  `
      : `background-color: ${palette.bigStone};`};
  width: 100%;
  flex: 1;
  margin: 0;
  position: relative;
`

const Title = styled.span`
  color: ${palette.white};
  font-size: 7rem;
  position: absolute;
  top: 44%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);

  @media (max-width: ${breakpoints.M}) {
    font-size: 5rem;
  }

  @media (max-width: ${breakpoints.S}) {
    font-size: 3.5rem;
  }
`
const Carousel: React.FC<{ t: TFunction; assets: HomePageAssets }> = ({ t, assets }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <PageWrapper background={assets.background.image}>
      <SwiperStyle />
      <BackgroundVideo background={assets.background.video} />
      <ScrollContainer>
        <DigitalEventsHeader currentPage="home" />
        <MainStyled>
          <Swiper
            onSwiper={swiper => {
              setSwiperRef(swiper)
            }}
            onSlideChange={swiper => {
              setCurrentIndex(swiper.activeIndex)
            }}
            breakpoints={{
              0: {
                spaceBetween: 120,
                slidesPerView: 1.6,
              },
              [breakpointsNoUnit.M]: {
                spaceBetween: 155,
                slidesPerView: 1.55,
              },
              [breakpointsNoUnit.L]: {
                spaceBetween: 242,
                slidesPerView: 1.57,
              },
            }}
            grabCursor={true}
            centeredSlides={true}
            slidesPerGroup={1}
            loop={false}
            className="swiper"
          >
            <SwiperSlide>
              <HomePageElement>
                <InnerContainer bg={assets.cards.calendar}>
                  <Title>{t('Homepage.calendar.title')}</Title>
                  <ExploreLink to="/digital-events/calendar">
                    {t('Homepage.calendar.button')}
                  </ExploreLink>
                </InnerContainer>
              </HomePageElement>
            </SwiperSlide>
            <SwiperSlide>
              <HomePageElement>
                <InnerContainer bg={assets.cards.dashboard}>
                  <Title>{t('Homepage.dashboard.title')}</Title>
                  <ExploreLink to="/digital-events/dashboard">
                    {t('Homepage.dashboard.button')}
                  </ExploreLink>
                </InnerContainer>
              </HomePageElement>
            </SwiperSlide>
            <SwiperSlide>
              <HomePageElement>
                <InnerContainer bg={assets.cards.brands}>
                  <Title>{t('Homepage.brands.title')}</Title>
                  <ExploreLink to="/digital-events/brands">
                    {t('Homepage.brands.button')}
                  </ExploreLink>
                </InnerContainer>
              </HomePageElement>
            </SwiperSlide>
          </Swiper>
          <div className="paginator-wrapper">
            <Icon
              type="left"
              className={`left-arrow ${currentIndex === 0 ? 'left-arrow-hidden' : ''}`}
              onClick={() => {
                swiperRef?.slidePrev()
              }}
            />
            <span className="numbers">
              <span className="current">{currentIndex + 1}</span>
              <span className="opaque"> / {swiperRef?.slides.length}</span>
            </span>
            <Icon
              type="right"
              onClick={() => {
                swiperRef?.slideNext()
              }}
              className={`right-arrow ${currentIndex === 2 ? 'right-arrow-hidden' : ''}`}
            />
          </div>
        </MainStyled>
        <DigitalEventsFooter />
      </ScrollContainer>
    </PageWrapper>
  )
}

const HomePage: React.FC = () => {
  const { t } = useTranslation()
  const background = useSelector(homePageBgSelector)
  const cards = useSelector(homePageCardsBgSelector)

  useFetchBackgrounds()

  const assets: HomePageAssets = {
    background,
    cards,
  }

  return <Carousel t={t} assets={assets} />
}

export default HomePage
