import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StyledCheckBox } from '../../containers/CheckoutPage/components/Common'
import { useAfaFilters } from '../../hooks/useAfaFilters'
import { sortAfaSizes } from '../../libs/afa'
import { AfaFacet } from '../../model/afa'
import { getFluidSizeWithFullFormula, palette, pxToRem } from '../../style/theme'
import { scrollbarCss } from '../CommonComponents'
import { getAfaFilterTranslation } from './AfaFiltersLayer'

const Wrapper = styled.div`
  grid-area: options;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: ${pxToRem(16)}rem;
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
`

const Options = styled.div`
  gap: ${getFluidSizeWithFullFormula('px', 16, 44, 1366, 3840)};
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  ${scrollbarCss}
`

const AfaFilterOption = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(16)}rem;
  user-select: none;
  color: ${palette.white};

  input {
    height: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
    width: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
  }

  label {
    line-height: 0;
  }

  input[disabled] + label {
    opacity: 0.5;
  }

  input:checked + label {
    color: ${palette.shadowBlue};
    font-weight: bold;
  }

  input:checked {
    color: ${palette.tangaroa};
  }
`

const SearchCategoryOption = styled.input.attrs({ type: 'text' })`
  border-radius: ${pxToRem(4)}rem;
  background-color: transparent;
  border: solid 1px ${palette.shadowBlue};
  padding: 0.25rem 0.5rem;
  color: ${palette.white};
  outline: none;
  width: ${getFluidSizeWithFullFormula('px', 280, 419, 1366, 3840)};
  height: ${getFluidSizeWithFullFormula('px', 36, 68, 1366, 3840)};
  font-size: ${getFluidSizeWithFullFormula('px', 18, 24, 1366, 3840)};
`

type Props = {
  selectedCategory?: AfaFacet
}

export const OPTIONS_THRESHOLD = 5

const AfaFiltersLayerCategoryOptions: React.FC<Props> = ({ selectedCategory }) => {
  const { t } = useTranslation()

  const [selectedFilters, setSelectedFilters] = useAfaFilters(
    selectedCategory && [selectedCategory],
  )

  const [optionSearch, setOptionSearch] = useState('')

  const showSearchBar = selectedCategory
    ? selectedCategory.options.length > OPTIONS_THRESHOLD
    : false

  const convertCategoryNameToLabel = (categoryName: string, optionName: string) => {
    if (categoryName === 'release') {
      return optionName
    } else if (optionName.includes('|')) {
      return optionName
        .split('|')
        .map(name => getAfaFilterTranslation(t, `Afa.fO.recommendedUse.${name}`, name))
        .join(', ')
    } else {
      return getAfaFilterTranslation(t, `Afa.fO.${categoryName}.${optionName}`, optionName)
    }
  }

  const optionsToRender = selectedCategory?.options.filter(({ name }) =>
    optionSearch ? name.toLowerCase().includes(optionSearch.toLowerCase()) : true,
  )

  if (selectedCategory?.name === 'avd' && optionsToRender) {
    optionsToRender.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  return (
    <Wrapper>
      {showSearchBar && (
        <SearchCategoryOption
          value={optionSearch}
          onChange={e => setOptionSearch(e.currentTarget.value)}
          placeholder={t('Afa.FiltersSearch')}
        />
      )}
      <Options>
        {!!selectedCategory &&
          optionsToRender
            ?.slice(0)
            .sort((a, b) => sortAfaSizes(a.name, b.name))
            .map(({ name, results }) => {
              const id = `afa-filter-option-${selectedCategory.name}.${name}`
              return (
                <AfaFilterOption key={name}>
                  <StyledCheckBox
                    id={id}
                    type="checkbox"
                    accentColor={palette.bermudaGray}
                    borderColor={palette.white}
                    checked={
                      !!(
                        selectedFilters &&
                        selectedFilters[selectedCategory.name] &&
                        selectedFilters[selectedCategory.name][name]
                      )
                    }
                    disabled={!results}
                    onChange={e => {
                      setSelectedFilters({
                        ...selectedFilters,
                        [selectedCategory.name]: {
                          ...(selectedFilters ? selectedFilters[selectedCategory.name] : undefined),
                          [name]: e.currentTarget.checked,
                        },
                      })
                    }}
                  />
                  <label htmlFor={id}>
                    {convertCategoryNameToLabel(selectedCategory.name, name)}
                  </label>
                </AfaFilterOption>
              )
            })}
      </Options>
    </Wrapper>
  )
}

export default AfaFiltersLayerCategoryOptions
