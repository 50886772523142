import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCustomerData, getEvents, getGeneralContent, loadBrands } from '../store/actions'
import {
  contentLoadedSelector,
  contentLoadingSelector,
  customerIdSelector,
  eventIdSelector,
  eventsSelector,
} from '../store/app/selectors'
import { tokenSelector } from '../store/auth/selectors'
import { customerLoadedSelector } from '../store/customer/selectors'
import { brandsLoadedSelector } from '../store/brands/selectors'
import { useParams } from 'react-router'

type Options = {
  loadContent?: boolean
  loadCustomer?: boolean
  loadBrands?: boolean
}

const useFetchInitialData = (options?: Options) => {
  const dispatch = useDispatch()
  const token = useSelector(tokenSelector)
  const customerId = useSelector(customerIdSelector)
  const generalContentLoading = useSelector(contentLoadingSelector)
  const generalContentLoaded = useSelector(contentLoadedSelector)
  const events = useSelector(eventsSelector)
  const eventsLoaded = !!events
  const customerLoaded = useSelector(customerLoadedSelector)
  const brandsLoaded = useSelector(brandsLoadedSelector)
  const eventId = useSelector(eventIdSelector)
  const { assortment } = useParams<{ assortment: string }>()

  useEffect(() => {
    if (token && !eventsLoaded) {
      dispatch(getEvents(token))
    }
  }, [token, dispatch, eventsLoaded])

  useEffect(() => {
    if (options?.loadContent && !generalContentLoaded && !generalContentLoading) {
      dispatch(getGeneralContent())
    }
  }, [dispatch, generalContentLoaded, generalContentLoading, options?.loadContent])

  useEffect(() => {
    if (options?.loadCustomer && customerId && !customerLoaded && token) {
      dispatch(getCustomerData(customerId, assortment || 'frames', token))
    }
  }, [customerId, customerLoaded, token, dispatch, options?.loadCustomer, assortment])

  useEffect(() => {
    if (!brandsLoaded && options?.loadBrands && token && customerId && eventId) {
      dispatch(loadBrands(customerId, eventId))
    }
  }, [customerId, brandsLoaded, token, dispatch, options?.loadBrands, eventId])
}

export default useFetchInitialData
