import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAfaFilters } from '../../hooks/useAfaFilters'
import { AfaFacet } from '../../model/afa'
import { getFluidSizeWithFullFormula, palette, pxToRem } from '../../style/theme'
import { scrollbarCss } from '../CommonComponents'
import ChevronRightIcon from '../icons/ChevronRightIcon'
import { getAfaFilterTranslation } from './AfaFiltersLayer'

const Wrapper = styled.div`
  grid-area: categories;
  font-size: ${getFluidSizeWithFullFormula('px', 16, 24, 1366, 3840)};
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${palette.tangaroa};
  color: ${palette.white};
  position: relative;
  overflow: auto;
  gap: ${getFluidSizeWithFullFormula('px', 16, 44, 1366, 3840)};
  ${scrollbarCss}
`

const FilterCategory = styled.div`
  gap: ${pxToRem(16)}rem;
  display: flex;
  color: ${palette.white};
  align-items: center;
  cursor: pointer;

  &.active {
    color: ${palette.shadowBlue};
  }

  svg {
    height: 1em;
  }
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  font-size: ${getFluidSizeWithFullFormula('px', 24, 32, 1366, 3840)};
  margin-left: auto;
`

type Props = {
  facets: AfaFacet[]
  selectedCategoryName: string
  setSelectedCategoryName: (name: string) => void
}

const AfaFiltersCategoryList: React.FC<Props> = ({
  facets,
  selectedCategoryName,
  setSelectedCategoryName,
}) => {
  const { t } = useTranslation()

  const [selectedFilters] = useAfaFilters(facets)

  const filtersOrder = {
    size: 1,
    sport: 2,
    colorFacet: 3,
    technology: 4,
    season: 5,
    afacatalogid: 6,
    po: 7,
    avd: 8,
    collection: 9,
    family: 10,
    productType: 11,
    gender: 12,
    fabric: 13,
    fit: 14,
  } as Record<AfaFacet['name'], number>

  return (
    <Wrapper>
      {facets
        .filter(({ options }) => options.length)
        .sort((a, b) => filtersOrder[a.name] - filtersOrder[b.name])
        .map(({ name }) => {
          const numberOfAppliedFilters = Object.values(
            selectedFilters && selectedFilters[name] ? selectedFilters[name] : {},
          ).filter(Boolean).length

          return (
            <FilterCategory
              key={name}
              onClick={() => {
                setSelectedCategoryName(name)
              }}
              className={name === selectedCategoryName ? 'active' : ''}
            >
              {getAfaFilterTranslation(t, `Afa.fC.${name}`, name)}{' '}
              {!!numberOfAppliedFilters && (
                <span data-testid="number-of-applied-filters">({numberOfAppliedFilters})</span>
              )}
              <StyledChevronRightIcon />
            </FilterCategory>
          )
        })}
    </Wrapper>
  )
}

export default AfaFiltersCategoryList
