import { createSelector } from '@reduxjs/toolkit'

import app_config from '../../config/app/config'
import { getBrandByBrandCode } from '../../libs/brand'
import { formatUrlElement, isCategoriesPage, isPageMatches } from '../../libs/url'
import { isEmptyObj, isNotUndefined } from '../../libs/utils'
import { Brand } from '../../model/brand'
import { PdpContent } from '../../model/content'
import { MocoCode, RootState } from '../../model/model'
import { Product } from '../../model/product'
import { isKamUserSelector } from '../app/selectors'
import { brandsSelector } from '../brands/selectors'
import { loadedProductsSelector } from '../couvettes/selectors'
import { cartModeSelector } from '../stars/selectors'

export const isPdpVisibleSelector = (state: RootState) => state.pdp && state.pdp.isPdpVisible

export const isVtoModalVisibleSelector = (state: RootState) =>
  // don't unmount if status is requested because vm needs time to unmount camera
  state.pdp.vtoModalVisible || state.pdp.virtualMirrorUnmountStatus === 'requested'

export const isSeethroughModalVisibleSelector = (state: RootState) =>
  state.pdp.isSeethroughModalVisible

export const pdpContentSelector = (state: RootState) => state.pdp.content

const isObject = <T>(x: T | boolean): x is T => typeof x === 'object'

export const isContentAvailableSelector = (state: RootState) => {
  const contentKeysManadatory_wall = [
    'titolo',
    'content1',
    'content2',
    'content3',
    'cu1',
    'cu2',
    'cu3',
  ]
  // moodboard is filtered by model code, so is the same for every moco of that model
  const contents = state.pdp.content && Object.values(state.pdp.content).filter(isObject)
  return contents && !isEmptyObj(contents[0] || {}, contentKeysManadatory_wall)
}

export const showSwitchButtonSelector = createSelector(
  isPdpVisibleSelector,
  isContentAvailableSelector,
  isKamUserSelector,
  () => isPageMatches('cart'),
  () => isCategoriesPage(),
  cartModeSelector,
  (isPdpVisible, isContentAvailable, isKamUser, isCartPage, isCategoriesPage, viewMode) => {
    return (
      isKamUser &&
      !isCategoriesPage &&
      ((isPdpVisible && isContentAvailable) ||
        (isCartPage && viewMode !== 'stars' && !isPdpVisible))
    )
  },
)

export const pdpViewTypeSelector = (state: RootState) => state.pdp.viewType

export const pdpCouvetteSelector = (state: RootState) => state.pdp.couvette

export const pdpMocosSortedSelector = createSelector(
  pdpCouvetteSelector,
  model => model && Object.values(model.mocos).sort((m1, m2) => m1.plpOrder - m2.plpOrder),
)

const extractPdPAvailability = (
  model: Product,
  content: Record<string, PdpContent>,
  accumulator = {} as Record<string, boolean>,
) => {
  const modelMocos = Object.values(model.mocos)
  return modelMocos.reduce((mocos, moco) => {
    mocos[moco.mocoCode] = mocos[moco.mocoCode] || false
    const mocoContent = content[moco.mocoCode]
    const hasVto = Object.values(moco.sizes).some(s => !!s.hasVto)
    const isSeeThemOnAvailable =
      hasVto ||
      (model.category?.id === app_config.sunCategory
        ? mocoContent &&
          mocoContent.with &&
          mocoContent.with.url &&
          (model.brandCode === 'OO' || (mocoContent.image && mocoContent.image.url))
        : mocoContent && mocoContent.prescriptionTips && mocoContent.opticianTips)
    mocos[moco.mocoCode] = !!isSeeThemOnAvailable
    return mocos
  }, accumulator)
}

export const pdpSeeThemOnAvailableForMocosSelector = createSelector(
  pdpCouvetteSelector,
  pdpContentSelector,
  (model, content) => {
    if (!model || !content || isEmptyObj(content)) {
      return {}
    }
    const mocosSeeThemOnAvailable = extractPdPAvailability(model, content)

    return mocosSeeThemOnAvailable
  },
)

export const pdpHighlightSeeThemOnAvailableForMocosSelector = createSelector(
  pdpContentSelector,
  loadedProductsSelector,
  (content, products: Product[]): Record<string, boolean> => {
    if (!content || !products || isEmptyObj(content)) {
      return {}
    }
    // eslint-disable-next-line prefer-const
    let accumulatedAvaiabilities = {}
    products.forEach(model => {
      extractPdPAvailability(model, content, accumulatedAvaiabilities)
    })
    return accumulatedAvaiabilities
  },
)

export const pdpSeeThemOnAvailableForMocoSelector = (
  mocoCode: MocoCode,
  isCollectionHighlights?: boolean,
) =>
  createSelector(
    pdpSeeThemOnAvailableForMocosSelector,
    pdpHighlightSeeThemOnAvailableForMocosSelector,
    (seeThemOnAvailableForMocos, pdpHighlightSeeThemOnAvailableForMocos) => {
      const pdpSeeThemOnAvailableForMoco = isCollectionHighlights
        ? pdpHighlightSeeThemOnAvailableForMocos[mocoCode]
        : seeThemOnAvailableForMocos[mocoCode]
      return pdpSeeThemOnAvailableForMoco || {}
    },
  )

export const advancedDoorSelectionOpenSelector = (state: RootState) =>
  state.pdp.advancedDoorSelectionOpen

export const virtualMirrorAvailabilitySelector = (state: RootState) =>
  state.pdp.virtualMirrorAvailability

export const pdpSelector = (state: RootState) => state.pdp

export const image360Selector = (state: RootState) => state.pdp.tre60Info

export const mocoCodeSelector = (state: RootState) => state.pdp.code

export const vtoSelectedUpcSelector = (state: RootState) => state.pdp.vtoSelectedUpc

export const virtualMirrorUrlSelector = (state: RootState) => state.pdp.virtualMirrorUrl

export const virtualMirrorUpcsAvailable = createSelector(
  virtualMirrorAvailabilitySelector,
  virtualMirrorAvaliability =>
    Object.values(virtualMirrorAvaliability)
      .filter(({ isAvailable }) => isAvailable)
      .map(({ upc }) => upc),
)

const processCouvetteVm = (
  couvette: Product | null | undefined,
  brands: Brand[],
  selectedUpc: string,
  upcs?: string[],
) => {
  if (couvette !== null && couvette !== undefined) {
    const firstItem = Object.values(couvette.mocos).find(({ sizes }) => !!sizes[selectedUpc])

    const sizes =
      upcs &&
      upcs
        .filter(a => a)
        .map(upc => Object.values(couvette.mocos).find(({ sizes }) => !!sizes[upc])?.sizes[upc])
        .filter(isNotUndefined)
        .sort(({ mocoCode }) => (firstItem && mocoCode === firstItem.mocoCode ? -1 : 0))

    const brand = getBrandByBrandCode(brands, couvette.brandCode)

    return sizes && brand && sizes.length > 0
      ? [
          ...sizes.map(size => ({
            code: couvette.modelCode,
            name: size.mocoCode,
            colorCode: size.colorCode,
            thumbnailUrl: `${app_config.assetsUrl}/${couvette.brandCode}/${formatUrlElement(
              size.modelCode,
            )}/${formatUrlElement(size.mocoCode)}.jpg?imwidth=600`,
            brandName: brand.brand,
            brandLogoUrl: brand.logo,
            upc: size.upc,
          })),
        ]
      : false
  } else {
    return false
  }
}

export const vmHashAllDataSelector = (selectedUpc: string, upcs?: string[]) =>
  createSelector(pdpCouvetteSelector, brandsSelector, (couvette, brands) => {
    return processCouvetteVm(couvette, brands, selectedUpc, upcs)
  })

export const vmHashAllDataSelectorFromModel = (
  selectedUpc: string,
  upcs: string[],
  model?: Product,
) =>
  createSelector(brandsSelector, brands => {
    return processCouvetteVm(model, brands, selectedUpc, upcs)
  })

export const hasPi21Selector = (colorCode: string) => {
  return createSelector(pdpCouvetteSelector, couvette => {
    const moco = couvette?.mocos[colorCode]
    return moco?.multipleImages.hasPi21
  })
}

export const hasPi20Selector = (colorCode: string) => {
  return createSelector(pdpCouvetteSelector, couvette => {
    const moco = couvette?.mocos[colorCode]
    return moco?.multipleImages.hasPi20
  })
}

export const has360Selector = (colorCode: string) => {
  return createSelector(pdpCouvetteSelector, couvette => {
    const moco = couvette && couvette.mocos[colorCode]
    return moco?.multipleImages.has360 || moco?.catalogImages.degrees
  })
}

export const seeThemOnViewTypeSelector = (state: RootState) => state.pdp.seeThemOnViewType

export const seeThemOnCurrentSlideSelector = (state: RootState) => state.pdp.seeThemOnCurrentSlide

export const seeThemOnCurrentFacesBlockId = (state: RootState) =>
  state.pdp.seeThemOnCurrentFacesBLockId

export const fullscreen360VisibleSelector = (state: RootState) => state.pdp.fullscreen360Visible

export const fullscreen360CatalogImages = (state: RootState) => state.pdp.fullscreen360CatalogImages

export const fullscreen360CodeSelector = (state: RootState) => state.pdp.fullscreen360Code

export const skuChangedByScrollingSelector = (state: RootState) => state.pdp.skuChangedByScrolling

export const isSearchingSelector = (state: RootState) => state.pdp.isSearching

export const loadErrorSelector = (state: RootState) => state.pdp.loadError

export const loadedSelector = (state: RootState) => state.pdp.loaded

export const pdpLoadingSelector = (state: RootState) => state.pdp.loading

export const seeThroughSelectedMocoSelector = (state: RootState) => state.pdp.seeThroughSelectedMoco

export const virtualMirrorConfigSelector = (state: RootState) => state.pdp.virtualMirrorConfig

export const virtualMirrorUnmountSelector = (state: RootState) =>
  state.pdp.virtualMirrorUnmountStatus
