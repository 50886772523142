import { RootState } from '../../model/model'

export const afaCartAdjustIsOpenSelector = (state: RootState) => state.afaCartAdjust.isOpen

export const afaCartAdjustIsManageQuantitiesSelector = (state: RootState) =>
  state.afaCartAdjust.isManageQuantities

export const afaCartAdjustCartProductsSelector = (state: RootState) =>
  state.afaCartAdjust.cartProducts

export const afaCartAdjustIsEditedSelector = (state: RootState) => state.afaCartAdjust.isEdited

export const afaCartAdjustRemovedKeysSelector = (state: RootState) =>
  state.afaCartAdjust.removedKeys
