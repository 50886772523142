import { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'

import { DeviceData } from '../config/devices'
import { aaApi } from '../flows/AssortmentAdvisor/services'
import { afaApi } from '../services/afa'
import { afaCartApi } from '../services/afaCart'
import { afaContentApi } from '../services/afaContents'
import { afaProductApi } from '../services/afaProduct'
import { afaProductsApi } from '../services/afaProducts'
import { cartApi } from '../services/cart'
import { contentApi } from '../services/contents'
import {
  AfaBrand,
  AfaCartAdjustState,
  AfaCartNotificationsState,
  AfaCartOutOfAssortmentState,
  AfaCartState,
  AfaCartSummaryState,
  AfaState,
  Viewport,
} from './afa'
import { Appointment } from './appointment'
import { Brand } from './brand'
import { CartState } from './cart'
import { CheckoutState } from './checkout'
import {
  Content,
  ContentDigitalEventsAppointmentTilesBackground,
  ContentState,
  SingleBrandPageContents,
} from './content'
import { CouvettesState, CouvetteVideoState } from './couvettes'
import { CustomerState } from './customer'
import { DislikeState } from './dislike'
import { RcEvent } from './events'
import { FiltersState } from './filters'
import { Initiative } from './initiative'
import { NotificationsState } from './notifications'
import { PdpState } from './pdp'
import { SearchState } from './search'
import { StarsState } from './stars'
import { WishlistState } from './wishlist'
import { WhiteboardState } from './whiteboard'
import { whiteboardApi } from '../services/whiteboard'
import { GeocodingResult } from '../flows/AssortmentAdvisor/Model/aaModel'
import { aaAddressApi } from '../flows/AssortmentAdvisor/addressServices'
import { virtualMirrorApi } from '../services/virtualMirror'
import { Environments } from './api'
import { InitiativesState } from './initiatives'
import { initiativesApi } from '../services/initiatives'

export const mainRoutes = {
  postLogin: 'post-login',
  settings: 'settings',
  events: 'events',
  customers: 'customers',
  digitalEvents: 'digital-events',
  service: 'service',
  chooseAssortment: 'choose-assortment',
  assortmentAdvisor: 'assortment-advisor',
}

export type AfaCatalogId = 'A' | 'P' | 'L'

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export type AppThunkPromise<R = void> = ThunkAction<Promise<R>, RootState, unknown, Action<string>>

export type TDispatch = ThunkDispatch<RootState, unknown, Action<string>>

export type RoomType = 'standard' | 'medium' | 'large' | 'extra-large' | 'ipad-only'

export type RoomTypeSlug = 'standard' | 'medium' | 'large' | 'extra-large' | 'ipad-only' | ''

export type DeviceName = 'table' | 'wall' | 'extra-ipad' | 'led-left' | 'led-right' | 'vto-ipad'

export type CategoryId = '1' | '2' | '3' //1 = optical, 2 = sun, 3 = electronics

export type FileName = string

export type UrlString = string

export type Upc = string

export type ModelCode = string

export type MocoCode = string

export type ColorCode = string

export type BrandCode = string

export type Base64File = string

export type DateString = string

export type DdMmYyyyDateString = string

export type AppLangCode =
  | 'en-US'
  | 'fr-FR'
  | 'es-ES'
  | 'it-IT'
  | 'pt-PT'
  | 'ru-RU'
  | 'tr-TR'
  | 'zh-CN'
  | 'ja-JP'

export type Customer = {
  id: string
  name: string
  nationality: string
  stars: boolean
}

export type AuthState = {
  token: null | string
  error: null | string
}

export type ScanCamerMode = 'environment'

export type AppContent = {
  intro: Content
  welcomeLeddx: Content
  nav_led_dx: Content
  act_led_dx: Content
  multibrandLeddx: Content
  digitalPreviewLeddx: Content
  welcomeLedsx: Content
  nav_led_sx: Content
  act_led_sx: Content
  multibrandLedsx: Content
  digitalPreviewLedsx: Content
  springsummerImage: Content
  digitalPreviewWall: Content
  welcome: Content
  multibrandWall: Content
}

export type AppState = {
  loadingLanguage: boolean
  eventId: string
  customerId: string
  events: null | RcEvent[]
  errors: any[]
  roomType?: RoomType
  roomName?: string
  deviceName?: DeviceName
  massiveOrder: boolean
  lang: AppLangCode
  languages: { name: string; code: AppLangCode }[]
  customers: null | Customer[]
  footerModalVisible: ModalType
  isKidCategoryModeEnabled: boolean
  content: AppContent
  scanCameraMode: ScanCamerMode
  isStickyHeader: boolean
  loaded: boolean
  loading: boolean
  notifications: Notification[]
  isBrandActivationPlaying: boolean
  isBrandActivationPlayed: boolean
  afaBrandSelected: AfaBrand
  showSpinner: boolean
  environment: Environments
}

export type DigitalEventState = {
  backgroundsLoaded: boolean
  homePage: {
    cardsBg: {
      calendar: string
      dashboard: string
      brands: string
    }
    background: {
      image: string
      video: string
    }
  }
  dashboardPage: {
    background: {
      image: string
      video: string
    }
  }
  appointmentPage: {
    background: {
      image: string
      video: string
    }
  }
  calendarPage: ContentDigitalEventsAppointmentTilesBackground & {
    background: {
      image: string
      video: string
    }
  }
  highlightsPage: {
    background: {
      image: string
      video: string
    }
  }
  brandsPage: {
    background: {
      image: string
      video: string
    }
  }
  initiativePage: {
    background: {
      image: string
      video: string
    }
  }
  initiatives: null | Initiative[]
  appointments: null | Appointment[]
  singleBrandPage: SingleBrandPageContents
  selectedAppointmentDate: string
}

export type BrandsState = {
  loading: boolean
  loaded: boolean
  items: Brand[]
}

export type SelectedAddress = {
  zipCode: string
  countryCode: string
}

export type AssortmentAdvisorState = {
  isMapBrandsFilterOpen: boolean
  searchedAddress?: GeocodingResult
  selectedDoorId: string
  getDefaultAddress: boolean
  mapDragged: boolean
}

export type RootState = {
  initiatives: InitiativesState
  whiteboard: WhiteboardState
  digitalEvents: DigitalEventState
  wishlist: WishlistState
  brands: BrandsState
  dislike: DislikeState
  app: AppState
  auth: AuthState
  customer: CustomerState
  notifications: NotificationsState
  pdp: PdpState
  search: SearchState
  filters: FiltersState
  cart: CartState
  checkout: CheckoutState
  stars: StarsState
  couvettes: CouvettesState
  couvetteVideo: CouvetteVideoState
  content: ContentState
  viewport: Viewport
  afa: AfaState
  afaCart: AfaCartState
  afaCartSummary: AfaCartSummaryState
  afaCartAdjust: AfaCartAdjustState
  afaCartOutOfAssortment: AfaCartOutOfAssortmentState
  afaCartNotifications: AfaCartNotificationsState
  assortmentAdvisor: AssortmentAdvisorState
  cartApi: ReturnType<typeof cartApi.reducer>
  afaApi: ReturnType<typeof afaApi.reducer>
  afaCartApi: ReturnType<typeof afaCartApi.reducer>
  afaProductsApi: ReturnType<typeof afaProductsApi.reducer>
  afaProductApi: ReturnType<typeof afaProductApi.reducer>
  afaContentApi: ReturnType<typeof afaContentApi.reducer>
  contentApi: ReturnType<typeof contentApi.reducer>
  aaApi: ReturnType<typeof aaApi.reducer>
  whiteboardApi: ReturnType<typeof whiteboardApi.reducer>
  initiativesApi: ReturnType<typeof initiativesApi.reducer>
  aaAddressApi: ReturnType<typeof aaAddressApi.reducer>
  virtualMirrorApi: ReturnType<typeof virtualMirrorApi.reducer>
}

export type EmitEvent = (
  name: string,
  data?: any,
  autorizedDevices?: DeviceData[] | DeviceData,
) => void

export type CartWishlistManagerHocChildrenProps = {
  updateCart: () => void
  revertableUpdateCart: () => void
  updateCartOnlyIfMainDoorIsEnabled: () => void
  setCartItemQuantity: () => void
  toggleWishlist: (
    items: { brandCode: string; upc: string; mocoCode: string; size: string; skuCode: string }[],
  ) => void
  toggleDislike: () => void
}

export type DecodedToken = {
  userData: {
    wcsToken: string
    userId: string
    role: 'kam' | 'customer'
  }
  exp: number
}

export type ViewType = 'tableView' | 'wallView' | 'leds'

export type Language = {
  name: string
  code: string
}

export type ModalTypePopulated =
  | 'sort'
  | 'filters'
  | 'login'
  | 'afaLogin'
  | 'doors'
  | 'activationVideo'
  | 'discoveryVideo'
  | 'advAndVideoContent'
  | 'planogram'
  | 'codeScanner'
  | 'filterByMonth'
  | 'filtersCheckout'

export type ModalType = ModalTypePopulated | ''

export type HTMLVideoElementWithDisablePip = HTMLVideoElement & { disablePictureInPicture: boolean }
