import React from 'react'
import styled from 'styled-components'
import CartIcon from '../../../../components/icons/CartIcon'
import { AASize } from '../../Model/aaModel'
import { useSelector } from 'react-redux'
import {
  cartProductsRawSelector,
  upcInCartMapByActiveDoorSelector,
} from '../../../../store/cart/selectors'
import CheckIcon from '../../../../components/icons/CheckIcon'
import { useUpdateCart } from '../../../../hooks/useUpdateCart'
import { buildMocoCode } from '../../../../libs/productsV2'
import { useGetSelectedDoorId } from '../../Hooks/usePreselectDefaultDoor'
import { errorNotification } from '../../../../components/Notification/notifications'
import { palette } from '../../../../style/theme'
import { useSendAnalytics } from '../../../../services/analytics'

const Wrapper = styled.div`
  border: solid 1px ${palette.iron};
  border-radius: 0.5vw;
  display: flex;
  height: 2.7vw;
  font-weight: 500;
  align-items: center;
  color: ${palette.midnight};
  font-size: max(0.75vw, 11px);

  &.in-cart {
    background-color: ${palette.zircon};
  }
`

const Size = styled.div`
  flex: 1;
  padding: 0.5vw;
`

const AddButton = styled.button`
  border-left: solid 1px ${palette.iron};
  height: 2.4vw;
  width: 2.4vw;
  padding: 0.6vw;
  position: relative;
`

const CheckIconWrapper = styled.div`
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.25vw;
  right: 0;
  width: 0.75vw;
  height: 0.75vw;
  background-color: ${palette.cornflowerBlueAA};
  color: white;

  svg {
    width: 50%;
  }
`

type Props = {
  size: AASize
}

const AddToCartButton: React.FC<Props> = ({ size }) => {
  const [sendAnalytics] = useSendAnalytics()

  const upcInCartMap = useSelector(upcInCartMapByActiveDoorSelector)
  const isInCart = upcInCartMap[size.upc]

  const updateCart = useUpdateCart()

  const selectedDoorId = useGetSelectedDoorId()

  const cartProducts = useSelector(cartProductsRawSelector)

  return (
    <Wrapper className={isInCart ? 'in-cart' : ''}>
      <Size>{size.size}</Size>
      <AddButton
        onClick={() => {
          if (!selectedDoorId) {
            errorNotification({
              message: 'no door selected',
            })
            return
          }

          updateCart({
            doors: [selectedDoorId],
            productSizes: [
              {
                ...size,
                doors: [selectedDoorId],
                enabledDoors: [selectedDoorId],
                mocoCode: buildMocoCode(size),
              },
            ],
            qntToAdd: isInCart ? 0 : 1,
            finalQuantity: isInCart ? 0 : undefined,
            cartProducts,
          })

          sendAnalytics('ADD_TO_CART_ACTION')
        }}
      >
        <CartIcon />
        {isInCart && (
          <CheckIconWrapper>
            <CheckIcon />
          </CheckIconWrapper>
        )}
      </AddButton>
    </Wrapper>
  )
}

export default AddToCartButton
