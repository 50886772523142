import React from 'react'
import styled from 'styled-components/macro'

import { getFluidSizeWithFullFormula as gF } from '../../style/theme'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import classnames from 'classnames'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${palette.platinum};
  color: ${palette.tangaroa};
  text-align: center;
  border-radius: 4px;
  justify-content: space-evenly;
  align-items: center;
  font-size: ${gF('px', 16, 24, 1366, 3840)};

  &.no-quantity {
    opacity: 0.5;
  }
  &.sizeAvailabilityAlert {
    background-color: ${palette.paleYellow};
    border-color: ${palette.darkYellow};
  }
`

const Label = styled.div`
  padding: 0.5rem;
  font-family: GilmerBold;
`

const Availability = styled.div`
  font-size: ${gF('px', 10, 12, 1366, 3840)};
  color: ${palette.cloudBurst};
  font-family: GilmerMedium;
  @media screen and (max-width: ${breakpoints.L}) {
    font-family: GilmerRegular;
  }

  & span {
    font-family: GilmerBold;
  }
`

const MiddleLine = styled.div<{
  showAvailabilityAlert: boolean
}>`
  border-bottom: solid ${pxToRem(1.4)}rem
    ${props => (props.showAvailabilityAlert ? palette.santasGray : palette.platinum)};
  width: 80%;
`

const Quantity = styled.div<{
  showAvailabilityAlert: boolean
}>`
  color: ${props => (props.showAvailabilityAlert ? palette.darkYellow : null)};
  padding: 0.5rem;
  padding-bottom: ${props => (props.showAvailabilityAlert ? 0 : null)};
  font-family: ${props => (props.showAvailabilityAlert ? 'GilmerMedium' : 'GilmerRegular')};
`

type Props = {
  size: { upc: string; size: string }
  availability?: number
  quantity: number
}

const CartSize: React.FC<Props> = ({ size, availability, quantity }) => {
  const showAvailabilityAlert = availability !== undefined && availability < quantity

  return (
    <Wrapper
      className={classnames({
        'no-quantity': quantity === 0,
        sizeAvailabilityAlert: showAvailabilityAlert,
      })}
    >
      <Label>{size.size !== '' ? size.size : 'N/A'}</Label>
      <MiddleLine showAvailabilityAlert={showAvailabilityAlert} />
      <Quantity showAvailabilityAlert={showAvailabilityAlert}>{quantity}</Quantity>
      {showAvailabilityAlert && (
        <Availability>
          <span>{availability < 0 ? 0 : availability}</span> available
        </Availability>
      )}
    </Wrapper>
  )
}

export default CartSize
