import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BackIcon from './BackIcon'
import { palette } from '../../../style/theme'
import { Link } from 'react-router-dom'
import { getBaseUrl } from '../../../libs/url'

const Button = styled(Link)`
  color: white;
  display: flex;
  font-size: min(1vw, 35px);
  align-items: center;
`

const IconWrapper = styled.div`
  border-radius: 1vw;
  padding: 0.75vw;
  background-color: ${palette.biscay};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.25em;
    height: 1.25em;
  }
`

const Text = styled.div`
  padding: 1vw;
  text-transform: capitalize;
`

type Props = {
  className?: string
}

const BackButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <Button className={className} to={`${getBaseUrl()}/choose-brand`}>
      <IconWrapper>
        <BackIcon />
      </IconWrapper>
      <Text>{t('AA.back')}</Text>
    </Button>
  )
}

export default BackButton
