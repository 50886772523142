import React, { Fragment } from 'react'

import { AnyAction, bindActionCreators, Dispatch } from '@reduxjs/toolkit'

import RCIcon from '../components/UI/RCIcon'
import * as actions from '../store/actions'
import { getImwidthParamterValue, isIPadView } from './url'

/**
 * Maps Redux's actions to props.
 * @param {function} dispatch Dispatch function, passed by Redux's connect().
 * @returns {object} Object with mapped actions.
 */
export const mapDispatchToProps = (): any => {
  return (dispatch: Dispatch<AnyAction>) => {
    return { actions: bindActionCreators(actions, dispatch), dispatch }
  }
}

/**
 * Clones object to completely new one by turning into JSON and parsing it,
 * as JS passes objects by links and it requires additional actions to
 * create completely new object.
 * @param {object} oldValue Object to clone.
 * @returns {object} New object.
 */
export const deepCopy = (oldValue: Record<string, any>) => {
  const strValue = JSON.stringify(oldValue)
  return JSON.parse(strValue)
}

/**
 * Splits array into chunks by chunk_size.
 * @param {array} array Array to split.
 * @param {number} chunk_size Size of chunks.
 * @returns {array} Array of arrays of chunk_size length.
 */
export const arrayChunks = <T extends unknown>(array: T[], chunk_size: number): T[][] =>
  array.map((_, i, all) => all.slice(i * chunk_size, (i + 1) * chunk_size)).filter(x => x.length)

/**
 * Formats price by adding currency symbol and splitting with symbols.
 * @param {string} currency Symbol of current currency.
 * @param {number} value Price to format.
 * @param {number} c Amount of symbols after dot.
 * @param {string} d Symbol to divide every three symbols; `,` by default.
 * @param {string} t Symbol to divide integer part of number from fractional part; `.` by default.
 * @returns {string} Formatted price.
 */
export const formatMoney = (
  currency: string,
  value: number | string,
  c: number = 2,
  d = ',',
  t = '.',
) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c
  const s = value < 0 ? ' -' : ' '
  const i = String(parseInt((value = Math.abs(Number(value) || 0).toFixed(c)), 10))
  let j = 0
  j = (j = i.length) > 3 ? j % 3 : 0
  value = parseFloat(value)
  return (
    currency +
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(value - parseInt(i))
          .toFixed(c)
          .slice(2)
      : '')
  )
}

/**
 * Cuts string to `limit`, but to the closet space.
 * @param {String} str String to shorten.
 * @param {Number} maxLen Max line length.
 * @param {String} separator Separator to cut by.
 * @returns {Array} Array of name lines.
 */
export const shortenToSeparator = (str: string, maxLen: number, separator = ' ') => {
  if (str.length <= maxLen) return str
  const lastIndex = str.lastIndexOf(separator, maxLen)

  const lineOne = str.substr(0, lastIndex).trim()
  const lineTwo = str.substr(lastIndex, str.length).trim()

  return [lineOne, lineTwo]
}

/**
 * Formats name by cutting it if it's longer than `limit` and adding `...` to the end.
 * If string is smaller, then just returns it.
 * @param {string} name String to format.
 * @param {number} limit Limit of symbols after what to cut string.
 * @param {boolean} markup Return markup for iPad.
 * @returns {string} Formatted string.
 */
export const formatName = (name: string, limit: number = 15, markup: boolean = false) => {
  const multilineName = shortenToSeparator(name, limit)

  if (name && name.length > limit) {
    const [lineOne, lineTwo] = multilineName

    if (isIPadView() && markup && lineOne.length > 0) {
      return (
        <Fragment>
          {lineOne}
          <br />
          {lineTwo.length > lineOne.length ? `${lineTwo.substr(0, lineOne.length)}...` : lineTwo}
        </Fragment>
      )
    }
    return `${name.substr(0, limit)}...`
  }
  return name
}

/**
 * Chooses icon by shortcut.
 * @param {string} value Icon's name shortcut.
 * @param {string} customClassname Class name to add to icon element.
 * @returns i element of icon or empty string.
 */
export const chooseIcon = (value: string, customClassname = '') => {
  let type

  switch (value) {
    case 'N':
      type = customClassname === 'pdp-info-icon' ? 'new-yellow' : 'new-pink'
      break
    case 'A':
      type = customClassname === 'pdp-info-icon' ? 'color' : 'color-plp'
      break
    case 'V':
      type = 'vto'
      break
    case 'M':
      type = 'media'
      break
    default:
      return ''
  }

  return <RCIcon type={type} iconClass={customClassname} />
}

/**
 * Creates data structure for 360 view.
 * @param {string} urlImgPath Base path for image without extension and angle.
 * @returns {object} Object of objects with product images of different angles.
 */
export const getInfoTre60 = (urlImgPath: string, fullscreen = false) => {
  const allDegrees = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330]
  const listDegree = [
    '_000A.png',
    '_030A.png',
    '_060A.png',
    '_090A.png',
    '_120A.png',
    '_150A.png',
    '_180A.png',
    '_210A.png',
    '_240A.png',
    '_270A.png',
    '_300A.png',
    '_330A.png',
  ]
  const imwidth = getImwidthParamterValue(fullscreen)

  return allDegrees.map((angle, key) => ({
    preview: `${urlImgPath}${listDegree[key]}?imwidth=${imwidth}`,
    file: `${urlImgPath}${listDegree[key]}?imwidth=${imwidth}`,
    angle,
  }))
}

/**
 * Checks if user should be redirected to the customer selection page.
 * @param {object} props Current app's props from Redux storage.
 * @returns {boolean} Wheter user should be redirected or not.
 */
export const checkRedirect = (props: { app: { eventId: string } }) => !props.app.eventId

/**
 * Splits array into chunks.
 * @param {arr} array Array to split.
 * @param {n} number Number of chunks.
 * @returns {array} Array of arrays of chunk length.
 */

export const arrToMatrix = (arr: any[], n: number) => {
  const rest = arr.length % n // how much to divide
  let restUsed = rest // to keep track of the division over the elements
  const partLength = Math.floor(arr.length / n),
    result = []
  for (let i = 0; i < arr.length; i += partLength) {
    let end = partLength + i,
      add = false
    if (rest !== 0 && restUsed) {
      // should add one element for the division
      end++
      restUsed-- // we've used one division element now
      add = true
    }
    result.push(arr.slice(i, end)) // part of the array
    if (add) {
      i++ // also increment i in the case we added an extra element for division
    }
  }
  return result
}

/**
 * Formats number so it always has length of two symbols.
 * @param {Number} num Number to format
 * @returns Formatted number
 */
export const makeTwoSymbols = (num: number): string => {
  let value: string = num.toString()

  if (num < 10) value = `0${num}`
  if (num > 1000) value = `${num}`.substr(2)

  return value
}

/**
 * Formats ADV date.
 * @param {String} str Date string from WCS
 * @returns Formatted date
 */
export const formatAdvDate = (str: string | number | Date) => {
  const date = new Date(str)

  return `${makeTwoSymbols(date.getDate())}/${makeTwoSymbols(date.getMonth() + 1)}/${makeTwoSymbols(
    date.getFullYear(),
  )}`
}
