import { configureStore as configureStoreRTK, PreloadedState } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { aaApi } from '../flows/AssortmentAdvisor/services'

import { RootState } from '../model/model'
import { afaApi } from '../services/afa'
import { afaCartApi } from '../services/afaCart'
import { afaContentApi } from '../services/afaContents'
import { afaProductApi } from '../services/afaProduct'
import { afaProductsApi } from '../services/afaProducts'
import { cartApi } from '../services/cart'
import { contentApi } from '../services/contents'
import { rtkQueryErrorLogger } from './errorCatchingMiddleware'
import { rootReducer } from './rootReducer'
import { syncDevicesMiddleware } from './syncDevicesMiddelware'
import { whiteboardApi } from '../services/whiteboard'
import { aaAddressApi } from '../flows/AssortmentAdvisor/addressServices'
import { virtualMirrorApi } from '../services/virtualMirror'

export function configureStore(preloadedState?: PreloadedState<RootState>) {
  const store = configureStoreRTK({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([
        syncDevicesMiddleware,
        rtkQueryErrorLogger,
        cartApi.middleware,
        afaApi.middleware,
        afaCartApi.middleware,
        afaProductsApi.middleware,
        afaProductApi.middleware,
        afaContentApi.middleware,
        contentApi.middleware,
        aaApi.middleware,
        aaAddressApi.middleware,
        whiteboardApi.middleware,
        virtualMirrorApi.middleware,
      ]),
    preloadedState,
  })

  return store
}

export const store = configureStore()

setupListeners(store.dispatch)
