import React, { createRef } from 'react'
import styled, { css } from 'styled-components/macro'

import { horizontalScrollbarCss } from '../components/CommonComponents'
import { breakpointsCross, getFluidSizeWithFullFormula, palette, pxToRem } from '../style/theme'
import { useSelector } from 'react-redux'
import {
  afaCartAdjustCartProductsSelector,
  afaCartAdjustIsManageQuantitiesSelector,
  afaCartAdjustIsOpenSelector,
} from '../store/afaCartAdjust/selectors'
import { afaCartIsUpdatungSelector } from '../store/afaCart/selectors'
import RCIcon from '../components/UI/RCIcon'
import { useGetExpiredCartProducts } from '../hooks/useGetExpiredCartProducts'
import { useSearchParams } from '../hooks/useSearchParams'
import { useGetAfaCartProducts } from '../hooks/useGetAfaCartProducts'
import { isCartPageSelector, isPDPPageSelector } from '../store/showButtonsSelectors'
import useAfaGetCartUnavailableKeys from '../hooks/useAfaGetCartUnavailableKeys'
import { useParams } from 'react-router'
import { AfaCartProduct } from '../model/afa'
import useAfaGetOutOfAssortmentCartProducts from '../hooks/useAfaGetOutOfAssortmentCartProducts'
import { getAllUnavailableCartProductsFromKeys } from '../libs/afa'

const Wrapper = styled.div`
  ${horizontalScrollbarCss};
`

const Inner = styled.div`
  display: flex;
`

const Tab = styled.div<{ isCartPage: boolean }>`
  cursor: pointer;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 24, 1366, 3840)};
  padding: 1.2em 2em;
  line-height: 1;
  text-align: center;
  background-color: ${palette.white};
  color: ${palette.tangaroa};
  border: ${palette.silver} 1px solid;
  flex-shrink: 0;
  text-transform: uppercase;
  gap: ${getFluidSizeWithFullFormula('px', 6, 12, 1366, 3840)};
  letter-spacing: 0.7px;
  margin-bottom: 0;
  margin-top: -1px;
  margin-left: -1px;
  font-family: GilmerRegular, sans-serif;
  width: ${getFluidSizeWithFullFormula('px', 296, 696, 1366, 3840)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${breakpointsCross.L.max}) {
    margin-bottom: -1px;
    margin-top: 0;
    padding: 1.5em 2em;
  }

  &.active {
    background-color: ${palette.tangaroa};
    color: white;
    font-family: GilmerMedium, sans-serif;
  }

  &:first-of-type {
    border-bottom-left-radius: ${pxToRem(10)}rem;
    margin-left: 0;

    @media (max-width: ${breakpointsCross.L.max}) {
      border-top-left-radius: ${pxToRem(10)}rem;
      border-bottom-left-radius: 0;
    }

    ${({ isCartPage }) =>
      isCartPage &&
      css`
        border-top-left-radius: ${pxToRem(10)}rem;
        border-bottom-left-radius: 0;
      `}
  }

  &:last-of-type {
    border-bottom-right-radius: ${pxToRem(10)}rem;

    @media (max-width: ${breakpointsCross.L.max}) {
      border-top-right-radius: ${pxToRem(10)}rem;
      border-bottom-right-radius: 0;
    }

    ${({ isCartPage }) =>
      isCartPage &&
      css`
        border-top-right-radius: ${pxToRem(10)}rem;
        border-bottom-right-radius: 0;
      `}
  }
`

const LabelTab = styled.span`
  margin-left: ${getFluidSizeWithFullFormula('px', 6, 10, 1366, 3840)};
`

type Props = {
  tabs: { id: string; label: string }[]
  selectedTabId?: string
  setSelectedTabId: (selectedTabId: string) => void
  className?: string
  cartIsLoadingUpdates?: boolean
}

const AfaTabSwitcher: React.FC<Props> = ({
  selectedTabId,
  setSelectedTabId,
  tabs,
  className,
  cartIsLoadingUpdates,
}) => {
  const wrapperRef = createRef<HTMLDivElement>()
  const isAdjust = useSelector(afaCartAdjustIsOpenSelector)
  const { expiredDropsCartProducts } = useGetExpiredCartProducts()

  const isUpdatingProducts = useSelector(afaCartIsUpdatungSelector)
  const isManageQuantities = useSelector(afaCartAdjustIsManageQuantitiesSelector)

  const adjustCartProducts = useSelector(afaCartAdjustCartProductsSelector)
  const queryCartProducts = useGetAfaCartProducts()
  const cartProducts = isManageQuantities ? adjustCartProducts : queryCartProducts.cartProducts

  const { unavailableKeys } = useAfaGetCartUnavailableKeys(cartProducts)
  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()

  const isCartPage = useSelector(isCartPageSelector)
  const isPDPPage = useSelector(isPDPPageSelector)
  const [searchParams] = useSearchParams()
  const { cartProductsByBrandCode, brandsInCart } = useGetAfaCartProducts()
  const selectedBrand = searchParams.get('brand') || brandsInCart[0]?.code
  const expiredProductsKeys = expiredDropsCartProducts.map(({ key }) => key)
  const outOfAssortmentKeys = outOfAssortmentCartProducts.map(({ key }) => key)
  const unavailableCartProductsGrouped = getAllUnavailableCartProductsFromKeys(
    cartProducts,
    unavailableKeys,
  )

  const { modelCode } = useParams<{ modelCode: string }>()
  const selectedColorCode = searchParams.get('color') || ''
  const selectedModelCode = modelCode || searchParams.get('model') || ''

  const afaTabSwitcher = document.getElementById('afa-tab-switcher') as HTMLElement

  const handleSelectedTab = (e: React.MouseEvent) => {
    const selectedTab = e.currentTarget
    const { right, left } = selectedTab.getBoundingClientRect()
    const switcher = afaTabSwitcher.getBoundingClientRect()
    const scrollRight = switcher.right - right - switcher.x
    const scrollLeft = left - switcher.left - switcher.x
    const scroll = scrollRight < 0 ? scrollRight : scrollLeft < 0 ? -scrollLeft : 0

    if (scroll !== 0) {
      afaTabSwitcher.scrollTo({
        left: afaTabSwitcher.scrollLeft - scroll,
        behavior: 'smooth',
      })
    }
    setSelectedTabId(selectedTab.id)
  }

  return (
    <Wrapper ref={wrapperRef} className={className} id="afa-tab-switcher">
      <Inner>
        {tabs.map(({ id, label }) => {
          const filteredCartProductsWithSelectedDeliveryDate = cartProductsByBrandCode[
            selectedBrand
          ]?.filter(p => p.deliveryDate === id)

          const findExpiredProductForSelectedDoor = (products: AfaCartProduct[]) => {
            return products?.find(
              ({ key, doorId, modelCode, colorCode }) =>
                expiredProductsKeys.includes(key) &&
                doorId === id &&
                modelCode === selectedModelCode &&
                colorCode === selectedColorCode,
            )
          }

          const unavailableCartProductForSelectedDoor = !!unavailableCartProductsGrouped?.find(
            ({ doorId, modelCode, colorCode }) =>
              doorId === id && modelCode === selectedModelCode && colorCode === selectedColorCode,
          )

          const cartWarnings =
            isCartPage &&
            !isAdjust &&
            !!filteredCartProductsWithSelectedDeliveryDate?.find(({ key }) => {
              return (
                expiredProductsKeys.includes(key) ||
                outOfAssortmentKeys.includes(key) ||
                unavailableKeys.includes(key)
              )
            })

          const adjustManageQuantitiesWarnings =
            isAdjust &&
            !cartIsLoadingUpdates &&
            (!!findExpiredProductForSelectedDoor(cartProducts) ||
              unavailableCartProductForSelectedDoor) &&
            isManageQuantities === 'manage-quantities'

          const adjustWarnings =
            isAdjust &&
            !isManageQuantities &&
            !cartIsLoadingUpdates &&
            (!!findExpiredProductForSelectedDoor(cartProducts) ||
              unavailableCartProductForSelectedDoor)

          const adjustExpiredQuantitiesWarnings =
            isManageQuantities === 'expired' &&
            !cartIsLoadingUpdates &&
            !!expiredDropsCartProducts?.find(({ doorId }) => doorId === id)

          const pdpWarnings =
            isPDPPage &&
            !isUpdatingProducts &&
            !!expiredDropsCartProducts?.find(
              ({ doorId, modelCode, colorCode }) =>
                doorId === id && modelCode === selectedModelCode && colorCode === selectedColorCode,
            )

          const warning =
            cartWarnings ||
            adjustManageQuantitiesWarnings ||
            adjustWarnings ||
            adjustExpiredQuantitiesWarnings ||
            pdpWarnings

          return (
            <Tab
              className={selectedTabId === id ? 'active' : ''}
              key={id}
              id={id}
              onClick={handleSelectedTab}
              isCartPage={isCartPage}
            >
              {warning && <RCIcon type="afa-warning" />}
              <LabelTab>
                {(isPDPPage || isManageQuantities || isAdjust) && `${id} - `}
                {label}
              </LabelTab>
            </Tab>
          )
        })}
      </Inner>
    </Wrapper>
  )
}

export default AfaTabSwitcher
