import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE = {
  initialNotificationCount: 0,
  initialNotificationsList: [] as string[],
  currentNotificationsList: [] as string[],
}

export const slice = createSlice({
  name: 'afaCartNotifications',
  initialState: DEFAULT_STATE,
  reducers: {
    setInitialNotificationCount: (state, { payload }: PayloadAction<number>) => {
      state.initialNotificationCount = payload
    },
    setInitialNotificationsList: (state, { payload }: PayloadAction<string[]>) => {
      state.initialNotificationsList = payload
    },
    setCurrentNotificationsList: (state, { payload }: PayloadAction<string[]>) => {
      state.currentNotificationsList = payload
    },
    reset: () => DEFAULT_STATE,
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
