import React from 'react'
import { useTranslation } from 'react-i18next'
import { DropType, UpdateAfaCartProductPayload } from '../../model/afa'
import { AfaCatalogId, DdMmYyyyDateString, Upc } from '../../model/model'
import SizeDropDate from './SizeDropDate'
import SizeRow from './SizeRow'
import { sortDdMmYyyyDates } from '../../libs/time'

type Props = {
  dropType: DropType
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  dropNumber: string
  customDeliveriesDates?: DdMmYyyyDateString[]
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  disabled: boolean
  afaCatalogId: AfaCatalogId
  availability: {
    availableQuantity: number
    upc: Upc
    afaCatalogId?: AfaCatalogId
  }[]
}

const SizeRowDropDate: React.FC<Props> = ({
  dropType,
  availabilityDate,
  customDeliveriesDates,
  minDeliveryDate,
  dropNumber,
  updateAfaCart,
  disabled,
  afaCatalogId,
  availability,
}) => {
  const { t } = useTranslation()

  const isLatePrebook = afaCatalogId === 'L'
  return (
    <>
      <SizeRow
        isLatePrebook={isLatePrebook}
        availability={isLatePrebook ? availability : undefined}
        title={
          isLatePrebook
            ? t('Afa.chooseSizeOfLatePrebook')
            : t('Afa.chooseSizeOfDrop', { dropNumber })
        }
        dropType={dropType}
        availabilityDate={availabilityDate}
        minDeliveryDate={minDeliveryDate}
        updateAfaCart={updateAfaCart}
        disabled={disabled}
        afaCatalogId={afaCatalogId}
      >
        <SizeDropDate minDeliveryDate={minDeliveryDate} dropNumber={dropNumber} />
      </SizeRow>

      {customDeliveriesDates &&
        customDeliveriesDates
          .slice()
          .sort(sortDdMmYyyyDates)
          .map(deliveryDate => (
            <SizeRow
              key={minDeliveryDate}
              title={t('Afa.requestedDelivery')}
              dropType={dropType}
              availabilityDate={availabilityDate}
              minDeliveryDate={minDeliveryDate}
              customDeliveryDate={deliveryDate}
              updateAfaCart={updateAfaCart}
              disabled={disabled}
            >
              <SizeDropDate
                minDeliveryDate={deliveryDate}
                dropNumber={dropNumber}
                type="customDelivery"
              />
            </SizeRow>
          ))}
    </>
  )
}

export default SizeRowDropDate
