import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import AfaAdvChip from '../../components/AfaAdvChip'
import app_config from '../../config/app/config'
import { useSearchParams } from '../../hooks/useSearchParams'
import { formatMoney } from '../../libs'
import { getDefaultColor } from '../../libs/afa'
import { AfaProduct } from '../../model/afa'
import { useGetRelatedProductsQuery } from '../../services/afaProduct'
import {
  breakpoints,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
  spacing,
} from '../../style/theme'
import { useGetBrandsQuery } from '../../services/afa'
import { useDispatch, useSelector } from 'react-redux'
import { showSrPriceSelector, showWhsPriceSelector } from '../../store/customer/selectors'
import { getProductImageUrl } from '../../libs/productImages'
import { CheckedIcon, PinButton, Pinned } from '../AfaKeylooks/AfaKeylooksProduct'
import RCIcon from '../../components/UI/RCIcon'
import whiteboardActions from '../../store/whiteboard/actions'
import { generateSkuCode } from '../../libs/whiteboard'
import { itemsPinModalSelector, openPinModalSelector } from '../../store/whiteboard/selectors'
import { useGetKeylookUrlWithState } from '../../hooks/useGetKeylookUrlWithState'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 97%;
  gap: ${pxToRem(7)}rem;

  @media screen and (min-width: ${breakpoints.L}) {
    gap: ${gF('px', 7, 42, 1920, 3840)};
  }
`

const ProductWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
  border-radius: ${gF('px', 4, 20, 1366, 3840)};
  overflow: hidden;
`

const HeaderWrapper = styled.div`
  padding: ${pxToRem(32)}rem ${pxToRem(32)}rem ${pxToRem(20)}rem ${pxToRem(32)}rem;

  @media screen and (min-width: ${breakpoints.L}) {
    padding-top: ${gF('px', 32, 64, 1920, 3840)};
    padding-right: ${gF('px', 32, 64, 1920, 3840)};
    padding-left: ${gF('px', 32, 64, 1920, 3840)};
    padding-bottom: ${gF('px', 20, 80, 1920, 3840)};
  }
`

const Name = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(19)}rem;

  span {
    font-weight: bold;
    color: ${palette.tangaroa};
    font-size: ${gF('px', 26, 64, 1366, 3840)};
  }

  div {
    flex-shrink: 0;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    margin-bottom: ${gF('px', 19, 40, 1920, 3840)};
  }
`

const CustomPinned = styled(Pinned)`
  span {
    font-size: ${gF('px', 14, 28, 1366, 3840)};
  }
`

const CustomPinButton = styled(PinButton)`
  height: ${gF('px', 21, 120, 768, 3840)};
  width: ${gF('px', 21, 120, 768, 3840)};
`

const CodeAndDates = styled.div`
  display: flex;
  gap: ${pxToRem(spacing(3))}rem;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  margin-bottom: ${pxToRem(15)}rem;

  @media screen and (min-width: ${breakpoints.L}) {
    margin-bottom: ${gF('px', 15, 32, 1920, 3840)};
  }
`

const CodeText = styled.span`
  font-weight: bold;
`

const AfaDates = styled.span`
  font-weight: bold;
  margin: 0 ${pxToRem(5)}rem;
`

const Prices = styled.div`
  display: flex;
  align-items: center;
  gap: ${gF('px', 24, 48, 1366, 3840)};
`

const WhPrice = styled.div`
  font-weight: bold;
  font-size: ${gF('px', 18, 48, 1366, 3840)};
`

const SrpPrice = styled.div`
  font-size: ${gF('px', 14, 28, 1366, 3840)};
`

const Badges = styled.div`
  display: flex;
  margin-top: ${pxToRem(15)}rem;
  min-height: ${pxToRem(29)}rem;
  gap: ${pxToRem(11)}rem;

  @media screen and (min-width: ${breakpoints.L}) {
    margin-top: ${gF('px', 15, 32, 1920, 3840)};
    min-height: ${gF('px', 29, 53, 1920, 3840)};
    gap: ${gF('px', 11, 32, 1366, 3840)};
  }
`

const Adv = styled(Link)`
  color: ${palette.tangaroa};
  padding: ${pxToRem(7)}rem ${pxToRem(18)}rem;
  border: 1.5px solid ${palette.extraLight};
  border-radius: ${pxToRem(30)}rem;
  margin-right: ${pxToRem(11)}rem;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  font-weight: bold;

  @media screen and (min-width: ${breakpoints.L}) {
    padding-block: ${gF('px', 7, 12, 1366, 3840)};
    padding-inline: ${gF('px', 18, 24, 1366, 3840)};
  }
`

const ImageFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0.5em 3em;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: ${breakpoints.L}) {
    padding: 0.5em;
  }
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;

  &:before {
    content: '';
    background-image: url('/assets/images/transparent_placeholder.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }
`

const CompleteTheLookButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${pxToRem(16)}rem;
  background-color: ${palette.white};
  border: 1px solid ${palette.tangaroa};
  border-radius: ${pxToRem(4)}rem;
  text-transform: uppercase;
  font-size: ${gF('px', 12, 24, 1366, 3840)};
  margin: auto auto 0 auto;
  padding-top: ${gF('px', 19, 27, 1366, 3840)};
  padding-bottom: ${gF('px', 19, 27, 1366, 3840)};
  padding-left: ${gF('px', 21, 24, 1366, 3840)};
  padding-right: ${gF('px', 21, 24, 1366, 3840)};
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.L}) {
    gap: ${pxToRem(spacing(2))}rem;
  }

  &.hide {
    visibility: hidden;
  }
`

const CompleteLookImg = styled.img`
  width: ${pxToRem(16)}rem;
  height: ${pxToRem(16)}rem;
`

type Props = {
  product?: AfaProduct
  currency?: string
}

const AfaPdpNameAndImage: React.FC<Props> = ({ product, currency }) => {
  const { t } = useTranslation()
  const showSrPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)
  const dispatch = useDispatch()
  const products = useSelector(itemsPinModalSelector)
  const openPinModal = useSelector(openPinModalSelector)
  const brandsQuery = useGetBrandsQuery()

  const [isProductPinned, setIsProductPinned] = useState(false)

  const [searchParams] = useSearchParams()
  const selectedColorCode = searchParams.get('color')
  const defaultColor = getDefaultColor(product)

  const selectedColor = selectedColorCode ? product?.mocos[selectedColorCode] : defaultColor

  const keylookUrl = useGetKeylookUrlWithState(product?.brandCodeParent, selectedColor?.keylooks)

  const whsPrice = Number(selectedColor?.whsPrice).toFixed(2)
  const srPrice = Number(selectedColor?.srPrice).toFixed(2)

  const relatedQuery = useGetRelatedProductsQuery(
    product?.modelCode,
    selectedColorCode || undefined,
  )
  const thereAreRelated = !!relatedQuery?.currentData?.length

  const defaultCurrency = brandsQuery.data?.[0].currency || ''
  const selectedCurrency = currency || defaultCurrency

  useEffect(() => {
    if (product) {
      setIsProductPinned(
        products.some(
          productPinned =>
            productPinned.modelCode === product.modelCode &&
            productPinned.name === product.name &&
            !openPinModal,
        ),
      )
    }
  }, [products, product, openPinModal])

  const mocoToPin: AfaProduct | undefined =
    selectedColorCode && selectedColor && product
      ? {
          ...product,
          mocos: {
            selectedColorCode: selectedColor,
          },
        }
      : undefined

  return (
    <Wrapper>
      <ProductWrapper>
        <HeaderWrapper>
          <Name>
            <span>{product?.name}</span>
            {mocoToPin &&
              (isProductPinned ? (
                <CustomPinned>
                  <CheckedIcon />
                  <span>{t('Whiteboard.pinned')}</span>
                </CustomPinned>
              ) : (
                <CustomPinButton
                  onClick={() => {
                    dispatch(whiteboardActions.resetItemsPinModal())
                    dispatch(whiteboardActions.setSelectedSkuCode(generateSkuCode([mocoToPin])))
                    dispatch(whiteboardActions.setOpenPinModal(true))
                    dispatch(whiteboardActions.setItemsPinModal([mocoToPin]))
                  }}
                >
                  <RCIcon type="pin" />
                </CustomPinButton>
              ))}
          </Name>
          <CodeAndDates>
            <div>
              <CodeText>{t('Afa.code')}:</CodeText> {selectedColor?.modelCode}{' '}
              {selectedColor?.colorCode}
            </div>
            <div>
              {t('Afa.po')}
              <AfaDates>
                {selectedColor?.availabilityDataList.length
                  ? format(new Date(selectedColor?.availabilityDataList[0].poDate), 'dd/MM/yyyy')
                  : '-'}
              </AfaDates>
            </div>
            <div>
              {t('Afa.avd')}
              <AfaDates>
                {selectedColor?.availabilityDataList.length
                  ? format(new Date(selectedColor?.availabilityDataList[0].avdDate), 'dd/MM/yyyy')
                  : '-'}
              </AfaDates>
            </div>
          </CodeAndDates>
          <Prices>
            {selectedColor && showWhsPrice && (
              <WhPrice>{formatMoney(selectedCurrency, whsPrice)}</WhPrice>
            )}
            {selectedColor && showSrPrice && (
              <SrpPrice>
                {t('Afa.srp')} {formatMoney(selectedCurrency, srPrice)}
              </SrpPrice>
            )}
          </Prices>
          <Badges>
            <AfaAdvChip product={product} isPDP />
            {keylookUrl && <Adv to={keylookUrl}>{t('Afa.KeyLookChipLink')}</Adv>}
          </Badges>
        </HeaderWrapper>
        {product && (
          <ImageFlex>
            <ImageWrapper>
              <Image
                src={getProductImageUrl({
                  path: selectedColor?.catalogImgPath || '',
                  imwidth: 800,
                })}
                alt={`model ${product?.modelCode}, color ${selectedColor?.colorCode}`}
              />
            </ImageWrapper>
          </ImageFlex>
        )}
      </ProductWrapper>

      <CompleteTheLookButton
        onClick={() => {
          document.querySelector('#complete-the-look')?.scrollIntoView({
            behavior: 'smooth',
          })
        }}
        className={!thereAreRelated ? 'hide' : ''}
      >
        <CompleteLookImg
          alt="arrow down"
          src={`${app_config.publicUrl}/assets/images/arrows/down.svg`}
        />
        <span>{t('Afa.completeTheLook')}</span>
      </CompleteTheLookButton>
    </Wrapper>
  )
}

export default AfaPdpNameAndImage
