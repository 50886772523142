import queryString from 'qs'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { useAfaFilters } from '../hooks/useAfaFilters'
import { useSearchParams } from '../hooks/useSearchParams'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { AfaFacetsWithCount, AfaProduct, AfaProductsQuery } from '../model/afa'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { useGetAfaWarehouse } from './afa'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'

const flatFiltersInParams = (params: AfaProductsQuery) => {
  const filtersParam = Object.entries(params.filters || {}).reduce(
    (result, [category, options]) => {
      const categoryValues = Object.entries(options)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, active]) => active)
        .map(([optionName]) => optionName)
        .join(',')

      if (categoryValues) {
        result[category] = categoryValues
      }

      return result
    },
    {} as Record<string, string>,
  )

  return {
    ...params,
    filters: undefined,
    ...filtersParam,
  }
}

export const afaProductsApi = createApi({
  reducerPath: 'afaProductsApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/afa`),
  endpoints: builder => ({
    getProducts: builder.query<
      {
        start: number
        rows: number
        count: number
        products: AfaProduct[]
      },
      AfaProductsQuery
    >({
      query: params => {
        return {
          url: `/products/search?${queryString.stringify(flatFiltersInParams(params))}`,
        }
      },
    }),

    getSearchSuggestions: builder.query<
      string[],
      {
        searchText: string
        customerId: string
        eventId: string
      }
    >({
      query: params => ({
        url: `/products/search/suggestions?${queryString.stringify(params)}`,
      }),
    }),

    getAllFacets: builder.query<AfaFacetsWithCount, Omit<AfaProductsQuery, 'filters'>>({
      query: params => ({
        url: `/products/facets?${queryString.stringify(params)}`,
      }),
    }),

    getUpdatedFacets: builder.query<AfaFacetsWithCount, AfaProductsQuery>({
      query: params => ({
        url: `/products/facets?${queryString.stringify(flatFiltersInParams(params))}`,
      }),
    }),
  }),
})

export const useGetAllPlpFacetsQuery = () => {
  const { category, brandCode, assortment } = useParams<{
    category?: string
    brandCode?: string
    assortment: string
  }>()
  const [searchParams] = useSearchParams()
  const sport = searchParams.get('sport') || undefined

  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const afawarehouse = useGetAfaWarehouse()

  return afaProductsApi.useGetAllFacetsQuery(
    {
      category,
      brandCode,
      assortment,
      sport,
      eventId,
      customerId,
      afawarehouse: afawarehouse || '',
    },
    { skip: afawarehouse === undefined },
  )
}

export const useGetPlpProductsQuery = (
  rows?: number,
  sportProps?: string | null,
  categoryProps?: string,
) => {
  const { category, brandCode, assortment } = useParams<{
    category?: string
    brandCode?: string
    assortment: string
  }>()
  const [searchParams] = useSearchParams()
  const sport = searchParams.get('sport') || undefined

  const categoryAsQueryParameter = searchParams.get('category') || undefined

  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const facetsQuery = useGetAllPlpFacetsQuery()
  const [selectedFilters] = useAfaFilters(facetsQuery.data?.facets)

  const searchTerm = searchParams.get('searchTerm') || undefined
  const start = Number(searchParams.get('start')) || 0
  const afawarehouse = useGetAfaWarehouse()

  return afaProductsApi.useGetProductsQuery(
    {
      category: categoryProps || category || categoryAsQueryParameter,
      brandCode,
      assortment,
      sport: sportProps || sport,
      eventId,
      customerId,
      filters: selectedFilters,
      search: searchTerm,
      afawarehouse: afawarehouse || '',
      start,
      rows,
    },
    { skip: facetsQuery.isFetching || afawarehouse === undefined || sportProps === null },
  )
}

export const useGetUpdatedPlpFacetsQuery = (propsCategory?: string, skipProp?: boolean) => {
  const { category, brandCode, assortment } = useParams<{
    category?: string
    brandCode?: string
    assortment: string
  }>()
  const [searchParams] = useSearchParams()
  const sport = searchParams.get('sport') || undefined

  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const facetsQuery = useGetAllPlpFacetsQuery()
  const [selectedFilters] = useAfaFilters(facetsQuery.data?.facets)

  const searchTerm = searchParams.get('searchTerm') || undefined
  const sort = searchParams.get('sort') || 'model'
  const afawarehouse = useGetAfaWarehouse()

  return afaProductsApi.useGetUpdatedFacetsQuery(
    {
      category: propsCategory || category,
      brandCode,
      assortment,
      sport,
      eventId,
      customerId,
      filters: selectedFilters,
      search: searchTerm,
      sort,
      afawarehouse: afawarehouse || '',
    },
    { skip: afawarehouse === undefined || skipProp },
  )
}

export const useGetWhiteboardFacetsQuery = (category: string, skipProp?: boolean) => {
  const { brandCode, assortment } = useParams<{
    brandCode?: string
    assortment: string
  }>()

  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const afawarehouse = useGetAfaWarehouse()

  return afaProductsApi.useGetUpdatedFacetsQuery(
    {
      category,
      brandCode,
      assortment,
      eventId,
      customerId,
      afawarehouse: afawarehouse || '',
    },
    { skip: afawarehouse === undefined || skipProp },
  )
}

export const useGetKeylookProductsQuery = (keylookFull?: boolean) => {
  const { category, brandCode, assortment } = useParams<{
    category?: string
    brandCode?: string
    assortment: string
  }>()
  const [searchParams] = useSearchParams()
  const keylook = searchParams.get('keylook') || undefined

  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const afawarehouse = useGetAfaWarehouse()

  return afaProductsApi.useGetProductsQuery(
    {
      category,
      brandCode,
      assortment,
      eventId,
      customerId,
      keylook,
      keylookfull: keylookFull,
      afawarehouse: afawarehouse || '',
    },
    { skip: !keylook || afawarehouse === undefined },
  )
}

const MIN_SEARCH_LENGTH = 3

export const useGetSearchSuggestionsQuery = (searchText: string, options?: { skip: boolean }) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const [debouncedSearchText, setDebouncedSearchText] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchText = useCallback(
    debounce((newText: string) => {
      setDebouncedSearchText(newText)
    }, 500),
    [],
  )

  useEffect(() => {
    debounceSearchText(searchText)
  }, [debounceSearchText, searchText])

  return afaProductsApi.useGetSearchSuggestionsQuery(
    {
      customerId,
      eventId,
      searchText: debouncedSearchText,
    },
    { ...options, skip: options?.skip || debouncedSearchText.length < MIN_SEARCH_LENGTH },
  )
}
export const { useGetProductsQuery } = afaProductsApi
