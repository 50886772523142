import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, RouteChildrenProps } from 'react-router-dom'
import styled from 'styled-components'

import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import PLPManager from '../../components/PLPManager'
import devices from '../../config/devices'
import { checkRedirect } from '../../libs'
import { categoryToId } from '../../libs/couvettes'
import { isTableDevice } from '../../libs/url'
import { Brand } from '../../model/brand'
import { EmitEvent } from '../../model/model'
import {
  getCouvettes,
  preselectBrandsAction,
  reloadCouvettes,
  setBrandVisited,
  setCategoryFilter,
  setFiltersActivePageName,
} from '../../store/actions'
import { eventIdSelector } from '../../store/app/selectors'
import { brandsSelector } from '../../store/brands/selectors'
import { thereIsMorePagesSelector } from '../../store/couvettes/selectors'
import { customerVisitedSelector } from '../../store/customer/selectors'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import starsActions from '../../store/stars/actions'
import { cartModeSelector, isPlpStarsModeSelector } from '../../store/stars/selectors'
import FooterNav from '../FooterNav/index'
import useFetchInitialData from '../../hooks/useFetchInitialData'

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const getBrand = (brands: Brand[], brandSlug: string) => {
  return brands.find(brandItem => brandItem.slug === brandSlug)
}

const emitNavigationEvent = ({
  brands,
  brandSlug,
  emitEvent,
  customerVisited,
  category,
}: {
  brands: Brand[]
  brandSlug: string
  emitEvent: EmitEvent
  customerVisited: Record<string, boolean>
  category: string
}) => {
  const brand = getBrand(brands, brandSlug)
  if (brand) {
    emitEvent(
      'open_singleBrandPlp_page',
      {
        brand,
        brandVisited: customerVisited[brand.slug],
        category: category,
      },
      [devices.table],
    )
  }
}

const SingleBrandPage: React.FC<RouteChildrenProps<{ category: string; brandSlug: string }> &
  PageSocketManagerProps> = ({ match, socketIoEvent, emitEvent }) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })
  const brands = useSelector(brandsSelector)
  const eventId = useSelector(eventIdSelector)
  const customerVisited = useSelector(customerVisitedSelector)
  const thereIsMorePages = useSelector(thereIsMorePagesSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const isPlpStarsMode = useSelector(isPlpStarsModeSelector)
  const catalogMode = useSelector(cartModeSelector)

  const dispatch = useDispatch()

  const category = match?.params.category || ''
  const brandSlug = match?.params.brandSlug || ''

  const customerVisitedRef = useRef(customerVisited)
  useEffect(() => {
    customerVisitedRef.current = customerVisited
  }, [customerVisited])

  useEffect(() => {
    dispatch(starsActions.setShowInOut(false))
  }, [dispatch])

  const brandsRef = useRef(brands)

  useEffect(() => {
    dispatch(setFiltersActivePageName('plp'))
    dispatch(preselectBrandsAction([brandSlug]))
    dispatch(setBrandVisited(brandSlug))

    return () => {
      dispatch(setBrandVisited(brandSlug))
    }
  }, [dispatch, brandSlug])

  useEffect(() => {
    const categoryId = categoryToId(category)
    if (categoryId) {
      dispatch(setCategoryFilter(categoryId))
    }
    if (!isPlpStarsMode) {
      brands.length && dispatch(reloadCouvettes())
    } else {
      dispatch(starsActions.resetStarsFilters())
    }
  }, [category, dispatch, isPlpStarsMode, brands.length])

  // when couvette video ends, table should broadcast again the navigation event
  // NOTE: we want the signal to be sent only for couvette videos, not the pdp video
  const couvetteVideoIsEnded = socketIoEvent === 'do_ended_video' && !isPdpVisible
  useEffect(() => {
    if (couvetteVideoIsEnded) {
      emitNavigationEvent({
        brands: brandsRef.current,
        brandSlug,
        emitEvent,
        customerVisited: customerVisitedRef.current,
        category,
      })
    }
  }, [couvetteVideoIsEnded, brandSlug, emitEvent, category])

  // when pdp gets closed, table should broadcast again the navigation event
  // pdp is closed at landing, so the event is emitted at landed too, which is correct
  const pdpIsClosed = !isPdpVisible
  useEffect(() => {
    if (pdpIsClosed) {
      emitNavigationEvent({
        brands: brandsRef.current,
        brandSlug,
        emitEvent,
        customerVisited: customerVisitedRef.current,
        category,
      })
    }
  }, [pdpIsClosed, brandSlug, emitEvent, category])

  if (checkRedirect({ app: { eventId } })) {
    return <Redirect to={isTableDevice() ? '/events' : ''} />
  }

  return (
    <>
      <div className={`layout single-brand-page device-table brand-${brandSlug} has-couvettes`}>
        <StyledContent className="layout-content">
          <PLPManager
            showBrandLogo={false}
            getNextPage={
              catalogMode === 'units'
                ? () => {
                    if (thereIsMorePages) {
                      return dispatch(getCouvettes())
                    }
                  }
                : () => undefined
            }
          />
        </StyledContent>
      </div>
      <FooterNav />
    </>
  )
}

const SingleBrandPageManager = PageSocketManager(SingleBrandPage, [
  'do_play_video',
  'do_ended_video',
])

export default SingleBrandPageManager
