import React from 'react'

import ServiceContent from '../components/ServiceContent'
import app_config from '../config/app/config'

export const ServicePage: React.FC = () => {
  return (
    <div className="layout">
      <div className="layout-content">
        <ServiceContent config={app_config} />
      </div>
    </div>
  )
}

export default ServicePage
