import classnames from 'classnames'
import queryString from 'qs'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import devices from '../../../config/devices'
import {
  categoryToId,
  isCategoryElectronics,
  isCategoryElectronicsOptical,
  isCategoryElectronicsSun,
  isCategoryOptical,
  isCategorySun,
} from '../../../libs/couvettes'
import socketManager from '../../../libs/socketManager'
import {
  getDeviceAbsolutePath,
  getUrlParts,
  isCategoriesPage,
  isElectronicPage,
  isPageMatches,
  isSearchPLP,
} from '../../../libs/url'
import { Brand as BrandType, BrandCategories } from '../../../model/brand'
import {
  applyFilters,
  reloadCouvettes,
  searchCouvettes,
  setCategoryFilter,
  setWishlistCurrentCategoryId,
  toggleCategoryAssortment,
} from '../../../store/actions'
import { isKidCategoryModeEnabledSelector } from '../../../store/app/selectors'
import { brandsSelector } from '../../../store/brands/selectors'
import { selectedCartCategorySelector } from '../../../store/cart/selectors'
import {
  couvettesBrandsSelector,
  couvettesSelector,
  isCouvettesLoadedgSelector,
  sortSelector,
} from '../../../store/couvettes/selectors'
import { filtersSelector } from '../../../store/filters/selectors'
import starsActions from '../../../store/stars/actions'
import { wishlistCurrentCategoryIdSelector } from '../../../store/wishlist/selectors'
import { breakpoints, getFluidFontSize, palette } from '../../../style/theme'
import BrandFooterLogo from '../BrandLogo'
import {
  categoryIsLoadingSelector,
  launchInitiativeSelector,
  selectedInitiativeSelector,
} from '../../../store/initiatives/selectord'
import initiativesActions from '../../../store/initiatives/actions'
import { useGetInitiativesQuery } from '../../../services/initiatives'
import { slice } from '../../../store/couvettes/slice'
import app_config from '../../../config/app/config'
import { useSearchParams } from '../../../hooks/useSearchParams'

const getBrand = (
  filters: { brand_slug?: { active: unknown[] } },
  couvettesBrands: (string | undefined)[],
  brands: BrandType[],
  isMonobrandInitiative: boolean,
) => {
  const filterBrands = filters.brand_slug?.active
  const parsedSearch = queryString.parse(location.search, { ignoreQueryPrefix: true })

  // Show brand in footer on multibrand PLP page only in two cases:
  // 1. Exactly one brand is selected in filters
  // 2. Only products of one brand are presented in search results
  const brandsArray =
    (isPageMatches('plp') && (parsedSearch.searchQuery || parsedSearch.inEvidence)) ||
    isMonobrandInitiative
      ? couvettesBrands
      : filterBrands
  const activeSlug = brandsArray && brandsArray.length === 1 && brandsArray[0]
  return activeSlug ? brands.find(el => el.slug === activeSlug) : null
}

export const getEvidenceCategories = (brands: string[], categoryId: string): string => {
  let result

  if (categoryId === (app_config.sunCategory || app_config.electronicsSunCategory)) {
    if (brands.length > 1 && brands.includes('RW')) {
      result = '2,K'
    } else if (brands?.length === 1 && brands[0] === 'RW') {
      result = 'K'
    } else {
      result = '2'
    }
  } else if (categoryId === (app_config.opticalCategory || app_config.electronicsOpticalCategory)) {
    if (brands.length > 1 && brands.includes('RW')) {
      result = '1,E'
    } else if (brands?.length === 1 && brands[0] === 'RW') {
      result = 'E'
    } else {
      result = '1'
    }
  } else {
    result = 'K,E'
  }

  return result
}

type CategoryLinkProps = {
  label: string
  disable: boolean
  categoryLabel: string
  brandSlug?: string
  className?: string
  category?: string
}

const CategoryLink: React.FC<CategoryLinkProps> = ({
  label,
  disable,
  categoryLabel,
  brandSlug,
  className,
  category,
}) => {
  const dispatch = useDispatch()
  const { data } = useGetInitiativesQuery()
  const initiatives = data?.initiatives || []

  const [searchParams] = useSearchParams()
  const isInEvidence = searchParams.get('initiative') !== null

  const sort = useSelector(sortSelector)
  const categoryIsLoading = useSelector(categoryIsLoadingSelector)
  const initiativeHasJustBeenLaunched = useSelector(launchInitiativeSelector)
  const couvettes = useSelector(couvettesSelector)
  const selectedInitiativeCode = useSelector(selectedInitiativeSelector)
  const selectedInitiative = initiatives.find(
    initiative => initiative.initiativecode === selectedInitiativeCode,
  )
  const selectedInitiativeUPCs = selectedInitiative?.products.map(({ upc }) => upc)
  const selectedInitiativeBrands = selectedInitiative?.brands || []

  const anchorRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (
      initiativeHasJustBeenLaunched &&
      !categoryIsLoading &&
      !couvettes.items.length &&
      label.toLowerCase() === 'optical' &&
      !className?.includes('active') &&
      isInEvidence &&
      anchorRef.current
    ) {
      dispatch(initiativesActions.setLaunchInitiative(false))
      anchorRef.current.click()
    }
  }, [
    categoryIsLoading,
    className,
    couvettes.items.length,
    dispatch,
    initiativeHasJustBeenLaunched,
    isInEvidence,
    label,
  ])

  if (disable) {
    return <div className={className}>{label}</div>
  }

  const onCategorySelected = (categoryId: string) => {
    socketManager.emitSignal(
      'change_category',
      {
        category,
        roomType: getUrlParts().roomType,
      },
      devices.not([devices.extraipad]),
    )

    if (isPageMatches('cart')) {
      dispatch(toggleCategoryAssortment())
    } else {
      dispatch(setWishlistCurrentCategoryId(categoryId))
      dispatch(setCategoryFilter(categoryId))
      dispatch(starsActions.resetStarsFilters())
      if (selectedInitiativeUPCs && isInEvidence) {
        dispatch(initiativesActions.setCategoryIsLoading(true))
        dispatch(slice.actions.removeCouvettes())
        dispatch(
          searchCouvettes(
            '',
            selectedInitiativeUPCs,
            getEvidenceCategories(selectedInitiativeBrands, categoryId),
            sort,
          ),
        )
      } else if (!isPageMatches('wishlist')) {
        dispatch(applyFilters())
        dispatch(reloadCouvettes())
      }
    }
  }

  return brandSlug && !isPageMatches('cart') && !isPageMatches('wishlist') && !isInEvidence ? (
    <Link
      to={`${getDeviceAbsolutePath()}/single-brand/${brandSlug}/${categoryLabel}`}
      className={className}
    >
      {label}
    </Link>
  ) : (
    <a
      ref={anchorRef}
      className={className}
      onClick={e => {
        if (!className?.includes('active')) {
          e.preventDefault()
          const categoryId = categoryToId(categoryLabel)
          if (categoryId) {
            onCategorySelected(categoryId)
          }
        }
      }}
    >
      {label}
    </a>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  &.with-brand {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StyledCategory = styled(CategoryLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 10px solid transparent;
  border-left: 1px solid ${palette.silver};
  border-right: 1px solid ${palette.silver};
  text-transform: none;
  color: ${palette.kobi};
  font-size: ${getFluidFontSize('14px')};

  @media screen and (max-width: ${breakpoints.L}) {
    border-top-width: 5px;
  }

  &.active {
    border-top-color: ${palette.congressBlue};
    box-shadow: 0px 0 4px 0 rgba(0, 0, 0, 0.15);
    font-weight: bold;
  }

  &.sun:not(.with-brand) {
    border-right: none;
  }

  &.optical.with-brand {
    grid-column-start: 3;
  }
`

const WrapperBrandFooterLogo = styled(BrandFooterLogo)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  grid-column-start: 2;
`

type Props = {
  isBrandColVisible: boolean
}

const FooterNavBrand: React.FC<Props> = ({ isBrandColVisible }) => {
  const { t } = useTranslation()

  const isCouvetteLoaded = useSelector(isCouvettesLoadedgSelector)
  const isKidCategoryModeEnabled = useSelector(isKidCategoryModeEnabledSelector)
  const filters = useSelector(filtersSelector)
  const cartCategory = useSelector(selectedCartCategorySelector)
  const couvettesBrands = useSelector(couvettesBrandsSelector)
  const brands = useSelector(brandsSelector)
  const { data: initiatives } = useGetInitiativesQuery()

  const plpCategory = filters.category?.active[0]
  const category = isPageMatches('cart') ? cartCategory : plpCategory

  const [searchParams] = useSearchParams()
  const selectedInitiativeParam = searchParams.get('initiative')
  const selectedInitiative = initiatives?.initiatives.find(
    ({ initiativecode }) => initiativecode === selectedInitiativeParam,
  )
  const selectedInitiativeBrands = brands.filter(
    ({ code, subBrands }) =>
      selectedInitiative?.brands.includes(code) ||
      subBrands.some(subBrand => selectedInitiative?.brands.includes(subBrand.code)),
  )

  const brand =
    getBrand(
      filters,
      [...new Set(couvettesBrands)],
      brands,
      selectedInitiativeBrands.length === 1,
    ) || undefined
  const wishlistCurrentCategoryId = useSelector(wishlistCurrentCategoryIdSelector)

  const isCategoryDisabled = (category: keyof BrandCategories) => {
    if (isCouvetteLoaded) {
      return false
    }

    if (!brand || !brand.categoriesAvailable) {
      return false
    }

    if (!isPageMatches('single-brand')) {
      return false
    }

    const { categoriesAvailable } = brand
    const juniorCategoryKey = `${category}Junior` as keyof BrandCategories

    // Buttons availability depends on isKidCategoryModeEnabled only on single brand PLP page
    return isKidCategoryModeEnabled && !isCategoriesPage()
      ? !categoriesAvailable[juniorCategoryKey]
      : !categoriesAvailable[category]
  }

  const isElectronics =
    category &&
    (isCategoryElectronics(category) ||
      isCategoryElectronicsSun(category) ||
      isCategoryElectronicsOptical(category))

  return (
    <Wrapper className={classnames({ 'footer-nav-tab': true, 'with-brand': isBrandColVisible })}>
      {!isSearchPLP() && !isElectronicPage() && (
        <StyledCategory
          className={classnames({
            sun: true,
            active:
              category &&
              (!isPageMatches('wishlist')
                ? isCategorySun(category) || isCategoryElectronicsSun(category)
                : (isCategorySun(category) || isCategoryElectronicsSun(category)) &&
                  !!wishlistCurrentCategoryId),
            'with-brand': isBrandColVisible,
          })}
          label={t('GenericWords.sun')}
          brandSlug={brand && brand.slug}
          disable={!isElectronics && isCategoryDisabled('sun')}
          categoryLabel={isElectronics ? 'electronics-sun' : 'sun'}
          category={category}
        />
      )}

      {isBrandColVisible && brand && (
        <WrapperBrandFooterLogo slug={brand.slug} brandCode={brand.code} />
      )}

      {!isSearchPLP() && !isElectronicPage() && (
        <StyledCategory
          className={classnames({
            optical: true,
            active:
              category &&
              (!isPageMatches('wishlist')
                ? isCategoryOptical(category) || isCategoryElectronicsOptical(category)
                : (isCategoryOptical(category) || isCategoryElectronicsOptical(category)) &&
                  !!wishlistCurrentCategoryId),
            'with-brand': isBrandColVisible,
          })}
          label={t('GenericWords.optical')}
          brandSlug={brand && brand.slug}
          disable={!isElectronics && isCategoryDisabled('optical')}
          categoryLabel={isElectronics ? 'electronics-optical' : 'optical'}
          category={category}
        />
      )}
    </Wrapper>
  )
}

export default FooterNavBrand
