import queryString from 'qs'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { createApi } from '@reduxjs/toolkit/query/react'

import app_config from '../config/app/config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import {
  CardContentOperation,
  CardDeltaOperation,
  ConfirmCartItemPayload,
  DoorWithShippingAddresses,
  UploadCartPayload,
} from '../model/cart'
import { Base64File } from '../model/model'
import { customerIdSelector, eventIdSelector } from '../store/app/selectors'
import { cartBackOfficeNoteSelector } from '../store/cart/selectors'

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: staggeredBaseQueryWithBailOut(`${app_config.apiUrl}/cart`),
  endpoints: builder => ({
    downloadCartFile: builder.query<
      { filename: string; file: Base64File },
      {
        eventId: string
        customerId: string
        showPrice: boolean
        showImage: boolean
        fileType: 'excel' | 'pdf'
      }
    >({
      query: ({ eventId, customerId, showPrice, showImage, fileType }) => ({
        url: `/download/customer/${customerId}/event/${eventId}?${queryString.stringify({
          showPrice,
          showImage,
          fileType,
        })}`,
      }),
    }),

    getDoorShippingAddress: builder.query<
      DoorWithShippingAddresses[],
      { eventId: string; customerId: string }
    >({
      query: ({ eventId, customerId }) => ({
        url: `/doors-addresses/customer/${customerId}/event/${eventId}`,
      }),
    }),

    uploadCart: builder.mutation<
      {
        fault?: boolean
        items: CardDeltaOperation[]
        content: CardContentOperation[]
      },
      {
        eventId: string
        customerId: string
        onlyCheck: boolean
        uploadCartPayload: UploadCartPayload
      }
    >({
      query: ({ eventId, customerId, onlyCheck, uploadCartPayload }) => ({
        url: `/upload/customer/${customerId}/event/${eventId}?onlyCheck=${onlyCheck}`,
        method: 'POST',
        body: uploadCartPayload,
      }),
    }),

    confirmCart: builder.mutation<
      {
        Result: boolean
        Mesage: string
      },
      {
        eventId: string
        customerId: string
        cartProductsToConfirm: ConfirmCartItemPayload[]
        backOfficeNote?: string
      }
    >({
      query: ({ eventId, customerId, cartProductsToConfirm, backOfficeNote }) => ({
        url: `/status/customer/${customerId}/event/${eventId}`,
        method: 'POST',
        body: {
          status: 'Confirmed by KAM',
          items: cartProductsToConfirm,
          backOfficeNote,
        },
      }),
    }),

    sendEmailCart: builder.mutation<
      {
        Result: boolean
        Mesage: string
      },
      {
        eventId: string
        customerId: string
        receiversAddresses: string[]
        showPrice: boolean
        showImage: boolean
        fileType: ('excel' | 'pdf')[]
      }
    >({
      query: ({ eventId, customerId, receiversAddresses, showPrice, showImage, fileType }) => ({
        url: `/mail/customer/${customerId}/event/${eventId}?${queryString.stringify({
          showPrice,
          showImage,
          fileType,
        })}`,
        method: 'POST',
        body: {
          receivers: receiversAddresses.map(mailaddress => ({ mailaddress })),
        },
      }),
    }),
  }),
})

export const useDownloadCartFileQuery = ({
  showPrice = false,
  showImage,
  startDownload,
  fileType,
}: {
  startDownload: boolean
  showPrice?: boolean
  showImage: boolean
  fileType: 'excel' | 'pdf' | undefined
}) => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return cartApi.useDownloadCartFileQuery(
    { eventId, customerId, showPrice, showImage, fileType: fileType || 'excel' },
    { skip: !eventId || !customerId || !startDownload },
  )
}

export const useGetDoorShippingAddressQuery = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  return cartApi.useGetDoorShippingAddressQuery(
    { eventId, customerId },
    { skip: !eventId || !customerId },
  )
}

export const useUploadCartMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_uploadCart, uploadCartResult] = cartApi.useUploadCartMutation({
    fixedCacheKey: 'cartUploadResult',
  })
  const uploadCart = useCallback(
    (onlyCheck: boolean, uploadCartPayload: UploadCartPayload) =>
      eventId && customerId && _uploadCart({ eventId, customerId, onlyCheck, uploadCartPayload }),
    [_uploadCart, customerId, eventId],
  )
  return [uploadCart, uploadCartResult] as [typeof uploadCart, typeof uploadCartResult]
}

export const useConfirmCartMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)
  const cartBackOfficeNote = useSelector(cartBackOfficeNoteSelector)

  const [_confirmCart, confirmCartResult] = cartApi.useConfirmCartMutation({
    fixedCacheKey: 'confirmCartResult',
  })
  const confirmCart = (cartProductsToConfirm: ConfirmCartItemPayload[], backOfficeNote?: string) =>
    eventId &&
    customerId &&
    _confirmCart({
      eventId,
      customerId,
      cartProductsToConfirm,
      backOfficeNote: `${cartBackOfficeNote}\n${backOfficeNote}`.trim(),
    })
  return [confirmCart, confirmCartResult] as [typeof confirmCart, typeof confirmCartResult]
}

export const useSendEmailCartMutation = () => {
  const eventId = useSelector(eventIdSelector)
  const customerId = useSelector(customerIdSelector)

  const [_sendEmailCart, sendEmailCartResult] = cartApi.useSendEmailCartMutation()
  const sendEmailCart = ({
    addresses,
    showPrice,
    showImage,
    fileType,
  }: {
    addresses: string[]
    showPrice: boolean
    showImage: boolean
    fileType: ('excel' | 'pdf')[] | undefined
  }) =>
    eventId &&
    customerId &&
    _sendEmailCart({
      eventId,
      customerId,
      receiversAddresses: addresses,
      showPrice,
      showImage,
      fileType: fileType || ['excel'],
    })
  return [sendEmailCart, sendEmailCartResult] as [typeof sendEmailCart, typeof sendEmailCartResult]
}
