import styled, { css } from 'styled-components/macro'
import { breakpoints, getFluidSizeWithFullFormula, palette } from '../../style/theme'
import { useEffect, useState } from 'react'
import { CustomDatePicker } from '../CustomDatePicker'
import CalendarIconAfa from '../icons/CalendarIconAfa'
import { useDispatch, useSelector } from 'react-redux'
import { cancellationDatesSelector } from '../../store/afa/selectors'
import afaActions from '../../store/afa/actions'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import Loading from '../Loading'
import { useHistory, useParams } from 'react-router'
import { getDeviceAbsolutePath } from '../../libs/url'
import {
  useGetCartDeliveryDatesQuery,
  useSetCancellationDatesMutation,
} from '../../services/afaCart'
import {
  convertDateToDdMmYyyyString,
  convertDdMmYyyyToDate,
  sortDdMmYyyyDates,
} from '../../libs/time'
import { ButtonCancel, ButtonSave, ButtonsGroup } from '../AfaCommonComponents'

export const getFluidSize = getFluidSizeWithFullFormula

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 ${getFluidSize('px', 77, 624, 1366, 3840)};
  color: ${palette.tangaroa};
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  flex: 1;
  padding-bottom: 2rem;
`

const Ul = styled.ul`
  overflow: auto;
  flex: 1;
`

const ModalTitle = styled.h1`
  font-size: ${getFluidSize('px', 26, 48, 1366, 3840)};
  font-family: GilmerBold, sans-serif;
  text-align: center;
  color: ${palette.tangaroa};
`

const gridStyle = css`
  display: grid;
  border-bottom: 2px solid ${palette.mischka};
  grid-template-columns: 3fr 2fr;
`

const DeliveryTitle = styled.h2`
  font-size: ${getFluidSize('px', 12, 32, 1366, 3840)};
  padding: ${getFluidSize('px', 40, 108, 1366, 3840)} 0 ${getFluidSize('px', 12, 40, 1366, 3840)};
  font-weight: bold;
  color: ${palette.tangaroa};
  ${gridStyle};
  text-transform: uppercase;
`

const DeliveryDate = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  font-size: ${getFluidSize('px', 16, 24, 1366, 3840)};
  letter-spacing: 0.96px;
`

const DeliveryLi = styled.li`
  height: ${getFluidSize('px', 68, 132, 1366, 3840)};
  ${gridStyle}
`

const LeftColumnLi = styled.div`
  display: flex;
  align-items: center;
`

const RightColumnLi = styled.div`
  display: flex;
  align-items: center;

  .react-date-picker {
    width: 100%;

    .react-date-picker__inputGroup {
      color: ${palette.tangaroa};
    }
  }

  @media (max-width: ${breakpoints.L}) {
    grid-template-columns: 3fr 2fr;

    .react-date-picker {
      width: 70%;
    }
  }
`

type Props = {
  onCancel: () => void
}

const AfaCancellationDateModal: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation()

  const cancellationDates = useSelector(cancellationDatesSelector)
  const [allCancellationDate, setAllCancellationDate] = useState<Date | null>(null)

  const dispatch = useDispatch()

  const middleDot = '\u00B7'

  const deliveryDatesQuery = useGetCartDeliveryDatesQuery()

  useEffect(() => {
    if (!deliveryDatesQuery.data) return

    const cancellationDatesAreUpToDateWithQuery =
      deliveryDatesQuery.data.length === cancellationDates.length &&
      deliveryDatesQuery.data.every(
        ({ deliveryDate, cancellationDate }, index) =>
          cancellationDates[index].deliveryDate === deliveryDate &&
          cancellationDates[index].cancellationDate === cancellationDate,
      )

    if (
      !cancellationDatesAreUpToDateWithQuery &&
      deliveryDatesQuery.data.length !== cancellationDates.length
    ) {
      dispatch(
        afaActions.setCancellationDates(
          deliveryDatesQuery.data.map(({ cancellationDate, deliveryDate }) => ({
            deliveryDate,
            cancellationDate: cancellationDate || deliveryDate,
          })),
        ),
      )
    }
  }, [dispatch, deliveryDatesQuery.data, cancellationDates])

  const history = useHistory()

  const { assortment } = useParams<{
    assortment: string
  }>()

  const [setCancellationDates, setCancellationDatesResult] = useSetCancellationDatesMutation()

  useEffect(() => {
    if (setCancellationDatesResult.isSuccess) {
      history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout`)
    } else if (setCancellationDatesResult.isError) {
      // TODO: what should we do in case of error?
      history.push(`${getDeviceAbsolutePath()}/${assortment}/checkout`)
    }
  }, [setCancellationDatesResult, assortment, history])

  const sortedDeliveryDates = cancellationDates
    .map(({ deliveryDate }) => deliveryDate)
    .sort(sortDdMmYyyyDates)

  return (
    <Wrapper>
      <Content>
        <ModalTitle>{t('Afa.Cart.cancellationDate')}</ModalTitle>
        <DeliveryTitle>
          <span>{t('Afa.Cart.deliveryDates')}</span>
          <span>{t('Afa.Cart.setLastCancellationDate')}</span>
        </DeliveryTitle>
        <Ul id="delivery-list">
          {sortedDeliveryDates.length > 0 && (
            <DeliveryLi>
              <LeftColumnLi>
                <DeliveryDate>{t('Afa.AllDeliveries')}</DeliveryDate>
              </LeftColumnLi>

              <RightColumnLi>
                <CustomDatePicker
                  calendarIcon={<CalendarIconAfa />}
                  value={allCancellationDate}
                  defaultValue={convertDdMmYyyyToDate(
                    sortedDeliveryDates[sortedDeliveryDates.length - 1],
                  )}
                  onChange={(newDate: Date) => {
                    setAllCancellationDate(newDate)
                    const newCancellationDates = cancellationDates.map(cancellationDate => {
                      return {
                        ...cancellationDate,
                        cancellationDate: convertDateToDdMmYyyyString(newDate),
                      }
                    })
                    dispatch(afaActions.setCancellationDates(newCancellationDates))
                  }}
                  minDate={convertDdMmYyyyToDate(
                    sortedDeliveryDates[sortedDeliveryDates.length - 1],
                  )}
                  format="dd/MM/yyyy"
                />
              </RightColumnLi>
            </DeliveryLi>
          )}
          {cancellationDates.map((cancellationInfo, index) => {
            const { deliveryDate, cancellationDate } = cancellationInfo

            return (
              <DeliveryLi key={`${deliveryDate}-${cancellationDate}`}>
                <LeftColumnLi>
                  <DeliveryDate>
                    {t('Afa.delivery')} {index + 1} {middleDot}{' '}
                    <span>{format(convertDdMmYyyyToDate(deliveryDate), 'dd/MM/yyyy')}</span>
                  </DeliveryDate>
                </LeftColumnLi>

                <RightColumnLi>
                  <CustomDatePicker
                    calendarIcon={<CalendarIconAfa />}
                    value={convertDdMmYyyyToDate(cancellationDate)}
                    onChange={(newDate: Date) => {
                      dispatch(
                        afaActions.setCancellationDate({
                          ...cancellationInfo,
                          cancellationDate: convertDateToDdMmYyyyString(newDate),
                        }),
                      )
                    }}
                    minDate={convertDdMmYyyyToDate(deliveryDate)}
                    format="dd/MM/yyyy"
                  />
                </RightColumnLi>
              </DeliveryLi>
            )
          })}
        </Ul>
      </Content>
      <ButtonsGroup>
        <ButtonCancel onClick={onCancel}>{t('GenericWords.cancel')}</ButtonCancel>
        <ButtonSave
          disabled={setCancellationDatesResult.isLoading}
          onClick={() => {
            setCancellationDates(cancellationDates)
          }}
        >
          {t('Afa.Cart.saveChanges')}
        </ButtonSave>
      </ButtonsGroup>
      {deliveryDatesQuery.isFetching || (setCancellationDatesResult.isLoading && <Loading />)}
    </Wrapper>
  )
}

export default AfaCancellationDateModal
