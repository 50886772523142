import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AfaMoco } from '../../model/afa'
import {
  breakpoints,
  getFluidSize,
  getFluidSizeWithFullFormula,
  palette,
  pxToRem,
} from '../../style/theme'

import { format } from 'date-fns'
import { formatMoney } from '../../libs'
import { useGetBrandsQuery } from '../../services/afa'
import { useSelector } from 'react-redux'
import { showSrPriceSelector, showWhsPriceSelector } from '../../store/customer/selectors'

const Wrapper = styled.div`
  background-color: white;
  color: ${palette.tangaroa};
  border: 2px solid ${palette.shadowBlue};
  border-left: none;
  border-bottom-right-radius: ${pxToRem(4)}rem;
  border-top-right-radius: ${pxToRem(4)}rem;
  min-height: ${getFluidSizeWithFullFormula('px', 98, 206, 1366, 3840)};
  padding: ${getFluidSize(`${pxToRem(12)}rem`, 17)} ${getFluidSize(`${pxToRem(19)}rem`, 19)};
  @media screen and (max-width: ${breakpoints.L}) {
    color: ${palette.cloudBurst};
  }
`

const Table = styled.table`
  width: 100%;
  font-size: ${getFluidSizeWithFullFormula('px', 14, 32, 1366, 3840)};
`

const Thead = styled.thead`
  text-transform: capitalize;
  border-bottom: solid ${getFluidSizeWithFullFormula('px', 1, 4, 1366, 3840)} ${palette.platinum};
  font-size: ${getFluidSizeWithFullFormula('px', 12, 24, 1366, 3840)};

  th {
    padding-bottom: ${getFluidSize(`${pxToRem(5)}rem`, 23)};
    text-align: left;

    &.afa-name {
      width: 30%;
    }
    &.afa-code {
      width: 15%;
    }
    &.afa-color {
      width: 15%;
    }
    &.afa-whs,
    &.afa-srp,
    &.afa-avd,
    &.afa-po {
      width: 10%;
    }
    @media screen and (max-width: ${breakpoints.L}) {
      font-family: GilmerRegular, sans-serif;
    }
  }
`

const Tbody = styled.tbody`
  td {
    font-size: ${getFluidSizeWithFullFormula('px', 12, 32, 1366, 3840)};

    padding-top: ${getFluidSizeWithFullFormula('px', 14, 28, 1366, 3840)};
    &.afa-product-name {
      font-size: ${getFluidSizeWithFullFormula('px', 14, 32, 1366, 3840)};

      font-family: GilmerBold, sans-serif;
    }
  }
`

type Props = {
  modelCode: string
  name: string
  selectedColor: AfaMoco
  currency?: string
}

const AfaPdpSelectedColorRecap: React.FC<Props> = ({
  modelCode,
  name,
  selectedColor,
  currency,
}) => {
  const { t } = useTranslation()
  const showSrPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)
  const hasAvailabilityDataList = selectedColor?.availabilityDataList.length > 0

  const brandsQuery = useGetBrandsQuery()
  const defaultCurrency = brandsQuery.data?.[0].currency || ''
  const selectedCurrency = currency || defaultCurrency

  return (
    <Wrapper>
      <Table>
        <Thead>
          <tr>
            <th className="afa-name">{t('Afa.Name')}</th>
            <th className="afa-code">{t('Afa.code')}</th>
            <th className="afa-color">{t('Afa.color')}</th>
            {showWhsPrice && <th className="afa-whs">{t('Afa.WHSPrice')}</th>}
            {showSrPrice && <th className="afa-srp">{t('Afa.srp')}</th>}
            {hasAvailabilityDataList && <th className="afa-avd">{t('Afa.avd')}</th>}
            {hasAvailabilityDataList && <th className="afa-po">{t('Afa.po')}</th>}
          </tr>
        </Thead>
        <Tbody>
          <tr>
            <td className="afa-product-name">{name}</td>
            <td>
              {modelCode}-{selectedColor.colorCode}
            </td>
            <td>{selectedColor.colorDescription}</td>
            {showWhsPrice && <td>{formatMoney(selectedCurrency, selectedColor.whsPrice)}</td>}
            {showSrPrice && <td>{formatMoney(selectedCurrency, selectedColor.srPrice)}</td>}
            {hasAvailabilityDataList && (
              <td>
                {format(new Date(selectedColor.availabilityDataList[0].avdDate), 'dd/MM/yyyy')}
              </td>
            )}
            {hasAvailabilityDataList && (
              <td className="afa-po">
                {format(new Date(selectedColor.availabilityDataList[0].poDate), 'dd/MM/yyyy')}
              </td>
            )}
          </tr>
        </Tbody>
      </Table>
    </Wrapper>
  )
}

export default AfaPdpSelectedColorRecap
