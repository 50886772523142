import { Col, Icon } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Header } from '../../components/Header'
import { AlphaNumericKeyboardInput } from '../../components/KeyboardInput'
import Loading from '../../components/Loading'
import PageSocketManager, { PageSocketManagerProps } from '../../components/PageSocketManager'
import devices from '../../config/devices'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import socketManager from '../../libs/socketManager'
import { getBaseUrl, isTableDevice } from '../../libs/url'
import { mainRoutes } from '../../model/model'
import {
  delCustomerId,
  resetErrors,
  retrieveCustomerList,
  setCustomerId,
  setEventId,
} from '../../store/actions'
import {
  customersSelector,
  eventIdSelector,
  eventsSelector,
  isDigitalEventSelector,
  isKamUserSelector,
} from '../../store/app/selectors'
import { usernameSelector } from '../../store/auth/selectors'
import { selectEventAndCustomerHeaderButton } from '../../style/DigitalEventsCommonComponents'
import {
  Content,
  CustomerList,
  HeadLeft,
  HeadTitle,
  LogoutButton,
  SelectTable,
  SelectTableBody,
  SelectTableButton,
  SelectTableHead,
  SelectTd,
  SelectTh,
  SelectTr,
  StyledStarsIcon,
  TableHead,
  Wrapper,
} from '../../style/SelectEventsAndCustomerComponents'
import {
  breakpoints,
  breakpointsCross,
  buttonBorderRadius,
  getFluidFontSize,
  getFluidSize,
  palette,
} from '../../style/theme'
import { useGetCustomerAssortmentTypesQuery } from '../../services/afa'

const SearchCustomersTitle = styled.h3`
  font-weight: bold;
  letter-spacing: 0.05em;
  background-color: ${palette.white};
  color: ${palette.hibiscus};
  text-transform: uppercase;
  border-bottom: none;
  margin: 2vh 0;

  font-size: ${getFluidFontSize('20px', 15)};
  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: 1rem;
  }
`

const HeadBackButton = styled.button`
  align-items: center;
  font-weight: bold;
  color: ${palette.curiousBlue};
  width: auto;
  border: none;
  min-width: auto;
  min-width: 5vw;
  cursor: pointer;
  margin-right: 1em;

  &:hover {
    color: ${palette.congressBlue};
  }

  font-size: ${getFluidFontSize('20px', 15)};
  @media (max-width: ${breakpointsCross.L.min}) {
    font-size: 1rem;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`

const LinkStyled = styled(Link)`
  ${selectEventAndCustomerHeaderButton}
  padding: 1vh 1vw;
  text-transform: capitalize;

  &:hover {
    color: ${palette.curiousBlue};
  }
`

const HeadEventDetail = styled(Col)`
  align-items: center;
`

const CustomersSearchWrapper = styled(Col)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0 3vh 3vh 3vh;
`

const SearchBox = styled(Col)`
  margin-left: 3rem;
  padding-top: 3vh;

  @media (max-width: ${breakpoints.XL}) {
    width: 20%;
    margin-left: 2rem;
  }

  @media (max-width: ${breakpoints.S}) {
    width: 15%;
    margin-left: 1rem;
  }

  .ant-input {
    height: ${getFluidSize('50px', 15)};
    border-radius: ${buttonBorderRadius};

    @media (max-width: ${breakpointsCross.L.min}) {
      height: auto;
    }
  }
`

const InputBox = styled.div`
  position: relative;
`

const SearchIcon = styled(Icon)`
  position: absolute;
  font-size: 25px;
  right: 0.1vw;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.1vh 1vh;
  background-color: ${palette.white};
`

const getRedirectPath = (isDigitalEvent: boolean, assortments: string[]) => {
  if (isDigitalEvent) {
    return '/digital-events'
  }

  if (assortments.some(assortment => assortment !== 'frames')) {
    return `/${mainRoutes.chooseAssortment}`
  }

  const classicFlowRedirectUri = `${getBaseUrl()}/choose-brand?assortment=frames`

  return classicFlowRedirectUri
}

export const SelectCustomerPage: React.FC<PageSocketManagerProps> = ({ emitEvent }) => {
  useFetchInitialData()

  const dispatch = useDispatch()

  const selectedEventId = useSelector(eventIdSelector)

  useEffect(() => {
    dispatch(delCustomerId())
    if (selectedEventId) {
      dispatch(retrieveCustomerList(selectedEventId))
    }
  }, [dispatch, selectedEventId])

  useEffect(() => {
    emitEvent('open_selectCustomers_page', {}, devices.table)
    socketManager.emitJoinSignal()
  })

  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState('')

  // is the user is a customer
  const [selectedCustomerId, _setSelectedCustomerId] = useState('')
  const setSelectedCustomerId = (customerId: string) => {
    _setSelectedCustomerId(customerId)
    dispatch(setCustomerId(customerId))
  }

  const assortmentsQuery = useGetCustomerAssortmentTypesQuery(
    {
      customerId: selectedCustomerId,
      eventId: selectedEventId,
    },
    { skip: !selectedEventId || !selectedCustomerId },
  )

  const isDigitalEvent = useSelector(isDigitalEventSelector)

  const history = useHistory()

  // we need to wait the assortments call to be resolved to know where to rediect
  useEffect(() => {
    if (assortmentsQuery.data) {
      history.push(getRedirectPath(isDigitalEvent, assortmentsQuery.data))
    }
  }, [assortmentsQuery.data, history, isDigitalEvent])

  const username = useSelector(usernameSelector)
  const events = useSelector(eventsSelector)
  const customers = useSelector(customersSelector)
  const isKamUser = useSelector(isKamUserSelector)

  if (!selectedEventId) {
    return <Redirect to="/events" />
  }

  const filteredCustomers = searchQuery
    ? customers?.filter(
        ({ name, id }) =>
          name.toLowerCase().includes(searchQuery.toLowerCase()) || id.includes(searchQuery),
      )
    : customers

  const eventDetails = events?.find(({ id }) => id === selectedEventId)

  const showInviteButton = isKamUser
  const showCalendarButton = isKamUser && isDigitalEvent

  return selectedCustomerId && assortmentsQuery.data ? (
    <Redirect to={getRedirectPath(isDigitalEvent, [])} />
  ) : (
    <Wrapper>
      <Content>
        {!customers || assortmentsQuery.isFetching ? (
          <Loading />
        ) : (
          <>
            <Header hideButtons={true} />
            <TableHead>
              <HeadLeft>
                <HeadBackButton
                  onClick={(e: any) => {
                    e.preventDefault()
                    setSearchQuery('')
                    setSelectedCustomerId('')
                    dispatch(setEventId(''))
                    history.push(`/${mainRoutes.events}`)
                  }}
                >
                  <Icon type="left" />
                  {t('CustomersList.back')}
                </HeadBackButton>
                {username}
              </HeadLeft>
              <HeadTitle>
                {`${t('CustomersList.title')} (${customers.length})`}
                <HeadEventDetail>
                  {eventDetails?.location} - {eventDetails?.id}
                </HeadEventDetail>
              </HeadTitle>
              <Buttons>
                {showInviteButton ? (
                  <LinkStyled to="/digital-events/external/contact-management">
                    {t('CustomersList.invite')}
                  </LinkStyled>
                ) : null}
                {showCalendarButton && (
                  <LinkStyled to="/digital-events/external/kam-calendar">
                    {t('CustomersList.SeeAllAppointments')}
                  </LinkStyled>
                )}
                <LogoutButton />
              </Buttons>
            </TableHead>
            <CustomersSearchWrapper>
              <CustomerList>
                <SelectTable>
                  <SelectTableHead>
                    <SelectTr>
                      <SelectTh>{t('CustomersList.column_client_code')}</SelectTh>
                      <SelectTh>{t('CustomersList.column_client_name')}</SelectTh>
                      <SelectTh>{t('CustomersList.column_nationality')}</SelectTh>
                      <SelectTh>{t('CustomersList.columnStars')}</SelectTh>
                      <SelectTh></SelectTh>
                    </SelectTr>
                  </SelectTableHead>
                  <SelectTableBody>
                    {filteredCustomers
                      ?.slice()
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(({ id, name, nationality, stars }) => (
                        <SelectTr key={id}>
                          <SelectTd>{id}</SelectTd>
                          <SelectTd>{name} </SelectTd>
                          <SelectTd>{nationality}</SelectTd>
                          <SelectTd>{stars && <StyledStarsIcon />}</SelectTd>
                          <SelectTd>
                            <SelectTableButton
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault()
                                dispatch(resetErrors())
                                setSelectedCustomerId(id)
                                if (isTableDevice()) {
                                  emitEvent('autologin', {
                                    customerId: id,
                                  })
                                }
                              }}
                            >
                              {t('GenericWords.choose')}
                            </SelectTableButton>
                          </SelectTd>
                        </SelectTr>
                      ))}
                  </SelectTableBody>
                </SelectTable>
              </CustomerList>

              <SearchBox>
                <SearchCustomersTitle>{t('FooterNav.item_search')}</SearchCustomersTitle>
                <InputBox>
                  <AlphaNumericKeyboardInput
                    isFirstLetterUppercase={false}
                    uppercaseAfterSpace={false}
                    inputClassName="ant-input"
                    onChange={setSearchQuery}
                    className="search-customer-field"
                    value={searchQuery}
                    placeholder={t('FooterNav.item_search')}
                  />
                  <SearchIcon type="search" />
                </InputBox>
              </SearchBox>
            </CustomersSearchWrapper>
          </>
        )}
      </Content>
    </Wrapper>
  )
}

export default PageSocketManager(SelectCustomerPage)
