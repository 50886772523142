import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import styled from 'styled-components'

import EmptyUser from '../../components/EmptyUser'
import { Header } from '../../components/Header'
import Loading from '../../components/Loading'
import useFetchInitialData from '../../hooks/useFetchInitialData'
import { delCustomerId, delEventId, setEnvironment, setEventId } from '../../store/app/actions'
import { eventIdSelector, eventsSelector } from '../../store/app/selectors'
import { usernameSelector } from '../../store/auth/selectors'
import {
  Content,
  EventsList,
  HeadLeft,
  HeadTitle,
  LogoutButton,
  SelectTable,
  SelectTableBody,
  SelectTableButton,
  SelectTableHead,
  SelectTd,
  SelectTh,
  SelectTr,
  TableHead,
  Wrapper,
} from '../../style/SelectEventsAndCustomerComponents'
import { palette } from '../../style/theme'

const CardTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: ${palette.biscay};
  text-transform: uppercase;
`

const SelectEventsPage: React.FC = (): JSX.Element => {
  useFetchInitialData()

  const events = useSelector(eventsSelector)
  const username = useSelector(usernameSelector)
  const selectedEventId = useSelector(eventIdSelector)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const eventsLoaded = !!events

  useEffect(() => {
    dispatch(setEnvironment())
    dispatch(delCustomerId())
    dispatch(delEventId())
  }, [dispatch])

  return selectedEventId ? (
    <Redirect to="/customers" />
  ) : (
    <Wrapper>
      <Content>
        {!eventsLoaded && <Loading />}
        {eventsLoaded && !events?.length && <EmptyUser username={username} />}
        {eventsLoaded && !!events?.length && (
          <>
            <Header hideButtons={true} />
            <TableHead>
              <HeadLeft>{username}</HeadLeft>
              <HeadTitle>{t('CustomersList.eventsList')}</HeadTitle>
              <LogoutButton />
            </TableHead>
            <CardTitle />

            <EventsList>
              <SelectTable>
                <SelectTableHead>
                  <SelectTr>
                    <SelectTh>{t('CustomersList.code')}</SelectTh>
                    <SelectTh>{t('CustomersList.location')}</SelectTh>
                    <SelectTh>{t('CustomersList.description')}</SelectTh>
                    <SelectTh />
                  </SelectTr>
                </SelectTableHead>
                <SelectTableBody>
                  {events
                    ?.slice()
                    .sort((a, b) => (a.description > b.description ? 1 : -1))
                    .map(({ id, location, description, newbackoffice }) => (
                      <SelectTr key={id}>
                        <SelectTd>{id}</SelectTd>
                        <SelectTd>{location}</SelectTd>
                        <SelectTd>
                          {description}{' '}
                          {newbackoffice ? (
                            <strong>{t('CustomersList.NewBackOffice')}</strong>
                          ) : null}
                        </SelectTd>
                        <SelectTd>
                          <SelectTableButton
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault()
                              dispatch(setEventId(id))
                            }}
                          >
                            {t('GenericWords.choose')}
                          </SelectTableButton>
                        </SelectTd>
                      </SelectTr>
                    ))}
                </SelectTableBody>
              </SelectTable>
            </EventsList>
          </>
        )}
      </Content>
    </Wrapper>
  )
}

export default SelectEventsPage
