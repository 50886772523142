import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { getFluidSizeWithFullFormula } from '../../style/theme'
import { AfaCartProduct, AfaSize, DropType, UpdateAfaCartProductPayload } from '../../model/afa'
import {
  getCartRowAvailableQuantity,
  getUpcAvailabilityByDelivery,
  sortAfaSizes,
} from '../../libs/afa'
import { SizeInput } from './SizeInput'
import { useParams } from 'react-router'
import { useAfaSelectedColor } from '../../hooks/useAfaSelectedColor'
import { useGetAfaCartQuery, useGetSelectedDelivery } from '../../services/afaCart'
import { useSearchParams } from '../../hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { afaCartProductDetailsSelector } from '../../store/afaCart/selectors'
import {
  afaCartAdjustCartProductsSelector,
  afaCartAdjustIsManageQuantitiesSelector,
  afaCartAdjustIsOpenSelector,
} from '../../store/afaCartAdjust/selectors'
import { DdMmYyyyDateString, Upc } from '../../model/model'
import {
  Debug,
  SizeAvailability,
  SizeAvailabilityContainer,
  SizeContainer,
  SizeInputContainer,
  SizeLabel,
  SizeRowContainer,
  SizeRowContent,
  SizeRowTitle,
  Sizes,
  SizeWrapper,
} from './DropRowsStyles'
import classnames from 'classnames'
import { useGetCartAvailabilityQuery } from '../../services/afaProduct'

const Spinner = styled.div`
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    animation: rotate 1.5s linear infinite;
  }
`

const AvailabilityAlert = styled.div`
  font-size: ${getFluidSizeWithFullFormula('px', 10, 20, 1366, 3840)};
  text-align: center;
  height: 1em;
  grid-column-start: 2;
`

type SizeRowProps = {
  title?: string
  availability?: {
    availableQuantity: number
    upc: Upc
    afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
  }[]
  dropType: DropType
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  customDeliveryDate?: DdMmYyyyDateString
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  disabled: boolean
  upcs?: string[]
  isLatePrebook?: boolean
  afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
}

type SizeProps = {
  size: AfaSize
  availability?: number
  cartProducts: AfaCartProduct[]
  dropType: DropType
  availabilityDate: DdMmYyyyDateString
  minDeliveryDate: DdMmYyyyDateString
  customDeliveryDate?: DdMmYyyyDateString
  doorId: string
  updateAfaCart: (products: UpdateAfaCartProductPayload[]) => void
  disabled: boolean
  upcs?: string[]
  isManageQuantities: boolean
  isLatePrebook?: boolean
  afaCatalogId?: 'A' | 'L' | 'P' //P: Prebook; L: Late Prebook; A: At Once
}

const Size: React.FC<SizeProps> = ({
  size,
  availability,
  cartProducts,
  dropType,
  availabilityDate,
  minDeliveryDate,
  customDeliveryDate,
  doorId,
  updateAfaCart,
  disabled,
  upcs,
  isManageQuantities,
  isLatePrebook,
  afaCatalogId,
}) => {
  const { t } = useTranslation()

  const cartQuery = useGetAfaCartQuery()
  const cartProductsQuery = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const productQuantitiesInCart = useMemo(() => {
    const cartProductsBase = isManageQuantities ? cartProductsQuery : cartProducts
    return cartProductsBase
      .filter(
        cartProduct =>
          cartProduct.upc === size.upc && cartProduct.availabilityDate === availabilityDate,
      )
      .reduce((result, cartProduct) => result + cartProduct.unconfirmedQuantity, 0)
  }, [availabilityDate, cartProducts, cartProductsQuery, isManageQuantities, size.upc])

  const [updatedAvailability, setUpdatedAvailability] = useState(
    availability !== undefined ? availability - productQuantitiesInCart : undefined,
  )

  useEffect(() => {
    setUpdatedAvailability(
      availability !== undefined ? availability - productQuantitiesInCart : undefined,
    )
  }, [availability, productQuantitiesInCart])

  const showAvailabilityAlert = updatedAvailability !== undefined && updatedAvailability < 0

  const ENABLE_DEBUG = false //show item's upc

  return (
    <SizeWrapper>
      <SizeContainer>
        <SizeLabel
          className={classnames({
            disabled:
              (updatedAvailability === undefined && dropType !== 'DROP') || showAvailabilityAlert,
          })}
        >
          {size.size !== '' ? size.size : 'N/A'}
        </SizeLabel>
        <SizeInputContainer>
          <SizeInput
            size={size}
            cartProducts={cartProducts}
            doorId={doorId}
            status="1"
            availability={availability}
            updatedAvailability={updatedAvailability}
            setUpdatedAvailability={setUpdatedAvailability}
            dropType={dropType}
            availabilityDate={availabilityDate}
            minDeliveryDate={minDeliveryDate}
            customDeliveryDate={customDeliveryDate}
            updateAfaCart={updateAfaCart}
            disabled={disabled || (upcs && !upcs.includes(size.upc))}
            afaCatalogId={afaCatalogId}
          />
          {disabled && (
            <Spinner>
              <div>{'҉'}</div>
            </Spinner>
          )}
          <SizeAvailabilityContainer
            className={classnames({
              sizeAvailabilityAlert: showAvailabilityAlert,
            })}
          >
            <SizeAvailability>
              {dropType === 'DROP' && !isLatePrebook ? '999+' : updatedAvailability}
            </SizeAvailability>
          </SizeAvailabilityContainer>
        </SizeInputContainer>
        <AvailabilityAlert>
          {showAvailabilityAlert && (
            <span>
              {t('Afa.Cart.totalAvailable')}: {availability}
            </span>
          )}
        </AvailabilityAlert>
        {ENABLE_DEBUG && (
          <Debug onClick={() => navigator.clipboard.writeText(size.upc)}>{size.upc}</Debug>
        )}
      </SizeContainer>
    </SizeWrapper>
  )
}

const SizeRow: React.FC<SizeRowProps> = ({
  title,
  children,
  availability,
  dropType,
  availabilityDate,
  minDeliveryDate,
  customDeliveryDate,
  updateAfaCart,
  disabled,
  upcs,
  isLatePrebook,
  afaCatalogId,
}) => {
  const { modelCode } = useParams<{ modelCode: string }>()

  const selectedDelivery = useGetSelectedDelivery()
  const isAdjustOpen = useSelector(afaCartAdjustIsOpenSelector)
  const adjustCartProducts = useSelector(afaCartAdjustCartProductsSelector)
  const manageQuantitiesMode = useSelector(afaCartAdjustIsManageQuantitiesSelector)
  const isManageQuantities = manageQuantitiesMode === 'manage-quantities'

  const [searchParams] = useSearchParams()
  const selectedDoorId = searchParams.get('doorId') || ''
  const selectedModelCode = searchParams.get('model') || ''
  const selectedColorCode = searchParams.get('color') || ''
  const { selectedColor } = useAfaSelectedColor(modelCode || selectedModelCode)

  const productDetails = useSelector(afaCartProductDetailsSelector(selectedModelCode))

  const productDetailsColor = Object.values(productDetails?.mocos || {}).find(
    ({ colorCode }) => colorCode === selectedColorCode,
  )

  const cartQuery = useGetAfaCartQuery()
  const cartProductsQuery = useMemo(() => {
    return cartQuery.data?.items || []
  }, [cartQuery.data])

  const cartProducts = useMemo(() => {
    return isAdjustOpen ? adjustCartProducts : cartProductsQuery
  }, [isAdjustOpen, adjustCartProducts, cartProductsQuery])

  const cartProductsOfColor = useMemo(() => {
    return cartProducts.filter(
      ({ colorCode }) => colorCode === selectedColor?.colorCode || productDetailsColor?.colorCode,
    )
  }, [cartProducts, productDetailsColor?.colorCode, selectedColor?.colorCode])

  const showRowInManageQuantities = cartProductsOfColor.some(cartProduct => {
    return (
      cartProduct.deliveryDate === selectedDelivery?.deliveryDate &&
      cartProduct.availabilityDate === availabilityDate &&
      cartProduct.deliveryDate === (customDeliveryDate || minDeliveryDate)
    )
  })

  const availabilityQuery = useGetCartAvailabilityQuery()
  const availabilityByUpc = useMemo(
    () =>
      cartProductsQuery.map(cartProduct => {
        const availableQuantity =
          cartProduct && getCartRowAvailableQuantity(cartProduct, availabilityQuery.data)
        return {
          upc: cartProduct.upc,
          availableQuantity,
          availabilityDate: cartProduct.availabilityDate,
        }
      }),
    [availabilityQuery.data, cartProductsQuery],
  )

  const upcsAvailabilityByDelivery = useMemo(() => {
    return selectedDelivery?.deliveryDate
      ? cartProducts.map(({ availabilityDate, upc }) => {
          const availabilitySize = availabilityByUpc.find(
            availability =>
              availability.upc === upc && availability.availabilityDate === availabilityDate,
          )?.availableQuantity

          return {
            upc,
            availability: isManageQuantities
              ? availabilitySize
              : getUpcAvailabilityByDelivery({
                  cartProducts: cartProductsQuery,
                  deliveryDate: selectedDelivery.deliveryDate,
                  availabilityDate: availabilityDate,
                  availabilityByUpc,
                  upc,
                }),
          }
        })
      : []
  }, [
    availabilityByUpc,
    cartProducts,
    cartProductsQuery,
    isManageQuantities,
    selectedDelivery?.deliveryDate,
  ])

  return !isManageQuantities || showRowInManageQuantities ? (
    <SizeRowContainer>
      {title && <SizeRowTitle>{title}</SizeRowTitle>}
      <SizeRowContent>
        {children}
        <Sizes>
          {Object.values(selectedColor?.sizes || productDetailsColor?.sizes || {})
            .sort((a, b) => sortAfaSizes(a.size, b.size))
            .map(size => {
              const upcAvailabilityByDelivery = upcsAvailabilityByDelivery?.find(
                ({ upc }) => upc === size.upc,
              )?.availability

              const sizeAvailability = availability?.find(({ upc }) => upc === size.upc)
                ?.availableQuantity

              // if sizeAvailability is defined but availableQuantity is undefined, it's a Drop
              // and we don't need to count availability
              const sizeAvailabilityWithFallback =
                availability && sizeAvailability === undefined
                  ? undefined
                  : upcAvailabilityByDelivery || sizeAvailability

              return (
                <Size
                  isLatePrebook={isLatePrebook}
                  upcs={upcs}
                  key={size.upc}
                  size={size}
                  availability={
                    isManageQuantities ? sizeAvailabilityWithFallback : sizeAvailability
                  }
                  isManageQuantities={isManageQuantities}
                  cartProducts={cartProductsOfColor}
                  dropType={dropType}
                  availabilityDate={availabilityDate}
                  minDeliveryDate={minDeliveryDate}
                  customDeliveryDate={customDeliveryDate}
                  doorId={selectedDoorId}
                  updateAfaCart={updateAfaCart}
                  disabled={disabled}
                  afaCatalogId={
                    availability?.find(({ upc }) => upc === size.upc)?.afaCatalogId || afaCatalogId
                  }
                />
              )
            })}
        </Sizes>
      </SizeRowContent>
    </SizeRowContainer>
  ) : (
    <></>
  )
}

export default SizeRow
