import { getAllCouvettes } from '../../api/restApi'
import i18n from '../../i18n'
import { getQueryParamsFromFilters } from '../../libs/couvettes'
import { handleErrors } from '../../libs/handleError'
import log from '../../libs/log'
import { loadMissingProductDetails } from '../../libs/productsV2'
import { AppThunk } from '../../model/model'
import { Product, QueryParams } from '../../model/product'
import { getAdvContents } from '../actions'
import { dataLoadedFail } from '../app/actions'
import {
  customerIdSelector,
  eventIdSelector,
  eventsSelector,
  isKidCategoryModeEnabledSelector,
  languageSelector,
  selectedEventSelector,
} from '../app/selectors'
import { tokenSelector } from '../auth/selectors'
import { subbrandToMainBrandMapSelector } from '../brands/selectors'
import { customerTypeSelector } from '../customer/selectors'
import { updateFiltersCounters } from '../filters/actions'
import { cartModeSelector, isPlpStarsModeSelector } from '../stars/selectors'
import { couvettesSelector } from './selectors'
import { slice } from './slice'
import { errorNotification } from '../../components/Notification/notifications'

export const toggleGridView = (): AppThunk => {
  return (dispatch, getState) => {
    const isGridView = getState().couvettes.isGridView
    return dispatch(slice.actions.setIsGridView(!isGridView))
  }
}

export const getCouvettes = (options?: {
  newSorting?: string
  appendAdvContent?: boolean
}): AppThunk => {
  return (dispatch, getState) => {
    const newSorting = options?.newSorting
    const appendAdvContent = options?.appendAdvContent

    const state = getState()

    const isPlpStarsMode = isPlpStarsModeSelector(state)
    const isKidCategoryModeEnabled = isKidCategoryModeEnabledSelector(state)
    const event = selectedEventSelector(state)
    const couvettes = couvettesSelector(state)
    const lang = languageSelector(state)
    const token = tokenSelector(state) as string
    const customerType = customerTypeSelector(state)
    const catalog = cartModeSelector(state) === 'stars' ? 'STARS' : 'WHOLESALE'

    const params = getQueryParamsFromFilters(
      isKidCategoryModeEnabled,
      state.filters.plp,
      state.app.customerId,
      state.brands,
      state.couvettes.pagination.start,
      event,
    )

    if (isPlpStarsMode) {
      // stars assortment is loaded via RTK Query hook
      return Promise.resolve()
    }

    const sortFallback = couvettes.pagination.sort
    const sort = newSorting || sortFallback

    const queryParams: QueryParams = {
      ...params,
      customerId: customerIdSelector(state),
      eventId: eventIdSelector(state),
      showFacet: params.showFacet ? params.showFacet : !params.start,
      start: couvettes.pagination.start ? couvettes.pagination.start : undefined,
      sort,
      customerType,
      catalog: params.modelCodes?.length ? 'WHOLESALE' : catalog,
    }

    dispatch(slice.actions.loadingCouvettes())

    return getAllCouvettes(token, lang, queryParams)
      .then(handleErrors)
      .then(data => {
        const pagination: {
          start?: string
          sort: string
          isFirstRequest: boolean
          count: number
        } = {
          start: data?.start,
          sort,
          isFirstRequest: couvettes && couvettes.pagination && couvettes.pagination.isFirstRequest,
          count: data?.count,
        }

        const state = getState()
        const eventId = eventIdSelector(state)
        const events = eventsSelector(state)
        const subbrandToMainBrandMap = subbrandToMainBrandMapSelector(state)
        const models: Product[] | null = data && data.items && data.items.length ? data.items : null
        if (events && events.length > 0 && eventId) {
          dispatch(
            getAdvContents(
              eventId,
              events,
              [...new Set(models?.map(({ brandCode }) => brandCode) || [])].map(
                brandCode => subbrandToMainBrandMap[brandCode],
              ),
              appendAdvContent,
            ),
          )
        }

        if (data && data.filters && data.filters.length) {
          dispatch(updateFiltersCounters({ facets: data.filters, pageType: 'plp' }))
        }

        return data && dispatch(slice.actions.loadCouvettesSuccess({ couvettes: data, pagination }))
      })
      .catch(err => {
        log.info(err)
        return dispatch(dataLoadedFail(err))
      })
  }
}

export const reloadCouvettes = (): AppThunk => {
  return dispatch => {
    dispatch(slice.actions.removeCouvettes())
    dispatch(getCouvettes())
  }
}

const isProductsChunks = (products: false | void | Product[]): products is Product[] =>
  Array.isArray(products)

export const getDetailFromModelCodes = (
  missingModelCodes: string[],
  allowPartial: boolean = false,
): AppThunk => (dispatch, getState) => {
  let state
  if (!missingModelCodes || !missingModelCodes.length) {
    return
  }
  try {
    state = getState()
  } catch (e) {
    return
  }
  const token = tokenSelector(state)
  const lang = languageSelector(state)
  const customerId = customerIdSelector(state)
  const eventId = eventIdSelector(state)
  const customerType = customerTypeSelector(state)

  if (missingModelCodes.length) {
    loadMissingProductDetails(
      missingModelCodes,
      { token, lang, customerId, eventId },
      false,
      'couvettes',
      customerType,
    )
      .then((productsChunks: any) => {
        const products = productsChunks.flatMap((chunk: Product) => chunk)
        dispatch(slice.actions.loadDetailsSuccess(products))
      })
      .catch(e => {
        if (allowPartial) {
          Promise.allSettled(
            missingModelCodes.map(moco => {
              return loadMissingProductDetails(
                [moco],
                { token, lang, customerId, eventId },
                false,
                'couvettes',
                customerType,
              )
            }),
          ).then(resolvedPromises => {
            const foundProducts = resolvedPromises
              .filter(promise => promise.status === 'fulfilled')
              .flatMap(promise => (promise as PromiseFulfilledResult<Product[][]>).value)
              .filter(isProductsChunks)
              .flatMap(chunk => chunk)

            if (foundProducts.length) {
              dispatch(slice.actions.loadDetailsSuccess(foundProducts))
            }

            const notFoundValues = missingModelCodes.filter(
              modelCode => !foundProducts.some(product => product.modelCode === modelCode),
            )
            if (notFoundValues.length) {
              errorNotification({
                message: i18n.t('Errors.loadingProductDetails', {
                  modelCodes: notFoundValues.join(', '),
                }),
              })
            }
          })
        } else {
          throw e
        }
      })
  }
}

export const sortCouvetteBy = (newSorting: string): AppThunk => {
  return dispatch => {
    dispatch(slice.actions.removeCouvettes())
    return dispatch(getCouvettes({ newSorting }))
  }
}

export const loadCouvettesSuccess = slice.actions.loadCouvettesSuccess
export const loadDetailsSuccess = slice.actions.loadDetailsSuccess
export const loadingCouvettes = slice.actions.loadingCouvettes
export const setIsGridView = slice.actions.setIsGridView
export const setCouvettes = slice.actions.setCouvettes
export const setSorting = slice.actions.setSorting

export default {
  ...slice.actions,
  toggleGridView,
  getCouvettes,
  reloadCouvettes,
  getDetailFromModelCodes,
  sortCouvetteBy,
}
