import queryString from 'qs'

import { CartAPI, RecommendationAPI } from '../../../api/restApi'
import { requestCalculateAnalytics } from '../../../libs/calculate-analytics'
import { countCartProductsByDoors } from '../../../libs/cart'
import log from '../../../libs/log'
import { loadMissingProductDetails } from '../../../libs/productsV2'
import { isPageMatches } from '../../../libs/url'
import { CartProduct } from '../../../model/cart'
import { AppThunk } from '../../../model/model'
import { Product } from '../../../model/product'
import { dataLoadedFail } from '../../app/actions'
import { customerIdSelector, eventIdSelector, languageSelector } from '../../app/selectors'
import { tokenSelector } from '../../auth/selectors'
import { brandsSelector, subbrandToMainBrandMapSelector } from '../../brands/selectors'
import { customerTypeSelector } from '../../customer/selectors'
import { setDislikeList } from '../../dislike/actions'
import { setSlowCartNotificationAction } from '../../notifications/actions'
import { loadWishlistSuccess } from '../../wishlist/actions'
import { recommendedBrandLoadedSelector } from '../selectors'
import {
  cartProductsDetailsSelector,
  cartProductsSelector,
  cartTimestampSelector,
  filteredCartProductsSelector,
} from '../selectors/cart'
import { slice } from '../slice'
import { subscribeToCartMessagesAction } from './subscriptions'
import { getCartCached, updateCartAction } from './updateCart'
import { subBrandToCategoryId } from '../../../libs/couvettes'

export { updateCartAction, subscribeToCartMessagesAction, getCartCached }

/* METHODS */

export const setCart = (
  cart: {
    products: CartProduct[]
    editable?: boolean
    timestamp?: number
    loaded?: boolean
    loading?: boolean
    updating?: boolean
    customerId?: string | null
    eventId?: string | null
    status?: string | null
    backOfficeNote?: string
  },
  reloadedCounter?: number,
): AppThunk => {
  return dispatch => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(saveUpdatedCart(cart, reloadedCounter))
    dispatch(slice.actions.setPendingApiRequestAsFullfilled())
  }
}

export const getCart = (
  customerId?: string | null,
  eventId?: string | null,
  forceFullCart = false,
  reloadedCounter = 0,
): AppThunk => {
  return (dispatch, getState) => {
    dispatch(slice.actions.loadingCartSection())
    const state = getState()

    customerId = customerId || customerIdSelector(state)
    eventId = eventId || eventIdSelector(state)
    if (!customerId || !eventId) {
      return Promise.reject()
    }

    const timestamp = cartTimestampSelector(state)
    const token = tokenSelector(state)
    const lang = languageSelector(state)

    if (!token) {
      return
    }

    const cartApi = new CartAPI(token, lang)
    return cartApi
      .getFromOrigin(customerId, eventId, timestamp, forceFullCart)
      .then(response => {
        const { cart, wishlist, dislikeList } = response

        dispatch(setCart(cart, reloadedCounter))
        dispatch(loadWishlistSuccess(wishlist))
        dispatch(setDislikeList(dislikeList))
      })
      .catch(error => {
        return dispatch(dataLoadedFail(error))
      })
  }
}

export const updateAnalytics = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const cartProductsDetails = cartProductsDetailsSelector(state)
  const cartProductsFiltered = filteredCartProductsSelector(state)
  const cartProductsFilteredByValidBrands = cartProductsSelector(state)

  const statistics = {
    itemsByDoors: countCartProductsByDoors(cartProductsFilteredByValidBrands),
  }
  if (isPageMatches('cart')) {
    requestCalculateAnalytics(cartProductsFiltered, state.brands.items, cartProductsDetails).then(
      analytics => {
        dispatch(slice.actions.setAnalytics({ analytics: analytics, statistics: statistics }))
      },
    )
  } else {
    dispatch(slice.actions.setAnalytics({ analytics: undefined, statistics: statistics }))
  }
}

export const loadMissingCartProductDetails = (
  missingModelCodes: string[],
  reloadedCounter = 0,
): AppThunk => (dispatch, getState) => {
  if (!missingModelCodes.length) {
    return dispatch(updateAnalytics())
  }

  dispatch(slice.actions.loadingCartSection('cartProductDetails'))

  const state = getState()

  const token = tokenSelector(state)
  const lang = languageSelector(state)
  const customerId = customerIdSelector(state)
  const eventId = eventIdSelector(state)
  const customerType = customerTypeSelector(state)

  if (missingModelCodes.length) {
    loadMissingProductDetails(
      missingModelCodes,
      { token, lang, customerId, eventId },
      false,
      'cart',
      customerType,
    )
      .then((productsChunks: any = []) => {
        const products = productsChunks.flatMap((chunk: Product) => chunk)
        return dispatch(slice.actions.saveCartProductsDetails(products))
      })
      .then(() => {
        return dispatch(updateAnalytics())
      })
      .catch(error => {
        if (reloadedCounter === 0) {
          dispatch(setSlowCartNotificationAction(true))
          return dispatch(getCart(customerId, eventId, true, reloadedCounter + 1))
        } else {
          return dispatch(dataLoadedFail(error))
        }
      })
  }
}

const setActiveBrandInUrl = (brandActive: string | null) => {
  const parsedSearch = queryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  parsedSearch['activeBrand'] = brandActive || undefined
  const urlParams = queryString.stringify(parsedSearch)
  if (urlParams) {
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
  }
}

export const getCartRecommendedAction = (activeBrand: string, category: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()
    const token = tokenSelector(state)
    const customerId = customerIdSelector(state)
    const lang = languageSelector(state)
    const eventId = eventIdSelector(state)
    const brands = brandsSelector(state)
    const subbrandToMainBrandMap = subbrandToMainBrandMapSelector(state)
    const customerType = customerTypeSelector(state)
    const recommendedBrandsLoaded = recommendedBrandLoadedSelector(state)

    if (
      !customerId ||
      !eventId ||
      !token ||
      recommendedBrandsLoaded.includes(`${activeBrand}-${category}`) ||
      recommendedBrandsLoaded.includes(`${subbrandToMainBrandMap[activeBrand]}-${category}`)
    ) {
      return
    }

    const brand = brands.find(brand => brand.code === subbrandToMainBrandMap[activeBrand])
    const brandsQueryParam = brand
      ? brand.subBrands
          .filter(subBrand => subBrandToCategoryId(subBrand) === category)
          .map(({ code }) => code) || activeBrand
      : [activeBrand]

    const cartSection = 'recommendation'
    dispatch(slice.actions.loadingCartSection(cartSection))

    const api = new RecommendationAPI(token, lang)
    api
      .get({
        customerId,
        eventId,
        brand: brandsQueryParam.join(','),
        customerType,
        category,
        brandParent: activeBrand,
      })
      .then(recommendations => {
        if (!recommendations) return
        dispatch(
          slice.actions.loadRecommended({
            recommendation: recommendations,
            brand: activeBrand,
            category,
          }),
        )
      })
      .catch(err => {
        log.error(err, 'getRecommendation')
        return dispatch(dataLoadedFail(err))
      })
  }
}

export const setActiveBrandAction = (activeBrand: string | null, syncChange = true): AppThunk => {
  return dispatch => {
    setActiveBrandInUrl(activeBrand)
    dispatch(
      !syncChange
        ? slice.actions.setActiveBrandCodeActionWithoutSync(activeBrand)
        : slice.actions.setActiveBrandCodeAction(activeBrand),
    )
  }
}

/**
 * @param cartData
 * @param reloadedCounter
 */
export const saveUpdatedCart = (
  cartData: { products: CartProduct[]; backOfficeNote?: string },
  reloadedCounter?: number,
): AppThunk => {
  return function saveUpdatedCartInner(dispatch, getState) {
    dispatch(slice.actions.loadCart(cartData))
    const state = getState()
    const cartProductsDetails = cartProductsDetailsSelector(state)
    const cartProducts = cartProductsSelector(state)

    const missingModelCodes = cartProducts
      .filter(({ modelCode, colorCode, upc, outOfAssortment }) => {
        return (
          !outOfAssortment &&
          (!cartProductsDetails[modelCode] ||
            !cartProductsDetails[modelCode].mocos[colorCode] ||
            !cartProductsDetails[modelCode].mocos[colorCode].sizes[upc])
        )
      })
      .map(({ modelCode }) => modelCode)

    dispatch(
      missingModelCodes.length
        ? loadMissingCartProductDetails(missingModelCodes, reloadedCounter)
        : (slice.actions.setCartProductsDetailsLoaded() as any),
    )
    dispatch(updateAnalytics())
  }
}

export const setCartProductsDetailsLoaded = slice.actions.setCartProductsDetailsLoaded
export const setLastAddedProductRecap = slice.actions.setLastAddedProductRecap
export const setCartUpdating = slice.actions.setCartUpdating
export const resetRecommended = slice.actions.resetRecommended
export const loadRecommended = slice.actions.loadRecommended
export const toggleCategoryAssortment = slice.actions.toggleCategoryAssortment
export const changeAssortmentViewType = slice.actions.changeAssortmentViewType
export const switchCartType = slice.actions.switchCartType
export const resetCartView = slice.actions.resetCartView
export const saveCartProductsDetails = slice.actions.saveCartProductsDetails
export const setAnalytics = slice.actions.setAnalytics
export const setCartViewType = slice.actions.setCartViewType
export const setMyShopActiveTab = slice.actions.setMyShopActiveTab
export const toggleMyShopAssortmentCollapse = slice.actions.toggleMyShopAssortmentCollapse
export const setWaitingApiRequestAsPending = slice.actions.setWaitingApiRequestAsPending
export const resetPendingApiRequestAsWaiting = slice.actions.resetPendingApiRequestAsWaiting
export const loadCart = slice.actions.loadCart
export const addToApiRequestBuffer = slice.actions.addToApiRequestBuffer
export const setActiveBrandCodeAction = slice.actions.setActiveBrandCodeAction
export const setPendingApiRequestAsFullfilled = slice.actions.setPendingApiRequestAsFullfilled
export const setMyShopLastAddedProductUpcs = slice.actions.setMyShopLastAddedProductUpcs

const cartActions = {
  ...slice.actions,
  setCart,
  getCart,
  updateAnalytics,
  loadMissingCartProductDetails,
  getCartRecommendedAction,
  setActiveBrandAction,
  saveUpdatedCart,
}

export default cartActions
