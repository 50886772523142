import { Col } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import app_config from '../../../config/app/config'
import { getDeviceAbsolutePath, isExtraIpadDevice } from '../../../libs/url'
import { Brand } from '../../../model/brand'
import { footerHeight, palette } from '../../../style/theme'

export const SingleBrandTitle = styled.div`
  font-size: 3vh;
  text-transform: uppercase;
  font-family: 'Avenir-Roman', 'Avenir-Roman-Custom', sans-serif;
  letter-spacing: 1.14px;
  line-height: 3vh;
  color: ${palette.white};
  font-weight: 700;
  position: absolute;
  bottom: 0vh;
  margin: 1.5vw;
  padding: 1vw;
  border-radius: 1.7vmin;
  background: ${palette.tundora};
  border: ${palette.tundora};
`
export const SingleBrandImg = styled.img<{
  viewportHeight: number
}>`
  width: auto;
  max-width: 100%;
  height: auto;
  margin: auto;
  max-height: calc(
    (${props => props.viewportHeight}px - ${footerHeight}) * 0.43
  ); //we cannot use 100% because of the relative positioned parent we need for the text, so we must compute this (43% is the parent row height)
  display: block;
  opacity: 1;
`

export const SingleBrandImgFallback = styled.img<{
  viewportHeight: number
}>`
  width: auto;
  max-width: 100%;
  margin: auto;
  max-height: calc(
    (${props => props.viewportHeight}px - ${footerHeight}) * 0.43
  ); //we cannot use 100% because of the relative positioned parent we need for the text, so we must compute this (43% is the parent row height)
  height: auto;
  display: block;
`

const SingleBrandImgFallbackWrapper = styled.div`
  min-width: 30vw;
  max-width: 100%;
`

export const SingleBrandBrand = styled.div<{
  small: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.small ? '30' : '43')}vw;
  height: 100%;
`

export const StyledCol = styled(Col)`
  height: 100%;
`

export const PlaceholderCol = styled(StyledCol)`
  width: 30vw;
`

const BandImageAndDescription = styled.div`
  max-width: 100%;
  height: auto;
  max-height: 100%;
  position: relative;
`

const getImage = (type: string, brand: Brand): string => {
  if (type === 'optical') {
    return brand.imgmenu_optical?.url || ''
  } else if (type === 'sun_kids') {
    return brand.imgmenu_sun_kids?.url || ''
  } else if (type === 'optical_kids') {
    return brand.imgmenu_optical_kids?.url || ''
  } else if (type === 'sun') {
    return brand.imgmenu_sun?.url || ''
  } else if (type === 'electronics') {
    return brand.imgmenu_electronic?.url || ''
  } else {
    return ''
  }
}

const CategoryImage: React.FC<Props> = ({
  title,
  type,
  brand,
  pageSlug,
  handleClick,
  small = false,
}) => {
  const imageUrl = getImage(type, brand)
  const [isError, setIsError] = useState(false)

  if (!imageUrl) return null

  return (
    <StyledCol>
      <Link
        to={`${getDeviceAbsolutePath()}/single-brand/${brand.slug}/${pageSlug}`}
        onClick={handleClick}
      >
        <SingleBrandBrand small={small}>
          <BandImageAndDescription>
            <SingleBrandImg
              viewportHeight={window.innerHeight}
              hidden={isError}
              alt="cover"
              src={imageUrl + (isExtraIpadDevice() ? '?resize=588px:328px' : '')}
              onError={() => setIsError(true)}
            />
            <SingleBrandImgFallbackWrapper>
              <SingleBrandImgFallback
                viewportHeight={window.innerHeight}
                hidden={!isError}
                alt="cover-fallback"
                src={`${app_config.publicUrl}/assets/images/shape/brand_${type}.svg`}
              />
            </SingleBrandImgFallbackWrapper>
            {title && <SingleBrandTitle>{title}</SingleBrandTitle>}
          </BandImageAndDescription>
        </SingleBrandBrand>
      </Link>
    </StyledCol>
  )
}

type Props = {
  title?: string
  type: string
  pageSlug: string
  brand: Brand
  handleClick: () => void
  small?: boolean
}

export default CategoryImage
