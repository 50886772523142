import { useMemo } from 'react'
import { useGetAfaCartQuery } from '../services/afaCart'
import { useGetCartAvailabilityQuery } from '../services/afaProduct'
import useAfaGetOutOfAssortmentCartProducts from './useAfaGetOutOfAssortmentCartProducts'
import { AfaCartProduct } from '../model/afa'

export const useGetExpiredCartProducts = (options?: {
  productData?: {
    modelCode: string
    colorCode: string
  }
  cartProducts?: AfaCartProduct[]
}) => {
  const modelCode = options?.productData?.modelCode
  const colorCode = options?.productData?.colorCode
  const cartProducts = options?.cartProducts

  const cartQuery = useGetAfaCartQuery()
  const cartProductsToCheck = cartProducts !== undefined ? cartProducts : cartQuery.data?.items

  const cartDropsQuery = useGetCartAvailabilityQuery(cartProductsToCheck)
  const outOfAssortmentCartProducts = useAfaGetOutOfAssortmentCartProducts()

  const outOfAssortmentKeys = useMemo(() => {
    return outOfAssortmentCartProducts.map(({ key }) => key)
  }, [outOfAssortmentCartProducts])

  const expiredCartProducts = useMemo(() => {
    if (!cartProductsToCheck || !cartDropsQuery.data || cartDropsQuery.isFetching) {
      return []
    }
    const cartDrops = cartDropsQuery.data
    return cartProductsToCheck.filter(cartProduct => {
      const modelMatch = !modelCode || cartProduct.modelCode === modelCode
      const colorMatch = !colorCode || cartProduct.colorCode === colorCode
      const dropMatch = cartDrops[cartProduct.upc]?.find(
        cartDrop =>
          cartProduct.availabilityDate === cartDrop.availabilityDate &&
          cartProduct.dropType === cartDrop.type,
      )

      return (
        !outOfAssortmentKeys.includes(cartProduct.key) && modelMatch && colorMatch && !dropMatch
      )
    })
  }, [
    cartProductsToCheck,
    cartDropsQuery.data,
    cartDropsQuery.isFetching,
    modelCode,
    colorCode,
    outOfAssortmentKeys,
  ])

  const expiredDropsCartProducts = useMemo(() => {
    return expiredCartProducts.filter(({ dropType }) => dropType === 'DROP')
  }, [expiredCartProducts])

  const expiredSeasonsCartProducts = useMemo(() => {
    return expiredCartProducts.filter(({ dropType }) => dropType === 'SEASON')
  }, [expiredCartProducts])

  return { expiredDropsCartProducts, expiredSeasonsCartProducts }
}
