import { AfaFacet } from '../model/afa'
import { useSearchParams } from './useSearchParams'

export const useAfaFilters = (facets: AfaFacet[] | undefined) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedFilters = facets
    ?.concat([
      {
        name: 'hasKeylooks',
        options: [{ name: 'true', results: 1 }],
      },
      {
        name: 'sort',
        options: [
          { name: 'name', results: 1 },
          { name: 'code', results: 1 },
          { name: 'relevance', results: 1 },
          { name: 'newest', results: 1 },
          { name: 'price_desc', results: 1 },
          { name: 'price_asc', results: 1 },
        ],
      },
    ])
    .reduce((result, category) => {
      const selectedOptions = searchParams.get(category.name)?.split(',') || []
      result[category.name] = category.options.reduce((result, option) => {
        result[option.name] = selectedOptions.includes(option.name)
        return result
      }, {} as Record<string, boolean>)
      return result
    }, {} as Record<string, Record<string, boolean>>)

  const setSelectedFilters = (selectedFiltersUpdated: Record<string, Record<string, boolean>>) => {
    Object.entries(selectedFiltersUpdated).forEach(([category, options]) => {
      const categoryValues = Object.entries(options)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, active]) => active)
        .map(([optionName]) => optionName)
        .join(',')

      if (categoryValues) {
        searchParams.set(category, categoryValues)
      } else {
        searchParams.delete(category)
      }
    })
    searchParams.delete('start')
    setSearchParams(searchParams, { replace: true })
  }

  return [selectedFilters, setSelectedFilters] as const
}
