import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useRevertableUpdateCart, useUpdateCart } from '../../hooks/useUpdateCart'
import { getSafeValueAndQuantity, MAXITEMS } from '../../libs/cart'
import log from '../../libs/log'
import { CartProduct, SizeInCart } from '../../model/cart'
import { Size } from '../../model/product'
import { isNewBackOfficeSelector } from '../../store/app/selectors'
import {
  cartEditableSelector,
  cartProductsFilteredByActiveDoorsSelector,
  disableDoorPurchaseControls,
  getPendingNotificationSelector,
} from '../../store/cart/selectors'
import { breakpointsNoUnit, getFluidSizeWithFullFormula, palette } from '../../style/theme'
import MinusIcon from '../icons/MinusIcon'
import PlusIcon from '../icons/PlusIcon'
import { NumberKeyboardInput } from '../KeyboardInput'
import Note from '../Note'

const DoorForm = styled.div`
  width: auto;
  display: flex;
  font-size: ${getFluidSizeWithFullFormula(
    'px',
    12,
    36,
    breakpointsNoUnit.XS,
    breakpointsNoUnit.XL,
  )};

  &.door-form {
    width: 100%;
    position: relative;
  }

  &.door-form--massive {
    .door-form-content {
      background-color: ${palette.tropicalBlue};
    }
    .input {
      border: solid 1px rgba(99, 177, 229, 0.2);
      background-color: rgba(233, 243, 252, 0.65);
      font-weight: 900;
    }
  }

  &:not(.door-form--massive) [disabled] {
    opacity: 0.5;
    border: 1px solid;
  }
`
const formContentInnerHeight = '2.5em'

const FormContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.75fr 2fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5em;
  align-items: center;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid ${palette.gallery};
  border-radius: 0.5em;
  padding: 0.5em 1em;
  box-sizing: content-box;
  height: ${formContentInnerHeight};
  margin-bottom: 0.5em;

  .keyboard-input {
    height: 100%;
    .input {
      border: 1px solid ${palette.bondiBlue};
      align-items: center;
      padding: 0 0.5em;
      border-radius: 0.25em;
      width: 70%;
      height: 100%;
    }
  }
`

const Label = styled.label`
  font-weight: 900;
  white-space: nowrap;
  flex: 1;
`

const Button = styled.button`
  border: 2px solid ${palette.cornflowerBlue};
  border-radius: 50%;
  color: ${palette.congressBlue};
  width: ${formContentInnerHeight};
  height: ${formContentInnerHeight};
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: 900;

  &[disabled] {
    opacity: 0.5;
  }

  svg {
    width: 70%;
    height: 70%;
  }

  &:focus,
  &:hover {
    border-color: ${palette.curiousBlue};
    color: ${palette.congressBlue};
  }
`

const ProcessedItems = styled.div`
  color: ${palette.dustyGray};
  opacity: 0;
  pointer-events: none;

  &.processed {
    opacity: 1;
  }
`

const PlusOneButton = styled(Button)`
  grid-column-start: 5;
  cursor: pointer;
`

const SmallerLabel = styled.div`
  font-size: 0.8em;
`

const getDoorQuantityFromSize = (doorIds: string[], size?: Size, cartProducts?: CartProduct[]) => {
  const quantities = doorIds
    .flatMap(
      door =>
        cartProducts?.filter(({ doorId, upc }) => {
          return doorId === door && size?.upc === upc
        }) || [],
    )
    .reduce(
      (total, product) => {
        total.initialQuantity += product.quantity || 0
        total.confirmedQuantity += product.confirmedQuantity || 0
        return total
      },
      { initialQuantity: 0, confirmedQuantity: 0 },
    )
  return quantities
}

interface Props {
  productSize: Size
  cartItemSize?: SizeInCart
  doorIds: string[]
  isMassiveDoor?: boolean
  showNotes?: boolean
  enabledDoors: string[]
  doorId?: string
  brandCode: string
}

const DoorPurchaseControls: React.FC<Props> = ({
  doorIds,
  cartItemSize,
  isMassiveDoor,
  doorId,
  productSize,
  enabledDoors,
  brandCode,
  showNotes,
}) => {
  const disabled = useSelector(disableDoorPurchaseControls({ productSize, doorIds, doorId }))
  const pendingNotification = useSelector(getPendingNotificationSelector({ productSize }))
  const cartProducts = useSelector(cartProductsFilteredByActiveDoorsSelector)
  const isNewBackOffice = useSelector(isNewBackOfficeSelector)
  const editable = useSelector(cartEditableSelector)

  const { initialQuantity, confirmedQuantity } = getDoorQuantityFromSize(
    doorIds,
    productSize,
    cartProducts,
  )
  const [quantity, setQuantity] = useState<number | ''>(initialQuantity)
  const [inputHasFocus, setInputHasFocus] = useState(false)

  const updateCart = useUpdateCart()
  const revertableUpdateCart = useRevertableUpdateCart()

  const doorsToConsider = isMassiveDoor
    ? pendingNotification?.doors
    : pendingNotification?.doors?.filter((door: string) => door === doorId)

  const newQuantity = initialQuantity + pendingNotification?.qntToAdd * doorsToConsider?.length

  useEffect(() => {
    if (inputHasFocus || disabled) {
      return
    } else {
      setQuantity(newQuantity)
    }
  }, [disabled, inputHasFocus, newQuantity])

  const prevQntRef = useRef<number | undefined>()
  const updatedQnt = cartItemSize ? initialQuantity : 0
  useEffect(() => {
    if (prevQntRef.current && prevQntRef.current !== updatedQnt) {
      setQuantity(updatedQnt)
    }
  }, [updatedQnt])
  prevQntRef.current = updatedQnt

  const onKeyboardChange = (value: string) => {
    let newQuantity: number | '' = parseInt(value, 10)
    if (Number(value) > MAXITEMS) {
      newQuantity = MAXITEMS
    }

    if (isNaN(newQuantity)) {
      newQuantity = ''
    }

    setQuantity(newQuantity)
  }

  const handleUpdateCart = (
    qntToAdd: number | null,
    finalQuantity?: number | null,
    notes?: string,
  ) => {
    const previousNote = cartItemSize?.doors?.find(door => door.doorId === doorId) || {
      notes: undefined,
    }

    const updateRevertableCartPayload = {
      doors: doorIds,
      productSizes: [{ ...productSize, enabledDoors, brandCode }],
      qntToAdd,
      cartProducts,
      finalQuantity,
    }

    const updateCartPayload = {
      ...updateRevertableCartPayload,
      notes: notes !== undefined ? notes : previousNote.notes,
    }

    const codes = cartItemSize ? [`${cartItemSize.modelCode}-${productSize.size}`] : []

    const doorsIdsMessage = doorIds.join(' - ')
    if (notes) {
      updateCart(updateCartPayload)
      log.info(`Update of notes for ${productSize.skuCode} to door ${doorsIdsMessage}`)
    } else {
      isMassiveDoor
        ? revertableUpdateCart(updateRevertableCartPayload, codes)
        : updateCart(updateCartPayload)

      log.info(
        `Update of ${qntToAdd} quantity items for ${productSize.skuCode} to door ${doorsIdsMessage}`,
      )
    }
  }

  const updateQuantity = (value: number) => {
    const { safeValue, safeNewQuantity } = getSafeValueAndQuantity(value, quantity)
    setQuantity(safeNewQuantity)
    handleUpdateCart(safeValue)
  }

  const onHideKeyboard = () => {
    handleUpdateCart(null, quantity !== '' ? quantity : 0)
    setQuantity(quantity || 0)
  }

  const { sizeLabel, size } = productSize
  const label = [sizeLabel, size].filter(el => el).join(' - ')
  const cartDoor = cartItemSize?.doors?.find(door => door.doorId === doorId)

  const doorEnabledOnItem =
    !isMassiveDoor && enabledDoors?.length ? doorId && enabledDoors.includes(doorId) : false

  const disabled2 =
    disabled ||
    (!doorEnabledOnItem && !isMassiveDoor) ||
    (doorIds.length === 0 && !doorId) ||
    !editable

  const hasConfirmedQuantity = confirmedQuantity > 0 && isNewBackOffice

  return (
    <DoorForm
      className={classnames({
        'door-form': true,
        'door-form--massive': isMassiveDoor,
      })}
    >
      {cartItemSize && (
        <Note
          showNotes={showNotes && quantity > 0}
          notes={cartDoor ? cartDoor.notes : ''}
          handleUpdateCart={handleUpdateCart}
        />
      )}
      <FormContent className="door-form-content">
        <Label>{label}</Label>
        <NumberKeyboardInput
          disabled={isMassiveDoor || disabled2}
          value={quantity}
          onChange={onKeyboardChange}
          onHideKeyboard={onHideKeyboard}
          onFocus={() => setInputHasFocus(true)}
          onBlur={() => {
            setInputHasFocus(false)
          }}
        />

        <ProcessedItems className={hasConfirmedQuantity ? 'processed' : ''}>
          + {confirmedQuantity}
        </ProcessedItems>

        {!isMassiveDoor && (
          <Button
            disabled={disabled2}
            onClick={() => updateQuantity(-1)}
            className="door-form-content__button minus-1-btn"
          >
            <MinusIcon />
          </Button>
        )}

        <PlusOneButton
          disabled={disabled2}
          onClick={() => updateQuantity(1)}
          className={
            hasConfirmedQuantity
              ? 'door-form-content__button plus-1-btn processed'
              : 'door-form-content__button plus-1-btn'
          }
        >
          <PlusIcon />
        </PlusOneButton>
        <Button
          disabled={disabled2}
          onClick={() => updateQuantity(5)}
          className="door-form-content__button plus-5-btn"
        >
          <SmallerLabel>+5</SmallerLabel>
        </Button>
        <Button
          disabled={disabled2}
          onClick={() => updateQuantity(10)}
          className="door-form-content__button plus-10-btn"
        >
          <SmallerLabel>+10</SmallerLabel>
        </Button>
      </FormContent>
    </DoorForm>
  )
}

export default DoorPurchaseControls
