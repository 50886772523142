import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Link, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { ButtonHeader } from '../containers/AfaCart/AfaCartHeader'
import { getDeviceAbsolutePath, getUrlParts, isExtraIpadDevice, isPageMatches } from '../libs/url'
import { useGetBrandsQuery } from '../services/afa'
import { useGetFilteredAfaCartQuery } from '../services/afaCart'
import { useGetBrandLogo } from '../services/afaContents'
import { useGetProductQuery } from '../services/afaProduct'
import { showFooterModal } from '../store/actions'
import afaActions from '../store/afa/actions'
import { afaBrandSelectedSelector, footerModalVisibleSelector } from '../store/app/selectors'
import { customerDoorsSelector, customerNameSelector } from '../store/customer/selectors'
import {
  breakpoints,
  breakpointsCross,
  footerHeight,
  footerMinHeight,
  getFluidFontSize,
  getFluidSizeWithFullFormula as gF,
  palette,
  pxToRem,
  spacing,
} from '../style/theme'
import Icon from '../styleguide/Icon'
import AfaSwitchMoodboardButton from './AfaSwitchPdpMoodboardButton'
import { afaFooterLinksAndButtonsStyle } from './CommonComponents'
import CheckAdjustButton from '../containers/AfaCart/CheckAdjustButton'
import app_config from '../config/app/config'
import {
  afaCartIsUpdatingSelector,
  importExportStepSelector,
  searchOpenSelector,
} from '../store/afa/selectors'
import { useSaveWhiteboardMutation } from '../services/whiteboard'
import AfaModal from './AfaModal'
import whiteboardActions from '../store/whiteboard/actions'
import WhiteboardModalCreation from '../containers/Whiteboard/WhiteboardModalCreation'
import {
  getSelectedWhiteboard,
  openCreateNewWhiteboardSelector,
  openWhiteboardSettingsSelector,
} from '../store/whiteboard/selectors'
import WhiteboardModalSettings from '../containers/Whiteboard/WhiteboardModalSettings'
import { useGetAfaCartProducts } from '../hooks/useGetAfaCartProducts'
import Loading from './Loading'

export const FOOTER_CSS = css`
  font-size: ${getFluidFontSize('10px')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 10000010;
  width: 100%;
  padding: 0 0.5em;
  color: ${palette.congressBlue};
  text-transform: capitalize;
  text-align: center;
  outline: 1px solid ${palette.silver};
  background: ${palette.white};
  color: ${palette.tangaroa};
  font-weight: bold;
  height: ${pxToRem(49)}rem;
  position: relative;

  @media screen and (min-width: ${breakpointsCross.XL.max}) {
    min-height: ${pxToRem(120)}rem;
  }
`

const Wrapper = styled.footer<{ cartIsUpdating: boolean }>`
  ${FOOTER_CSS};
  ${({ cartIsUpdating }) =>
    cartIsUpdating &&
    css`
      pointer-events: none;
    `}
`

export const LeftCol = styled.div`
  display: flex;
  padding-left: ${gF('px', 32, 64, 1366, 3840)};
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${pxToRem(spacing(1))}rem;
  padding-right: ${gF('px', 32, 64, 1366, 3840)};
  border-right: solid 1px ${palette.mischka};
`

const MenuButton = styled(Link)`
  ${afaFooterLinksAndButtonsStyle};
  padding-left: ${pxToRem(32)}rem;
  padding-right: ${pxToRem(32)}rem;
`

const CentralCol = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 100%;
`

const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: max(${footerHeight}, ${footerMinHeight});
  padding: 0 ${pxToRem(spacing(1))}rem;
  box-sizing: border-box;

  img {
    object-fit: contain;
    height: max(${footerHeight}, ${footerMinHeight});
    padding: ${pxToRem(spacing(1.25))}rem 0;
    width: auto;
  }
`

const RightCol = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`

const DoorsButton = styled.button`
  ${afaFooterLinksAndButtonsStyle};
`

const AccountButton = styled.button<{
  customerName: string
}>`
  ${afaFooterLinksAndButtonsStyle};

  div {
    width: ${gF('px', 172, 330, 1366, 3840)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    animation: ${props => (props.customerName === '' ? 'rotate 1.5s linear infinite' : 'none')};
    margin-right: ${props => (props.customerName === '' ? '1em' : 'none')};

    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }
`

const IntroVideoButton = styled.button`
  ${afaFooterLinksAndButtonsStyle};
  display: none;
`

const IntroVideoImage = styled.img`
  width: ${pxToRem(18)}rem;
  height: ${pxToRem(18)}rem;
`
const SearchButton = styled.button`
  ${afaFooterLinksAndButtonsStyle};
  padding-left: ${pxToRem(32)}rem;
  padding-right: ${pxToRem(32)}rem;

  @media screen and (max-width: ${breakpoints.L}) {
    display: none;
  }
`

const WhiteBoardButton = styled(Link)`
  ${afaFooterLinksAndButtonsStyle};
  padding: 0 ${gF('px', 16, 65, 1366, 3840)};
  min-width: ${gF('px', 100, 268, 1366, 3840)};
`

const CartButton = styled(Link)`
  ${afaFooterLinksAndButtonsStyle};
  height: 100%;
  position: relative;

  &.active {
    background-color: ${palette.shadowBlue};
    color: ${palette.white};
    fill: ${palette.white};
  }

  div.loading-wrapper {
    width: 70%;

    span {
      width: ${gF('px', 18, 36, 1366, 3840)};
      height: ${gF('px', 18, 36, 1366, 3840)};

      i {
        width: ${gF('px', 8, 16, 1366, 3840)};
        height: ${gF('px', 8, 16, 1366, 3840)};
      }
    }
  }
`

const CartText = styled.span<{ cartQuantity: number; isLoading: boolean }>`
  display: flex;
  align-items: center;

  &.active {
    padding-left: 1.2em;

    &::before {
      content: '';
      display: inline-block;
      height: 1.4em;
      width: 1.4em;
      background-size: 1.4em 1.4em;
      background-repeat: no-repeat;
      margin-right: 0.5em;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.667 3.571c1.38.042 2.204.196 2.774.766.781.78.781 2.038.781 4.552v1.778c0 2.514 0 3.77-.78 4.552C13.66 16 12.402 16 9.888 16H6.333c-2.514 0-3.77 0-4.552-.781C1 14.438 1 13.18 1 10.667V8.889c0-2.514 0-3.771.781-4.552.57-.57 1.394-.724 2.775-.766v-.015a3.556 3.556 0 1 1 7.11 0v.015zM4.556 5.349c.002 1.46.4 1.762.888 1.762.491 0 .89-.305.89-1.778h3.555c0 1.473.398 1.778.889 1.778.489 0 .886-.303.889-1.762.362.012.657.032.911.066.51.069.595.166.605.178h.001l.001.002c.012.01.11.095.178.605.078.58.081 1.382.081 2.689v1.778c0 1.307-.003 2.109-.081 2.689-.069.51-.166.595-.178.605l-.002.002c-.01.011-.095.109-.605.177-.58.079-1.382.082-2.69.082H6.334c-1.307 0-2.109-.003-2.689-.082-.51-.068-.595-.166-.605-.177v-.001h-.002c-.011-.011-.109-.096-.177-.606-.078-.58-.082-1.382-.082-2.69V8.89c0-1.307.004-2.11.082-2.69.068-.51.166-.594.177-.604l.001-.001.001-.001c.01-.012.095-.11.605-.178a9.15 9.15 0 0 1 .912-.066zm5.333-1.793a1.778 1.778 0 1 0-3.556 0H9.89z' fill='%23fff'/%3E%3C/svg%3E");
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  // Badge counter
  ${({ cartQuantity, isLoading }) =>
    css`
      ::after {
        content: '${isLoading ? ' ҉' : cartQuantity}';
        margin-bottom: 1.5rem;
        background-color: ${palette.tangaroa};
        color: ${palette.white};
        border-radius: 2rem;
        font-size: ${gF('px', 12, 16, 1366, 3840)};
        padding: ${pxToRem(4)}rem ${pxToRem(6)}rem;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        height: 1.5rem;
        min-width: 1.5rem;
        animation: ${isLoading ? 'rotation 3s infinite' : 'none'};
      }
    `}
`

const Buttons4k = styled(ButtonHeader)`
  display: block;
  @media (max-width: ${breakpoints.L}) {
    display: none;
  }
`

const CheckAdjust4k = styled(CheckAdjustButton)`
  display: block;
  @media (max-width: ${breakpoints.L}) {
    display: none;
  }
`

const SwitchMoodboard4K = styled(AfaSwitchMoodboardButton)`
  @media (max-width: ${breakpoints.L}) {
    display: none;
  }
`

type Props = {
  productBrand?: string
  hideSearch?: boolean
  leftColContent?: JSX.Element
  centralColContent?: JSX.Element | null
}

const AfaFooter: React.FC<Props> = ({
  hideSearch,
  productBrand,
  leftColContent,
  centralColContent,
}) => {
  const { assortment, brandCode, modelCode } = useParams<{
    assortment: string
    brandCode: string
    modelCode: string
  }>()

  const history = useHistory()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const cartIsUpdating = useSelector(afaCartIsUpdatingSelector)

  const modalVisible = useSelector(footerModalVisibleSelector)

  const isSearchVisible = useSelector(searchOpenSelector)

  const customerName = useSelector(customerNameSelector)

  const importExportStep = useSelector(importExportStepSelector)

  const brandsQuery = useGetBrandsQuery()

  const isCart = !!useRouteMatch(`${getDeviceAbsolutePath()}/afa/cart`)

  const isImportExport = !!useRouteMatch(`${getDeviceAbsolutePath()}/afa/checkout/import-export`)

  const { brandsInCart } = useGetAfaCartProducts()

  const brands = brandsQuery?.data || []
  const selectedBrand = useSelector(afaBrandSelectedSelector)
  const brand =
    selectedBrand ||
    brands.find(item => item.code === brandCode || productBrand) ||
    brandsInCart[0] ||
    brands[0]

  const productQuery = useGetProductQuery(modelCode)
  const product = productQuery.data

  const menuLink = `${getDeviceAbsolutePath()}/${assortment}/single-brand/${brand?.code ||
    product?.brandCodeParent}`
  const menuButtonIsActive = window.location.pathname === menuLink
  const whiteboardButtonIsActive = window.location.pathname.includes(
    `${getDeviceAbsolutePath()}/${assortment}/${brand?.code ||
      product?.brandCodeParent}/whiteboard`,
  )

  const whiteboardGridLink = `${getDeviceAbsolutePath()}/${assortment}/${brand?.code ||
    product?.brandCodeParent}/whiteboard`

  const brandLogoLink = `${getDeviceAbsolutePath()}/${assortment}/choose-brand`

  const cartLink = `${getDeviceAbsolutePath()}/${assortment}/cart`

  const getCartQuery = useGetFilteredAfaCartQuery()

  const cartQuantity = (getCartQuery.data?.items || []).reduce((result, item) => {
    result += item.unconfirmedQuantity
    return result
  }, 0)

  const { brandLogo, isSuccess } = useGetBrandLogo(brand?.code || '')

  const customerDoors = useSelector(customerDoorsSelector)

  const openCreateNewWhiteboard = useSelector(openCreateNewWhiteboardSelector)
  const openWhiteboardSettings = useSelector(openWhiteboardSettingsSelector)

  const isPageThankYou = () => {
    const urlParts = getUrlParts()
    const pageName = urlParts.extrapath.split('/')[2] || ''
    return pageName === 'thank-you'
  }

  const selectedWhiteboard = useSelector(getSelectedWhiteboard)
  const [saveWhiteboard] = useSaveWhiteboardMutation()

  const handleBackAction = async () => {
    if (isImportExport && importExportStep === 2) {
      dispatch(afaActions.setImportExportStep(1))
    } else {
      if (window.location.href.includes('whiteboard') && selectedWhiteboard) {
        await saveWhiteboard({ selectedWhiteboard: selectedWhiteboard })
        history.goBack()
      } else {
        history.goBack()
      }
    }
  }

  const customerListLink = '/customers'

  useEffect(() => {
    dispatch(
      whiteboardActions.setWhiteboardLinks({
        'footer-menu': menuLink,
        'footer-whiteboard-grid': whiteboardGridLink,
        'footer-brand-logo': brandLogoLink,
        'footer-cart': cartLink,
        'customer-list-link': customerListLink,
      }),
    )
  }, [brandLogoLink, cartLink, dispatch, menuLink, whiteboardGridLink])

  return (
    <Wrapper cartIsUpdating={cartIsUpdating}>
      <LeftCol>
        {!isPageThankYou() && (
          <BackButton id="footer-back-button" onClick={handleBackAction}>
            <Icon type="left" />
            <div>{t('CustomersList.back')}</div>
          </BackButton>
        )}

        <MenuButton
          id="footer-menu"
          className={`${menuButtonIsActive && 'active'}`}
          to={isPageThankYou() ? brandLogoLink : menuLink}
        >
          {t('Afa.menu')}
        </MenuButton>

        {leftColContent}

        {
          <WhiteBoardButton
            id="footer-whiteboard-grid"
            className={whiteboardButtonIsActive ? 'active whiteboardd' : 'whiteboardd'}
            to={whiteboardGridLink}
          >
            {t('Afa.WhiteBoard')}
          </WhiteBoardButton>
        }

        {brand && (
          <IntroVideoButton>
            <IntroVideoImage alt="" src={`${app_config.publicUrl}/assets/images/video.svg`} />
            <span>{t('Afa.introVideo')}</span>
          </IntroVideoButton>
        )}

        {!hideSearch && (
          <SearchButton
            id="header-search"
            onClick={() => dispatch(afaActions.toggleSearchOpen())}
            className={isSearchVisible ? 'active' : ''}
          >
            <Icon type="search" /> <span>{t('FooterNav.item_search')}</span>
          </SearchButton>
        )}

        <SwitchMoodboard4K />

        {isCart && (
          <>
            {customerDoors.length > 1 && cartQuantity > 0 && (
              <Buttons4k
                className="duplicate"
                onClick={() => {
                  dispatch(afaActions.toggleDuplicateCartOnDoorModalOpen())
                }}
              >
                {t('Afa.duplicate')}
              </Buttons4k>
            )}

            {cartQuantity > 0 && <CheckAdjust4k className="check-adjust" />}

            {cartQuantity > 0 && (
              <Buttons4k
                className="share"
                onClick={() => {
                  dispatch(afaActions.toggleShareCartOnDoorModalOpen())
                }}
              >
                {t('Afa.share')}
              </Buttons4k>
            )}
          </>
        )}
      </LeftCol>

      <CentralCol>
        {centralColContent}
        {centralColContent === undefined && (
          <LogoWrapper id="footer-brand-logo" to={brandLogoLink}>
            {brand ? (
              brandLogo || !isSuccess ? (
                <img
                  className={'brand-footer-logo-img'}
                  src={brandLogo}
                  alt={brand.name || brand.code}
                />
              ) : (
                <span>{brand.name || brand.code}</span>
              )
            ) : (
              <div></div>
            )}
          </LogoWrapper>
        )}
      </CentralCol>

      <RightCol>
        {!isExtraIpadDevice() && (
          <DoorsButton
            id="footer-doors"
            className={`doors ${modalVisible === 'doors' ? 'active' : ''}`}
            onClick={() => dispatch(showFooterModal(modalVisible === 'doors' ? null : 'doors'))}
          >
            <span className="label">{t('GenericWords.doors')}</span>
            <Icon type={modalVisible === 'doors' ? 'up' : 'down'} theme="outlined" />
          </DoorsButton>
        )}

        <CartButton
          id="footer-cart"
          className={isPageMatches('cart') ? 'active' : ''}
          to={cartLink}
        >
          <CartText
            className={isPageMatches('cart') ? 'active' : ''}
            cartQuantity={cartQuantity}
            isLoading={getCartQuery.isLoading}
          >
            {t('FooterNav.item_cart')}
          </CartText>
          {cartIsUpdating && <Loading isFullPage={false} />}
        </CartButton>

        <AccountButton
          id="footer-customer-name"
          className={modalVisible === 'afaLogin' ? 'active' : ''}
          onClick={() => dispatch(showFooterModal(modalVisible === 'afaLogin' ? null : 'afaLogin'))}
          customerName={customerName}
        >
          <div>{customerName === '' ? '҉' : customerName}</div>
        </AccountButton>
      </RightCol>
      <AfaModal
        isOpen={openCreateNewWhiteboard}
        onRequestClose={() => {
          dispatch(whiteboardActions.setOpenCreateNewWhiteboard(false))
        }}
        fullscreen={false}
      >
        <WhiteboardModalCreation />
      </AfaModal>
      <AfaModal
        isOpen={openWhiteboardSettings}
        onRequestClose={() => {
          dispatch(whiteboardActions.setOpenWhiteboardSettings(false))
          dispatch(whiteboardActions.setWhiteboardFromTemplate(null))
          dispatch(whiteboardActions.setOpenPinModal(false))
          dispatch(whiteboardActions.resetItemsPinModal())
          dispatch(whiteboardActions.setWhiteboardToUpdateId(''))
        }}
        fullscreen={false}
      >
        <WhiteboardModalSettings />
      </AfaModal>
    </Wrapper>
  )
}

export default AfaFooter
