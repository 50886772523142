import { RootState } from '../../model/model'

export const afaCartIsLoadingSelector = (state: RootState) => state.afaCart.isLoading

export const afaCartIsUpdatungSelector = (state: RootState) => state.afaCart.isUpdating

export const afaCartDataSelector = (state: RootState) => state.afaCart.data

export const afaCartProductDetailsSelector = (modelCode: string) => (state: RootState) =>
  state.afaCart.data && state.afaCart.data[modelCode]

export const afaCartAllProductDetailsSelector = (state: RootState) => state.afaCart.data

export const afaCartErrorSelector = (state: RootState) => state.afaCart.error

export const afaCartIsErrorSelector = (state: RootState) => state.afaCart.isError

export const getSelectedKeys = (state: RootState) => state.afaCart.selectedKeys

export const getDeliveryReference = (state: RootState) => state.afaCart.deliveryReferences
