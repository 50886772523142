import { Action, combineReducers } from '@reduxjs/toolkit'

import { RootState } from '../model/model'
import { afaApi } from '../services/afa'
import { afaCartApi } from '../services/afaCart'
import { afaContentApi } from '../services/afaContents'
import { afaProductApi } from '../services/afaProduct'
import { afaProductsApi } from '../services/afaProducts'
import { cartApi } from '../services/cart'
import { contentApi } from '../services/contents'
import afa from './afa/slice'
import afaCart from './afaCart/slice'
import afaCartSummary from './afaCartSummary/slice'
import afaCartAdjust from './afaCartAdjust/slice'
import afaCartOutOfAssortment from './afaCartOutOfAssortment/slice'
import afaCartNotifications from './afaCartNotifications/slice'
import viewport from './viewport/slice'
import app from './app/slice'
import auth from './auth/slice'
import brands from './brands/slice'
import cart from './cart/slice'
import checkout from './checkout/slice'
import content from './content/slice'
import couvettes from './couvettes/slice'
import couvetteVideo from './couvetteVideo/slice'
import customer from './customer/slice'
import digitalEvents from './digitalEvents/slice'
import dislike from './dislike/slice'
import filters from './filters/slice'
import notifications from './notifications/slice'
import pdp from './pdp/slice'
import search from './search/slice'
import stars from './stars/slice'
import wishlist from './wishlist/slice'
import whiteboard from './whiteboard/slice'
import initiatives from './initiatives/slice'
import assortmentAdvisor from './assortmentAdvisor/slice'
import { aaApi } from '../flows/AssortmentAdvisor/services'
import { whiteboardApi } from '../services/whiteboard'
import { initiativesApi } from '../services/initiatives'
import { aaAddressApi } from '../flows/AssortmentAdvisor/addressServices'
import { virtualMirrorApi } from '../services/virtualMirror'

const appReducer = combineReducers({
  app,
  auth,
  brands,
  cart,
  couvettes,
  couvetteVideo,
  content,
  customer,
  dislike,
  filters,
  notifications,
  pdp,
  search,
  wishlist,
  digitalEvents,
  checkout,
  stars,
  afa,
  afaCart,
  afaCartSummary,
  afaCartAdjust,
  afaCartOutOfAssortment,
  afaCartNotifications,
  viewport,
  whiteboard,
  initiatives,
  assortmentAdvisor,
  [cartApi.reducerPath]: cartApi.reducer,
  [afaApi.reducerPath]: afaApi.reducer,
  [afaCartApi.reducerPath]: afaCartApi.reducer,
  [afaProductsApi.reducerPath]: afaProductsApi.reducer,
  [afaProductApi.reducerPath]: afaProductApi.reducer,
  [afaContentApi.reducerPath]: afaContentApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [aaApi.reducerPath]: aaApi.reducer,
  [aaAddressApi.reducerPath]: aaAddressApi.reducer,
  [whiteboardApi.reducerPath]: whiteboardApi.reducer,
  [virtualMirrorApi.reducerPath]: virtualMirrorApi.reducer,
  [initiativesApi.reducerPath]: initiativesApi.reducer,
})

export const rootReducer = (state: RootState | undefined, action: Action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') {
    state = undefined
  }

  return appReducer(state, action)
}
