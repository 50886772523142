import { useCallback, useMemo } from 'react'
import { getUnavailableKeys } from '../libs/afa'
import { useGetAfaCartProducts } from './useGetAfaCartProducts'
import { useGetCartAvailabilityQuery } from '../services/afaProduct'
import { AfaCartProduct } from '../model/afa'
import { useGetExpiredCartProducts } from './useGetExpiredCartProducts'

const useAfaGetCartUnavailableKeys = (cartProducts?: AfaCartProduct[]) => {
  const dropsQuery = useGetCartAvailabilityQuery()
  const cartProductsQuery = useGetAfaCartProducts()
  const { expiredSeasonsCartProducts } = useGetExpiredCartProducts()

  const unavailableKeys = useMemo(() => {
    const cartProductsToCheck =
      cartProducts === undefined ? cartProductsQuery.cartProducts : cartProducts
    const expiredSeasonsCartProductsUpcs = expiredSeasonsCartProducts.map(({ key }) => key)
    return getUnavailableKeys(cartProductsToCheck, dropsQuery.data).concat(
      expiredSeasonsCartProductsUpcs,
    )
  }, [cartProducts, cartProductsQuery.cartProducts, dropsQuery.data, expiredSeasonsCartProducts])

  const checkUnavailableCartProducts = useCallback(
    (cartProducts: AfaCartProduct[]) => {
      return getUnavailableKeys(cartProducts, dropsQuery.data)
    },
    [dropsQuery.data],
  )

  return { unavailableKeys, checkUnavailableCartProducts }
}

export default useAfaGetCartUnavailableKeys
