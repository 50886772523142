import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../style/theme'

const Wrapper = styled.div`
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  font-size: 100px;
  padding-bottom: 100px;

  @media screen and (max-width: ${breakpoints.L}) {
    font-size: 20px;
    padding-bottom: 20px;
  }

  > div {
    display: inline-block;
    padding-bottom: 20px;
    animation: rotate 1.5s linear infinite;

    @media screen and (max-width: ${breakpoints.L}) {
      padding-bottom: 4px;
    }
  }
`
const Spinner: React.FC = () => {
  return (
    <Wrapper>
      <div>{'҉'}</div>
    </Wrapper>
  )
}

export default Spinner
