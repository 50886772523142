import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router-dom'

import ActivationVideo from '../components/ActivationVideo'
import CouvetteVideoPlayer from '../components/CouvetteVideoPlayer'
import DiscoveryVideo from '../components/DiscoveryVideo'
import DisplayMedia from '../components/DisplayMedia'
import PrivateRoute from '../components/PrivateRoute'
import WallCart from '../components/WallCart'
import AfaKeylooks from '../containers/AfaKeylooks/AfaKeylooks'
import AfaPdp from '../containers/AfaPdp/AfaPdp'
import AfaPlp from '../containers/AfaPlp/AfaPlp'
import WallPdp from '../containers/WallPdp'
import AssortmentAdvisorRoutes from '../flows/AssortmentAdvisor/Routes'
import useFetchInitialData from '../hooks/useFetchInitialData'
import socketManager from '../libs/socketManager'
import { formatUrlElement } from '../libs/url'
import { CmsContent } from '../model/content'
import {
  contentSelector,
  deviceNameSelector,
  roomNameSelector,
  roomTypeSelector,
} from '../store/app/selectors'
import cartActions from '../store/cart/actions'
import pdpActions from '../store/pdp/actions'

const withBrandCheck = (fn: (data: any) => void) => (data: any) => {
  if (!data.brand) {
    // this happens when the table is reloaded while in categories page RED-1839
    return ''
  }
  return fn(data)
}

const pageEventsMap: Record<string, (data?: any) => undefined | void | string> = {
  do_autologin: () => undefined,
  do_open_login_page: () => '/welcome',
  do_logout: () => '/welcome',
  do_open_selectCustomers_page: () => '/welcome',
  do_open_chooseAssortment_page: () => '/welcome',
  do_open_chooseBrand_page: (data: any) => {
    // show welcome page only the first time user visit 'choosebrand'
    return data.welcomeVideoVisited ? '/multibrand' : '/welcome'
  },
  do_open_categories_page: withBrandCheck(data => {
    return `/${data.brand.slug}/${data.brandVisited ? 'navigation' : 'activation'}`
  }),
  do_open_keyLooks_page: (data: { wall: CmsContent }) => {
    return data.wall.type === 'internalUrl' ? `/${data.wall.url}` : '/afa-navigation'
  },
  do_open_aaChooseBrand_page: () => '/welcome',
  do_open_aaPlp_page: (data: { wall: CmsContent }) => {
    return `/${data.wall.url}`
  },
  do_open_afaPlp_page: (data: { wall: CmsContent }) => {
    return data.wall.type === 'internalUrl' ? `/${data.wall.url}` : '/afa-navigation'
  },
  do_open_afaPdp_page: (data: { wall: CmsContent }) => {
    return data.wall.type === 'internalUrl' ? `/${data.wall.url}` : '/afa-navigation'
  },
  do_play_video: withBrandCheck(data => {
    return `/ ${data.brand.slug} /navigation`
  }),
  do_play_activation_video: withBrandCheck(data => {
    return `/${data.brand.slug}/activation`
  }),
  do_play_discovery_video: withBrandCheck(data => {
    return `/${data.brand.slug}/discovery`
  }),
  do_open_singleBrandPlp_page: data => {
    return `/${data.brand.slug}/${data.brandVisited ? 'navigation' : 'discovery'}`
  },
  do_open_filteredPlp_singleBrand: data => {
    return `/${data.brand.slug}/navigation`
  },
  do_open_filteredPlp_multiBrand: () => '/multibrand',
  do_open_pdp_page: data => {
    return `/product/${formatUrlElement(data.modelCode)}/${data.viewType}`
  },
  do_scroll_pdp_page: data => {
    return `/product/${formatUrlElement(data.modelCode)}/${data.viewType}`
  },
  do_open_tableCart_page: data => {
    return `/cart/${data.viewType}`
  },
  do_open_tableWishlist_page: () => 'multibrand',
  do_switch_pdp_view: data => {
    return `/product/${formatUrlElement(data.modelCode)}/${data.viewType}`
  },
  do_play_digitalPreview_video: () => '/digital-preview',
  do_open_checkout_page: () => '/welcome',
}

const WallView: React.FC<RouteChildrenProps> = ({ match, history }) => {
  useFetchInitialData({ loadCustomer: true, loadContent: true, loadBrands: true })

  const content = useSelector(contentSelector)
  const roomName = useSelector(roomNameSelector)
  const roomType = useSelector(roomTypeSelector)
  const deviceType = useSelector(deviceNameSelector)

  const dispatch = useDispatch()

  const eventData = useRef<any>(null)
  const selectedBrand = eventData.current && eventData.current.brand

  const currentBaseUrl = `/${roomName}/${roomType}/${deviceType}`
  useEffect(() => {
    const events = Object.keys(pageEventsMap)
    const eventsHandler = events.map(eventName => (data: any) => {
      const resetLastAddedProductRecapEvents = [
        'do_open_pdp_page',
        'do_open_singleBrandPlp_page',
        'do_change_category',
      ]
      if (resetLastAddedProductRecapEvents.includes(eventName)) {
        dispatch(cartActions.setLastAddedProductRecap(undefined))
      }

      if (eventName === 'do_switch_pdp_view') {
        dispatch(pdpActions.setPDPCode(data.code))
      }

      eventData.current = data

      const redirectSlug = pageEventsMap[eventName](data)
      if (redirectSlug) {
        return history.push(`${currentBaseUrl}${redirectSlug}`)
      }
    })
    events.forEach((eventName, index) => {
      socketManager.bindListener(eventName, eventsHandler[index])
    })

    return () => {
      events.forEach((eventName, index) => {
        socketManager.removeListener(eventName, eventsHandler[index])
      })
    }
  }, [history, currentBaseUrl, dispatch])

  const redirectToNavigation = () =>
    selectedBrand && history.push(`${currentBaseUrl}/${selectedBrand.slug}/navigation`)

  const handleDigitalPreviewEnded = () => {
    history.push(`${currentBaseUrl}/multibrand`)
  }

  return (
    <div className="wall-view-container">
      <CouvetteVideoPlayer />
      <Switch>
        <PrivateRoute
          path={`${match?.url}/assortment-advisor`}
          component={AssortmentAdvisorRoutes}
        />

        <Route exact path={`${match?.url}/:assortment/pdp/:modelCode`} component={AfaPdp} />
        <Route
          exact
          path={`${match?.url}/:assortment/single-brand/:brandCode/key-looks`}
          component={AfaKeylooks}
        />
        <PrivateRoute
          exact
          path={`${match?.url}/:assortment/single-brand/:brandCode/:category`}
          component={AfaPlp}
        />
        <Route
          exact
          path={`${match?.url}`}
          render={() => <DisplayMedia media={content.intro} loop={true} />}
        />
        <Route
          exact
          path={`${match?.url}/login`}
          render={() => <DisplayMedia media={content.intro} loop={true} />}
        />
        <Route
          exact
          path={`${match?.url}/afa-navigation`}
          render={() => <DisplayMedia media={eventData.current?.wall} loop={true} />}
        />
        <Route
          exact
          path={`${match?.url}/digital-preview`}
          render={() => (
            <DisplayMedia
              media={content.digitalPreviewWall}
              loop={false}
              handleVideoEnded={handleDigitalPreviewEnded}
            />
          )}
        />
        <Route
          exact
          path={`${match?.url}/welcome`}
          render={() => <DisplayMedia media={content.welcome} loop={true} />}
        />
        <Route
          exact
          path={`${match?.url}/multibrand`}
          render={() => <DisplayMedia media={content.multibrandWall} loop={true} />}
        />
        <Route
          exact
          path={`${match?.url}/:brand/activation`}
          render={() => {
            if (selectedBrand) {
              return (
                <ActivationVideo
                  brandCode={selectedBrand.code}
                  media={selectedBrand.act_wall}
                  videoPlayedCallback={redirectToNavigation}
                />
              )
            }
            return <Redirect to={match?.url || ''} />
          }}
        />
        <Route
          exact
          path={`${match?.url}/:brand/navigation`}
          render={() => {
            if (selectedBrand) {
              return <DisplayMedia media={selectedBrand.nav_wall} displayLast={true} />
            } else {
              return <Redirect to={match?.url || ''} />
            }
          }}
        />
        <Route
          exact
          path={`${match?.url}/:brand/discovery`}
          render={() => {
            if (selectedBrand) {
              return (
                <DiscoveryVideo
                  brandCode={selectedBrand.code}
                  media={selectedBrand.discovery_wall}
                  videoPlayedCallback={redirectToNavigation}
                />
              )
            } else {
              return <Redirect to={match?.url || ''} />
            }
          }}
        />
        <Route
          exact
          path={`${match?.url}/product/:modelCode/:viewType`}
          render={() => {
            if (
              (eventData.current &&
                eventData.current.couvette &&
                !eventData.current.skuChangedByScrolling) ||
              (eventData.current && eventData.current.skuChangedByScrolling)
            ) {
              return (
                <WallPdp
                  content={eventData.current.content}
                  couvette={eventData.current.couvette}
                  viewType={eventData.current.viewType}
                  code={eventData.current.code}
                  skuChangedByScrolling={eventData.current.skuChangedByScrolling}
                />
              )
            } else {
              return <Redirect to={match?.url || ''} />
            }
          }}
        />
        <Route
          exact
          path={`${match?.url}/cart/:viewType`}
          render={() => {
            if (eventData.current) {
              return <WallCart />
            } else {
              return <Redirect to={match?.url || ''} />
            }
          }}
        />
        <Route
          exact
          path={`${match?.url}/checkout`}
          render={() => {
            return <DisplayMedia media={content.welcome} loop={true} />
          }}
        />
      </Switch>
    </div>
  )
}

export default WallView
