import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import {
  breakpoints,
  palette,
  getFluidSizeWithFullFormula as gF,
  pxToRem,
} from '../../../../style/theme'
import ResizeButton from '../../Components/ResizeButton'
import ControlPanel from './ControlPanel'
import Footer from '../../Components/Footer'
import Products from './Products'
import { usePreselectDefaultDoor } from '../../Hooks/usePreselectDefaultDoor'
import { useHandleOpenAaPlpPage } from '../../../../hooks/useHandleOpenPages'
import useGetFilteredMocos from '../../Hooks/useGetFilteredMocos'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import Toggle from '../../Components/Toggle'
import RCIcon from '../../../../components/UI/RCIcon'
import classnames from 'classnames'
import { firstLetterCapital } from '../../../../helpers/genericHelper'
import { Choice } from '../../Model/aaModel'
import { isWallDevice } from '../../../../libs/url'
import { useSelector } from 'react-redux'
import { roomTypeSelector } from '../../../../store/app/selectors'
import AreaTrendsButton from '../../Components/AreaTrendsButton'
import useSetDefaultPositionParam from '../../../../hooks/useSetPositionParam'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${gF('px', 12, 24, 1366, 3840)};
  padding-bottom: 0;
  background-image: radial-gradient(circle at 7% 5%, #172e52, #061a31 48%, ${palette.swamp} 91%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'EuclidCircularB-Regular', sans-serif;
`

const Main = styled.div`
  flex: 1;
  display: flex;
  gap: 0.5vw;
  overflow: hidden;
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${gF('px', 40, 60, 1366, 3840)};
`

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ToggleMenu = styled.div<{ collapseControlPanel: boolean }>`
  display: flex;
  padding-right: ${({ collapseControlPanel }) => (collapseControlPanel ? '5vw' : null)};
  gap: 1.5vw;
`

const ToggleLabel = styled.div`
  font-family: EuclidCircularB-Regular, sans-serif;
  font-size: max(1vw, 16px);
  color: ${palette.night};
  padding-right: 1vw;
`

const TitleAndProducts = styled.div`
  display: grid;
  grid-auto-rows: auto 1fr;
  flex: 1066;
  border-radius: 1vw;
  background-image: linear-gradient(
    180deg,
    rgba(226, 234, 253, 0.96) 0%,
    rgba(255, 246, 246, 0.96) 85%,
    rgba(255, 255, 255, 0.96) 100%
  );
  padding: 3vw 1.5vw;
  overflow: auto;

  &.with-trends {
    grid-auto-rows: auto auto 1fr;
  }

  @media (min-width: ${breakpoints.L}) {
    flex: 1570;
  }
`

const Title = styled.div`
  font-size: max(2.4vw, 34px);
  color: black;

  span:first-child {
    text-transform: capitalize;
  }

  span:last-child {
    color: ${palette.cornflowerBlueAA};
  }
`

const ResizeButtonMain = styled(ResizeButton)`
  background-color: ${palette.blueZodiac};
  padding: 0.75vw;
  margin-left: 2vw;
  position: absolute;
  top: 3vw;
  right: 2vw;
`

const AreaTrendsButtonWithMargin = styled(AreaTrendsButton)`
  margin-bottom: ${gF('px', 10, 20, 1366, 3840)};
`

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: EuclidCircularB-Regular, sans-serif;
  position: relative;

  > span {
    margin-right: ${pxToRem(10)}rem;
    font-size: max(1vw, 16px);
    color: ${palette.night};

    &:nth-of-type(2) {
      color: ${palette.cornflowerBlueAA};
      font-family: EuclidCircularB-Medium, sans-serif;
    }
  }

  img {
    width: ${gF('px', 36, 72, 1366, 3840)};
    height: ${gF('px', 36, 72, 1366, 3840)};
    cursor: pointer;
  }
`

const SortMenu = styled.div`
  width: ${gF('px', 360, 720, 1366, 3840)};
  position: absolute;
  right: 0;
  color: #001625;
  top: calc(-1 * ${gF('px', 30, 100, 1366, 3840)});
  border-radius: ${pxToRem(22)}rem;
  background-color: ${palette.white};
  padding: ${gF('px', 22, 44, 1366, 3840)} ${gF('px', 28, 56, 1366, 3840)};
  padding-bottom: ${gF('px', 60, 120, 1366, 3840)};
  font-size: ${gF('px', 16, 32, 1366, 3840)};
  font-family: EuclidCircularB-Regular, sans-serif;
  z-index: 100001;

  h3 {
    text-transform: capitalize;
    font-size: ${gF('px', 20, 40, 1366, 3840)};
    margin-bottom: ${gF('px', 11, 22, 1366, 3840)};
    font-family: EuclidCircularB-Regular, sans-serif;
  }
`

const SortMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: EuclidCircularB-Regular, sans-serif;
  margin-bottom: ${gF('px', 47, 94, 1366, 3840)};
  font-size: ${gF('px', 16, 32, 1366, 3840)};

  > div {
    &:nth-of-type(1) {
      display: flex;
      gap: ${gF('px', 6, 12, 1366, 3840)};

      span {
        &:nth-of-type(2) {
          font-family: EuclidCircularB-Medium, sans-serif;
          color: ${palette.cornflowerBlueAA};
        }
      }
    }

    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${gF('px', 43, 86, 1366, 3840)};
      height: ${gF('px', 43, 86, 1366, 3840)};
      border-radius: ${gF('px', 12, 24, 1366, 3840)};
      background-color: rgba(2, 31, 56, 0.07);
    }
  }
`

const SortLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(15px);
  background-color: rgba(100, 100, 100, 0.1);
  width: 100%;
  height: calc(100% - ${gF('px', 104, 208, 1366, 3840)});
  z-index: 100000;
`

const ChoiceWrapper = styled.div`
  margin-top: ${gF('px', 24, 48, 1366, 3840)};

  > div {
    &:nth-of-type(2) {
      margin-top: ${gF('px', 5, 10, 1366, 3840)};
    }
  }
`

const SortChoice = styled.div`
  height: ${gF('px', 48, 96, 1366, 3840)};
  padding: 14px 22px;
  border-radius: ${gF('px', 13, 26, 1366, 3840)};
  border: solid 1px rgba(15, 38, 74, 0.2);
  display: flex;
  align-items: center;
  color: ${palette.cornflowerBlueAA};
  font-family: EuclidCircularB-Medium, sans-serif;
  cursor: pointer;

  &.selected {
    background-color: ${palette.midnight};
    color: ${palette.white};
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: '';
      width: ${gF('px', 9, 18, 1366, 3840)};
      height: ${gF('px', 9, 18, 1366, 3840)};
      background-color: ${palette.cornflowerBlueAA};
      border-radius: 50%;
    }
  }
`

const AAPlp: React.FC = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const [collapseControlPanel, setCollapseControlPanel] = useState(false)
  const [showAllTagsToggleActive, setShowAllTagsToggleActive] = useState(false)
  const [showMatchToggleActive, setShowMatchToggleActive] = useState(false)
  const roomType = useSelector(roomTypeSelector)
  const isWall = isWallDevice()
  const [showSortLayer, setShowSortLayer] = useState(false)
  const [sortCriteria, setSortCriteria] = useState<Choice>('match-desc')

  const CHOICES_LABELS = {
    'match-desc': `${firstLetterCapital(t('AA.match'))}`,
    'brand-asc': `${firstLetterCapital(t('AA.alphabeticalOrder'))}`,
    'whsPrice-asc': `${firstLetterCapital(t('AA.increasingPrice'))}`,
    'whsPrice-desc': `${firstLetterCapital(t('AA.decreasingPrice'))}`,
  }

  useSetDefaultPositionParam()

  usePreselectDefaultDoor()
  const { mocos } = useGetFilteredMocos()

  const showDebugOverlay = searchParams.get('showDebugOverlay') === 'true'
  const counts = {
    young: showDebugOverlay ? mocos.filter(({ positioning }) => positioning === 'young').length : 0,
    innovative: showDebugOverlay
      ? mocos.filter(({ positioning }) => positioning === 'innovative').length
      : 0,
    sophisticated: showDebugOverlay
      ? mocos.filter(({ positioning }) => positioning === 'sophisticated').length
      : 0,

    sport: showDebugOverlay ? mocos.filter(({ positioning }) => positioning === 'sport').length : 0,

    everyday: showDebugOverlay
      ? mocos.filter(({ positioning }) => positioning === 'everyday').length
      : 0,
    sun: showDebugOverlay ? mocos.filter(({ category }) => category === '2').length : 0,
    optical: showDebugOverlay ? mocos.filter(({ category }) => category === '1').length : 0,
    new: showDebugOverlay ? mocos.filter(({ isNew }) => isNew).length : 0,
    carryover: showDebugOverlay ? mocos.filter(({ isNew }) => !isNew).length : 0,
  }

  useHandleOpenAaPlpPage()

  const handleSelectedChoce = (choice: Choice) => {
    setSortCriteria(choice)
    setTimeout(() => {
      setShowSortLayer(false)
    }, 500)
  }

  const showAreaTrends = !isWall && roomType !== 'ipad-only'

  return (
    <Wrapper>
      <Main>
        {showDebugOverlay && (
          <div
            style={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              zIndex: 9999,
              backgroundColor: 'rgba(0,0,0,0.7)',
              color: 'white',
              padding: '10px',
            }}
          >
            <p>Sun: {counts.sun}</p>
            <p>Optical: {counts.optical}</p>
            <p style={{ paddingLeft: '5px', paddingTop: '2px' }}>
              Totale: {counts.sun + counts.optical}
            </p>
            <br />
            <p>New: {counts.new}</p>
            <p>Carryover: {counts.carryover}</p>
            <p style={{ paddingLeft: '5px', paddingTop: '2px' }}>
              Totale: {counts.new + counts.carryover}
            </p>
            <br />
            <p>Young: {counts.young}</p>
            <p>Innovative: {counts.innovative}</p>
            <p>Sophisticated: {counts.sophisticated}</p>
            <p>Sport: {counts.sport}</p>
            <p>Everyday: {counts.everyday}</p>
            <p style={{ paddingLeft: '5px', paddingTop: '2px' }}>
              Totale:{' '}
              {counts.young +
                counts.innovative +
                counts.sophisticated +
                counts.sport +
                counts.everyday}
            </p>
          </div>
        )}
        <TitleAndProducts className={classnames({ 'with-trends': showAreaTrends })}>
          <Header>
            <Title>
              <span>{t('AA.recommended')}</span> <span>{t('AA.forYou')}</span>
            </Title>
            {!isWall && (
              <ToggleMenu collapseControlPanel={collapseControlPanel}>
                <ToggleWrapper>
                  <ToggleLabel>{t('AA.Plp.tagDetailsToggleLabel')}</ToggleLabel>
                  <Toggle
                    active={showAllTagsToggleActive}
                    action={() => setShowAllTagsToggleActive(!showAllTagsToggleActive)}
                  />
                </ToggleWrapper>

                <ToggleWrapper>
                  <ToggleLabel>{t('AA.Plp.showMatchToggleLabel')}</ToggleLabel>
                  <Toggle
                    active={showMatchToggleActive}
                    action={() => setShowMatchToggleActive(!showMatchToggleActive)}
                  />
                </ToggleWrapper>

                <SortWrapper>
                  <span>{t('Afa.sortBy')}:</span>
                  <span>{CHOICES_LABELS[sortCriteria]}</span>
                  <RCIcon type="icon-filtri" onClick={() => setShowSortLayer(true)} />

                  {showSortLayer && (
                    <SortMenu>
                      <SortMenuHeader>
                        <div>
                          <span>{t('Afa.sortBy')}:</span>
                          <span>{CHOICES_LABELS[sortCriteria]}</span>
                        </div>
                        <div onClick={() => setShowSortLayer(false)}>
                          <RCIcon type="icon-filtri" />
                        </div>
                      </SortMenuHeader>
                      <ChoiceWrapper>
                        <h3>{t('AA.match')}</h3>
                        <SortChoice
                          className={classnames({ selected: sortCriteria === 'match-desc' })}
                          onClick={() => handleSelectedChoce('match-desc')}
                        >
                          <span>{CHOICES_LABELS['match-desc']}</span>
                        </SortChoice>
                      </ChoiceWrapper>
                      <ChoiceWrapper>
                        <h3>{t('AA.brand')}</h3>
                        <SortChoice
                          className={classnames({ selected: sortCriteria === 'brand-asc' })}
                          onClick={() => handleSelectedChoce('brand-asc')}
                        >
                          <span>{CHOICES_LABELS['brand-asc']}</span>
                        </SortChoice>
                      </ChoiceWrapper>
                      <ChoiceWrapper>
                        <h3>{t('AA.price')}</h3>
                        <SortChoice
                          className={classnames({ selected: sortCriteria === 'whsPrice-asc' })}
                          onClick={() => handleSelectedChoce('whsPrice-asc')}
                        >
                          <span>{CHOICES_LABELS['whsPrice-asc']}</span>
                        </SortChoice>
                        <SortChoice
                          className={classnames({ selected: sortCriteria === 'whsPrice-desc' })}
                          onClick={() => handleSelectedChoce('whsPrice-desc')}
                        >
                          <span>{CHOICES_LABELS['whsPrice-desc']}</span>
                        </SortChoice>
                      </ChoiceWrapper>
                    </SortMenu>
                  )}
                </SortWrapper>
              </ToggleMenu>
            )}
          </Header>
          {showAreaTrends && <AreaTrendsButtonWithMargin mode="plp" />}
          <Products
            settings={{ showAllTagsToggleActive, showMatchToggleActive }}
            sortCriteria={sortCriteria}
          />
        </TitleAndProducts>

        {!isWall && (
          <ControlPanel collapsed={collapseControlPanel} setCollapsed={setCollapseControlPanel} />
        )}
        {!isWall && collapseControlPanel && (
          <ResizeButtonMain
            togglePanelCollapsed={() => setCollapseControlPanel(!collapseControlPanel)}
            collapsed={collapseControlPanel}
          />
        )}
      </Main>

      {!isWall && <Footer />}
      {showSortLayer && (
        <SortLayer
          onClick={e => {
            e.preventDefault()
            setShowSortLayer(false)
          }}
        />
      )}
    </Wrapper>
  )
}

export default AAPlp
