import PropTypes from 'prop-types'

export const errorData = PropTypes.shape({
  code: PropTypes.string,
  externalUrl: PropTypes.string,
  localUrl: PropTypes.string,
  message: PropTypes.string,
  operationNamespace: PropTypes.string,
  origin: PropTypes.string,
  priority: PropTypes.number,
  stack: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
})
