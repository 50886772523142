import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  useConfirmCartMutation,
  useGetDoorShippingAddressQuery,
  useUploadCartMutation,
} from '../../../../services/cart'
import { getCart } from '../../../../store/actions'
import checkoutActions from '../../../../store/checkout/actions'
import {
  checkoutCurrentPageSelector,
  checkoutStepSelector,
  importExportStepSelector,
  orderNotesSelector,
  selectCheckoutProducts,
  selectConfirmCartPayload,
  selectDoorsInCheckout,
  selectedAddressesSelector,
} from '../../../../store/checkout/selectors'
import { pxToRem, spacing } from '../../../../style/theme'
import { Notification } from '../../../../components/Notification'
import { NextButton } from '../Common'
import ConfirmCartUploadButton from './ConfirmCartUploadButton'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(spacing(3))}rem;
`

const PopupWrapper = styled.div`
  position: fixed;
  bottom: 6vh;
  right: 2vw;
  z-index: 1020;
  max-width: 450px;
  text-transform: initial;
`

const CheckoutFooterButton: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const step = useSelector(checkoutStepSelector)
  const page = useSelector(checkoutCurrentPageSelector)
  const confirmCartPayload = useSelector(selectConfirmCartPayload)
  const checkoutProducts = useSelector(selectCheckoutProducts)
  const importExportStep = useSelector(importExportStepSelector)
  const backOfficeNote = useSelector(orderNotesSelector)
  const { isLoading, isError, data: doors } = useGetDoorShippingAddressQuery()
  const selectedAddresses = useSelector(selectedAddressesSelector)
  const doorsInCart = useSelector(selectDoorsInCheckout)

  const [confirmCart, confirmCartResult] = useConfirmCartMutation()

  const submitCartDisabled = isLoading || isError

  useEffect(() => {
    if (confirmCartResult.isSuccess) {
      dispatch(checkoutActions.nextStep())
      dispatch(checkoutActions.setOrderNotes(''))
      dispatch(getCart())
    }
  }, [dispatch, confirmCartResult.isSuccess])

  const [, uploadCartResult] = useUploadCartMutation()
  const [showAddressNotification, setShowAddressNotification] = useState(false)
  const [outOfAssortmentNotificationClosed, setOutOfAssortmentNotificationClosed] = useState(false)

  const uploadFault = uploadCartResult.data?.fault

  const outOfAssormentProducts = checkoutProducts.filter(
    ({ outOfAssortment, status }) => outOfAssortment && status === '1',
  )

  return page === 'process' ? (
    <Wrapper>
      {step === 0 && (
        <NextButton
          onClick={() => {
            if (outOfAssormentProducts.length > 0) {
              setOutOfAssortmentNotificationClosed(false)
              return
            }
            setShowAddressNotification(false)
            setOutOfAssortmentNotificationClosed(false)
            dispatch(checkoutActions.nextStep())
          }}
        >
          {t('Checkout.Footer.Next')}
        </NextButton>
      )}
      {step === 1 && (
        <NextButton
          disabled={submitCartDisabled}
          onClick={() => {
            if (
              !(
                doors &&
                doors
                  .filter(({ id }) => doorsInCart.includes(id))
                  .every(({ id }) => selectedAddresses.find(({ doorId }) => id === doorId))
              )
            ) {
              setShowAddressNotification(true)
              return
            }
            setShowAddressNotification(false)
            setOutOfAssortmentNotificationClosed(false)
            confirmCart(confirmCartPayload, backOfficeNote)
          }}
        >
          {t('Checkout.Footer.SubmitBackOffice')}
        </NextButton>
      )}
      <PopupWrapper>
        {showAddressNotification && (
          <Notification
            text={t('Checkout.Address.MissingDeliveryAddress')}
            type="notice"
            onClose={() => setShowAddressNotification(false)}
          />
        )}
        {outOfAssormentProducts.length > 0 && !outOfAssortmentNotificationClosed && (
          <Notification
            text={t('Checkout.Process.OutOfAssortmentNotification')}
            type="notice"
            onClose={() => setOutOfAssortmentNotificationClosed(true)}
          />
        )}
      </PopupWrapper>
    </Wrapper>
  ) : (
    <Wrapper>
      {importExportStep === 1 && (
        <NextButton
          onClick={() => {
            dispatch(checkoutActions.setImportExportStep(2))
          }}
        >
          {t('Checkout.Footer.Next')}
        </NextButton>
      )}
      {importExportStep === 2 && !uploadFault && (
        <ConfirmCartUploadButton
          onClick={() => {
            uploadCartResult.reset()
          }}
        />
      )}
    </Wrapper>
  )
}

export default CheckoutFooterButton
