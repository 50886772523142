import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AfaProduct } from '../../model/afa'
import { ModelCode, Upc } from '../../model/model'

import { resetForNewClient } from '../extraActions'

const DEFAULT_STATE = {
  isLoading: false,
  isUpdating: false,
  isError: false,
  data: undefined as Record<ModelCode, AfaProduct | null> | undefined,
  error: undefined as undefined | { message: string; name: string; stack?: string },
  selectedKeys: {} as Record<string, Upc[]>,
  deliveryReferences: {} as Record<string, string>,
}

export const slice = createSlice({
  name: 'afaCart',
  initialState: DEFAULT_STATE,
  reducers: {
    setIsLoading: state => {
      state.isLoading = true
    },

    setIsUpdating: (state, { payload }) => {
      state.isUpdating = payload.value
    },

    upsertData: (state, { payload }: PayloadAction<Record<string, AfaProduct | null>>) => {
      state.isLoading = false

      state.data = {
        ...state.data,
        ...payload,
      }
    },

    setError: (state, { payload }: PayloadAction<Error>) => {
      state.isLoading = false
      state.isError = true
      state.error = {
        message: payload.message,
        name: payload.name,
        stack: payload.stack,
      }
    },

    setSelectedKeys: (state, { payload }: PayloadAction<Record<string, Upc[]>>) => {
      state.selectedKeys = payload
    },

    setDeliveryReferences: (state, { payload }: PayloadAction<{ key: string; value: string }>) => {
      state.deliveryReferences[payload.key] = payload.value
    },

    clearDeliveryReferences: state => {
      state.deliveryReferences = {}
    },
  },
  extraReducers: builder => {
    builder.addCase(resetForNewClient, () => DEFAULT_STATE)
  },
})

export default slice.reducer
