import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { errorData as PropErrorData } from '../../types/errorData'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../libs'
import { openedBrandsSelector } from '../../store/brands/selectors'
import { plpFiltersSelector } from '../../store/filters/selectors'
import { withTranslation } from 'react-i18next'
import { sendClearSessionEvent } from '../../libs/errors'

import styled from 'styled-components'

const GlobalErrorStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: #222;
  color: #fff;
`

const GlobalErrorWrapperStyled = styled.div`
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  color: #fff;
`

const Body = styled.div`
  padding: 1em 0;
  word-break: break-all;
  overflow-y: scroll;
`

const ReloadButton = styled(Button)`
  margin-right: 1rem;
`

const renderError = (value, label) =>
  value && (
    <div key={label}>
      <span>
        <strong>{label}:</strong> {value}
      </span>
    </div>
  )

const chooseSmartlookCustomEvent = (options, message) => {
  const canOpenPDP =
    options.locationPath.includes('single-brand') ||
    options.locationPath.includes('wishlist') ||
    options.locationPath.includes('cart')

  const conditionCustomEvents = {
    login: options.token === null || options.token === '',
    eventCustomer: options.locationPath.includes('customers'),
    home: options.locationPath.includes('choose-brand'),
    plp:
      options.locationPath.includes('single-brand') &&
      options.openedBrands.length &&
      !options.pdpVisible &&
      options.isSelectedCategory,
    pdp: canOpenPDP && options.pdpVisible,
    cart: options.locationPath.includes('cart'),
    wishlist: options.locationPath.includes('wishlist'),
  }

  const [selectedSection] = Object.entries(conditionCustomEvents).find(([, value]) => value) || [
    'generic',
  ]
  const customEventName = `error-${selectedSection}`

  // eslint-disable-next-line no-console
  console.log(`Smartlook custom event: ${customEventName} > ${message}`)

  window.smartlook && window.smartlook('track', customEventName, { message })
}

export const GlobalError = ({
  errors,
  goBack,
  token,
  eventId,
  customerId,
  pdpVisible,
  openedBrands,
  plpFilters,
  t,
}) => {
  const thereAreInfoToReload = token && eventId && customerId

  const locationPath = window.location.pathname.split('/')
  const isSelectedCategory = plpFilters && plpFilters.category.active.length > 0

  return (
    <GlobalErrorStyled>
      {errors.map((error, i) => {
        const { errorData, goToHome } = error

        const safeErrorData = {
          priority: 1,
          origin: 'JavaScript',
          message: error.message,
          stack: error.stack,
          ...errorData,
        }

        chooseSmartlookCustomEvent(
          {
            pdpVisible,
            token,
            openedBrands,
            locationPath,
            isSelectedCategory,
          },
          safeErrorData.message,
        )
        return (
          <GlobalErrorWrapperStyled
            key={`${safeErrorData.code}_${i}`}
            className={['global-error-wrapper', `priority_${safeErrorData.priority}`].join(' ')}
            style={{ order: -safeErrorData.priority }}
          >
            <Title>{`${safeErrorData.origin} ${
              safeErrorData.status ? ` - ${safeErrorData.status}` : ''
            } - Something went wrong!`}</Title>
            {goToHome ? (
              <Button onClick={() => goBack(error)}>{t('FooterNav.item_goback')}</Button>
            ) : (
              <div className="buttons">
                {thereAreInfoToReload && (
                  <ReloadButton type="primary" onClick={() => window.location.reload()}>
                    {t('GenericWords.reload_page')}
                  </ReloadButton>
                )}
                <Button className="back-login-button" onClick={() => sendClearSessionEvent()}>
                  {t('User.backToLogin')}
                </Button>
              </div>
            )}
            <Body>
              <div>
                {safeErrorData.operationNamespace &&
                  `Operation: ${safeErrorData.operationNamespace}`}
              </div>
              {[
                [safeErrorData.code, 'Code'],
                [safeErrorData.stack, 'Stack'],
                [safeErrorData.message, 'Message'],
                [safeErrorData.localUrl, 'Local url'],
                [safeErrorData.externalUrl, 'External url'],
              ].map(([value, label]) => renderError(value, label))}
            </Body>
          </GlobalErrorWrapperStyled>
        )
      })}
    </GlobalErrorStyled>
  )
}

GlobalError.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      errorData: PropErrorData,
      goToHome: PropTypes.bool,
    }),
  ),
  goBack: PropTypes.func,
  customerId: PropTypes.string,
  eventId: PropTypes.string,
  token: PropTypes.string,
  pdpVisible: PropTypes.bool,
  openedBrands: PropTypes.array,
  plpFilters: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  pdpVisible: isPdpVisibleSelector(state),
  openedBrands: openedBrandsSelector(state),
  plpFilters: plpFiltersSelector(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps(),
)(withTranslation('common')(GlobalError))
