import RCIcon from '../../../../components/UI/RCIcon'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { SelectedSwitchType, WhiteboardCardType } from '../../../../model/whiteboard'
import {
  useDeleteWhiteboardMutation,
  useLockWhiteboardMutation,
} from '../../../../services/whiteboard'
import Modal from '../Modal'
import ButtonsContainer from '../ButtonsContainer'
import WhiteboardDownloadModal from '../../WhiteboardDownloadModal'
import whiteboardActions from '../../../../store/whiteboard/actions'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import Toggle from '../Toggle'
import {
  Card,
  CardHeader,
  CardWrapper,
  LockIcon,
  Button,
  Info,
  InfoNotOwner,
  CreationInfo,
  Title,
  OptionsMenu,
  OptionsMenuAction,
  OptionsMenuHeader,
  OptionsMenuBody,
  Sports,
  WhiteboardImageWrapper,
  WhiteboardImage,
  ModalContent,
} from './style'
import { breakpoints, breakpointsCross, pxToRem } from '../../../../style/theme'
import { viewportSelector } from '../../../../store/viewport/selectors'
import {
  getSelectedSwitch,
  templatesSelector,
  whiteboardsSelector,
} from '../../../../store/whiteboard/selectors'
import Loading from '../../../../components/Loading'
import { errorNotification } from '../../../../components/Notification/notifications'
import WhiteboardModalShareTemplate from '../ModalShareTemplate'
import { generateUniqueFileName } from '../../../../helpers/generateUniqueFileName'
import { NO_BACKGROUND } from '../../WhiteboardPage'
import appActions from '../../../../store/app/actions'

const ToggleSmall = styled(Toggle)`
  > div {
    span {
      height: calc(100% - ${pxToRem(4)}rem);
      aspect-ratio: 1;
      width: initial;
      top: ${pxToRem(2)}rem;
    }
  }

  @media screen and (max-width: ${breakpoints.L}) {
    height: 28px !important;
    width: 60px !important;

    > div {
      span {
        &.locked {
          transform: translateX(${pxToRem(32)}rem);
        }
      }
    }
  }

  @media screen and (min-width: ${breakpointsCross.L.over}) {
    height: 48px !important;
    width: 102px !important;

    > div {
      span {
        height: calc(100% - ${pxToRem(6)}rem);
        top: ${pxToRem(3)}rem;

        &.locked {
          transform: translateX(${pxToRem(51)}rem);
        }
      }
    }
  }
`

type ActionType = {
  label: string
  icon: string
  disabled: boolean
  show: boolean
  action: () => void
  info?: string
  Component?: React.FC<{ action: () => void; locked: boolean }>
}

type SettingsModalType = {
  name: string
  isFirstCardOfRow: boolean
  release: string
  numberOfProducts: number
  locked: boolean
  fullAccess: boolean
  actions: ActionType[]
}

const SettingsModal: React.FC<SettingsModalType> = ({
  name,
  locked,
  fullAccess,
  isFirstCardOfRow,
  release,
  numberOfProducts,
  actions,
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const { bottom } = ref.current.getBoundingClientRect()
      const { height } = document.getElementsByTagName('footer')[0]?.getBoundingClientRect()

      const delta = bottom - (document.body.clientHeight - height)
      if (delta > 0) {
        const container = document.getElementById('wb-grid-container') as HTMLElement
        container.scrollTo({
          top: container.scrollTop + delta + 10,
          behavior: 'smooth',
        })
      }
    }
  }, [ref])

  return (
    <OptionsMenu ref={ref} onClick={e => e.preventDefault()} moveCentrally={isFirstCardOfRow}>
      <OptionsMenuHeader>
        <h3>{name}</h3>
        <div>
          <span>{release}</span>
          <span>
            {numberOfProducts} {t('Afa.Cart.Items')}
          </span>
        </div>
      </OptionsMenuHeader>
      <OptionsMenuBody>
        {actions.map(({ action, show, icon, label, Component, info, disabled }, index) => (
          <React.Fragment key={index}>
            {show && (
              <OptionsMenuAction className={classnames({ disabled })}>
                <span onClick={action}>
                  <RCIcon type={icon} />
                  {label}
                </span>
                {Component && <Component action={action} locked={locked} />}
                {info && (
                  <Info>
                    <small>{info}</small>
                  </Info>
                )}
              </OptionsMenuAction>
            )}
          </React.Fragment>
        ))}
      </OptionsMenuBody>
      {!fullAccess && (
        <InfoNotOwner>
          <small>{t('Whiteboard.onlyOwnerLabel')}</small>
        </InfoNotOwner>
      )}
    </OptionsMenu>
  )
}

type Props = {
  whiteboardCardData: WhiteboardCardType
  isFirstCardOfRow: boolean
  currentPreviewIsUpdating: boolean
  duplicateWhiteboard: ({
    keyToCopy,
    newName,
    type,
  }: {
    keyToCopy: string
    newName: string
    type?: SelectedSwitchType
  }) => void
}

const WhiteboardCard: React.FC<Props> = ({
  whiteboardCardData,
  isFirstCardOfRow,
  currentPreviewIsUpdating,
  duplicateWhiteboard,
}) => {
  const {
    creationDate,
    locked,
    name,
    owner,
    isOwner,
    release,
    sports,
    id,
    numberOfProducts,
    previewProducts,
    key,
  } = whiteboardCardData

  const { width: viewportWidth } = useSelector(viewportSelector)
  const [showMenu, setShowMenu] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [lockWhiteboard] = useLockWhiteboardMutation({ key: whiteboardCardData.key || id, locked })
  const [deleteWhiteboard, deleteWhiteboardResult] = useDeleteWhiteboardMutation()
  const [isDeleteModalOpen, setDeleteModalIsOpen] = useState(false)
  const [isShareModalOpen, setShareModalIsOpen] = useState(false)
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false)
  const whiteboardRef = useRef<HTMLDivElement>(null)
  const whiteboardPreview = new Image()
  whiteboardPreview.src = previewProducts || ''
  const selectedType = useSelector(getSelectedSwitch)

  const { whiteboards: templates } = useSelector(templatesSelector)
  const { whiteboards } = useSelector(whiteboardsSelector)

  const whiteboardsDataToCheck = selectedType === 'template' ? whiteboards : templates
  const whiteboardsOrTemplatesNames = whiteboardsDataToCheck?.map(wb => wb.name) || []

  const handleClickOutside = (event: Event) => {
    if (
      showMenu &&
      whiteboardRef.current &&
      !whiteboardRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const fullAccess = isOwner || !locked

  const actions: ActionType[] = [
    {
      label:
        selectedType === 'whiteboard'
          ? t('Whiteboard.whiteboardSettings')
          : t('Whiteboard.templateSettings'),
      icon: 'icon-settings',
      disabled: false,
      show: fullAccess,
      action: () => {
        dispatch(whiteboardActions.setWhiteboardToUpdateId(id))
        dispatch(whiteboardActions.setWhiteboardToUpdatePreviewProducts(previewProducts || ''))
        dispatch(whiteboardActions.setOpenWhiteboardSettings(true))
      },
    },
    {
      label: t('Whiteboard.duplicate'),
      icon: 'icon-duplicate',
      disabled: false,
      show: true,
      action: () => {
        duplicateWhiteboard({
          keyToCopy: key,
          newName: `${name}_${t('Whiteboard.copy')}`,
          type: selectedType,
        })
        setShowMenu(false)
      },
    },
    {
      label: t('Whiteboard.download'),
      icon: 'icon-download',
      disabled: !numberOfProducts,
      show: true,
      action: () => {
        setShowMenu(false)
        setDownloadModalOpen(true)
        return
      },
    },
    {
      label:
        selectedType === 'whiteboard'
          ? t('Whiteboard.saveAsTemplate')
          : t('Whiteboard.saveAsWhiteboard'),
      icon: 'icon-template',
      disabled: false,
      show: true,
      action: () => {
        const newName = generateUniqueFileName(name, whiteboardsOrTemplatesNames)
        duplicateWhiteboard({
          keyToCopy: key,
          newName: newName,
          type: selectedType === 'whiteboard' ? 'template' : 'whiteboard',
        })
        return
      },
    },
    {
      label: t('Whiteboard.share'),
      icon: 'icons-share',
      disabled: false,
      show: selectedType === 'template',
      action: () => setShareModalIsOpen(true),
    },
    {
      label: t('Whiteboard.delete'),
      icon: 'icons-trash',
      disabled: false,
      show: fullAccess,
      action: () => setDeleteModalIsOpen(true),
    },
    {
      label: t('Whiteboard.blockChanges'),
      icon: 'icon-lock',
      disabled: false,
      show: isOwner && selectedType === 'whiteboard',
      action: () => lockWhiteboard(),
      Component: ToggleSmall,
      info: locked ? t('Whiteboard.blockedLabel') : t('Whiteboard.unblockedLabel'),
    },
    {
      label: 'preview-pdf',
      icon: 'icon-download',
      disabled: !numberOfProducts,
      show: process.env.NODE_ENV === 'development',
      action: () => window.open(`pdf-preview/${id}`, '_blank'),
    },
  ]

  const handleConfirm = () => {
    deleteWhiteboard({ whiteboardId: id })
    setDeleteModalIsOpen(false)
  }

  useEffect(() => {
    const result = deleteWhiteboardResult

    if (result?.data?.warnings.length) {
      result.data.warnings.forEach((warning: { message: string }) => {
        errorNotification({ message: warning.message })
      })
    }
  }, [deleteWhiteboardResult])

  return (
    <CardWrapper ref={whiteboardRef}>
      <Card>
        <CardHeader>
          <LockIcon className={[!locked && 'hide'].filter(Boolean).join(' ')}>
            <RCIcon type="icon-lock" />
          </LockIcon>
          <Button className={[showMenu && 'active'].filter(Boolean).join(' ')}>
            <RCIcon
              type="icon-menu"
              onClick={e => {
                e.preventDefault()
                setShowMenu(show => !show)
              }}
              size={'100%'}
            />
            {showMenu && (
              <SettingsModal
                numberOfProducts={numberOfProducts}
                name={name}
                actions={actions}
                release={release}
                locked={locked}
                fullAccess={fullAccess}
                isFirstCardOfRow={isFirstCardOfRow}
              />
            )}
          </Button>
        </CardHeader>

        <WhiteboardImageWrapper
          key={currentPreviewIsUpdating ? '1' : '0'}
          to={`whiteboard/${id}`}
          onClick={() => dispatch(appActions.setShowSpinner(true))}
        >
          {whiteboardPreview.src !== NO_BACKGROUND && (
            <>
              <WhiteboardImage
                src={whiteboardPreview.src}
                alt=""
                onError={e => ((e.target as HTMLImageElement).style.display = 'none')}
              />
              {currentPreviewIsUpdating && <Loading isFullPage={false} />}
            </>
          )}
        </WhiteboardImageWrapper>

        <Title>
          <h3>{name}</h3>
        </Title>
        <Sports>
          {sports
            .slice(0, viewportWidth < parseInt(breakpoints.XL) ? 6 : 8)
            .sort()
            .map(sport => (
              <span key={sport}>{sport}</span>
            ))}
        </Sports>
      </Card>
      <CreationInfo>
        <span>{owner}</span>
        <span>{creationDate}</span>
      </CreationInfo>
      {isDeleteModalOpen && (
        <Modal
          fullsize={viewportWidth <= parseInt(breakpoints.M)}
          title={`Delete ${selectedType}`}
          onCancel={() => setDeleteModalIsOpen(false)}
        >
          <ModalContent>
            {selectedType === 'whiteboard' ? (
              <>
                <div>{t('Whiteboard.deleteWhiteboard.top')}</div>
                <div>{t('Whiteboard.deleteWhiteboard.bottom')}</div>
              </>
            ) : (
              <>
                <div>{t('Whiteboard.deleteTemplate.top')}</div>
                <div>{t('Whiteboard.deleteWhiteboard.bottom')}</div>
              </>
            )}
          </ModalContent>
          <ButtonsContainer
            cancelText={t('Whiteboard.cancel')}
            confirmText={t('Whiteboard.confirm')}
            onConfirm={handleConfirm}
            onCancel={() => setDeleteModalIsOpen(false)}
          />
        </Modal>
      )}

      {isShareModalOpen && (
        <WhiteboardModalShareTemplate setShareModalIsOpen={setShareModalIsOpen} id={key} />
      )}

      {isDownloadModalOpen && (
        <WhiteboardDownloadModal id={id} name={name} setDownloadModalOpen={setDownloadModalOpen} />
      )}
    </CardWrapper>
  )
}

export default WhiteboardCard
