import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { formatMoney } from '../../libs'
import { showSrPriceSelector, showWhsPriceSelector } from '../../store/customer/selectors'
import { getFluidFontSize } from '../../style/theme'

const ProductPrices = styled.div`
  font-size: ${getFluidFontSize('10px')};
  text-transform: uppercase;
  display: flex;

  &.vertical {
    flex-direction: column;
    align-items: flex-end;
  }

  &.horizontal {
    align-items: center;
  }
`

const WhsPrice = styled.div`
  font-weight: bold;
`

const SrPrice = styled.div`
  font-size: 80%;
`

type Props = {
  whsPrice?: number | string
  srPrice?: number | string
  currency: string
  className?: string
  variant: 'horizontal' | 'vertical'
}

export const Price: React.FC<Props> = ({ className, variant, whsPrice, srPrice, currency }) => {
  const showSrPrice = useSelector(showSrPriceSelector)
  const showWhsPrice = useSelector(showWhsPriceSelector)

  return (
    <ProductPrices
      className={['product-prices', className, variant].join(' ')}
      data-testid="product-prices"
    >
      {showWhsPrice && whsPrice?.toString() && (
        <WhsPrice data-testid="product-prices__whsPrice" className="product-prices__whsPrice">
          {formatMoney(currency, whsPrice)}
        </WhsPrice>
      )}
      {showSrPrice && srPrice?.toString() && (
        <SrPrice data-testid="product-prices__srPrice" className="product-prices__srPrice">
          srp {formatMoney(currency, srPrice)}
        </SrPrice>
      )}
    </ProductPrices>
  )
}

export default Price
