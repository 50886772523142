import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { grayScrollbar } from '../../components/CommonComponents'
import { useSearchParams } from '../../hooks/useSearchParams'
import { AfaMoco } from '../../model/afa'
import {
  breakpoints,
  breakpointsCross,
  getFluidFontSize,
  getFluidSizeWithFullFormula,
  palette,
} from '../../style/theme'
import { getProductImageUrl } from '../../libs/productImages'
import { useDispatch, useSelector } from 'react-redux'
import { itemsPinModalSelector } from '../../store/whiteboard/selectors'
import { generateSkuCode } from '../../libs/whiteboard'
import whiteboardActions from '../../store/whiteboard/actions'
import { useLocationState } from '../../hooks/useLocationState'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${getFluidFontSize('24px')};
  gap: 1em;
  color: ${palette.tangaroa};
  position: relative;
  overflow-y: auto;
  margin-right: calc(-1.25em + 4px);
  @media (min-width: ${breakpoints.L}) {
    margin-right: calc(-1.25em + 8px);
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: ${getFluidFontSize('20px')};
  text-transform: capitalize;
`

const Colors = styled.div`
  ${grayScrollbar};

  padding-right: calc(1.25em - 16px);

  @media (min-width: ${breakpoints.L}) {
    padding-right: calc(1.25em - 8px);
  }

  display: flex;
  flex-direction: column;
  gap: ${getFluidSizeWithFullFormula('px', 16, 48, 1920, 3840)};
  overflow-y: auto;
  height: 100%;
`

const Color = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getFluidSizeWithFullFormula('px', 10, 32, 1920, 3840)};
`

const ImageWrapper = styled.div`
  aspect-ratio: 1;
  background-color: white;
  border: 4px solid ${palette.wildSand};
  height: ${getFluidSizeWithFullFormula('px', 157, 335, 1366, 3840)};
  width: ${getFluidSizeWithFullFormula('px', 157, 335, 1366, 3840)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpointsCross.S.min}) and (max-width: ${breakpointsCross.M.max}) {
    height: auto;
    width: 100%;
  }

  &.active {
    border: 4px solid ${palette.shadowBlue};
  }
`

const Image = styled.img`
  height: 90%;
  object-fit: contain;
  position: relative;

  &:before {
    content: '';
    background-image: url('/assets/images/transparent_placeholder.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }
`

const Name = styled.div`
  width: ${getFluidSizeWithFullFormula('px', 157, 335, 1366, 3840)};
  text-align: center;
  color: ${palette.tangaroa};
  font-weight: bold;
  font-size: ${getFluidFontSize('14px')};

  @media (min-width: ${breakpointsCross.S.min}) and (max-width: ${breakpointsCross.M.max}) {
    width: 100%;
  }
`

const AfaPdpColors: React.FC<{ colors: AfaMoco[] }> = ({ colors }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const locationState = useLocationState()

  const products = useSelector(itemsPinModalSelector)

  const [searchParams, setSearchParams] = useSearchParams()

  const numerOfColors = (colors.length || '').toString()
  const selectedColorCode = searchParams.get('color')

  useEffect(() => {
    if (!selectedColorCode && colors.length > 0) {
      const colorCode = colors[0].colorCode
      searchParams.set('color', colorCode)
      setSearchParams(searchParams, { replace: true })
    }
  }, [selectedColorCode, setSearchParams, colors, searchParams])

  const resetPinItems = (modelCode: string, colorCode: string) => {
    if (
      products.length &&
      generateSkuCode([products[0]]) !== `${modelCode}-${colorCode}` &&
      products[0].modelCode === modelCode
    ) {
      dispatch(whiteboardActions.resetItemsPinModal())
    }
  }

  return (
    <Wrapper>
      <Title>
        {t('Afa.colors')} ({numerOfColors})
      </Title>

      <Colors>
        {colors.map(({ colorDescription, colorCode, modelCode, catalogImgPath }) => (
          <Color
            key={colorCode}
            onClick={() => {
              searchParams.set('color', colorCode)
              setSearchParams(searchParams, { replace: true, state: locationState })
              resetPinItems(modelCode, colorCode)
            }}
          >
            <ImageWrapper className={selectedColorCode === colorCode ? 'active' : ''}>
              <Image
                src={getProductImageUrl({ path: catalogImgPath })}
                alt={`thumbnail model ${modelCode}, color ${colorCode}`}
              />
            </ImageWrapper>
            <Name>{colorDescription}</Name>
          </Color>
        ))}
      </Colors>
    </Wrapper>
  )
}

export default AfaPdpColors
