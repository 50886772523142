import AfaHeader from '../../../components/Header/AfaHeader'
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AfaFooter from '../../../components/AfaFooter'
import Sidebar from './components/Sidebar'
import {
  getSelectedBoard,
  getSelectedBoardId,
  getSelectedCategory,
  getSelectedItems,
  getSelectedSwitch,
  getSelectedWhiteboard,
  getShowImagePlaceholder,
  initialFirstBoardStateSelector,
  initialWhiteboardStateSelector,
  itemIsBeingAddedToBoardSelector,
  latestSaveWhiteboardSelector,
  openAddItemsInCartSelector,
  repeatActionSelector,
  whiteboardDeleteBoardSelector,
  whiteboardIsWaitingForScreenshotSelector,
  whiteboardLinksSelector,
  whiteboardSettingsSelector,
} from '../../../store/whiteboard/selectors'
import { useDispatch, useSelector } from 'react-redux'
import ProductsList from './components/ProductsList'
import Header from './components/Header'
import Toolbar from './components/Toolbar'
import { useParams } from 'react-router'
import {
  useGetImageURLMutation,
  useGetSingleWhiteboardQuery,
  useGetVisibleWhiteboardsQuery,
  useSaveWhiteboardMutation,
} from '../../../services/whiteboard'
import whiteboardActions from '../../../store/whiteboard/actions'
import DraggableItem from './components/Draggable/DraggableItem'
import {
  Board,
  BoardWrapper,
  PageWrapper,
  Main,
  Wrapper,
  ClickStopper,
  BoardContent,
} from './style'
import DraggableText from './components/Draggable/DraggableText/DraggableText'
import { Rnd } from 'react-rnd'
import { convertPxToNumber } from '../../../libs/whiteboard'
import SettingsModal from './components/SettingsModal'
import DeleteBoardModal from './components/DeleteBoardModal'
import { useSearchParams } from '../../../hooks/useSearchParams'
import AddItemsToCartModal from '../components/AddItemsToCartModal'
import { useGetAfaCartProducts } from '../../../hooks/useGetAfaCartProducts'
import {
  errorNotification,
  successNotification,
} from '../../../components/Notification/notifications'
import { useUpdateAfaCartProductsMutation } from '../../../services/afaCart'
import { useTranslation } from 'react-i18next'
import { afaCartAdjustCartProductsSelector } from '../../../store/afaCartAdjust/selectors'
import useViewportUpdate from './components/useViewportUpdate'
import AfaSearch from '../../../components/AfaSearch/AfaSearch'
import { WhiteboardType } from '../../../model/whiteboard'
import DraggableImage from './components/Draggable/DraggableImage'
import { ImagePlaceholder } from './components/ProductsList/style'
import app_config from '../../../config/app/config'
import { toJpeg } from 'html-to-image'
import useActionDispatcher from './components/useActionDispatcher'
import { showFooterModal } from '../../../store/actions'
import { footerModalVisibleSelector } from '../../../store/app/selectors'
import Loading from '../../../components/Loading'
import {
  clickIsInsideElementBoundaries,
  getFirstBoardState,
  getWhiteboardState,
} from '../../../helpers/genericHelper'
import { ModalType } from '../../../model/model'
import classNames from 'classnames'
import appActions from '../../../store/app/actions'

export const NO_BACKGROUND = 'no-background'

const WhiteboardPage = () => {
  const { t } = useTranslation()

  const { whiteboardId } = useParams<{
    whiteboardId: string
  }>()
  const selectedSwitch = useSelector(getSelectedSwitch)
  const whiteboardQuery = useGetSingleWhiteboardQuery(selectedSwitch, whiteboardId)
  const { data } = useGetVisibleWhiteboardsQuery('', selectedSwitch)
  const whiteboard = whiteboardQuery.data
  const currentWhiteboardFromGetVisible = useMemo(() => {
    return data?.whiteboards.find(whiteboardFromList => whiteboardFromList.key === whiteboard?.key)
  }, [data?.whiteboards, whiteboard?.key])
  const unchangedProductsPreview = currentWhiteboardFromGetVisible?.previewProducts || ''
  const whiteboardIsLocked = currentWhiteboardFromGetVisible?.locked || false
  const whiteboardOwner = currentWhiteboardFromGetVisible?.isOwner || false
  const selectedBoard = useSelector(getSelectedBoard)
  const items = useMemo(() => {
    return selectedBoard?.items || []
  }, [selectedBoard?.items])
  const texts = selectedBoard?.texts || []
  const images = selectedBoard?.images || []
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [needNotificationStatusCheck, setNeedNotificationStatusCheck] = useState(false)
  const [showBadgeTemp, setShowBadgeTemp] = useState(false)
  const [, updateAfaCartProductsResult] = useUpdateAfaCartProductsMutation()
  const whiteboardLinks = useSelector(whiteboardLinksSelector)
  const modalVisible = useSelector(footerModalVisibleSelector)
  const blockAutosave = whiteboardIsLocked && !whiteboardOwner
  const selectedWhiteboard = useSelector(getSelectedWhiteboard)

  const selectedSection = useSelector(getSelectedCategory)
  const boardRef = useRef<HTMLDivElement>(null)

  const selectedBoardItems = useSelector(getSelectedItems)
  const isAddItemsToCartOpened = useSelector(openAddItemsInCartSelector)
  const productsAddedToCart = useSelector(afaCartAdjustCartProductsSelector)
  const isSettingsModalOpen = useSelector(whiteboardSettingsSelector)
  const isDeleteBoardModalOpen = useSelector(whiteboardDeleteBoardSelector)
  const { cartProducts } = useGetAfaCartProducts()
  const { viewportUpdated } = useViewportUpdate()
  const itemIsBeingAddedToBoard = useSelector(itemIsBeingAddedToBoardSelector)
  const initialFirstBoardState = useSelector(initialFirstBoardStateSelector)
  const initialWhiteboardState = useSelector(initialWhiteboardStateSelector)
  const whiteboardState = getWhiteboardState(selectedWhiteboard)
  const firstBoardState = getFirstBoardState(selectedWhiteboard)
  const firstBoardHasChangesToSave = initialFirstBoardState !== firstBoardState
  const whiteboardIsWaitingForScreenshot = useSelector(whiteboardIsWaitingForScreenshotSelector)
  const latestSaveWhiteboard = useSelector(latestSaveWhiteboardSelector)
  const firstBoardHasElements =
    !!selectedWhiteboard?.boards[0].items.length ||
    !!selectedWhiteboard?.boards[0].images.length ||
    !!selectedWhiteboard?.boards[0].texts.length
  const firstBoardElementsLength =
    (selectedWhiteboard &&
      [
        ...selectedWhiteboard.boards[0].items,
        ...selectedWhiteboard?.boards[0].images,
        ...selectedWhiteboard?.boards[0].texts,
      ].length) ||
    0

  const updateWhiteboardStateAfterSaving = useCallback(() => {
    dispatch(whiteboardActions.setInitialFirstBoardState(firstBoardState))
    dispatch(whiteboardActions.setInitialWhiteboardState(whiteboardState))
    dispatch(whiteboardActions.setLatestSaveWhiteboard(null))
  }, [dispatch, firstBoardState, whiteboardState])

  useEffect(() => {
    if (latestSaveWhiteboard) {
      updateWhiteboardStateAfterSaving()
    }
  }, [latestSaveWhiteboard, updateWhiteboardStateAfterSaving])

  const productsAddedToCartFiltered = productsAddedToCart.filter(
    ({ unconfirmedQuantity }) => unconfirmedQuantity > 0,
  )

  const selectedItems = useMemo(() => {
    return [...selectedBoardItems.items]
  }, [selectedBoardItems.items])
  const selectedTexts = useMemo(() => {
    return [...selectedBoardItems.texts]
  }, [selectedBoardItems.texts])
  const selectedImages = useMemo(() => {
    return [...selectedBoardItems.images]
  }, [selectedBoardItems.images])

  const firstBoardId = selectedWhiteboard?.boards?.[0]?.key ?? ''
  const [saveWhiteboard] = useSaveWhiteboardMutation()
  const selectedBoardId = useSelector(getSelectedBoardId)
  const showImagePlaceholder = useSelector(getShowImagePlaceholder)
  const [getImageURL] = useGetImageURLMutation()
  const isFirstBoardSelected =
    !!selectedBoardId && !!firstBoardId && firstBoardId === selectedBoardId

  const boardHeight = useRef<number>()
  const snapshotURL = useRef<string | null>(null)
  const clickedEvent = useRef<Event | null>(null)
  const repeatAction = useSelector(repeatActionSelector)
  const lastModalOpened = useRef<string | null>('')
  const dispatchAction = useActionDispatcher()
  const [showLoader, setShowLoader] = useState(false)

  const BOARD_CHANGING_TIME = 500
  const ITEM_UPLOAD_TIME = 1000

  const setInitialWhiteboardState = (selectedWhiteboard: WhiteboardType) => {
    localStorage.setItem('lastWhiteboardVisitedKey', selectedWhiteboard.key)
    dispatch(whiteboardActions.setInitialWhiteboardState(whiteboardState))
    dispatch(whiteboardActions.setInitialFirstBoardState(firstBoardState))
  }

  const setCurrentWhiteboardState = useCallback(() => {
    dispatch(whiteboardActions.setCurrentWhiteboardState(whiteboardState))
    dispatch(whiteboardActions.setCurrentFirstBoardState(firstBoardState))
    setTimeout(() => {
      dispatch(whiteboardActions.setItemIsBeingAddedToTheBoard(false))
    }, ITEM_UPLOAD_TIME)
  }, [dispatch, firstBoardState, whiteboardState])

  useEffect(() => {
    if (selectedWhiteboard) {
      setInitialWhiteboardState(selectedWhiteboard)
    }
    // It must be done just once, don't add dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWhiteboard?.key])

  useEffect(() => {
    if (selectedWhiteboard) {
      setCurrentWhiteboardState()
    }
  }, [selectedWhiteboard, setCurrentWhiteboardState])

  useEffect(() => {
    dispatch(whiteboardActions.resetWBFilters())
  }, [dispatch])

  const getSnapshot = useCallback(async () => {
    let result = NO_BACKGROUND

    if (firstBoardHasElements) {
      try {
        const board = document.getElementById('board') as HTMLDivElement
        const whiteboardPreviewProducts = await toJpeg(board.firstElementChild as HTMLElement)
        const res = await getImageURL({
          base64: whiteboardPreviewProducts.replace(/data:image\/(.*);base64,/, ''),
          filename: `${selectedWhiteboard?.name}-${new Date().getDate()}.jpeg`,
        })
        const {
          data: { URL },
        } = res as { data: { fileName: string; URL: string } }
        snapshotURL.current = URL
        result = URL
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error in taking snapshot:', error)
        snapshotURL.current = unchangedProductsPreview
        dispatch(whiteboardActions.setRepeatAction(true))
        result = unchangedProductsPreview
      }
    } else {
      snapshotURL.current = NO_BACKGROUND
    }

    return result
  }, [
    dispatch,
    firstBoardHasElements,
    getImageURL,
    selectedWhiteboard?.name,
    unchangedProductsPreview,
  ])

  const delaySnapshot = (delay: number) => {
    setTimeout(() => {
      getSnapshot()
    }, delay)
  }

  const selectFirstBoard = () => {
    dispatch(whiteboardActions.setSelectedBoardId(whiteboard?.boards[0].key || ''))
  }

  const delayFirstBoardSelection = (delay: number) => {
    setTimeout(() => {
      selectFirstBoard()
    }, delay)
  }

  const dispatchModalToggle = useCallback(
    (modalVisibleName: ModalType) => {
      if (lastModalOpened.current !== modalVisibleName) {
        dispatch(showFooterModal(modalVisible === modalVisibleName ? null : modalVisibleName))
        lastModalOpened.current = modalVisibleName
      } else {
        dispatch(showFooterModal(null))
        lastModalOpened.current = null
      }
    },
    [dispatch, modalVisible],
  )

  const externalClickableComponents: {
    cssId: string
    action: (() => void) | 'goBack' | 'push'
    linkToPush?: string
  }[] = useMemo(() => {
    return [
      { cssId: 'footer-back-button', action: 'goBack' },
      {
        cssId: 'footer-menu',
        action: 'push',
        linkToPush: whiteboardLinks['footer-menu'],
      },
      {
        cssId: 'footer-whiteboard-grid',
        action: 'push',
        linkToPush: whiteboardLinks['footer-whiteboard-grid'],
      },
      {
        cssId: 'footer-brand-logo',
        action: 'push',
        linkToPush: whiteboardLinks['footer-brand-logo'],
      },
      {
        cssId: 'footer-doors',
        action: () => dispatchModalToggle('doors'),
      },
      { cssId: 'footer-cart', action: 'push', linkToPush: whiteboardLinks['footer-cart'] },
      {
        cssId: 'footer-customer-name',
        action: () => dispatchModalToggle('afaLogin'),
      },
      {
        cssId: 'customer-list-link',
        action: 'push',
        linkToPush: whiteboardLinks['customer-list-link'],
      },
    ]
  }, [dispatchModalToggle, whiteboardLinks])

  const isLeavingThePage = () => {
    setShowLoader(true)
    dispatch(whiteboardActions.setSportPromisesAreFulfilled(false))
    dispatch(whiteboardActions.setWhiteboardIsWaitingForScreenshot(false))
  }

  const handleClickOutsideWhiteboard = (event: Event) => {
    const mouseEvent = event as MouseEvent
    const { clientX: x, clientY: y } = mouseEvent

    const clickIsOutsideWhiteboard =
      !clickIsInsideElementBoundaries('whiteboard-wrapper', x, y) ||
      clickIsInsideElementBoundaries('customer-list-link', x, y)

    const firstBoardHasBeenModified = initialFirstBoardState !== firstBoardState

    const firstBoardHasHeavyLoad = firstBoardElementsLength > 100

    const modalVisibleIsOpening =
      clickIsInsideElementBoundaries('footer-doors', x, y) ||
      clickIsInsideElementBoundaries('footer-customer-name', x, y)

    externalClickableComponents
      .filter(
        component =>
          component.cssId !== 'footer-doors' && component.cssId !== 'footer-customer-name',
      )
      .forEach(component => {
        if (clickIsInsideElementBoundaries(component.cssId, x, y)) {
          isLeavingThePage()
        }
      })

    if (clickIsOutsideWhiteboard && !isAddItemsToCartOpened) {
      clickedEvent.current = event

      if (blockAutosave || modalVisibleIsOpening) {
        dispatch(whiteboardActions.setRepeatAction(true))
      } else if (isFirstBoardSelected) {
        if (itemIsBeingAddedToBoard || firstBoardHasHeavyLoad) {
          delaySnapshot(ITEM_UPLOAD_TIME)
        } else {
          getSnapshot()
        }
      } else if (firstBoardHasBeenModified || whiteboardIsWaitingForScreenshot) {
        if (itemIsBeingAddedToBoard) {
          delayFirstBoardSelection(ITEM_UPLOAD_TIME)
          delaySnapshot(ITEM_UPLOAD_TIME + BOARD_CHANGING_TIME)
        } else {
          selectFirstBoard()
          delaySnapshot(BOARD_CHANGING_TIME)
        }
      } else {
        snapshotURL.current = unchangedProductsPreview
      }
    } else {
      dispatch(whiteboardActions.setRepeatAction(false))
    }
  }

  useEffect(() => {
    if (selectedWhiteboard && snapshotURL.current && clickedEvent.current && !blockAutosave) {
      const saveWhiteboardAndRepeatAction = async () => {
        saveWhiteboard({
          selectedWhiteboard: {
            ...selectedWhiteboard,
            previewProducts: snapshotURL.current || '',
          },
        })
        snapshotURL.current = null

        dispatch(whiteboardActions.setRepeatAction(true))
      }
      saveWhiteboardAndRepeatAction()
    }
  }, [saveWhiteboard, dispatch, blockAutosave, selectedWhiteboard])

  useEffect(() => {
    if (repeatAction && clickedEvent.current) {
      const target = clickedEvent.current as MouseEvent
      const { clientX: x, clientY: y } = target

      externalClickableComponents.forEach(component => {
        if (clickIsInsideElementBoundaries(component.cssId, x, y)) {
          dispatchAction(component.action, component.linkToPush)
        }
      })

      dispatch(whiteboardActions.setRepeatAction(false))
    }
  }, [repeatAction, dispatch, externalClickableComponents, dispatchAction])

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideWhiteboard)
    return () => {
      document.removeEventListener('click', handleClickOutsideWhiteboard)
    }
  })

  const AUTOSAVE_INTERVAL = 60000 //milliseconds
  const [runIntervalSaving, setRunIntervalSaving] = useState(false)
  const whiteboardHasChangesToSave = initialWhiteboardState !== whiteboardState

  const IntervalSaving = useCallback(async () => {
    if (selectedWhiteboard) {
      if (isFirstBoardSelected) {
        await getSnapshot()
      } else if (firstBoardHasChangesToSave) {
        dispatch(whiteboardActions.setWhiteboardIsWaitingForScreenshot(true))
      }
      saveWhiteboard({
        selectedWhiteboard: {
          ...selectedWhiteboard,
          previewProducts: snapshotURL.current || unchangedProductsPreview,
        },
      })
    }
  }, [
    dispatch,
    firstBoardHasChangesToSave,
    getSnapshot,
    isFirstBoardSelected,
    saveWhiteboard,
    selectedWhiteboard,
    unchangedProductsPreview,
  ])

  useEffect(() => {
    if (whiteboardHasChangesToSave) {
      const intervalId = setTimeout(() => setRunIntervalSaving(true), AUTOSAVE_INTERVAL)
      return () => {
        clearTimeout(intervalId)
      }
    } else {
      setRunIntervalSaving(false)
    }
  }, [whiteboardHasChangesToSave])

  useEffect(() => {
    if (runIntervalSaving) {
      setRunIntervalSaving(false)
      IntervalSaving()
    }
  }, [IntervalSaving, runIntervalSaving])

  const itemsWithRef = useRef<Record<string, React.MutableRefObject<Rnd> | null>>({})
  const textsWithRef = useRef<Record<string, React.MutableRefObject<Rnd> | null>>({})
  const imagesWithRef = useRef<Record<string, React.MutableRefObject<Rnd> | null>>({})

  itemsWithRef.current = items.reduce((res, item) => {
    res[item.id] = createRef<React.MutableRefObject<Rnd> | null>()

    return res
  }, {} as any)

  textsWithRef.current = texts.reduce((res, text) => {
    res[text.id] = createRef<React.MutableRefObject<Rnd> | null>()

    return res
  }, {} as any)

  imagesWithRef.current = images.reduce((res, text) => {
    res[text.id] = createRef<React.MutableRefObject<Rnd> | null>()

    return res
  }, {} as any)

  useEffect(() => {
    if (whiteboard) {
      const whiteboard_ = {
        ...whiteboard,
        boards: whiteboard.boards.map(board => ({
          ...board,
          items: board.items.map(item => {
            // Find out if the item was selected and update the whiteboard state.
            // We don't save this information on the backend, but we need to keep selection after saving and query refetch.
            const boardItems = selectedWhiteboard?.boards.find(({ key }) => key === board.key)
              ?.items

            const isItemSelected = !!boardItems?.find(
              ({ id }) => id.toLowerCase() === item.id.toLowerCase(),
            )?.selected

            return {
              ...item,
              selected: isItemSelected,
            }
          }),
        })),
      }

      dispatch(whiteboardActions.setSelectedWhiteboard(whiteboard_))
      dispatch(appActions.setShowSpinner(false))
    }

    // disabled cause it goes in loop with selectedWhiteboard dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whiteboard, dispatch])

  useEffect(() => {
    return () => {
      dispatch(whiteboardActions.deselectWhiteboard())
      dispatch(whiteboardActions.setOpenAddItemsInCart(false))
      dispatch(whiteboardActions.setSelectedCategory({ category: '' }))
    }
  }, [dispatch])

  useEffect(() => {
    if (!needNotificationStatusCheck) {
      return
    }

    const { isError, isSuccess, data } = updateAfaCartProductsResult

    if (!isError && !isSuccess) return

    if (isError || !!data?.warnings.length) {
      errorNotification({ message: t('Whiteboard.addItemsToCartModal.errorMessage') })
    } else if (isSuccess && data?.result) {
      successNotification({ message: t('Whiteboard.addItemsToCartModal.successMessage') })

      setShowBadgeTemp(true)
      setTimeout(() => {
        setShowBadgeTemp(false)
      }, 10 * 1000)
    }

    updateAfaCartProductsResult.reset()
    setNeedNotificationStatusCheck(false)
  }, [dispatch, t, updateAfaCartProductsResult, needNotificationStatusCheck])

  const updatePosition = useCallback(() => {
    const pxRegex = /[0-9]+(\.[0-9]+)?(px)/g
    selectedItems.forEach(item => {
      const itemRef = itemsWithRef.current[item.id]?.current as Rnd

      if (!itemRef) return

      const itemEl = itemRef.resizableElement.current?.style.transform
      const [transformX, transformY] = itemEl?.match(pxRegex) || []

      itemRef.updatePosition({
        x: convertPxToNumber(transformX || ''),
        y: convertPxToNumber(transformY),
      })
    })
  }, [selectedItems, itemsWithRef])

  const updateTextPosition = useCallback(() => {
    const pxRegex = /[0-9]+(\.[0-9]+)?(px)/g
    selectedTexts.forEach(item => {
      const textRef = textsWithRef.current[item.id]?.current as Rnd

      if (!textRef) return

      const text = textRef.resizableElement.current?.style.transform
      const [transformX, transformY] = text?.match(pxRegex) || []

      textRef.updatePosition({
        x: convertPxToNumber(transformX || ''),
        y: convertPxToNumber(transformY),
      })
    })
  }, [selectedTexts, textsWithRef])

  const updateImagesPosition = useCallback(() => {
    const pxRegex = /[0-9]+(\.[0-9]+)?(px)/g
    selectedImages.forEach(item => {
      const imgRef = imagesWithRef.current[item.id]?.current as Rnd

      if (!imgRef) return

      const text = imgRef.resizableElement.current?.style.transform
      const [transformX, transformY] = text?.match(pxRegex) || []

      imgRef.updatePosition({
        x: convertPxToNumber(transformX || ''),
        y: convertPxToNumber(transformY),
      })
    })
  }, [selectedImages, imagesWithRef])

  //Il click sulla board fa uscire dalla modalità multiselect
  const toggleMultiselect = (e: React.MouseEvent) => {
    const resizing = searchParams.get('resizing')
    if (e.currentTarget !== e.target || resizing) {
      return
    }

    dispatch(whiteboardActions.enableMultiselectActions())
    dispatch(whiteboardActions.deselectAllItems())
  }

  useEffect(() => {
    const request = window.indexedDB.open('rc-whiteboard')
    request.onupgradeneeded = function(event: IDBVersionChangeEvent) {
      const target = event?.target as IDBOpenDBRequest
      const db = target.result
      db.createObjectStore('proxy-images', { keyPath: 'path' })
    }
  }, [items])

  useEffect(() => {
    dispatch(whiteboardActions.deselectAllItems())
  }, [selectedBoard?.key, dispatch])

  useEffect(() => {
    const boardWrapper = document.getElementById('board-wrapper')
    if (boardWrapper) {
      const { height } = boardWrapper.getBoundingClientRect()
      boardHeight.current = height
    }
  }, [viewportUpdated])

  return isAddItemsToCartOpened ? (
    <AddItemsToCartModal
      onClose={() => setShowLoader(false)}
      onConfirm={() => setNeedNotificationStatusCheck(true)}
    />
  ) : (
    <PageWrapper>
      {showLoader && <Loading isFullPage={true} />}

      <AfaSearch />
      <AfaHeader />

      <Wrapper id="whiteboard-wrapper">
        <Header />
        {(whiteboardOwner || !whiteboardIsLocked) && <Sidebar selectedSection={selectedSection} />}
        <Main id={'whiteboard-main'}>
          {(whiteboardOwner || !whiteboardIsLocked) && (
            <ProductsList boardRef={boardRef.current} selectedCategory={selectedSection} />
          )}
          <BoardWrapper id={'board-wrapper'}>
            <Board
              height={boardHeight.current}
              ref={boardRef}
              id="board"
              className={classNames({ centered: !whiteboardOwner && whiteboardIsLocked })}
            >
              <BoardContent id="board-content" onClick={toggleMultiselect}>
                {selectedBoard &&
                  viewportUpdated &&
                  items.map(item => {
                    const isInCart = !!cartProducts.find(
                      ({ modelCode, colorCode }) =>
                        modelCode === item.product.productNumber &&
                        colorCode === item.product.colorCode,
                    )

                    const hasBeenAdded = !!productsAddedToCartFiltered.find(
                      ({ modelCode, colorCode }) =>
                        modelCode === item.product.productNumber &&
                        colorCode === item.product.colorCode,
                    )

                    const showAlert =
                      (isInCart && selectedBoard.showItemInCart) || (hasBeenAdded && showBadgeTemp)
                    return (
                      <DraggableItem
                        boardSettings={selectedBoard}
                        itemSettings={item.settings}
                        key={item.id}
                        item={item}
                        updatePosition={updatePosition}
                        reference={itemsWithRef.current[item.id]}
                        showAlert={showAlert}
                        whiteboardIsLocked={!whiteboardOwner && whiteboardIsLocked}
                      />
                    )
                  })}

                {texts.map(text => (
                  <DraggableText
                    key={text.id}
                    text={text}
                    updatePosition={updateTextPosition}
                    reference={textsWithRef.current[text.id]}
                    whiteboardIsLocked={!whiteboardOwner && whiteboardIsLocked}
                  />
                ))}
                {images.map(image => (
                  <DraggableImage
                    key={image.id}
                    image={image}
                    updatePosition={updateImagesPosition}
                    reference={imagesWithRef.current[image.id]}
                    whiteboardIsLocked={!whiteboardOwner && whiteboardIsLocked}
                  />
                ))}
                {showImagePlaceholder && (
                  <ImagePlaceholder parentWidth={boardRef?.current?.clientWidth || 0}>
                    <img src={`${app_config.publicUrl}/assets/images/spinner.png`} alt="Loading" />
                  </ImagePlaceholder>
                )}
              </BoardContent>
            </Board>
            <Toolbar
              currentSnapshot={snapshotURL.current || unchangedProductsPreview || NO_BACKGROUND}
              whiteboardIsLocked={!whiteboardOwner && whiteboardIsLocked}
              isOwner={whiteboardOwner}
              getSnapshot={getSnapshot}
            />
          </BoardWrapper>
        </Main>
      </Wrapper>

      <ClickStopper className="click-stopper">
        <AfaFooter />
      </ClickStopper>

      {isSettingsModalOpen && (
        <SettingsModal onCancel={() => dispatch(whiteboardActions.setIsSettingsModalOpen(false))} />
      )}

      {isDeleteBoardModalOpen && (
        <DeleteBoardModal
          onCancel={() => dispatch(whiteboardActions.setIsDeleteBoardModalOpen(false))}
        />
      )}
    </PageWrapper>
  )
}

export default WhiteboardPage
