const getEnvVar = varName => {
  const vars = {
    // PUBLIC_URL: 'http://localhost:3000',
    REACT_APP_ENVIRONMENT_NAME: 'dev',
    REACT_APP_API_URL: 'http://localhost:5000/api/v1',
    REACT_APP_SOCKETIO_URL: 'http://localhost:5000',
    REACT_APP_ASSETS_URL: 'https://redcarpet-im.luxottica.com',
    REACT_APP_LOG_SERVER_URL: '',
    REACT_APP_LOGIN_URL: 'https://poctecluxrouter.azurewebsites.net/local/signin',
    REACT_APP_LOGOUT_URL: 'https://poctelux.b2clogin.com/poctelux.onmicrosoft.com',
    REACT_APP_COURTESY_TIMEOUT: '300000',
    REACT_APP_COURTESY_WAIT_TIMEOUT: '1800000',
    REACT_APP_RELIC_ENABLED: 'false',
    REACT_APP_RTR_URL: 'https://staging-rtr-viewer.luxottica.com',
  }

  // ladleRunning is defined in
  // vite.config.js
  // it is true when ladle (storybook replacement) is running
  if (global.ladleRunning) {
    return vars[varName]
  } else {
    return process.env[varName]
  }
}

const app_config = {
  env:
    (getEnvVar('REACT_APP_ENVIRONMENT_NAME') &&
      getEnvVar('REACT_APP_ENVIRONMENT_NAME').toLowerCase()) ||
    'lcl',
  socketIoUrl: getEnvVar('REACT_APP_SOCKETIO_URL'),
  socketIoEnabled: true,
  authKey: 'rc-intersession-auth-token',
  apiUrl: getEnvVar('REACT_APP_API_URL'),
  apiAddressUrl: getEnvVar('REACT_APP_API_ADDRESS_URL'),
  analyticsApiUrl: getEnvVar('REACT_APP_ANALYTICS_API_URL'),
  assetsUrl: getEnvVar('REACT_APP_ASSETS_URL'),
  publicUrl: getEnvVar('PUBLIC_URL'),
  loginUrl: getEnvVar('REACT_APP_LOGIN_URL'),
  logoutUrl: getEnvVar('REACT_APP_LOGOUT_URL'),
  RTRBasePath: getEnvVar('REACT_APP_RTR_URL'),
  googleMapsApiKey: getEnvVar('REACT_APP_GOOGLE_MAPS_API_KEY'),
  googleMapId: getEnvVar('REACT_APP_GOOGLE_MAP_ID'),
  sunCategory: '2',
  opticalCategory: '1',
  electronicsSunCategory: 'K',
  electronicsOpticalCategory: 'E',
  electronicsCategory: 'K,E',
  langDefault: 'en-US',
  vtoLangDefault: 'en-US',
  languages: [
    { name: 'English', code: 'en-US' },
    { name: 'Français', code: 'fr-FR' },
    { name: 'Espanol', code: 'es-ES' },
    { name: 'Italiano', code: 'it-IT' },
    { name: 'Portuguese', code: 'pt-PT' },
    { name: 'Portuguese (Brazil)', code: 'pt-BR' },
    { name: 'Russian', code: 'ru-RU' },
    { name: 'Turkish', code: 'tr-TR' },
    { name: 'Chinese', code: 'zh-CN' },
    { name: 'Japanese', code: 'ja-JP' },
    { name: 'Deutsch', code: 'de-DE' },
  ],
  digitalPreviewBrands: [
    'giorgio-armani',
    'burberry',
    'dolce-gabbana',
    'emporio-armani',
    'coach',
    'michael-kors',
    'miu-miu',
    'tiffany',
    'tory-burch',
    'valentino',
    'versace',
  ],
  digitalPreviewVisible: {
    chooseBrandPage: false,
    singleBrandPage: false,
    filtersOption: false,
  },
  materialAvailableFor: ['0AR6088', '0BE4290', '0VA4063'],
  vtoVideosId: {
    '1': [
      // man
      'western-south-square-male-1',
      'western-south-oval-male-1',
      'western-south-round-male-1',
      'western-south-triangle-male-1',
      'western-north-oval-male-1',
      'indian-square-male-1',
      'afro-american-oval-male-1',
      'japanese-square-male-1',
      'chinese-oval-male-1',
      'chinese-square-male-1',
    ],
    '2': [
      // woman
      'western-south-square-female-1',
      'western-south-oval-female-1',
      'western-south-round-female-1',
      'western-south-triangle-female-1',
      'western-north-square-female-1',
      'indian-oval-female-1',
      'afro-american-oval-female-1',
      'japanese-triangle-female-1',
      'japanese-triangle-female-2',
      'chinese-round-female-1',
      'chinese-square-female-1',
    ],
    '5': [
      // unisex
      'western-south-square-male-1',
      'western-south-square-female-1',
      'western-south-oval-male-1',
      'western-south-oval-female-1',
      'western-south-round-male-1',
      'western-south-round-female-1',
      'western-south-triangle-male-1',
      'western-south-triangle-female-1',
      'western-north-oval-male-1',
      'western-north-square-female-1',
      'indian-square-male-1',
      'indian-oval-female-1',
      'afro-american-oval-male-1',
      'afro-american-oval-female-1',
      'japanese-square-male-1',
      'japanese-triangle-female-1',
      'chinese-oval-male-1',
      'japanese-triangle-female-2',
      'chinese-square-male-1',
      'chinese-square-female-1',
      'chinese-round-female-1',
    ],
    asianunisex: [
      'japanese-square-male-1',
      'japanese-triangle-female-1',
      'chinese-oval-male-1',
      'japanese-triangle-female-2',
      'chinese-square-male-1',
      'chinese-square-female-1',
      'chinese-round-female-1',
    ],
    asianman: ['japanese-square-male-1', 'chinese-oval-male-1', 'chinese-square-male-1'],
    asianwoman: [
      'japanese-triangle-female-1',
      'japanese-triangle-female-2',
      'chinese-square-female-1',
      'chinese-round-female-1',
    ],
    junior: [
      'junior-western-north-square-female-1',
      'junior-western-north-round-male-1',
      'junior-western-south-square-female-1',
      'junior-western-south-oval-male-1',
      'junior-afro-oval-female-1',
      'junior-afro-triangle-male-1',
      'junior-asian-round-female-1',
      'junior-asian-round-male-1',
    ],
  },
  viewType: {
    table: 'tableView',
    wall: 'wallView',
    leds: 'leds',
  },
  cartType: {
    cart: 'cart',
    assortments: 'assortments',
  },
  errorTypes: {
    SEARCH_NOT_ENOUGH_CHARACTERS: 'ModalProductSearch.not_enough_characters',
    SEARCH_FAILED: 'ModalProductSearch.server_error_message',
    SEARCH_DISPLAYING_SIMILAR_SIZE_OR_COLOR: 'ModalProductSearch.displaying_similar_size_or_color',
  },
  roomType: {
    SMALL: 'standard',
    MEDIUM: 'medium',
    LARGE: 'large',
    FULL: 'extra-large',
    IPAD_ONLY: 'ipad-only',
  },
  search: {
    minCharactersCount: 4,
  },
  checkout: {
    storeId: 30001,
    length: 200,
    pageNumber: 0,
  },
  plpPageSize: 10,
}

export default app_config
