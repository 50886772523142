import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useSearchParams } from '../../hooks/useSearchParams'
import { AfaFacet } from '../../model/afa'
import AfaFilterChip from './AfaFilterChip'
import { getAfaFilterTranslation } from './AfaFiltersLayer'
import { breakpoints, palette, pxToRem } from '../../style/theme'
import { useGetReleaseNameFormCmsAfaTag } from '../../hooks/useGetReleaseNameFromCmsAfaTag'

const Wrapper = styled.div`
  border-top: solid 1px ${palette.cornflowerBlue};
  display: flex;
  gap: 1rem;
  border-bottom: solid 1px ${palette.cornflowerBlue};
  padding: ${pxToRem(32)}rem 0;

  @media (min-width: ${breakpoints.L}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

type Props = {
  facets: AfaFacet[]
}

const AfaFiltersChips: React.FC<Props> = ({ facets }) => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const hasKeyLookOn = searchParams.get('hasKeylooks') === 'true'
  const advOn = searchParams.get('adv') === 'X'

  const release = useGetReleaseNameFormCmsAfaTag()
  const releaseFilterOn = release && searchParams.get('release') === release

  const facetNameMap = {
    _keylook_facet_: {
      active: hasKeyLookOn,
      paramName: 'hasKeylooks',
    },
    adv: {
      active: advOn,
      paramName: 'adv',
    },
    [release]: {
      active: releaseFilterOn,
      paramName: 'release',
    },
  } as Record<string, { active: boolean; paramName: string }>

  return (
    <Wrapper>
      {facets.map(facet => {
        const active = facetNameMap[facet.name]?.active

        return (
          <AfaFilterChip
            disabled={facet.options.length === 0 && !active}
            key={facet.name}
            name={facet.name}
            active={active}
            onChange={() => {
              const paramName = facetNameMap[facet.name]?.paramName
              if (!paramName) {
                return
              }

              if (active) {
                searchParams.delete(paramName)
              } else {
                switch (paramName) {
                  case 'adv':
                    searchParams.set(paramName, 'X')
                    break
                  case 'release':
                    searchParams.set(paramName, release)
                    break
                  default:
                    searchParams.set(paramName, 'true')
                    break
                }
              }

              setSearchParams(searchParams, { replace: true })
            }}
          >
            {getAfaFilterTranslation(t, `Afa.fC.${facet.name}`, facet.name)}
          </AfaFilterChip>
        )
      })}
    </Wrapper>
  )
}

export default AfaFiltersChips
